@charset "UTF-8";


.icon-bell{
  position: relative;
  width: 50px;
  height: 30px;
  display: inline-block;

  &::before{
    display: block; 
    content: " ";
    background: url("../img/notif_icon_2x.png") no-repeat center center;
    width: 21px;
    height: 20px;
    background-size: 21px 20px;
    position: absolute;
    transition: all 0.4s ease;
    left: 10px;
    top: 11px;
  }
  > span{
    background-color: $pink-base;
    font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
    border-radius: 10px;
    width: 21px;
    height: 20px;
    color: #ffffff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 22px;
    top: 11px;
  }
}

.containerMenu{
  position: fixed;
  top: 0;
  right: -800px;
  height: 100vh;
  width: 390px;
  z-index: 10002;
  box-shadow: 0 0 10px rgba(0,0,0,0.4);
  overflow-y:auto;
  transition: all 0.6s ease;

  &::before{

      content: " ";
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 100vw;
      z-index: 990;
    transition: all 0.4s ease;
  }
  &.show-notif{
    right: -10px;

    &::before{
      content: " ";
      background: rgba(0,0,0,0.4);
      left: 0;
    }
  }

}

.notif-wrap a {
  color: #b8b7ad;
}

.notif-wrap a:hover,
.notif-wrap a:focus {
  color: #c94e50;
}

.content-wrap {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.content {
  position: relative;
  background: #b4bad2;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    content: '';
    opacity: 0;
   -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
    -webkit-transition: opacity 0.4s, -webkit-transform 0s 0.4s;
    transition: opacity 0.4s, transform 0s 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
  }
}

/* Menu Button */
.menu-button {
  position: fixed;
  z-index: 1000;
  left: 50vw;
  top: 20px;
  margin: 1em;
  padding: 0;
  width: 2.5em;
  height: 2.25em;
  border: none;
  text-indent: 2.5em;
  font-size: 1.5em;
  color: transparent;
  background: transparent;

  &::before {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    bottom: 0.5em;
    left: 0.5em;
    background: linear-gradient(#373a47 20%, transparent 20%, transparent 40%, #373a47 40%, #373a47 60%, transparent 60%, transparent 80%, #373a47 80%);
    content: '';
  }

  &:hover {
    opacity: 0.6;
  }

}

/* Close Button */
.close-button {
  width: 1em;
  height: 1em;
  position: absolute;
  right: 1em;
  top: 1em;
  overflow: hidden;
  text-indent: 1em;
  font-size: 0.75em;
  border: none;
  background: transparent;
  color: transparent;


  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0;
    left: 50%;
    background: #bdc3c7;
  }

  &::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}


/* Menu */
.notif-wrap {
  position: absolute;
  z-index: 1001;
  max-width: 380px;

  width: 100%;
  height: 100%;
  background: $white;
  padding: 40px 0 0 0;
  font-size: 1.15em;
 /* -webkit-transform: translate3d(-320px,0,0);
  transform: translate3d(-320px,0,0);
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
  transition-timing-function: cubic-bezier(0.7,0,0.3,1);*/

}
body .notif-wrap{
  header{
    position: absolute;
    top: 0;
    left: 35px;
    color: $grey-litige;
    font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    padding: 0;
    width: 180px;
  }
}

.notifications{

  height: 100vh;
}
.notif-list {
  height: calc(100vh - 40px);
  overflow-y: scroll;
}
body{
  overflow: auto;
  &.lock{
    height: 100vh;
    overflow: hidden;
  }
}

.notif-list {

  background-color: $white;
  /*-webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);

  -webkit-transition: -webkit-transform 0s 0.4s;
  transition: transform 0s 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
  transition-timing-function: cubic-bezier(0.7,0,0.3,1);*/

  > div.section{
    display: block;
    -webkit-transform: translate3d(0,100vh,0);
    transform: translate3d(0,100vh,0);
    -webkit-transition: -webkit-transform 0s 0.4s;
    transition: transform 0s 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);

    transition: all 0.4s cubic-bezier(0.7,0,0.3,1) 0.55s;
    background: $white;
     padding: 20px 40px;
    h4{
      color: $grey-light;
      text-align: left;
    }
     &.action-requise{
       background-color: $grey-really-lighter;
     }

     &:nth-child(2) {
       -webkit-transform: translate3d(0,150vh,0);
       transform: translate3d(0,150vh,0);

      transition: all 0.4s cubic-bezier(0.7,0,0.3,1) 0.6s;
     }

     &:nth-child(3) {
       -webkit-transform: translate3d(0,200vh,0);
       transform: translate3d(0,200vh,0);

    transition: all 0.4s cubic-bezier(0.7,0,0.3,1) 0.65s;
     }

     &:nth-child(4) {
       -webkit-transform: translate3d(0,250vh,0);
       transform: translate3d(0,250vh,0);

    transition: all 0.4s cubic-bezier(0.7,0,0.3,1) 0.7s;
     }

     &:nth-child(5) {
       -webkit-transform: translate3d(0,300vh,0);
       transform: translate3d(0,300vh,0);

      transition: all 0.4s cubic-bezier(0.7,0,0.3,1) 0.75s;
     }
      span {
       margin-left: 10px;
     }


     h4{
       color: $grey-blue-light3;
       font-size: 17px;
       margin: 0px 0 25px 0;
       font-family: 'Bentonsans-book', Arial, Helvetica, sans-serif;

     }
  }
}

.show-notif{
  .notif-list {
    > div.section,
    > div.section:nth-child(2),
    > div.section:nth-child(3),
    > div.section:nth-child(4) {
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }
  }
}

.notif-container{
  text-align: left;

  &:not(:last-of-type) .notif .details{
    border-bottom: 1px solid $grey-lighter;
    margin-bottom: 15px;
  }

  .notif{
    display: flex;
    align-items: flex-start;




    .icon{
      flex-basis: 50px;
      height: 50px;
      background-color: $grey-lighter;
      color: $white;
      margin-right: 10px;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .details{
      flex-basis: calc(100% - 50px);
      padding-bottom: 15px;
      margin-bottom: 30px;
      padding-top: 15px;


      span.date{
        font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
        font-size: 11px;
        color: $grey-light;
        display: block;
        margin: 0;
      }
      h3{
        font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
        font-size: 18px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $grey-light;
      }
      p{
        color: $grey-litige;
        font-size: 13px;
        line-height: 18px;
        font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
        padding-bottom: 10px;
      }

      & + .hc-block-news-more {
        display: flex;
        position: absolute;
        bottom: 25px;
        right: 0;
        background: $white;
        height: 20px;
        width: 34px;
        border: 1px solid $grey-light;
        color: $grey-light;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease;

      }

      & + .hc-block-footerlink {
        display: flex;
        position: absolute;
        bottom: 25px;
        right: 0;

      }
    }


    .dot{
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: $pink-base;
      position: absolute;
      right: 0;
      top: 30px;
    }

    &:hover, &:active, &:focus{


      .details + .hc-block-news-more {
        color: $white;
        border-color: $white;
      }
      .details + .hc-block-footerlink::before {
        opacity: 1;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);

      }
    }

  }

    .read {
       .details p, .details h3 {
           color: #a7afb5 !important;
       }
        .dot, .icon {
            background-color: #a7afb5 !important;
        }
    }

  /*---------- Read variations -----------------*/
  &.read{
    
    .details{
      h3, p{
        color:$grey-light;
      }
    }

    .dot{
      background-color: $grey-light;
    }
  }
  /*---------- Color variations -----------------*/
  &.pink{
    .icon{
      background-color: $pink-base;
    }
    .details{
      h3{
        color: $pink-base;
      }
    }
    &:hover, &:active, &:focus{

      .details + .hc-block-news-more {
        background-color: $pink-base;
      }
    }
  }

  &.userprofile{
    .icon{
      background-color: $orange-base;
    }
    .details{
      h3{
       color: $orange-base;
      }
    }

    &:hover, &:active, &:focus{

      .details + .hc-block-news-more {
        background-color: $orange-base;
      }
    }
  }

  &.medbottle{
    .icon{
      background-color: $purple-base;
    }
    .details{
      h3{
        color: $purple-base;
      }
    }

    &:hover, &:active, &:focus{

      .details + .hc-block-news-more {
        background-color: $purple-base;
      }
    }
  }

  &.contrats, &.graph{
    .icon{
      background-color: $blue-base;
    }
    .details{
      h3{
        color: $blue-base;
      }
    }

    &:hover, &:active, &:focus{

      .details + .hc-block-news-more {
        background-color: $blue-base;
      }
    }
  }

  &.actus2{
    .icon{
      background-color: $blue-base2;
    }
    .details{
      h3{
        color: $blue-base2;
      }

    }

    &:hover, &:active, &:focus{

      .details + .hc-block-news-more {
        background-color: $blue-base2;
      }
    }
  }

}

/* Shown menu */
.show-notif{
  .notif-wrap {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    
  }
     .notif-list,
     .notif-list a {
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
      -webkit-transition: -webkit-transform 0.8s;
      transition: transform 0.8s;
      -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
      transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    }

     .notif-list a {
      -webkit-transition-duration: 0.9s;
      transition-duration: 0.9s;
    }

     .content::before {
      opacity: 1;
      -webkit-transition: opacity 0.8s;
      transition: opacity 0.8s;
      -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
      transition-timing-function: cubic-bezier(0.7,0,0.3,1);
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }

}
