.headband {
    &--container {
        padding: 30px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &--item-left {
        display: flex;
        align-items: center;
        flex: 2;
    }

    &--item-right {
        flex: 1;
    }

    &--icon {
        font-size: 10rem;
        color: white;
    }

    &--content {
        margin-left: 20px;
        color: white;
        padding: 10px;

        &--title {
            display: block;
            margin: 0 0 10px 0;
            font-size: 28px;
        }

        &--subtitle {
            display: block;
            font-size: 16px;
            line-height: 1.5em;
        }
    }

    &--pmp-block {
        &-container {
            display: flex;
        }

        &-left {
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 105px;
            border-color: #e6e9ed;
        }

        &-right {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-left: 15px;
            height: 105px;
            border-color: #e6e9ed;
        }

        &-icon {
            font-size: 4rem;
            color: white;
        }

        &-col {
            display: flex;
            flex-direction: column;
        }

        &-title {
            display: table-cell;
            font: normal 22px/28px bentonsans-ultracondensed, sans-serif;
            text-transform: uppercase;

            span {
                color: white;
            }
        }

        &-subtile {
            color: white !important;
            margin-top: 15px !important;
        }
    }
}

@media screen and (max-width: 1000px) {
    .headband {
        &--pmp-block {
            &-container {
                display: flex;
                flex-wrap: wrap;
                max-width: 180px;
                float: right;
            }

            &-right {
                margin-left: 0px;
            }
        }
    }
}

/*
* Responsive mobile
**/
@media screen and (max-width: 480px) {
    .headband--container {
        flex-direction: column;
    }
}
