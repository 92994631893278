.invoice-promotional-block {
    height: 100%;
    border-radius: 8px;
    background-image: url("../img/promotional-block/my-invoices.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'bentonsans-medium', sans-serif;
    color: #F4F4F4;

    &-text {
        height: 100%;
        margin-left: 31px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-btn {
            display: inline-block;
            width: 100px;
            margin-top: 5px;
            background-color: #0F52BA;
            border-radius: 25px;
            padding: 10px 10px;
        }
    }
}
