.image2 {
    &-align-left {
        float: left;
    }

    &-align-right {
        float: right;
    }

    &-align-center {
        text-align: center;
    }

    &-align-center > img {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
    }
}
