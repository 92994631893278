@charset "UTF-8";

.main_menu {
    @include transition-ease-in-out();
    position: fixed;
    z-index: 10001;
    top: 0;
    height: 100vh;

    ul {
        list-style-type: none;
        margin: 0;

        li {
            a {
                color: $white;
            }
        }
    }
}

.mm-link-txt:before {
    @include transition-ease-in-out();
    @include opacity(0);
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 12px;
    border-color: transparent;
}

.mm-root {
    li {
        margin: 0;
        @include hover-focus-active {
            .mm-link-txt:before {
                @include opacity(1);
                right: -19px;
            }
        }

        &.current {
            .mm-link-txt:before {
                @include opacity(1);
                right: -19px;
            }
        }
    }
}

.mm-toggle {
    display: none;
}

.mm-overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

}

.mm-root-link {
    display: table;
    padding: 10px 7px 10px 7px;
    height: 19.8vh;
    width: $menuWidth;
    min-width: 67px;
}

.mm-root-link-inside {
    @include transition-ease-in-out();
    display: table-cell;
    vertical-align: bottom;
    position: relative;
    font-size: 16px;

    span {
        display: block;
        font: normal 17px/1em bentonsans-ultracondensed, sans-serif;
        font-stretch: ultra-condensed;
        text-transform: uppercase;
        color: $white;
    }

    [class^="ic-"], [class*=" ic-"] {
        position: absolute;
        right: 0;
        top: -5px;
        color: $white;
    }

    @include hover-focus-active {
        text-decoration: none;
    }
}

.mm-ssmenu {
    @include transition-ease-in-out(0.4s);
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(#{$menuWidth} - 1px);
    width: 0;
    overflow: hidden;
    padding: 0;
    z-index: 2;

    li {
        margin: 0 0 20px 0;
    }

    li > a:hover {
        color: red;
    }

    span {
        font-size: 16px;
        color: white;

        &.mm-ssmenu-close {
            font-size: 30px;
        }
    }


    .submenu {
        a {
            margin-top: 6px;
            padding-left: calc(10vh + 20px);
        }
    }

    .menu_level_1 {
        span {
            cursor: pointer;
            @media (min-width: 1200px) {
                padding-left: 10vh;
            }

            i {
                display: inline-block;
                vertical-align: middle;
                margin-left: 6px;
            }
        }
    }

    a {
        @media (min-width: 1200px) {
            padding-left: 10vh;
        }
        display: block;
        font-size: 16px;

        [class^="ic-"], [class*=" ic-"] {
            display: inline-block;
            vertical-align: middle;
            float: right;
            font-size: 25px;
        }

    }

    .menu_level_1 {
        overflow-x: auto;
    }
}

.mm-ssmenu span.mm-ssmenu-close {
    display: block;
    position: absolute;
    color: $white;
    right: 20px;
    top: 15px;
    cursor: pointer;
}

.mm-ssmenu-inside {
    padding: 10vh 0px 10vh 0px;
    width: 20vw;
}

.menu_level_1 {
    a {
        display: table;
        width: 100%;

        [class^="ic-"], [class*=" ic-"],
        .mm-link-txt {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.mm-ssmenu-inside-header {
    font-size: 30px;
    color: lighten($pink-base, 25%);
    margin: 0 0 90px 10vh;

    [class^="ic-"], [class*=" ic-"] {
        margin-left: -20px;
        position: static;
        font-size: 65px;
        color: $white;
    }

    span {
        display: block;
        font: normal 35px/1em bentonsans-ultracondensed, sans-serif;
        text-transform: uppercase;
        font-size: 35px;
        color: $white;
    }
}

.mm-ssmenu-inside {
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

.mm-accueil {
    .mm-link-txt:before {
        border-left-color: $orange-base !important;
    }

    .mm-root-link {
        background-color: $orange-base;

        [class^="ic-"], [class*=" ic-"] {
            font-size: 28px;
        }
    }

    .mm-ssmenu-inside-header {
        color: lighten($orange-base, 35%);
    }

    .mm-ssmenu {
        background-color: $orange-base;

        a {
            @include hover-focus-active {
                color: lighten($orange-base, 35%);
            }
        }
    }
}

.mm-catalogue {
    .mm-link-txt:before {
        border-left-color: $purple-base !important;
    }

    .mm-root-link {
        background-color: $purple-base;
        color: lighten($purple-base, 35%);

        [class^="ic-"], [class*=" ic-"] {
            font-size: 35px;
        }
    }

    .mm-ssmenu-inside-header {
        color: lighten($purple-base, 35%);
    }

    .mm-ssmenu {
        background-color: $purple-base;

        a {
            @include hover-focus-active {
                color: lighten($purple-base, 35%);
            }
        }
    }
}

.mm-formations {
    .mm-link-txt:before {
        border-left-color: $blue-base2 !important;
    }

    .mm-root-link {
        background-color: $blue-base2;
        color: lighten($blue-base2, 25%);

        [class^="ic-"], [class*=" ic-"] {
            font-size: 30px;
        }
    }

    .mm-ssmenu-inside-header {
        color: lighten($blue-base2, 35%);
    }

    .mm-ssmenu {
        background-color: $blue-base2;

        a {
            @include hover-focus-active {
                color: lighten($blue-base2, 35%);
            }
        }
    }
}

.mm-gestion_officine {
    .mm-link-txt:before {
        border-left-color: $blue-base !important;
    }

    .mm-root-link {
        background-color: $blue-base;
        color: lighten($blue-base, 35%);

        [class^="ic-"], [class*=" ic-"] {
            font-size: 35px;
        }
    }

    .mm-ssmenu-inside-header {
        color: lighten($blue-base, 35%);
    }

    .mm-ssmenu {
        background-color: $blue-base;

        a {
            @include hover-focus-active {
                background-color: #09677B;
                font-weight: bold;
                color: lighten($blue-base, 35%);
            }
        }
    }
}

.mm-services_patients {
    .mm-link-txt:before {
        border-left-color: $green-base !important;
    }

    .mm-root-link {
        background-color: $green-base;
        color: lighten($green-base, 35%);

        [class^="ic-"], [class*=" ic-"] {
            font-size: 30px;
        }
    }

    .mm-ssmenu-inside-header {
        color: lighten($green-base, 35%);;
    }

    .mm-ssmenu {
        background-color: $green-base;

        a {
            @include hover-focus-active {
                color: lighten($green-base, 35%);
            }
        }
    }
}

/* Animations sous menu */
.mm-root-link-inside.fadeOut {
    opacity: 0;
}

.mm-ssmenu.mm-ssmenu-open {
    width: 22vw;
}

/* Media queries */
@media screen and (max-width: 1300px) {
    .mm-root-link-inside {
        font-size: 16px;

        span {
            font-size: 15px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .mm-root-link-inside {
        font-size: 15px;

        span {
            font-size: 15px;
        }
    }
}

@media screen and (max-width: 767px) {

    .submenu {
        a {
            margin-top: 2px;
            padding-left: 5px;
        }
    }

    .main_menu {
        @include transition-ease-in-out();
        left: -310px;
    }
    .mm-root {
        position: absolute;
        top: 0;
        padding: 16vh 0 0 0;
        background-color: $white;
    }
    .main_menu.mm-open {
        left: 0;
        width: 310px;
        overflow-x: auto;
        background-color: $white;
    }
    .mm-toggle {
        display: table;
        table-layout: fixed;
        position: absolute;
        z-index: 1;
        height: 16vh;
        width: 310px;
        background-color: $white;

        div {
            display: table-cell;
            vertical-align: middle;
        }

        .mm-toggle-ic {
            width: 70px;
            text-align: center;
            font-size: 30px;
            color: $black;
            cursor: pointer;
        }

        .mm-toggle-txt {
            color: $black;
            font-size: 36px;
        }
    }
    .mm-root li.current .mm-link-txt:before {
        display: none;
    }
    .mm-root-link {
        color: $grey-blue !important;
        table-layout: fixed;
        padding: 0;
        width: 310px;
        min-width: 0;
        height: 14vh;

        .mm-root-link-inside {
            display: table-row;
        }

        [class^="ic-"], [class*=" ic-"] {
            position: static;
            display: table-cell;
            vertical-align: middle;
            width: 70px;
            color: $white;
            padding: 10px;
            font-size: 45px !important;
        }

        .mm-link-txt {
            background-color: $white;
            display: table-cell;
            vertical-align: middle;
            padding: 10px 10px 10px 30px;
            font-size: 17px;

            span {
                display: inline;
                font: normal 17px/1em bentonsans-book, sans-serif;
                text-transform: none;
                color: $black;
            }
        }
    }

    .mm-accueil {
        [class^="ic-"], [class*=" ic-"] {
            background-color: $orange-base;
        }

        .mm-root-link {
            @include hover-focus-active {
                background-color: $orange-base;
            }
        }
    }
    .mm-catalogue {
        [class^="ic-"], [class*=" ic-"] {
            background-color: $purple-base;
        }

        .mm-root-link {
            @include hover-focus-active {
                background-color: $purple-base;
            }
        }
    }
    .mm-formations {
        [class^="ic-"], [class*=" ic-"] {
            background-color: $blue-base2;
        }

        .mm-root-link {
            @include hover-focus-active {
                background-color: $blue-base2;
            }
        }
    }
    .mm-gestion_officine {
        [class^="ic-"], [class*=" ic-"] {
            background-color: $blue-base;
        }

        .mm-root-link {
            @include hover-focus-active {
                background-color: $blue-base;
            }
        }
    }
    .mm-services_patients {
        [class^="ic-"], [class*=" ic-"] {
            background-color: $pink-base;
        }

        .mm-root-link {
            @include hover-focus-active {
                background-color: $pink-base;
            }
        }
    }
    /* Sous menu */
    .mm-ssmenu {
        left: 70px;

        li {
            margin: 0 0 15px 0;
        }
    }
    .mm-ssmenu-inside, .mm-ssmenu.mm-ssmenu-open {
        width: 240px;
        z-index: 2;
    }
    .mm-ssmenu-inside-header {
        font-size: 23px;
        margin: 0 0 20px 0;

        [class^="ic-"], [class*=" ic-"] {
            margin-left: 0;
            font-size: 40px;
        }

        span {
            font-size: 30px;
        }
    }
    .mm-root-link-inside.fadeOut {
        opacity: 1;

        [class^="ic-"], [class*=" ic-"] {
            @include transition-ease-in-out();
            opacity: 0;
        }
    }
    /* Sous menu */
    .mm-link-txt:before {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .mm-ssmenu.mm-ssmenu-open {
        width: 35vw;
    }
    .mm-ssmenu-close {
        right: 15px;
        top: 10px;
        font-size: 22px;
    }

    .mm-ssmenu-inside-header [class^="ic-"], .mm-ssmenu-inside-header [class*=" ic-"] {
        font-size: 47px;
        margin: 0 0 30px 0;
    }

    .mm-ssmenu-inside-header {
        font-size: 30px;
        margin: 0 0 35px 0;

        span {
            font-size: 30px;
        }
    }

    .mm-ssmenu-inside {
        padding: 5vh 4vw 5vh 4vw;
        width: 35vw;
    }

    .mm-ssmenu a [class^="ic-"], .mm-ssmenu a [class*=" ic-"] {
        font-size: 22px;
    }

    .mm-ssmenu {
        li {
            margin: 0 0 30px 0
        }
    }
}

/*
* Responsive mobile
**/
@media screen and (max-width: 480px) {
    .mm-ssmenu a, .mm-ssmenu .menu_level_1 span {
        padding-left: 5vh !important;
    }
}
