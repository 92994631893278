.dispo-max {
    padding: 4% 21%;

    &-content {
        border: 1px solid #BCCED6;
        background: #ffffff;
        padding: 0 10%;
    }

    &-img {
        margin: 30px 0;

        img {
            margin: auto;
        }
    }

    &-title {
        font-size: 20px;
        font-family: bentonsans-bold, sans-serif;
        color: #CB2F7B;
        margin-top: 36px;

        &-center {
            text-align: center;
        }

        &-marge {
            padding: 0 1px 0px 10px;
        }
    }

    &-text {
        margin: 22px 0;
        font-size: 14px;
        font-family: bentonsans-regular, sans-serif;
        color: #004C97;

        &-paragraph {
            margin-bottom: 10px;

            p {
                margin: 10px 0 0 0;
            }

            &-text-suivi-commande {
                text-align: center;
                margin: unset;
            }

            &-btn-suivi-commande {
                text-align: center;
                margin: unset !important;
            }
        }

        .text-bold {
            font-family: bentonsans-bold, sans-serif;
        }

        .link-suivi-commande {
            text-decoration: underline;
            color: #00A1E4;

            .center {
                text-align: center;
            }
        }

    }

    &-subscribed {
        display: flex;
        align-items: center;
        margin: 23px auto;
        padding: 10px;
        color: #ffffff;

        &-left {
            i {
                font-size: 43px;
                padding: 25px;
            }

        }

        &-right {
            &-officine {
                font-size: 14px;
                font-family: bentonsans-regular, sans-serif;
                border-bottom: 1px solid #ffffff;
                padding-bottom: 10px;
            }

            &-date {
                margin-top: 12px;
                font-size: 18px;
                font-family: bentonsans-bold, sans-serif;
            }

            &-previous {
                font-family: bentonsans-regular, sans-serif;
                font-size: 12px;
                opacity: 60%;
            }

            &-gc {
                display: flex;
                margin-top: 20px;

                &-cts, &-ugc {
                    margin-right: 20px;
                    font-family: bentonsans-regular, sans-serif;
                    font-size: 12px;
                    cursor: pointer;
                    text-decoration: underline;

                    i {
                        margin-right: 8px;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &-subscribed.dm {
        background: #00A1E4;
    }

    &-subscribed.dmplus {
        background: #004C97;
    }

    &-details {
        display: flex;

        &-livraisons, &-lgo {
            width: 50%;
            color: #004C97;

            &-head {
                display: flex;
                align-items: center;

                &-icon {
                    font-size: 28px;
                    color: #004C97;
                    margin-right: 10px;
                }

                &-title {
                    font-family: bentonsans-bold, sans-serif;
                    font-size: 16px;
                }

                position: relative;
                z-index: 1;
            }

            &-head:before {
                content: "";
                position: absolute;
                left: 0;
                top: 25px;
                height: 1px;
                width: 90%; // percentage of the element width
                border-bottom: 1px solid #004C97;
                margin-top: 1.5em; //move the border below the text
            }
        }
    }

    &-container {
        display: flex;
        margin-top: 25px;
        color: #004C97;
        font-family: bentonsans-regular, sans-serif;

        &-dates {
            width: 43%;
            overflow: auto;
            height: 180px;

            p {
                font-size: 14px;
                margin: 0 0 10px 0px;

                i {
                    margin-right: 5px;
                }
            }
        }

        &-dates::-webkit-scrollbar {
            width: 5px;
            height: 18px;
            background-color: #aaa; // or add it to the track
        }

        // Add a thumb
        &-dates::-webkit-scrollbar-thumb {
            background: #004C97;
            width: 10px;
        }

        &-infos {
            margin-left: 7%;
            width: 50%;

            &-texte {
                font-size: 14px;
                margin-bottom: 20px;
            }

            &-btns {
                &-title {
                    font-family: bentonsans-bold, sans-serif;
                    font-size: 14px;
                    margin-bottom: 15px;
                }

                &-buttons {
                    display: flex;

                    a {
                        button {
                            margin-right: 10px;
                            width: 125px;
                            padding: 10px 10px;
                            justify-content: space-between;
                            align-items: center;
                            background: #004C97;
                            font-size: 14px;
                            font-family: bentonsans-bold, sans-serif;
                            color: #ffffff;
                            text-transform: uppercase;

                            i {
                                margin-left: 10px;
                            }
                        }
                    }

                }
            }
        }
    }

    &-asterisk {
        margin: 34px auto 0 auto;
        padding: 0 2%;
        font-size: 12px;
        font-family: 'bentonsans-regular', sans-serif;
        color: #004C97;
        text-align: center;
    }

    &-subscribed.dispo-max-plus-page {
        background: #004C97;
    }

    &-unsubscribe {
        margin: 20px 0 10px 0;
        font-size: 12px;
        font-family: bentonsans-regular, sans-serif;
        text-align: center;
        color: #00A1E4;
        text-decoration: underline;
    }

    &-unsubscribe:hover {
        cursor: pointer;
    }

    &-subscription {
        width: 67%;
        margin: 0 auto;
        background: #00A1E4;
        padding: 21px 36px;

        &-subtitle {
            color: #004C97;
            font-size: 14px;
            font-family: bentonsans-bold, sans-serif;
        }

        &-subtitle.dispo-max-plus-page {
            color: #00A1E4;
        }

        &-text {
            margin: 10px 0;
            color: #ffffff;
            font-size: 12px;
            font-family: bentonsans-regular, sans-serif;
        }

        &-code {
            &-label {
                margin: 10px 0;
                display: flex;
                color: #ffffff;
                font-size: 14px;
                font-family: bentonsans-bold, sans-serif;

                .label-text {
                    margin: auto 0;
                }

                .label-icon > i {
                    margin-left: 7px;
                    color: #004C97;
                    font-size: 20px;
                }

                .label-icon.dispo-max-plus-page > i {
                    color: #00A1E4;
                }
            }

            &-input {
                .validation-code-form, .unsubscribe-validation-code-form {
                    &-bloc {
                        margin: 5px 0;
                        display: flex;

                        .selectric-wrapper {
                            display: block;

                            .selectric .label {
                                font-size: 13px;
                                font-family: bentonsans-regular, sans-serif;
                                font-weight: 700;
                                color: #00A1E4;
                            }

                            span {
                                color: #00A1E4;
                            }

                            .selectric-items li:hover, .selectric-items li.highlighted, .selectric-items li.selected {
                                color: #00A1E4;
                            }
                        }

                        .unsubscribe-reason-input {
                            border: none;
                            width: 100%;
                            padding: 10px;
                            color: #00A1E4;

                            &:focus-visible {
                                border: none;
                            }
                        }
                    }

                    .validation-code, .unsubscribe-validation-code {
                        &-label {
                            position: unset;
                            width: 100%;

                            .icheckbox {
                                position: unset;
                                width: 18px;
                                height: 18px;
                                border-radius: unset;
                                background-color: #ffffff;
                            }
                        }

                        &-label.label-code {
                            background-color: #ffffff;
                        }

                        &-label.label-condition {
                            width: unset;
                        }

                        $char-w: 1ch;
                        $gap: .5*$char-w;
                        $n-char: 8;
                        $in-w: $n-char*($char-w + $gap);

                        &-input {
                            display: block;
                            margin: 8px auto;
                            border: none;
                            padding: 0;
                            width: $in-w;
                            height: 30px;
                            background: repeating-linear-gradient(90deg,
                                #00A1E4 0, #00A1E4 $char-w,
                                transparent 0, transparent $char-w + $gap
                            ) 0 100%/ #{$in-w - $gap} 1px no-repeat;
                            color: #00A1E4;
                            font: 3ch droid sans mono, consolas, monospace;
                            letter-spacing: $gap;

                            &:focus {
                                outline: none;
                            }
                        }

                        &-cb {
                            &-text {
                                margin-left: 10px;
                                color: #ffffff;
                                text-decoration: underline;
                            }

                            &-text:hover {
                                cursor: pointer;
                            }
                        }

                        &-btn {
                            margin-top: 20px;
                            padding: 10px 19px;
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            background: #004C97;
                            font-size: 14px;
                            font-family: bentonsans-bold, sans-serif;
                            color: #ffffff;
                            text-transform: uppercase;

                            i {
                                color: #ffffff;
                            }
                        }

                        &-btn.dispo-max-plus-page {
                            background: #00A1E4;
                        }

                        &-btn[disabled] {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }

    &-subscription.dispo-max-plus-page {
        background: #004C97;
    }

    &-know-more {
        margin: 23px auto;
        font-size: 14px;
        font-family: bentonsans-bold, sans-serif;
        color: #004C97;
        text-align: center;

        span {
            color: #00A1E4;
        }

        &-left {
            text-align: left !important;
        }
    }

    &-know-more.delegue {
        margin: -15px !important;
    }

    &-asterix {
        margin: 40px 0 12px 0;
        font-size: 11px;
        font-family: bentonsans-regular, sans-serif;
        color: #004C97;
    }

    &-footer {
        padding: 36px 13%;
        background: #004C97;

        &-img {
            img {
                margin: auto;
            }
        }

        &-subtitle {
            margin: 42px 0 9px 0;
            font-size: 18px;
            font-family: bentonsans-bold, sans-serif;
            color: #FFFFFF;
        }

        &-text {
            font-size: 14px;
            font-family: bentonsans-regular, sans-serif;
            color: #00A1E4;
        }

        &-btn {
            margin-top: 22px;
            display: flex;
            justify-content: space-between;

            &-discover {
                padding: 10px 19px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #00A1E4;
                font-size: 14px;
                font-family: bentonsans-bold, sans-serif;
                color: #ffffff;
                text-transform: uppercase;

                i {
                    margin-left: 15px;
                    color: #ffffff;
                }
            }

            &-discover.dispo-max-plus-page {
                padding: 7px 17px;
                background: #004C97;

                i {
                    margin-left: unset;
                    margin-right: 10px;
                    color: #ffffff;
                    font-size: 20px;
                }
            }

            &-text {
                margin: auto 0;
                font-size: 11px;
                font-family: bentonsans-regular, sans-serif;
                color: #00A1E4;
            }
        }

        &-btn.dispo-max-plus-page {
            justify-content: center;
        }
    }

    &-footer.dispo-max-plus-page {
        background: #00A1E4;
    }

    &-popin {
        &-ugc, &-cts, &-unsubscribe {
            background-color: #ffffff;
            color: #485E68;
            font-family: bentonsans-regular, sans-serif;
            width: 600px;
        }

        &-unsubscribe, &-unsubscribe-confirmed {
            background-color: #00A1E4;
            width: 375px;
            padding-bottom: 10px;

            &-header {
                padding: 4px;
                font-size: 12px;
                text-align: end;
            }

            &-close {
                background-color: #00A1E4;
                color: #ffffff;
                font-size: 12px;
            }

            &-content {
                margin: 0 25px;

                &-title {
                    font-size: 14px;
                    color: #004C97;
                    font-family: bentonsans-bold;
                }

                &-subtitle {
                    color: #FFFFFF;
                    font-family: bentonsans-regular;
                    font-size: 12px;
                    opacity: 1;
                    letter-spacing: 0px;
                    margin-bottom: 5px;
                }

                &-codeLibelle {
                    color: #ffffff;
                    font-size: 14px;
                    font-family: bentonsans-bold, sans-serif;
                }

                &-codeValue {

                }

                &-title1 {
                    font-size: 12px;
                    color: #FFFFFF;
                    font-family: bentonsans-regular;
                    opacity: 1;
                    margin-bottom: 15px;
                }

                &-subtitle1 {
                    font-family: bentonsans-bold;
                    font-size: 14px;
                    color: #004C97;
                    opacity: 1;
                    margin-bottom: 10px;
                    text-align: center;
                }

                &-buttons {
                    display: flex;

                    &-validated {
                        background-color: #CB2F7B;
                    }

                    &-canceled {
                        background-color: #004C97;
                    }

                    &-validated, &-canceled {
                        margin-right: 10px;
                        padding: 10px 10px;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;
                        font-family: bentonsans-bold, sans-serif;
                        color: #ffffff;
                        text-transform: uppercase;

                        i {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }


        &-header {
            margin: 0 45px;
            padding: 45px 0 10px 0;
            font-size: 18px;
            font-family: bentonsans-bold, sans-serif;
            color: #00657B;
        }

        &-content {
            margin: 0 45px;
            font-size: 16px;
            font-family: bentonsans-regular, sans-serif;

            &-bloc {
                margin: 30px 0;

                &-title {
                    color: #064A89;
                    text-decoration: underline;
                    margin: 10px 0 5px 0;
                    font-size: 16px;
                }

                &-subtitle {
                    margin: 5px 0 5px 0;
                    font-size: 16px;

                    &-number {
                        color: #064A89;
                    }

                    &-lib {
                        color: #064A89;
                        text-decoration: underline;
                    }

                    &-text {
                        margin: 5px 0;
                    }

                    &-asterisk {
                        margin: 10px 0 5px 0;
                        font-size: 11px;
                    }
                }

                &-annexe {
                    &-title {
                        font-size: 16px;
                        text-align: center;
                        text-transform: uppercase;
                        color: #064A89;
                        margin: 10px 0 5px 0;
                    }

                    &-subtitle {
                        font-size: 16px;
                        color: #064A89;
                        margin: 5px 0 5px 0;
                    }

                    &-text {
                        font-size: 16px;
                        margin: 5px 0;
                    }
                }
            }
        }

        &-close {
            font-size: 14px;
            font-family: bentonsans-regular, sans-serif;
            background-color: #ffffff;
            color: #00657B;
        }

        &-btn {
            display: flex;
            justify-content: space-between;
            padding: 18px 45px;
            border-top: 1px solid #7070703D;

            &-accept {
                padding: 10px 20px;
                background-color: $blue-base;
                color: #ffffff;
                font-size: 14px;
                font-family: bentonsans-bold, sans-serif;
                text-transform: uppercase;
            }
        }

        &-header-and-texte {
            height: 425px;
            overflow: auto;
        }
    }
}
