@charset "UTF-8";

.pharmacien-commandes {
    background: #F4F4F4;

    .tooltipster-base {
        padding: 25px;
        background-color: #FFFFFF;
        border: 2px solid #2F485C;
        color: #464646;
        font-family: 'bentonsans-regular', sans-serif;

        .day {
            border-radius: 20px;
        }

        .date-calendar {
            padding: 5px;
            background-color: #F4F4F4;
            border-radius: 4px;
            margin-bottom: 17px;
            margin-top: 10px;
        }

        .btn-date {
            background: #2F485C 0% 0% no-repeat padding-box;
            border-radius: 4px;
            color: #FFFFFF;
            width: 79px;
            height: 33px;
            font-family: 'bentonsans-bold', sans-serif;
        }

        .title-calendar {
            font-family: 'bentonsans-bold', sans-serif;
        }

        .active {
            background-color: #2F485C !important;
        }

        .table-condensed {
            margin: auto;
        }

        thead tr:last-child {
            th {
                background-color: #D9D9D9;
                border-radius: 0px;
            }

            th:first-child {
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
            }

            th:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
}

.orders {
    $colorText: #72BBFF;
    $listLineColor: #2F485C;
    $backgroundHeadColor: #72BBFF33;
    $backgroundCell: #FAFAFA;
    $backgroundCellSelected: #72BBFF0D;
    $borderSelectedColor: #72BBFF80;
    @import 'block/ged_table';

    padding: 0 7vw;

    .cell-centered {
        text-align: center;
    }

    &-promotional-blocks {
        margin: 0 0 25px;
        padding: 0 7vw;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .csp-block {
            width: 26%;
        }

        .csp-block-contact {
            width: 17%;
        }

        .pharmacist-tools-block {
            width: 26%;
        }

        .contact-block {
            width: 16%;
        }

        img {
            margin: unset;
            width: 100%;
        }
    }

    &-switcher {
        margin: 0 0 25px;
        padding: 0 7vw;
        display: block;
        float: left;
        width: 100%;
        border-bottom: 1px solid #B2BAC0;

        span {
            font-family: 'bentonsans-bold', sans-serif;
            float: left;
            font-size: 18px;
            color: #B2BAC0;
            padding: 10px 20px;
            cursor: pointer;
        }

        span.active {
            color: $blue-base;
            border-bottom: 5px solid $blue-base;
            cursor: default;
        }
    }

    &-question {
        font-family: 'bentonsans-bold', sans-serif;
        float: right;
        text-transform: uppercase;
        text-decoration: underline;
        font-size: 14px;
        color: $blue-base;
        padding: 10px 20px;
        transition: 1s;
    }

    &-question:hover {
        color: $blue-base;
        text-decoration: underline;
        opacity: 0.7;
    }

    &-filters {
        margin: 0 0 25px;
        padding: 0 7vw;
        display: inline-block;

        @import "../elements/bloc_ged_filter";
    }

    &-list {
        &-content {
            .list-content {
                width: 100%;
                border-collapse: separate;
                border-spacing: 0 10px;
                padding-top: 20px;
                padding-left: 20px;
                padding-right: 20px;
                background: #FFFFFF;
                border-radius: 8px;

                label {
                    display: unset;
                    width: unset;
                    margin: unset;
                    position: unset;
                    left: unset;
                    top: unset;
                }

                .block-container-icone-arrow-desktop {
                    .fa-angle-down {
                        display: none;
                    }

                    .fa-angle-up {
                        display: unset;
                    }
                }
            }

            .list-head {
                background: #E6EEF7;
                font-size: 14px;
                font-family: Bentonsans-bold, sans-serif;
                color: #568BC9;
                border-radius: 4px;

                .title-flexed {
                    position: relative;
                    display: flex;
                    align-items: center;

                    .title-icon {
                        margin-left: 4px;
                        margin-bottom: 14px;
                        font-size: 14px;
                    }

                    .title-icon:hover {
                        cursor: pointer;
                    }

                    .order-hidden-image {
                        display: none;
                        position: absolute;
                        left: 100%;
                        bottom: 0;
                        width: 800px;
                        margin: 0;
                        z-index: 2;
                    }
                }

                .head-title:first-child {
                    padding-left: 20px;
                }

                .head-title {
                    padding-top: 13px;
                    padding-bottom: 13px;
                    vertical-align: middle;

                    .title-sorted {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .title {
                        margin-right: 10px;
                    }

                    .icons-sort-out {
                        .icons {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            i:hover {
                                cursor: pointer;
                            }
                        }
                    }

                    &-invoice {
                        text-align: unset !important;
                    }
                }

                .head-centered {
                    text-align: center;
                }

                .head-title:last-child {
                    padding-right: 20px;
                }
            }

            .order-bloc {
                pointer-events: none;
            }

            .order-bloc:hover {
                background: #E6EEF7;
                cursor: pointer;
            }

            .list-line {
                border-radius: 4px;
                background: #FAFAFA;
                font-size: 12px;
                font-family: bentonsans-regular, sans-serif;
                color: #2F485C;


                &-cell:first-child {
                    padding-left: 20px;
                }

                &-cell:nth-child(2) {
                    border-radius: unset !important;
                }

                &-cell {
                    padding: 10px 0;

                    &-icon {
                        margin-right: 8px;
                        border-radius: 50%;
                    }

                    .cell-flexed {
                        display: flex;
                        align-items: center;
                    }

                    .favorite-icon {
                        display: flex;
                        justify-content: center;

                        i {
                            width: 29px;
                            height: 29px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #E7EDF5 0 0 no-repeat padding-box;
                            color: #FFFFFF;
                        }
                    }

                    .favorite-icon.favorite {
                        i {
                            background: #ECBDD4 0 0 no-repeat padding-box;
                            color: #DB74A6;
                        }
                    }

                    .favorite-icon {
                        i:hover {
                            cursor: pointer;
                        }
                    }

                    &-invoice {
                        padding-right: unset !important;
                        border-radius: unset !important;
                    }
                }

                &-cell:nth-last-child(2) {
                    border-radius: unset !important;
                }

                &-cell:last-child {
                    padding-right: 20px;
                }

                .cell-triggered {
                    pointer-events: visible;
                }

                .cell-action {
                    pointer-events: visible;
                }

                .btn-invoice {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 7px 12px;
                    border-radius: 25px;
                    background: #7F96A8;
                    color: #FFFFFF;
                    font-size: 12px;
                    font-family: Bentonsans-bold, sans-serif;

                    &-icon {
                        margin-right: 7px;
                    }
                }
                .btn-invoice:hover {
                    background: #568BC9;
                }
            }

            .show-more {
                display: flex;
                justify-content: center;
                background: #FFFFFF;
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;

                &-btn {
                    margin: 20px 0;
                    display: flex;
                    align-items: center;
                    background: #FFFFFF;
                    font-size: 14px;
                    font-family: Bentonsans-bold, sans-serif;
                    color: #568BC9;

                    i {
                        margin-right: 8px;
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .orders-footer {
        display: block;
        float: left;
        width: 100%;
        color: #2F485C;
        font-size: 11px;
        font-family: bentonsans-regular, sans-serif;
        margin: 10px 0;
    }
}

.popup-order-details {
    overflow-y: auto;
    overflow-x: hidden;
}

.order-details-header {
    display: block;
    float: left;
    width: 100%;
    background: #F2F4F6;
    padding: 10px;
    margin-bottom: 10px;
}

.order-details-close {
    padding: 10px;
    margin: 7px;

    img {
        width: 14px;
    }

    img:hover {
        cursor: pointer;
    }
}

.order-details {
    &-content {
        background: #FFFFFF;
    }

    &-download {
        float: left;
        font-family: 'bentonsans-bold', sans-serif;
        color: #FFFFFF;
        background: $blue-base;
        font-size: 12px;
        text-transform: uppercase;
        padding: 5px 11px 5px 5px;
        margin: 20px auto;

        a {
            color: #FFFFFF;
        }

        img {
            width: 23px;
        }

        span {
            vertical-align: middle;
            margin-left: 12.5px;
        }
    }

    &-download-icon {
        margin: 0;
        display: initial;
    }

    &-table-header {
        font-family: 'bentonsans-bold', sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        color: #485E68;
    }

    &-table-body {
        font-family: 'bentonsans-regular', sans-serif;
        font-size: 12px;
        color: #B2BAC0;
    }

    &-unavailable {
        width: fit-content;
        padding: 2px 3px;
        border: 1px solid $blue-base;
        color: $blue-base;
        text-transform: uppercase;
    }

    &-unavailable-notice {
        font-family: 'bentonsans-regular', sans-serif;
        font-size: 13px;
        color: #B2BAC0;
        margin: 20px auto;
    }
}

.order-status {
    font-family: 'bentonsans-regular', sans-serif;
    font-size: 12px;

    &-received {
        color: #568BC9;
    }

    &-in-preparation {
        color: #F09A04;
    }

    &-shipped {
        color: #2DB4D1;

        > img {
            margin-top: 4px;
        }
    }

    &-partly-delivered {
        color: #92B90D;
    }

    &-delivered {
        color: #92B90D;
    }

    &-canceled {
        color: #707070;
    }

    &-delivering-dates {
        font-family: bentonsans-regular, sans-serif;
        font-size: 10px;
        text-transform: initial;
    }
}

/*
* Responsive mobile
**/
@media screen and (max-width: 480px) {


    .orders-promotional-blocks {
        flex-direction: column;
        gap: 20px;
    }
    .csp-block, .pharmacist-tools-block, .contact-block {
        width: unset !important;
    }

    .orders {
        $colorText: #72BBFF;
        $listLineColor: #2F485C;
        $backgroundHeadColor: #72BBFF33;
        $backgroundCell: #FAFAFA;
        $backgroundCellSelected: #72BBFF0D;
        $borderSelectedColor: #72BBFF80;
        @import 'block/ged_table';
        padding: 0 2vw;;

        &-list {
            &-content {
                .list-head {
                    font-size: 9px !important;
                }

                .list-line {
                    font-size: 9px !important;
                }

                .list-content {
                   // padding: 0;
                }

                .head-title:first-child {
                    padding-left: 10px !important;
                }

                .list-line-cell:first-child {
                    padding-left: 10px !important;
                    padding-right: 5px;
                }

            }
        }
    }
}
