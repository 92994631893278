.commande-flash-unavailable {
    background: #E9EDEF;

    &-title {
        margin-top: 4%;
        color: #1C4537;
        font-size: 32px;
        font-family: Bentonsans-book, sans-serif;
    }

    &-content {
        padding: 2% 25%;
        color: #1C4537;
        font-size: 16px;
        font-family: Bentonsans-book, sans-serif;
    }
}

@media screen and (max-width: 767px) {
    .commande-flash-unavailable {
        background: #E9EDEF;

        &-title {
            margin: 4%;
        }

        &-content {
            padding: 4% 10%;
        }
    }
}
