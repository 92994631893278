.otc-universe-container {
    width: 100%;

    a:focus {
        color: #ffffff !important;
    }
}

.otc-universe-row {
    margin: 40px 0;
}

.otc-universe-text-row {
    margin: 40px 0;
}

.otc-universe-text-container {
    overflow: hidden;
}

.otc-universe-text-row-title {
    display: block;
    position: relative;
    width: fit-content;
    margin: auto auto 10px auto;
    font-family: Bentonsans, sans-serif;
    font-size: 22px;
    color: #485E68;
    font-weight: 300;
    text-align: center;
}

.otc-universe-text-row-title:before {
    content: '';
    border-top: 1px solid #485E68;
    width: 1500px;
    z-index: 99999;
    position: absolute;
    top: 50%;
    left: -1525px;
}

.otc-universe-text-row-title:after {
    content: '';
    border-top: 1px solid #485E68;
    width: 1500px;
    z-index: 99999;
    position: absolute;
    top: 50%;
    right: -1525px;
}

.otc-universe-text-row-subtitle {
    display: block;
    font-family: Bentonsans, sans-serif;
    font-size: 12px;
    color: #485E68;
    font-weight: normal;
    text-align: center;
}

.otc-universe-text-row-description {
    display: block;
    width: 60%;
    margin: auto;
    font-family: Bentonsans, sans-serif;
    font-size: 12px;
    color: #485E68;
    font-weight: bold;
    text-align: center;
}

/**
LARGE BLOCKS
 */
.otc-universe-container > .col-md-12 > .otc-universe-row {
    display: flex;
}

.otc-universe-container > .col-md-12 > .otc-universe-row > .col-md-6 {
    display: flex;
}

.otc-universe-block-l {
    background-color: #FFFFFF;
    position: relative;
    border-radius: 3px;
}

.otc-universe-block-l-title {
    display: block;
    font-family: 'bentonsans-condensedbold', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    padding: 20px;
    border-left: 5px solid;
}

.otc-universe-block-l-content {
    padding: 10px 25px;
}

.otc-universe-contracts-disabled {
    font-family: bentonsans-bold, sans-serif;
    font-size: 14px;
    display: block;
    color: #E53838;
    font-weight: bold;
    margin-bottom: 10px;
}

.otc-universe-contracts-disabled img {
    display: inline;
    margin: 0;
}

.otc-universe-text {
    display: block;
    font-family: 'bentonsans-condensedregular', sans-serif;
    font-size: 12px;
    color: #838D97;
}

.otc-universe-botc .otc-universe-text {
    //width: 80%;
    display: inline-block;
}

.otc-universe-secondtext {
    display: block;
    font-family: 'bentonsans-condensedbold', sans-serif;
    font-size: 12px;
}

.otc-universe-contracts-secondtext {
    color: $blue-base;
}

.otc-universe-catalogue-secondtext {
    color: #782FCB;
    margin-bottom: 5px;
}

.otc-universe-block-l-button {
    display: block;
    width: fit-content;
    margin-top: 15px;
    color: #FFFFFF;
    font-family: 'bentonsans-condensedregular', sans-serif;
    padding: 10px 20px;
    text-transform: uppercase;
    transition: 1s;
}

.otc-universe-block-l-button:hover {
    color: #FFFFFF;
    opacity: 0.8;
}

.otc-universe-contracts .otc-universe-block-l-title {
    color: $blue-base;
    border-color: $blue-base;
}

.otc-universe-contracts .otc-universe-block-l-button {
    background: $blue-base;
}

.otc-universe-catalogue .otc-universe-block-l-title {
    color: #782FCB;
    border-color: #782FCB;
}

.otc-universe-catalogue .otc-universe-block-l-button {
    background: #782FCB;
}

.otc-universe-contracts-statutes-na {
    display: block;
    width: 100%;
    font-family: bentonsans-regular, sans-serif;
    color: #B3BDC7;
    font-size: 11px;
    text-align: center;
}

.otc-universe-contracts-status {
    display: block;
    float: left;
    width: 100%;
    font-family: 'bentonsans-condensedregular', sans-serif;
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 16px;
    padding: 5px;
    border-radius: 3px;
    margin-top: 5px;
}

.otc-universe-contracts-status-signed {
    background: $green-base;
}

.otc-universe-contracts-status-inprogress {
    background: $blue-base;
}

.otc-universe-contracts-status-tosign {
    background: #CB2F7B;
}

.otc-universe-contracts-status-canceld {
    background: #E53838;
}

.otc-universe-contracts-status i {
    padding: 0 5px;
}

.otc-universe-contracts-status em {
    float: right;
    font-family: bentonsans-bold, sans-serif;
    padding: 0 10px;
    background: #FFFFFF;
    border-radius: 2px;
}

.otc-universe-contracts-status-signed em {
    color: $green-base;
}

.otc-universe-contracts-status-inprogress em {
    color: $blue-base;
}

.otc-universe-contracts-status-tosign em {
    color: #CB2F7B;
}

.otc-universe-contracts-status-canceld em {
    color: #E53838;
}

/**
SMALL BLOCKS
 */
.otc-universe-container > .col-md-12 > .otc-universe-row > .col-md-4 {
    display: flex;
}

.otc-universe-block-s {
    display: block;
    float: left;
    background-color: #FFFFFF;
    position: relative;
    border-radius: 3px 3px 0 0;
    padding: 30px 25px;
    margin-bottom: 25px;
}

.otc-universe-block-s-title-container {
    position: absolute;
    width: 100%;
    top: -20px;
    left: 0;
}

.otc-universe-block-s-title {
    font-family: bentonsans-condensedbold, sans-serif;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 20px;
    padding: 10px 20px;
    display: block;
    width: fit-content;
    margin: auto;
}

.otc-universe-orders .otc-universe-block-s-title {
    background: $blue-base;
}

.otc-universe-voucher .otc-universe-block-s-title {
    background: $blue-base;
}

.otc-universe-botc .otc-universe-block-s-title {
    background: #4E8BE5;
}

.otc-universe-imi .otc-universe-block-s-title {
    background: #64275D;
}

.otc-universe-noereka .otc-universe-block-s-title {
    background: #00A888;
}

.otc-universe-block-s-subtitle-container {
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

.otc-universe-block-s-subtitle {
    display: block;
    font-family: bentonsans-regular, sans-serif;
    font-size: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #DDDDDD;
}

.otc-universe-block-s-subtitle {
    color: #CB2F7B;
}

.otc-universe-imi .otc-universe-block-s-subtitle {
    color: #64275D;
}

.otc-universe-noereka .otc-universe-block-s-subtitle {
    color: #00A888;
}

.otc-universe-orders-not-engaged {
    font-family: bentonsans-regular, sans-serif;
    font-size: 12px;
    display: block;
    color: #E53838;
    font-weight: bold;
    margin-bottom: 20px;
}

.otc-universe-orders-statutes {
    display: block;
    width: 100%;
    position: relative;
}

.otc-universe-orders-status {
    display: block;
    float: left;
    width: 90%;
    font-family: bentonsans-bold, sans-serif;
    text-transform: uppercase;
    padding: 10px;
    margin-bottom: 10px;
    color: #FFFFFF;
    font-size: 11px;
    border-radius: 3px;
    margin-left: 28px;
}

.otc-universe-orders-status span {
    float: right;
}

.otc-universe-orders-status-active {
    background: #F09A04;
}

.otc-universe-orders-status-inactive {
    background: #B3BDC7;
}

.otc-universe-orders-status:before {
    font-family: "Font Awesome 5 Free", sans-serif;
    position: absolute;
}

.otc-universe-orders-status-active:before {
    content: url("/assets/img/otc-universe/order-on.png");
    left: 0;
}

.otc-universe-orders-status-inactive:before {
    content: url("/assets/img/otc-universe/order-off.png");
    left: 2px;
}

.otc-universe-orders-status-date {
    background: #FFFFFF;
    color: #485E68;
    padding: 3px 5px;
    border-radius: 3px;
}

.otc-universe-orders-status-date-label {
    font-family: bentonsans-regular, sans-serif;
    text-transform: initial;
    margin-right: 5px;
    opacity: 0.5;
}

.otc-universe-block-s-icon {
    display: inline;
    margin: 0;
}

.otc-universe-botc .otc-universe-block-s-icon {
    margin-top: -15px;
}

.otc-universe-block-s-button {
    position: absolute;
    display: block;
    width: 100%;
    top: 100%;
    left: 0;
    background: #485E68;
    color: #FFFFFF;
    font-family: bentonsans-regular, sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    padding: 5px;
    text-align: center;
    transition: 1s;
}

.otc-universe-block-s-button:hover {
    color: #FFFFFF;
    opacity: 0.8;
}

.otc-universe-block-s-img {
    width: 100%;
    margin: 10px 0 0 0;
}

