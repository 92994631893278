.popin-commande-flash-gel-hydro-dashboard {
    .popup-base {
        width: 596px;
        background: #FFFFFF;
    }

    .popup-title {
        background: #002786;
        color: #FFFFFF;
        font-family: bentonsans-regular, sans-serif;
        font-size: 11px;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
    }

    .popup-commande-flash-close {
        float: right;
    }

    .popup-commande-flash-close:hover {
        cursor: pointer;
    }

    .popup-content-container {
        background-image: url('../img/commande-flash/gel-hydro/popin-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;

        .popup-content {
            &-title {
                position: relative;
                top: 15px;
                font-family: bentonsans-bold, sans-serif;
                font-size: 18px;
                color: #002786;
                text-transform: uppercase;
            }

            &-img {
                margin: 15px auto;

                img {
                    width: 85%;
                    margin: 0 auto;
                }
            }

            &-btn {
                margin-top: 24px;

                img {
                    margin: 0 auto;
                    width: 40%;
                }
            }
        }

        .popup-footer {
            padding: 12px;
            font-family: Bentonsans, sans-serif;
            font-size: 10px;
            color: #002786;
        }
    }
}

@media screen and (max-width: 767px) {
    .popin-commande-flash-gel-hydro-dashboard {
        .popup-base {
            width: 100%;
        }

        .popup-content-container {
            img {
                width: 100%;
            }
        }
    }
}
