.pharmacien-documents {
    background: #F4F4F4;
}

.documents {
    padding: 0 7vw;
    $colorText: #72BBFF;
    $listLineColor: #2F485C;
    $backgroundHeadColor: #72BBFF33;
    $backgroundCell: #FAFAFA;
    $backgroundCellSelected: #72BBFF0D;
    $borderSelectedColor: #72BBFF80;
    @import 'block/ged_table';

    .cell-centered {
        text-align: center;
    }

    .all-documents-section {
        display: flex;
        justify-content: space-between;
        height: 278px;
        width: 100%;
        margin-bottom: 32px;

        .documents-promotional-blocks {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 40%;

            .block-line {
                display: flex;
                justify-content: space-between;

                a {
                    height: 100%;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                }
                &-midwith {
                    margin-bottom: 40px;
                }

                &-midwith .block-line-img {
                    width: calc(50% - 10px);
                    height: 100%;
                }

                .block-line-button {
                    border-radius: 20px;
                    color: #fff;
                    font-size: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 10px;
                    background-color: #0F52BA;
                    font-family: BentonSans-Medium, sans-serif;
                }

                &-text {
                    font-size: 16px;
                    font-family: BentonSans-Medium, sans-serif;
                    color: #FFFFFF;
                    margin-bottom: 7px;
                }

                &-img {
                    width: 50%;
                    img {
                        margin: unset;
                        width: 100%;
                    }
                }
            }

            .img-container-bloc2 {
                width: 96%;
                margin: 0 auto;
                .block-line {
                    &-img {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-block {
        margin-bottom: 20px;

        &-title {
            margin: 10px 0;
            font-size: 20px;
            font-family: BentonSans-Medium, sans-serif;
            color: #2F485C;
        }

        &-content {
            background: #FFFFFF;
            border-radius: 8px;
            padding: 20px;
        }

        .all-documents-content {
            display: flex;

            .sub-block {
                position: relative;
                margin: 0 15px;
                padding: 30px 75px;
                border-radius: 5px;

                &-contracts {
                    background-color: #0F52BA;

                    .sub-block-icon {
                        opacity: 1;
                        width: 90px;
                        text-align: center;
                    }

                    img {
                        margin: 0;
                        display: unset;
                    }

                    img:hover {
                        filter: saturate(8.5);
                    }

                    i {
                        color: #72BBFF;
                    }
                }

                &-contracts:hover {
                    box-shadow: 5px 5px 10px rgba($color: #2DB4D1, $alpha: 0.5);
                }

                &-invoices {
                    background-color: #72BBFF;

                    .sub-block-icon {
                        opacity: 0.4;
                        width: 90px;
                        text-align: center;
                    }

                    img {
                        margin: 0;
                        display: unset;
                    }

                    img:hover {
                        filter: saturate(1.5);
                    }

                    i {
                        color: #0F52BA;
                    }
                }

                &-invoices:hover {

                    box-shadow: 5px 5px 10px rgba($color: #2DB4D1, $alpha: 0.5);
                }

                &-icon {
                    font-size: 90px;
                    opacity: 0.4;
                }

                &-text {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 16px;
                    font-family: BentonSans-Medium, sans-serif;
                    color: #FFFFFF;
                }
            }
        }

        .recent-documents-content {
            .list {
                &-line {
                    /* border: none; */
                }

                &-content {
                    width: 100%;
                    border-collapse: separate;
                    border-spacing: 0 11px;

                    label {
                        display: unset;
                        width: unset;
                        margin: unset;
                        position: unset;
                        left: unset;
                        top: unset;
                    }
                }

                &-head {
                    background: #E0F4F8;
                    font-size: 14px;
                    font-family: Bentonsans-bold, sans-serif;
                    color: #2DB4D1;
                    border-radius: 4px;
                    /*
                    .head-title:first-child {
                        padding-left: 60px;
                    }
                    */
                    .head-title {
                        padding-top: 18px;
                        padding-bottom: 18px;
                        vertical-align: middle;
                        text-align: center;
                    }

                    /*
                        .head-title:last-child {
                        padding-right: 60px;
                        }
                    */
                    .head-centered {
                        text-align: center;
                    }

                    .gap {
                        padding-left: 60px;
                    }
                }

                &-line {
                    border-radius: 4px;
                    background: #FAFAFA;
                    font-size: 12px;
                    font-family: bentonsans-regular, sans-serif;
                    color: #2F485C;

                    &-cell:first-child {
                        padding-left: 25px;
                    }

                    &-cell {
                        padding: 11px 0;

                        &-icon {
                            margin-right: 8px;
                            border-radius: 50%;
                        }

                        &-icon,
                        .icon-lightgreen {
                            padding: 5px 10px;
                            background: #DBE7B3;
                            font-size: 14px;
                            color: #92B90D;
                        }

                        &-icon,
                        .icon-grey {
                            padding: 11px 7px;
                            background: #7F96A8;
                            font-size: 4px;
                            color: #FFFFFF;
                        }

                        .cell-flexed {
                            display: flex;
                            align-items: center;
                        }

                        a {
                            color: #000000;
                        }

                        .ref-invoice:hover {
                            a {
                                color: #C7E4FF;
                            }
                        }

                        .ref-contract:hover {
                            a {
                                color: #106EFF;
                            }
                        }
                    }

                    &-cell:last-child {
                        // padding-right: 25px;
                    }

                    .cell-flexed {
                        display: flex;
                        align-items: center;

                        &-actions {
                            justify-content: center;

                            &-send:hover {
                                cursor: pointer;
                            }
                        }

                        &-icon {
                            margin-right: 8px;
                            padding: 6px 8px;
                            border-radius: 50%;
                            font-size: 11px;
                            color: #FFFFFF;
                        }

                        &-icon,
                        .icon-blue {
                            background: #0F52BA;
                        }

                        &-icon,
                        .icon-grey {
                            background: #7F96A8;
                        }

                        &-icon,
                        .icon-light-green {
                            background: #2DB4D1;
                        }

                        &-icon,
                        .icon-light-red {
                            background: #FF0000;
                        }
                    }

                    .cell-centered {
                        text-align: center;
                    }
                }
            }
        }
    }

    .download {
        display: none;
    }

    .print {
        display: none;
    }

    .send {
        display: none;
    }

    #mobile {
        display: none;
    }
}

/*
* Responsive mobile
**/
@media screen and (max-width: 480px) {

    .documents-block .recent-documents-content {

        .list-line-cell {
            font-size: 16px;
            padding: 6px 0;

            a {
                .cell-flexed-icon {
                    margin-right: 8px;
                    padding: 6px 8px;
                }
            }
        }

        .list-line-cell:first-child {
            padding-left: 0;
        }

        .list-line-cell:nth-child(4) {
            display: unset;
        }

        .list-line .cell-flexed {
            margin-left: 0;
        }

        #desktop {
            display: none;
        }

        .list-line .cell-flexed-icon {
            display: inline;
            margin-right: 0;
            padding: 0;
        }

        .cell-flexed-text {
            display: none;
        }

        .recent-documents-content .list-content {
            table-layout: fixed;
        }

        .header-breadcrumb-current {
            font-size: 13px;
        }
    }

    .documents {
        padding: 0 1.5vw;

        table td {
            display: unset;
        }

        .head-title:first-child {
            padding-left: 35px !important;
        }

        /* &-block-content {
            padding: 10px 0 10px 0 !important;
        } */

        .all-documents-section {
            height: unset;
            flex-direction: column;
        }

        .all-documents-content {
            flex-direction: column;
            gap: 15px;

            .sub-block-contracts {
                img {
                    transform: translateX(55px);
                }
            }

            .sub-block-invoices {
                img {
                    transform: translateX(68px);
                }
            }
        }

        .documents-block-content {
            padding: unset;
        }

        .recent-documents-content .list-line {
            word-break: break-word;
        }

        .list-head th {
            padding-left: 3px !important;
            font-size: 11px !important;
        }

        .img-container-bloc2 {
            width: 95%;
            margin: 0 auto;
        }
    }

    .documents-promotional-blocks {
        width: unset !important;
    }

    .download {
        display: block !important;
        padding-right: 10px;
        font-size: 11px;
    }

    .print {
        display: block !important;
        padding-right: 10px;
        font-size: 11px;
    }

    .send {
        display: block !important;
        font-size: 11px;
    }

    #mobile {
        display: unset !important;
    }
}
