@charset "UTF-8";

// Tables
tr:nth-child(2n) td:first-child::before{
    .editorial-content &{
        @include gradient-vertical($blue-base, lighten($blue-base, 10%));
    }
    .news_details &,
    .cke_editable &{
        @include gradient-vertical(lighten(saturate($green-base2, 40%), 20%), $grey-lighter);
    }
}
tr td:first-child::before{
    .editorial-content &{
        @include gradient-vertical(lighten($blue-base2, 15%), $blue-base2);
    }
    .news_details &,
    .cke_editable &{
        @include gradient-vertical(lighten(saturate($green-base2, 40%), 10%), darken($green-base2, 5%));
    }
}


// Lists
ul li::before {
    .editorial-content & {
        color: $blue-base2;
    }
    .news_details &,
    .cke_editable &{
        color: $blue-base2;
    }
}
ul {
    li::before {
        .editorial-content & {
            color: $blue-base2;
        }
        .news_details &,
        .cke_editable &{
            color: $green-base2;
        }
    }
}

// Typo
a{
    .editorial-content &{
        color: $blue-base2;
    }
    .news_details &,
    .cke_editable &{
        color: $green-base2;
    }
}

blockquote{
    .editorial-content &{
        color: $blue-base2;
        &::before, &::after{
            background-color: lighten($blue-base2, 30%);
        }
        .blockquote-author{
            color: lighten($blue-base2, 30%);
        }
    }
    .news_details &,
    .cke_editable &{
        color: $green-base2;
        &::before, &::after{
            background-color: lighten($green-base2, 20%);
        }
        .blockquote-author{
            font-family:'bentonsans-medium', sans-serif;
            color: lighten($green-base2, 20%);
        }
    }
}
.block-mise_en_avant {
    .editorial-content &{
        background-color: $blue-base2;
        color: lighten($blue-base2, 30%);
    }
    .news_details &,
    .cke_editable &{
        background-color: $green-base2;
        color: lighten($green-base2, 90%);
    }
}
.generic-surtitle {
    .editorial-content &{
        //color: $blue-base2;
    }
    .cke_editable &{
        color: $green-base2;
    }
}
.image-legend {
    .editorial-content &{
        &::after{
            background-color: lighten($blue-base2, 30%);
        }
    }
    .news_details &,
    .cke_editable &{
        &::after{
            background-color: lighten($green-base2, 20%);
        }
    }
}

// Contenu sur 2 colonnes
.engagements-menu {
    a::before,
    li::before{
        color: $pink-base;
    }
}
.servicespatients-menu{
    a::before,
    li::before {
        color: $green-base;
    }
}

// Tooltips

.tooltip_toggle{
    .contrat_listing &{
        color: $blue-base;
        background-color: lighten($blue-base, 40%);
        border-color:$grey-lighter;
    }
    .pharmacien-equipe &{
        color: $orange-base;
        background-color: lighten($orange-base, 40%);
        border-color:$grey-lighter;
    }
    .cata_filters &{
        color: $purple-base;
        background-color: lighten($purple-base, 48%);
        border-color:lighten($purple-base, 40%);
    }
    .dashboard-insidecol &{
        color: $green-base2;
        background-color: lighten($green-base2, 40%);
        border-color:#dae0e9;
    }
}
.tooltipster-base {
    .contrat_listing &{
        border: 1px solid rgba($blue-base, 0.3);
        background-color: lighten($blue-base, 42%);
        color: $blue-base;
    }
    .pharmacien-equipe &{
        border: 1px solid rgba($orange-base, 0.3);
        background-color: lighten($orange-base, 42%);
        color: $orange-base;
    }
    .pharmacien-catalogue &{
        border: 1px solid rgba($purple-base, 0.3);
        background-color: lighten($purple-base, 48%);
        color: $purple-base;
    }
    .pharmacien-dashboard &{
        border: 1px solid rgba($green-base2, 0.3);
        background-color: lighten($green-base2, 40%);
        color: $green-base2;
    }
}

/******************************************************** Profil */

.page.profil .main-content h1{
    background-color: $orange-base;
}

/******************************************************** Fin Profil */

/******************************************************** Base */

.page .main-content h1{
    background-color: $blue-base2;
}

/******************************************************** Fin Base */

/******************************************************** News */

[class^="pharmacien-news"] h1,
.news_details h1{
    background-color: $green-base2;
}

/******************************************************** Fin news */

/******************************************************** Services patients */
$bordercolor-servicespatients: lighten($green-base, 6%);
body.pharmacien-services-patients {
    .main-content {
        .h1-header-pmp {
            h1{
                background-color: $green-base;
            }
            .bloc-pmp-contrat {
                border-color: $bordercolor-servicespatients;
                background-color: lighten($green-base, 3%);
                .hc-block-title {
                    color: darken($green-base, 10%);
                }
                .hc-block-delegue-tel {
                    a {
                        color: darken($green-base, 10%);
                    }
                }
            }
            .bloc-pmp-mobile{
                background-color: lighten($green-base, 7%);
            }
        }
    }
    .breadcrumb span{
        color: $green-base;
    }
}

@media screen and (max-width : 767px) {
    body.pharmacien-services-patients {
        .main-content {
            .h1-header-pmp {
                .block-col {
                    border-top-color: $bordercolor-servicespatients;
                    .bloc-pmp-contrat:first-child {
                        border-right-color: $bordercolor-servicespatients;
                    }
                    .bloc-pmp-contrat:last-child {
                        border-top-color: $bordercolor-servicespatients;
                    }
                }
            }
        }
    }
}
/******************************************************** Fin services patients */

/******************************************************** Catalogue + Produit */
$bordercolor-catalogue: #a47ad9;
body.pharmacien-catalogue, .pharmacien-produit {
    .main-content {
        h1{
            background-color: $purple-base;
        }
        .h1-header-pmp {
            .bloc-pmp-contrat {
                border-color: $bordercolor-catalogue;
                background-color: lighten($purple-base, 10%);
                .hc-block-title {
                    color: lighten($purple-base, 30%);
                }
                .hc-block-delegue-tel {
                    a {
                        color: lighten($purple-base, 40%);
                    }
                }
            }
            .bloc-pmp-mobile{
                background-color: #b87cfb;
            }
        }
    }
    .breadcrumb span{
        color: $purple-base;
    }
}

@media screen and (max-width : 767px) {
    body.pharmacien-catalogue {
        .main-content {
            .h1-header-pmp {
                .block-col {
                    border-top-color: $bordercolor-catalogue;
                    .bloc-pmp-contrat:first-child {
                        border-right-color: $bordercolor-catalogue;
                    }
                    .bloc-pmp-contrat:last-child {
                        border-top-color: $bordercolor-catalogue;
                    }
                }
            }
        }
    }
}
/******************************************************** Fin catalogue */

/******************************************************** Engagements */

body.pharmacien-engagements{
    .main-content {
        h1 {
            background-color: $pink-base;
        }
    }
}
/******************************************************** Fin engagements */

/******************************************************** Formations */

$bordercolor-formations: lighten($blue-base2, 20%);
body.formations {
    .main-content {
        .h1-header-pmp {
            h1 {
                background-color: $blue-base2;
            }
            .bloc-pmp-contrat {
                border-color: $bordercolor-formations;
                background-color: lighten($blue-base2, 5%);
                .hc-block-title {
                    color: lighten($blue-base2, 25%);
                }
                .hc-block-delegue-tel {
                    a {
                        color: lighten($blue-base2, 25%);
                    }
                }
            }
            .bloc-pmp-mobile{
                background-color: #84b6ff;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    body.formations {
        .main-content {
            .h1-header-pmp {
                .block-col {
                    border-top-color: $bordercolor-formations;
                    .bloc-pmp-contrat:first-child {
                        border-right-color: $bordercolor-formations;
                    }
                    .bloc-pmp-contrat:last-child {
                        border-top-color: $bordercolor-formations;
                    }
                }
            }
        }
    }
}

/******************************************************** Fin formations */

/******************************************************** Contrats */
$bordercolor-contrats: lighten($blue-base, 25%);
body.contrat_listing, body.pharmacien-faq, body.pharmacien-remis   {
    .main-content {
        h1{
            background-color: $blue-base;
        }
        .h1-header-pmp {
            .bloc-pmp-contrat {
                border-color:$bordercolor-contrats;
                background-color: lighten($blue-base, 10%);
                .hc-block-title {
                    color: lighten($blue-base, 30%);
                }
                .hc-block-delegue-tel {
                    a {
                        color: lighten($blue-base, 40%);
                    }
                }
            }
            .bloc-pmp-mobile{
                background-color: #6dd4ea;
            }
        }
    }
    .breadcrumb span{
        color: $blue-base;
    }
}
body.pharmacien-equipe   {
    .main-content {
        h1{
            background-color: $orange-base;
        }
        .h1-header-pmp {
            .bloc-pmp-contrat {
                border-color:$bordercolor-contrats;
                background-color: lighten($orange-base, 10%);
                .hc-block-title {
                    color: lighten($orange-base, 30%);
                }
                .hc-block-delegue-tel {
                    a {
                        color: lighten($orange-base, 40%);
                    }
                }
            }
            .bloc-pmp-mobile{
                background-color: #6dd4ea;
            }
        }
    }
    .breadcrumb span{
        color: $orange-base;
    }
}

@media screen and (max-width: 767px) {
    body.contrat_listing,
    body.pharmacien-equipe {
        .main-content {
            .h1-header-pmp {
                .block-col {
                    border-top-color: $bordercolor-contrats;
                    .bloc-pmp-contrat:first-child {
                        border-right-color: $bordercolor-contrats;
                    }
                    .bloc-pmp-contrat:last-child {
                        border-top-color: $bordercolor-contrats;
                    }
                }
            }
        }
    }
}
/******************************************************** Fin contrats */


/******************************************************** Databusiness */

body.pharmacien-dashboard{
    .main-content {
        h1 {
            background-color: $blue-base;
        }
        .h1-header-pmp {
            .bloc-pmp-contrat {
                border-color: rgba($white, 0.4);
                background-color: lighten($blue-base, 10%);
                .hc-block-title {
                    color: lighten($blue-base, 30%);
                }
                .hc-block-delegue-tel {
                    a {
                        color: lighten($blue-base, 40%);
                    }
                }
            }
            .bloc-pmp-mobile{
                background-color: lighten($blue-base, 20%);
            }
        }
    }
    .breadcrumb span{
        color: $blue-base;
    }
}
@media screen and (max-width: 767px) {
    body.pharmacien-dashboard {
        .main-content {
            .h1-header-pmp {
                .block-col {
                    border-top-color: rgba($white, 0.4);
                    .bloc-pmp-contrat:first-child {
                        border-right-color: rgba($white, 0.4);
                    }
                    .bloc-pmp-contrat:last-child {
                        border-top-color: rgba($white, 0.4);
                    }
                }
            }
        }
    }
}
/******************************************************** Databusiness */



/******************************************************** OTC */

body.pharmacien-otc{
    .main-content {
        h1 {
            background-color: $blue-dark4;
        }
        .h1-header-pmp {
            .bloc-pmp-contrat {
                border-color: rgba($white, 0.4);
                background-color: lighten($blue-dark4, 10%);
                .hc-block-title {
                    color: lighten($blue-dark4, 30%);
                }
                .hc-block-delegue-tel {
                    a {
                        color: lighten($blue-dark4, 40%);
                    }
                }
            }
            .bloc-pmp-mobile{
                background-color: lighten($blue-dark4, 20%);
            }
        }
    }
    .breadcrumb span{
        color: $blue-dark4;
    }
}
@media screen and (max-width: 767px) {
    body.pharmacien-otc {
        .main-content {
            .h1-header-pmp {
                .block-col {
                    border-top-color: rgba($white, 0.4);
                    .bloc-pmp-contrat:first-child {
                        border-right-color: rgba($white, 0.4);
                    }
                    .bloc-pmp-contrat:last-child {
                        border-top-color: rgba($white, 0.4);
                    }
                }
            }
        }
    }
}
/******************************************************** OTC */

/******************************************************** COMMANDES */

body.pharmacien-commandes{
    .breadcrumb span{
        color: $blue-base;
    }
}

/******************************************************** COMMANDES */

