&-block {
    &-content {
        background: #FFFFFF;
        border-radius: 10px;
    }
}

@import "../block/block";

.number-of-selected {
    padding: 6px 9px;
    border-radius: 5px;
    background: $backgroundHeadColor;
    font-size: 12px;
    font-family: BentonSans-Medium, sans-serif;
    color: $colorText;
    display: inline-block;
}

&-list {
    &-filters {

        @import '../../elements/_bloc_ged_filter.scss';
    }

    &-selections {
        margin: 10px 0;
        display: flex;
        height: 30px;

        .list-total {
            margin-right: 18px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: bentonsans-regular, sans-serif;
            color: $listLineColor;
        }
    }

    &-content {
        .list-content {

            width: 100%;
            border-collapse: separate;
            border-spacing: 0 0px;

            .icheckbox.checked {
                background: url("../../../../assets/img/icheck-ged-page.png") no-repeat center center;
            }

            tr:first-child {
                border-spacing: 0;
            }

            tr {
                input[type="checkbox"] {
                    vertical-align: unset;
                    width: 16px;
                    height: 16px;
                }
            }

            label {
                display: unset;
                width: unset;
                margin: unset;
                position: unset;
                left: unset;
                top: unset;
            }
        }

        .list-head {
            font-size: 14px;
            font-family: Bentonsans-bold, sans-serif;
            color: $colorText;
            border-radius: 10px;


            .icheckbox {
                padding-right: 2px;
                position: unset;
                background-color: #FFFFFF !important;
                border: 1px solid $colorText;
            }

            .icheckbox.checked {
                padding-right: 2px;
                position: unset;
                background-color: $colorText !important;
                border: 0;

                .iCheck-helper {
                    background-color: $colorText !important;
                }
            }

            .head-title:first-child {
                width: 20px;
                border-radius: 10px 0 0 10px;
            }

            .head-title:nth-child(2) {
                padding-left: 11px;
            }

            .head-title:nth-child(6) {
                text-align: center;
            }

            .head-title:nth-last-child(2) {
                text-align: center;
            }

            .head-title:last-child {
                width: 20px;
                border-radius: 0 10px 10px 0;
            }

            .head-title {
                background: $backgroundHeadColor;
                padding-top: 13px;
                padding-bottom: 13px;
                vertical-align: middle;

                .title-sorted {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .title {
                    margin-right: 7px;
                }

                .icons-sort-out {
                    .icons {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: nowrap;
                        height: 100%;

                        a,
                        a:hover {
                            color: $colorText;
                        }

                        a {
                            line-height: 0px;
                        }
                    }
                }
            }

            .head-title:last-child {
                padding-right: 20px;
            }
        }

        .list-top-margin {
            height: 18px;
        }

        .list-margin {
            height: 10px;
        }

        .list-line {
            border-radius: 4px;
            font-size: 12px;
            font-family: bentonsans-regular, sans-serif;
            color: $listLineColor;


            .icheckbox {
                padding-right: 2px;
                position: unset;
                background-color: #FFFFFF !important;
                border: 2px solid $listLineColor;
            }

            &-cell:first-child {
                background-color: inherit;
                width: 20px;
                border-radius: 10px 0 0 10px;
            }

            &-cell:last-child {
                background-color: inherit;
                width: 20px;
                border-radius: 0px 10px 10px 0px;
            }

            &-cell:nth-child(2) {
                padding-left: 11px;
                border-radius: 10px 0 0 10px;
            }

            &-cell:nth-last-child(2) {
                padding-right: 12px;
                border-radius: 0px 10px 10px 0px;
            }

            &-cell {
                //background: $backgroundCell;
                padding: 10px 0;

                @import '../../elements/_bloc_ged_actions.scss';

                .cell-flexed {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                }

                .ref-td {
                    text-decoration: none;
                    color: #000000;

                }

                .ref-td:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    color: #72BBFF;
                }
            }

            &-cell:last-child {
                padding-right: 20px;
                border-radius: 0 10px 10px 0;
            }

            .cell-centered {
                text-align: center;
            }

            .statut_signe {
                color: #92B90D;
            }

            .statut_a_signer {
                color: #FF0000;
            }

            .statut_en_cours {
                color: #F09A04;
            }

            .statut_refuse {
                color: #707070;
            }
        }

        .list-line-selected {

            .list-line-cell {
                background: $backgroundCellSelected;
            }

            .icheckbox {
                padding-right: 2px;
                position: unset;
                background-color: $colorText !important;
                border: 0;

                .iCheck-helper {
                    background-color: $colorText !important;
                }
            }

            .list-line-cell:nth-child(2) {
                border-left: 2px solid $borderSelectedColor;
            }

            .list-line-cell:first-child {
                background-color: inherit;
                border-top: 0;
                border-bottom: 0;
            }

            .list-line-cell {
                border-top: 2px solid $borderSelectedColor;
                border-bottom: 2px solid $borderSelectedColor;
            }

            .list-line-cell:nth-last-child(2) {
                border-right: 2px solid $borderSelectedColor;
            }

            .list-line-cell:last-child {
                background-color: inherit;
                border-top: 0;
                border-bottom: 0;
            }
        }

        .show-more {
            margin-top: -10px;
            display: flex;
            justify-content: center;
            padding-bottom: 10px;

            &-btn {
                margin: 20px 0;
                display: flex;
                align-items: center;
                background: #FFFFFF;
                font-size: 14px;
                font-family: Bentonsans-bold, sans-serif;
                color: $colorText;

                i {
                    margin-right: 8px;
                    font-size: 24px;
                }
            }
        }
    }
}

.contrats-list-filters .filters-form {
    align-items: flex-start;
}

#mob-reference, #mob-icone, #date, #montant, #facture, #statut, #actions, #periode, #type, #nbr-commande, #nbr-delivered, #canal, #processed {
    display: none;
}

.block-container-icone-arrow, .block-statut-a-cacher {
    display: none;
}

/*
    * Reponsive mobile
 */
@media screen and (max-width: 480px) {
    .block-container-icone-arrow {
        display: unset;
        padding-right: 20px;
    }
    .block-statut-a-cacher {
        display: block;
    }
    #mob-responsive {
        justify-content: space-between;
    }

    #mob-icone, #date, #montant, #facture, #statut, #actions, #periode, #type, #nbr-commande, #nbr-delivered, #canal, #processed {
        display: block;
        color: #0F52BA;
        font-family: bentonsans-medium;
        font-size: 12px;
    }

    #mob-reference {
        display: unset;
        background: #0F52BA;
        color: white;
        border-radius: 10px;
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 2px;
        font-family: bentonsans-medium;
        font-size: 14px;

        i {
            margin-right: 5px;
        }
    }

    #actions {
        position: relative;
    }

    #actions::after {
        content: '';
        position: absolute;
        left: 20%;
        right: 5%;
        top: 25%;
        background: #000;
        z-index: 0;
        border: 0.15pt solid #E2E2E2;
    }

    #actions {
        padding-bottom: 10px;
    }

    .list-line-cell:nth-child(4) {
        display: none;
    }

    .contrats-list-filters {
        flex-direction: column;
        align-items: self-start;
    }

    .list-head {
        font-size: 9px !important;
    }

    .list-line {
        font-size: 9px !important;
        background-color: #72BBFF0D;
        padding-left: 15px;
        border-radius: 10px;
        border: 2pt solid #0F52BA80;
        margin-bottom: 20px;
    }

    .invoices-list-filters {
        align-items: flex-start;
        flex-direction: column;
    }

    table {
        padding: 0 15px 0 15px;

        thead {
            display: none;
        }

        tr {
            display: block;
        }

        td {
            display: flex;
            align-items: center;
        }
    }

    .contrats-list-content .list-line-cell:nth-child(2), .contrats-list-content .list-line-cell:nth-last-child(2) {
        border-radius: unset;
    }

    .list-line-cell {
        background: unset;
        padding: 0px 0px 10px 0px;
    }

    .list-margin {
        display: none;
    }

    .list-line-selected {
        .list-line-cell {
            background: unset;
            border-top: unset;
            border-bottom: unset;
        }

        .list-line-cell:nth-child(2) {
            border-left: unset;
        }

        .list-line-cell:nth-last-child(2) {
            border-right: unset;
        }
    }
    .cell-flexed {
        width: 100%;
    }

    .list-line-cell, .list-line-cell a {
        font-family: bentonsans-medium;
        font-size: 16px;
        color: #2F485C;
    }

    .statut_signe {
        color: #92B90D;
    }

    .statut_a_signer {
        color: #FF0000;
    }

    .statut_en_cours {
        color: #F09A04;
    }

    .statut_refuse {
        color: #707070;
    }

    .list-line-cell {
        background: unset;
    }

    .list-line-cell:first-child, .list-line-cell:last-child {
        background: unset;
    }

    .affichage-desktop {
        display: none;
    }

    .number-of-selected {
        padding: 6px 9px;
        border-radius: 5px;
        background: $backgroundHeadColor;
        font-size: 12px;
        font-family: BentonSans-Medium, sans-serif;
        color: $colorText;
        display: none;
        max-width: 65%;
    }

    &-block {
        &-content {
            margin-top: 20px;
        }
    }
}
