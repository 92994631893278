@charset "UTF-8";

.tooltip_toggle{
    @include border-radius(50%);
    display:inline-block;
    width:17px;
    height:17px;
    line-height:17px;
    position: absolute;
    border-style:solid;
    border-width: 1px;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
    cursor: pointer;
    font-size:11px;
    font-family:'bentonsans-condensedregular', sans-serif;
    text-align: center;
}
.tooltipster-base {
    @include border-radius(5px);
    @include box-shadow(0px 5px 30px 0px rgba(#485e68, 0.3));
    position: absolute;
    padding: 15px 25px;
    font-size:13px;
}
.tooltip_content{
    display: none ;
    .tooltipster-content &{
        display: block;
    }
}
