.ged-group-actions {
    text-align: center;
    display: inline-block;
    margin-bottom: 10px;

    .disabled {
        color: #7F96A8 !important;
    }

    &-title {
        font-size: 13px;
        font-family: bentonsans-regular, sans-serif;
        color: #2F485C;


        &-icon {
            padding: 2px 3px;
            color: $colorText;
        }
    }

    &-icons {
        min-width: 128px;
        padding: 10px 10px;
        border: 2px solid #E8E8E8;
        border-radius: 4px;
        background: #E8E8E8;
        display: flex;
        justify-content: space-between;


        div:disabled,
        div[disabled] {
            background: #9CBED9 0% 0% no-repeat padding-box;
            cursor: default !important;
        }

        &-icon {
            padding: 6px 8px;
            border-radius: 50%;
            background: #2F485C;
            font-size: 14px;
            color: #FFFFFF;
        }

        &-icon:hover {
            cursor: pointer;
        }
    }
}

/*.bloc-1 {
    display: none;
}

.bloc-2 {
    display: none;
}*/
.affichage-mob {
    display: none;
}

@media screen and (max-width: 480px) {
    .affichage-mob {
        display: unset;
    }

    .ged-group-actions {
        font-family: bentonsans-regular;
        font-size: 13px!important;
        color: #2F485C;
        display: flex;
        width: 100%;
        text-align: unset;
        align-items: center;

        .bloc-1 {
            width: 60%;
            align-items: flex-start;
        }
    }

}

.tooltipp {
    position: relative;
    display: inline-block;
}

.tooltiptext {
    visibility: hidden;
    font-size: 12px;
    font-family: BentonSans-Medium, sans-serif;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    color: #2F485C;
    white-space: nowrap;
    margin-top: -18px;
    margin-left: -76px;
}

.tooltipp:hover .tooltiptext {
    visibility: visible;
}
