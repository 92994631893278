.simotc-loader-wrapper {
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 10050;
  background-color: rgba(255, 255, 255, 0.4);
  top: 0;
  left: 0;
  @extend %display-flex;
  align-items: center;
  justify-content: center;
  display: none;

  .loader {
    border: 6px solid $simotc-light-grey;
    border-radius: 50%;
    border-top: 6px solid $simotc-dark-blue;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}