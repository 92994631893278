@charset "UTF-8";

$border-color: lighten($grey-lighter, 5%);

.contrat_listing {
    .chapeau {
        margin: 0 0 20px 0;
    }
}

.contrat-block {
    margin: 0 0 40px 0;
    padding-bottom: 30px;
}

// Block souscription au contrat
.block-econtrat-content {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0px 3px 6px #8484840D;
    border-radius: 8px;
    padding: 15px 15px 30px 15px;
    font-size: 12px;
    margin: 0 7vw;

    span {
        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
        font-size: 22px;
        color: #0F52BA;

        p {
            padding-top: 27px;
            text-align: center;
        }
    }

    h3 {
        font-size: 15px;
        font-style: normal;
        color: #292929;
        text-align: center;
        line-height: 2em;
        font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
    }
}


.block-econtrat-row {
    display: table;
    margin: 0 0 20px 0;
}

.block-econtrat-col {
    width: 32%;
    display: table-cell;
    vertical-align: top;
    padding: 0 30px;
    text-align: center;
    font-size: 12px;
    border-right: 1px solid $border-color;

    &:last-child {
        border-right: none;
    }

    [class^="ic-"],
    [class*=" ic-"] {
        display: block;
        color: $blue-base;
        font-size: 35px;
        margin: 0 0 10px 0;
    }
}

.block-econtrat-content-retour {
    text-align: center;

    .btn {
        position: relative;
        color: #FFFFFF;
        background: #CB2F7B 0 0 no-repeat padding-box;
        padding: 14px 20px;
        border-radius: 4px;
        letter-spacing: unset;
        font-size: 14px;
        font-family: Bentonsans-bold, sans-serif;
        text-transform: none;
    }

    .btn:hover {
        background-color: $pink-base-light;
    }
}


// Form
.block-econtrat-form {
    @include transition-ease-in-out();
    position: relative;
    margin: 50px auto 0;
    border: 2px solid $pink-base;
    border-radius: 4px;
    width: 55%;
    height: 50px;
    overflow: hidden;

    .block-econtrat-form-fieldset {
        position: relative;
        float: left;
        width: 70%;
    }

    .block-econtrat-form-submit {
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
        height: 100%;
        background-color: $pink-base;

        .btn {
            padding: 0 20px;
            font-size: 11px;
            font-family: BentonSans-Medium, sans-serif;
            text-transform: none;
            letter-spacing: unset;
        }
    }

    &.open {
        height: 125px;
    }
}

.block-econtrat-content-mandatory {
    margin: 5px auto 30px;
    width: 55%;
    font-size: 11px;
    font-family: bentonsans-regular, sans-serif;
    color: #707070;
}

.block-econtrat-form-optin-row {
    display: table;
    padding: 15px;

    .block-econtrat-form-checkboxlabel,
    .block-econtrat-form-optin {
        display: table-cell;
        vertical-align: middle;
    }

    .block-econtrat-form-checkboxlabel {
        display: inline-block;
        padding: 0 0 0 10px;
        margin: 0;
        line-height: 1em;
        font-size: 11px;
        font-style: normal;
        color: $pink-base;
    }

    .icheckbox {
        border: 1px solid $pink-base;
    }
}

.toggleFormregisterContrat {
    //display: none;
    padding: 0 15px;
    margin: 10px 0 0 0;

    .form-group {
        width: 47.5%;
        display: inline-block;
        vertical-align: top;

        &:first-child {
            margin-right: 4%;
        }
    }
}


// Block contrat deja souscrit
.block-econtrat-subscribed {
    display: table;
    width: 100%;
    table-layout: fixed;

    .spacer {
        display: table-cell;
        width: 2%;
    }
}

.block-econtrat-subscribed-leftcol,
.block-econtrat-subscribed-rightcol {
    display: table-cell;
}

.block-econtrat-subscribed-leftcol {
    width: 60%;
    border: 1px solid $border-color;
    @include border-radius(6px);
}

.block-econtrat-subscribed-rightcol {
    width: 38%;
    vertical-align: middle;
}

.block-econtrat-subscribed-footer {
    display: table;
    border-top: 2px dotted;
    @include border-btm-left-radius(6px);
    @include border-btm-right-radius(6px);
    border-top: 2px dotted $border-color;
    background-color: $blue-light;
    display: table;
    width: 100%;
}

.block-econtrat-subscribed-footer-leftcol,
.block-econtrat-subscribed-footer-rightcol {
    width: 50%;
    display: table-cell;
    padding: 20px 10px;
    text-align: center;

    [class^="ic"] {
        font-size: 25px;
        vertical-align: middle;
        color: $blue-base;
    }
}

.block-econtrat-subscribed-footer-leftcol {
    border-right: 1px solid $border-color;
}

.block-econtrat-subscribed-rightcol {
    border: 1px solid $pink-base;
    text-align: center;
    padding: 20px;
    color: $grey-blue;

    .block-econtrat-subscribed-scli {
        font-family: 'bentonsans-condensedregular', sans-serif;
    }

    a {
        font-family: 'bentonsans-medium', sans-serif;
        color: $pink-base;
        white-space: nowrap;
    }
}

.block-econtrat-subscribed-aplocal {
    font-size: 12px;
    color: $pink-base;
    display: block;
}

// Block Montants reçus
.block-amounts-received-blocklist {
    list-style: none;
    display: table;
    width: 100%;
    table-layout: fixed;

    li {
        display: table-cell;
        position: relative;

        .tooltip_toggle {
            top: 15px;
            right: 15px;
        }
    }

    .spacer {
        width: 2%;
    }
}

.block-amounts-received-block {
    padding: 15px 20px;
    color: $blue-base;
    font-family: 'bentonsans-medium', sans-serif;

    span {
        display: block;
        font-size: 31px;
        color: $grey-blue;
        font-family: 'bentonsans-light', sans-serif;
        line-height: 1em;
        white-space: nowrap;
    }
}

.block-amounts-received-block1,
.block-amounts-received-block2 {
    border: 2px solid $grey-lighter;
}

.block-amounts-received-block3 {
    border: none;
    background-color: $blue-base;
    color: $white;

    span {
        color: $white;
    }
}


///*************************************
//* Media queries
//*************************************/
@media screen and (max-width: 980px) {
    .block-econtrat-form {
        width: 97%;
    }

    .block-amounts-received-block {
        span {
            font-size: 26px;
        }
    }
}

@media screen and (max-width: 767px) {
    .block-amounts-received-blocklist li {
        display: block;
        margin: 0;
        padding: 15px 4%;

        span {
            margin: 10px 0 0 0;
            font-size: 30px;
        }

        &.spacer {
            display: none;
        }

        &.block-amounts-received-block3 {
            clear: both;
            padding: 25px 4%;

            span {
                margin: 0;
                padding: 0 0 0 10%;
                display: inline-block;
                vertical-align: middle;
                font-size: 35px;
            }
        }

        &.block-amounts-received-block1,
        &.block-amounts-received-block2 {
            float: left;
            width: 50%;
        }

        &.block-amounts-received-block1 {
            border-right: none;
        }
    }
}

@media screen and (max-width: 620px) {
    .block-econtrat-form {
        //height:auto;
        width: 100%;

        .block-econtrat-form-fieldset,
        .block-econtrat-form-submit {
            width: 100%;
            float: none;
        }

        .block-econtrat-form-submit {
            height: 50px;
        }

        &.open {
            height: auto;
        }
    }

    .block-econtrat-content {
        border: none;
        padding: 0;
        padding-bottom: 25px;

        h3,
        .block-econtrat-row {
            display: none;
        }
    }

    .block-econtrat-subscribed {
        display: block;

        .spacer {
            display: none;
        }

        .block-econtrat-subscribed-leftcol {
            margin: 0 0 20px 0;
        }

        .block-econtrat-subscribed-leftcol,
        .block-econtrat-subscribed-rightcol {
            display: block;
            width: 100%;
        }
    }

}

@media screen and (max-width: 460px) {
    .toggleFormregisterContrat .form-group {
        width: 100%;
    }

    .block-amounts-received-blocklist li {

        &.block-amounts-received-block1,
        &.block-amounts-received-block2 {
            float: none;
            width: 100%;
            border: 2px solid $grey-lighter;
        }

        &.block-amounts-received-block1 {
            border-bottom: none;
        }

        &.block-amounts-received-block3 span {
            display: block;
            margin: 10px 0 0 0;
            padding: 0;
        }
    }

    .block-econtrat-content {
        margin: 0 25px;
        padding-bottom: 25px;
    }

    .block-econtrat-form {
        width: 90%;
    }
}