.hospital-form {
    background-color: #F9F7F6;
    color: #485E68;

    &-header {
        position: relative;

        &-bg {
            img {
                width: 100%;
                margin: unset;
            }
        }

        @media screen and (max-width: 767px) {
            &-bg {
                margin-left: -125%;

                img {
                    width: unset;
                    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
                }
            }
        }

        &-content {
            position: absolute;
            bottom: 9px;
            width: 100%;

            &-img {
                img {
                    margin: 0 auto;
                }

                @media screen and (max-width: 767px) {
                    img {
                        width: 282px;
                    }
                }
            }

            &-text {
                margin: 22px 36% 0 36%;
                text-align: center;
                font-weight: 700;
            }

            @media screen and (max-width: 767px) {
                &-text {
                    font-size: 14px;
                    font-family: bentonsans-regular, sans-serif;
                    margin: 22px 16% 0 16%;
                }
            }
        }
    }

    &-content {
        width: 100%;
        padding: 9px 0;

        &-form {
            width: 26%;
            margin: 0 auto;

            &-bloc {
                margin-bottom: 13.5px;

                .hospital-form {
                    &-label {
                        font-size: 14px;
                        font-family: bentonsans-bold, sans-serif;
                        display: unset;
                        width: unset;
                        margin: unset;
                        position: unset;
                        left: unset;
                        top: unset;
                    }

                    &-input {
                        margin-top: 4px;
                        padding: 10px;
                        border-radius: 4px;
                        font-size: 13px;
                        font-family: bentonsans-regular, sans-serif;
                    }
                }

                &-cb {
                    margin-bottom: 13.5px;
                    display: flex;

                    .cb {
                        &-label {
                            position: relative;
                            margin: 0 9px 0 0;

                            .icheckbox {
                                width: 20px;
                                height: 20px;
                                position: unset;
                                background-color: #ffffff;
                            }
                        }

                        &-text {
                            font-size: 14px;
                            font-family: bentonsans-regular, sans-serif;

                            a {
                                color: #485E68;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .text-bold {
                    font-weight: 700;
                }
            }

            &-btn {
                margin: 20px 0 32px 0;
                text-align: center;

                .btn-send {
                    padding: 11px 18px;
                    font-size: 14px;
                    font-family: bentonsans-bold, sans-serif;
                    text-transform: uppercase;
                    background-color: #00A1E4;
                    color: #ffffff;
                    border-radius: 8px;

                    i {
                        margin-left: 5px;
                    }
                }

                .btn-send:hover {
                    background-color: #ffffff;
                    color: #00A1E4;
                    border: 2px solid #00A1E4;
                }
            }

            @media screen and (max-width: 767px) {
                &-btn {
                    margin: 25px 0 0 0;
                }
            }
        }

        @media screen and (max-width: 767px) {
            &-form {
                width: 90%;
            }
        }
    }

    @media screen and (max-width: 767px) {
        &-content {
            margin: 20px 0;
        }
    }

    &-footer {
        margin: 0 auto;
        width: 26%;
        font-size: 11px;
        font-family: bentonsans-regular, sans-serif;

        &-po {
            margin: 77px 0 -35px 0;
            font-size: 10px;
            text-align: center;
        }

        @media screen and (max-width: 767px) {
            &-po {
                margin: 60px 0 10px 0;
            }
        }

        &-banner {
            img {
                width: 100%;
                margin: unset;
            }
        }
    }

    @media screen and (max-width: 767px) {
        &-footer {
            width: 90%;
        }
    }
}
