@charset "UTF-8";

.breadcrumb{
    border-bottom:1px solid $grey-lighter;
    padding:19px 0 15px 7.5vw;
    background-color: $white;
    margin-bottom: 20px;
    a{
        font:normal 13px/1em 'bentonsans-ultracondensed', sans-serif ;
        text-transform: uppercase;
        color: $grey-medium;
        letter-spacing:1.5px;
        @include hover-focus-active{
            color: $black;
        }
    }
    span{
        padding:0 15px 0 0;
        color: $orange-base;
        font-size:13px;
        .pharmacien-engagements &{
            color: #000000;
        }
        .news_details &, .news_listing &{
            color: $green-base2;
        }
        [class^="pharmacien-news"] &{
            color: $green-base2;
        }
        .news_listing &{
            color: $green-base2;
        }
        .pharmacien-services-patients &{
            color: $green-base;
        }
    }
}

@media screen and (max-width: 1200px) {
    .breadcrumb {
        padding: 19px 0 15px 4vw;
    }
}
