.customer-service-block {
    height: 100%;
    border-radius: 8px;
    background-image: url("../img/promotional-block/customer-service.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'bentonsans-medium', sans-serif;
    color: #F4F4F4;

    &-text {
        margin-top: 40px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        &-btn {
            width: 155px;
            margin: 16px auto;
            display: inline-block;
            border-radius: 25px;
            padding: 10px 10px;
            font-size: 16px;
            color: #7bbbfe;
        }

        &-footer {
            font-size: 12px;
        }
    }
}
