.block-my-pharmacy {
    border-left: 3px solid $green-base;

    &-header {
        background: $green-base;
    }

    &-icon-my-officine {
        margin: unset;
        width: 35px;
    }

    &-content {
        &-block {
            margin-bottom: 25px;

            &-header {
                padding-bottom: 2px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $green-base;
                border-bottom: 2px solid $green-base;

                &-left {
                    font-family: bentonsans-bold, sans-serif;
                }

                &-right {
                    .plus-icon {
                        a {
                            font-size: 19px;
                            color: $green-base;
                        }
                    }

                    .add-member-link {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        font-family: Bentonsans, sans-serif;

                        a {
                            color: $green-base;
                            text-decoration: underline;
                        }

                        i {
                            margin-right: 5px;
                            font-size: 16px;
                            text-decoration: none;
                        }
                    }

                    .update-grpt-link {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        font-family: bentonsans-regular, sans-serif;
                        color: #E54725;

                        a {
                            color: #E54725;
                            text-decoration: underline;
                        }

                        i {
                            margin-right: 5px;
                            color: #E54725;
                            font-size: 16px;
                            text-decoration: none;
                        }
                    }
                }
            }

            &-content {
                .contact-section {
                    margin-top: 15px;

                    &-title {
                        margin-bottom: 5px;
                        font-family: bentonsans-regular, sans-serif;
                        color: #485E68;
                        font-size: 12px;
                    }

                    &-title:before {
                        content: "\2022";
                        color: $green-base;
                        font-weight: bold;
                        display: inline-block;
                        width: 10px;
                    }

                    &-text {
                        display: flex;
                        margin-left: 1.1em;
                        padding-bottom: 15px;
                        font-family: bentonsans-bold, sans-serif;
                        font-size: 14px;
                        color: #485E68;

                        &-delegate {
                            width: 50%;
                        }

                        &-phone {
                            width: 50%;

                            i {
                                transform: rotate(90deg);
                                margin-right: 8px;
                            }

                            a {
                                color: #485E68;
                                border-bottom: 1px solid #485E68;
                                padding-bottom: 2px;
                            }
                        }
                    }
                }

                .team-section {
                    margin-left: 15px;

                    &-description {
                        margin: 10px 0;
                        font-size: 12px;
                        font-family: bentonsans-regular, sans-serif;
                    }

                    &-line {
                        margin: 10px 0;
                        display: flex;
                        align-items: center;
                        font-family: Bentonsans-bold, sans-serif;

                        &-number {
                            margin-right: 10px;
                            font-size: 11px;

                            span {
                                width: 19px;
                                height: 19px;
                                padding: 4px 7px;
                                border-radius: 25px;
                                background-color: $green-base;
                                color: #FFFFFF;
                            }
                        }

                        &-text {
                            font-size: 12px;
                            color: #485E68;
                        }
                    }
                }

                .grpt-section {
                    margin-left: 15px;

                    &-name {
                        margin: 10px 0;
                        font-size: 12px;
                        font-family: bentonsans-bold, sans-serif;
                        color: #485E68;
                    }
                }
            }
        }

        &-block:last-child {
            margin-bottom: 0;
        }
    }
}
