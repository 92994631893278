@charset "UTF-8";

body.homepage {
  .alert-browser {
    text-align: center;
    .img_oldBrowser {
      margin: 0 auto;
      padding: 40px 0;
    }
    hr{
      margin: 0;
    }
    p.titreCheckBrowser {
      color: #485e68;
      font-size: 24px;
      text-decoration: underline;
      text-decoration-color: #b2b9bd;
      padding: 55px 0 20px 0;
      line-height:30px;
    }
    p.contentCheckBrowser{
      padding-bottom: 50px;
      font-size: 20px;
      line-height: 27px;
      color: #a8babd;
    }
  }
}


