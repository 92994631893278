@charset "UTF-8";

.contact-form-loggedin{
    @include border-radius(15px);
    margin:0 0 60px 0;
    padding:45px 13%;
    border: 1px solid $grey-lighter;
    #contact_message{
        margin:0;
    }
    .required-legend{
        text-align: left;
        margin:0 0 20px 0;
    }
    .contact-submit{
        text-align: right;
        button[type="submit"]{
            width:45%;
        }
    }
    textarea{
        height:150px;
        padding:10px 15px 10px 0;
    }
    #contact_message + span.required{
        top:14px;
    }
}

@media screen and (max-width: 767px) {
    .contact-form-loggedin{
        padding:30px 5%;
        margin:0 0 30px 0;
    }
}

.blocs_client-help-txt {
    padding-left: 25px;
    padding-right: 25px;
}
