.simotc-calculator-price-charts {
    justify-content: center;
    background-color: $simotc-blue;
    height: 60%;
    color: $white;
    border-radius: 0 0 5px 5px;
    text-transform: uppercase;

    @media handheld, screen and (max-width: 768px) {
        height: inherit;
        justify-content: space-around;
    }

    .simotc-calculator-doughnut-container {
        @extend %display-flex;
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 50%;

        .simotc-calculator-doughnut-chart {
            padding: 0;
            margin: 0;
            height: 120px;

            #doughnut-chart {
                width: 120px;
                height: 120px;
            }
        }

        .simotc-calculator-doughnut-legend {
            @extend %display-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .simotc-calculator-doughnut-chart-percentage {
                font-size: 25px;
                font-family: bentonsans-bold;
            }

            .simotc-calculator-doughnut-chart-margin {
                font-size: 13px;
                font-family: bentonsans-condensedbold;
            }

        }

        @media handheld, screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            width: inherit;

            .simotc-calculator-doughnut-chart {
                margin: 0;

                #doughnut-chart {
                    width: 100px;
                    height: 100px;
                }
            }
        }

    }

    .simotc-calculator-bar-container {
        @extend %display-flex;
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-width: 140px;
        width: 50%;

        @media handheld, screen and (max-width: 768px) {
            width: inherit;
        }

        #simotc-calculator-bar-background {
            width: 30px;
            height: 75%;
            margin-bottom: 30px;
            background-color: white;
            margin-left: 5px;
            transform: rotate(180deg);

            #simotc-calculator-bar {
                width: 30px;
                height: 0;
                border-bottom: 4px solid $white;
            }

        }

        #simotc-calculator-bar-legend {
            height: 75%;
            @for $i from 1 through 100 {
                .bar-legend-#{$i} {
                    height: 1%;
                    font-size: 10px;
                    font-weight: bold;
                    text-align: right;
                }
            }

            .bar-legend {
                margin-top: -5px;
            }
        }

        .simotc-calculator-bar-description {
            height: 100%;
            width: 92px;
            position: relative;
        }
    }

}

.tippy-popper[x-placement^='right'] {
    .tippy-tooltip {
        .tippy-arrow {
            border-right-color: white;
        }
    }
}

.tippy-tooltip {
    background-color: white;
    border-radius: 0;
    height: 32px;
    width: 76px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    .tippy-arrow {
        margin: 0;
        padding: 0;
    }

    .tippy-content, .tippy-backdrop {
        color: $simotc-dark-blue;
        font-size: 23px;
        font-family: bentonsans-condensedbold;
        line-height: 23px;
    }

    &::after{
        content: attr(data-bar-description);
        color: white;
        font-family: bentonsans-condensedbold;
        font-size: 13px;
        text-transform: uppercase;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        top: 42px;
        width: 100%;
    }
}
