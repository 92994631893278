@charset "UTF-8";
html{
    height:100%;
}
.error-page{
    height:100%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e1e8ed+0,ffffff+79 */
    background: #e1e8ed; /* Old browsers */
    background: -moz-linear-gradient(top, #e1e8ed 0%, #ffffff 79%) no-repeat; /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #e1e8ed 0%,#ffffff 79%) no-repeat; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #e1e8ed 0%,#ffffff 79%) no-repeat; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e8ed', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

    .page-wrap{
        height:100%;
        margin-bottom:-99px;
    }
    .page-wrap:after {
        content: "";
        display: block;
    }
    .container{
        display: table;
        height:calc(100% - 149px);
    }
    footer{
        border-top:none;
        padding:0;
        height:99px;
    }
    header{
        padding:0 0 0 20px;
        height:50px;
    }
    h1{
        color: $blue-base;
        margin:0 0 50px;
    }
    h2{
        margin:0 0 60px;
    }
    .error-col{
        display: table-cell;

        &.error-leftcol{
            width:40%;
            vertical-align: middle;
        }
        &.error-rightcol{
            width:60%;
            vertical-align: bottom;
            position: relative;
        }
    }
    .error-code{
        margin:0 0 200px 0;
        font-family:'bentonsans-thin', sans-serif;
        color: rgba($blue-base, 0.2);
        font-size:230px;
        line-height:1em;
        text-align: center;
    }
    .error-txt{
        position: relative;
        font-size:14px;
        line-height:26px;
        margin:0 0 40px 0;
        &::before {
            content: "";
            display: block;
            height: 1px;
            width:26px;
            background-color: $blue-base;
            position: absolute;
            left: -40px;
            top: 10px;
        }
    }
    .btn{
        padding:18px 50px;
    }
    .error-img{
        position: absolute;
        bottom:0;
        right:0;
        margin:0;
    }
}

@media screen and (max-width: 980px) {
    .error-page{
        .error-code{
            font-size:180px;
        }
    }
}
@media screen and (max-width: 767px) {
    .error-page{
        .hdr-logo{
            margin:8px 0 0 0;
        }
        .page-wrap {
            height: auto;
            margin:0;
        }
        .container{
            display: block;
            height:auto;
            padding:25px;
        }
        .error-col{
            display: block;

            &.error-leftcol{
                width:100%;
                margin:0 0 25px 0;
                text-align: center;
            }
            &.error-rightcol{
                width:100%;
                text-align: right;
            }
        }
        h1{
            margin:0 0 20px 0;
        }
        h2{
            margin:0 0 20px 0;
        }
        .error-img{
            position: static;
            display: inline-block;
            margin:0 -25px 0 0;
        }
        .error-code{
            margin:0;
        }
        .error-txt{
            &::before{
                display: none;
            }
        }
    }
}
@media screen and (max-width: 580px) {
    .error-page{
        .error-code{
            font-size:150px;
        }
    }
}
