@charset "UTF-8";

.blocs_client-pagebtm{
    display: table;
    width:100%;
    table-layout:fixed;
    .spacer{
        width:3%;
    }
}
/* Bloc scli */
.blocs_client-scli{
    display: table-cell;
    width:42%;
    margin:0 20px 0 0;
    padding:30px;
    background-color: $grey-really-lighter;
}
.blocs_client-scli-txt{
    font-size:12px;
    color: $grey-light;
    margin:0 0 5px 0;
}
.blocs-client-scli-nb{
    font-size:25px;
    color: $blue-base;
    .ic-phone2{
        position: relative;
        top:5px;
        font-size:30px;
    }
}
.blocs_client-scli-note{
    font-size:10px;
    color: $grey-light;
    margin:0;
}

/* Bloc help */
.blocs_client-help{
    width:55%;
    padding:0 10px;
    display: table-cell;
    position: relative;
    vertical-align: middle;
    text-align: center;
    background-color: $blue-base;
    color: $white;
    .ic-scli{
        font-size:90px;
    }
    .hc-block-footerlink{
        width:100%;
    }
}

@media screen and (max-width: 767px) {
    .blocs_client-pagebtm {
        .spacer {
            display: none;
        }
    }
    .blocs_client-scli,
    .blocs_client-help{
        display: block;
        width:100%;
    }
    .blocs_client-help{
        margin:20px 0 0 0;
        .hc-block-footerlink{
            position: static;
            margin:0 -10px;
            width: auto;
        }
    }
}
