@charset "UTF-8";

.hc-block-indicateurs {
    border-left: 2px solid $blue-base;

    .hc-block-indicateurs-otc {

        border: 1px solid $grey-blue-light;
        margin: 15px;
        padding: 15px;
        border-radius: 5px;
    }
}

.dashboard-bordercol {
    .hc-block-indicateurs-otc {
        margin: 0;
        padding: 10px;
        border-radius: 5px;

        @media screen and (max-width: 1302px) and (min-width: 1002px) {
            .hc-block-engagez {
                background-size: auto 200%;
            }
        }

        .hc-block-footerlink {
            position: relative;
            width: 80%;
            float: right;
        }
    }
}

.hc-block-indicateurs, .dashboard-bordercol {
    .hc-block-indicateurs-otc {
        .hc-block-content-top {
            color: $grey-medium;
            margin-bottom: 10px;
            font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
            font-size: 15px;
            font-weight: normal;
        }

        .hc-block-content-sep {
            position: relative;

            &::before {
                display: block;
                content: " ";
                background: $blue-dark3;
                position: absolute;
                left: 0;
                top: -1px;
                height: 3px;
                width: 30px;
            }
        }

        .hc-block-bubble {
            background: $blue-dark3;
            border-radius: 5px;
            position: relative;
            padding: 7px 10px;
            color: $white;
            text-transform: uppercase;
            width: auto;
            display: inline-block;
            margin: -15px 30px;

            &::before, &::after {
                content: "";
                position: absolute;
                border: 0;
                display: block;
                width: 0;
                border-style: solid;
                bottom: -15px;
                left: 30px;
            }

            &::before {
                border-left-width: 24px;
                border-bottom-width: 15px;
                border-color: transparent $blue-dark3;
            }

            &::after {
                border-left-width: 8px;
                border-bottom-width: 15px;
                border-color: transparent #E9EEF1;
            }


        }

        .hc-block-flex {
            display: flex;
            align-items: flex-end;
            justify-content: space-around;

            img {
                height: 40px;
                width: auto;
                margin: 0 0 10px;
            }

            p {
                width: 80%;
                margin: 0 15px 8px;
            }
        }

        .block-otc-offer-container {
            width: 100%;
        }

        @media screen and (max-width: 1250px) and (min-width: 768px) {
            .block-otc-offer-container {
                display: flex;
                flex-direction: column;
            }
        }

        .block-otc-offer-item-container {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            flex-direction: column;
        }

        .block-otc-offer-item-container:not(:last-child) {
            border-bottom: 1px solid;
        }
        .block-otc-offer-item {
            display: flex;
            flex-direction: row;
            padding: 15px;
            justify-content: space-between;
        }

        .block-otc-offer-year-preorder {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

        }
        .block-otc-offer-year {
            display: flex;
            color: black;
            font-weight: bold;
            text-transform: uppercase;
        }
        .block-otc-offer-preorder {
            display: flex;

            p.titre-indicateurs {
                color: #858d93;
                margin: 0;
                width: 100%;
            }
        }

        .block-otc-offer-bubble {
            margin: 0px 10px 20px 10px;
            text-align: center;
            padding: 10px 10px;
        }

        .block-otc-offer-icon {
            width: 35px !important;
            height: 35px !important;

            grid-column: 1 span;
        }

        .block-otc-exclued-dashboard-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .block-otc-exclued-dashboard-text {
            color: #af2323;
            font-size: 18px;
            font-weight: 800;
            font-family: Bentonsans Condensed, sans-serif;
        }

        .hc-block-engagez {
            margin-top: 15px;
            padding-bottom: 5px;
            background: #ecf0f3 url('../img/dashboard/visuel-engagez-vous_2x.png') no-repeat right center;
            background-size: auto 200%;
            width: 100%;
            min-height: 120px;

            img {
                width: 25px;
                margin: 8px 0 0 0;
            }

            .hc-block-content-sep {
                position: relative;
                margin-left: 10px;
                margin-bottom: 7px;
                width: 140px;

                &::before {
                    display: none;
                }
            }

            p {
                font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
                font-size: 13px;
                padding: 0 45% 0 10px;
                line-height: 1.5em;
                margin: 0 0 10px 0;
            }

            p:first-child {
                font-size: 13px;
                color: $blue-dark3;
                padding: 30px 150px 10px 15px;
                margin: 0 0 10px 0;
                position: relative;
                text-transform: uppercase;
                line-height: 0.8em;

                &::after {
                    background: $grey-blue-light;
                    display: block;
                    content: " ";
                    position: absolute;
                    height: 1px;
                    bottom: 0;
                    left: 15px;
                    width: 40%;
                }

            }

            + .hc-block-bigfooter {
                max-width: 80%;
                line-height: 1.6em;
                padding: 7px 0;
            }

            @media screen and (max-width: 450px) {
                background: #ecf0f3;
                p {
                    padding: 0 15px !important;

                    &:first-child {
                        padding: 30px 15px 10px 15px !important;
                    }
                }
                + .hc-block-bigfooter {
                    width: auto;
                    line-height: 2.2em;
                    max-width: 90%;
                }

            }
            @media screen and (max-width: 1001px) and (min-width: 768px) {
                background: #ecf0f3;
                p {
                    padding: 0 15px !important;

                    &:first-child {
                        padding: 30px 15px 10px 15px !important;
                    }
                }
                + .hc-block-bigfooter {
                    width: auto;
                    line-height: 2.2em;
                    max-width: 90%;
                }

            }
        }

        .hc-block-commandes {
            margin: 15px 0 0;
            border-radius: 0;
            border: 1px solid $grey-blue-light4;
            padding: 20px 0 20px 15px;

            p {
                text-transform: uppercase;
                color: $grey-litige;
                font-family: 'bentonsans-condensedregular', Arial, Helvetica, sans-serif;
                font-size: 17px;
                margin: 0 0 10px 0;

                &.small {
                    font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
                    font-size: 12px;
                    color: $grey-popin-litige;
                }
            }

            .hc-block-commandes-etats {
                display: flex;
                align-items: center;
                justify-content: stretch;
                position: relative;
                height: 100px;

                > div {
                    z-index: 10;
                    flex-basis: 25%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    &::before {
                        display: block;
                        content: " ";
                        position: absolute;
                        background-color: $grey-lighter;
                        width: 1px;
                        height: 34%;
                        top: 30px;
                        left: 50%;
                        z-index: 0;
                    }

                    > span {
                        margin: 5px 0;
                        z-index: 20;
                        position: relative;
                    }

                    .etat {
                        border-radius: 5px;
                        padding: 5px;;
                        min-width: 70px;
                        text-align: center;
                        font-size: 12px;
                        color: $white;
                        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                    }

                    .bullet {
                        display: block;
                        border-radius: 10px;
                        width: 10px;
                        height: 10px;
                    }

                    .infos {
                        font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        color: $grey-medium;
                        text-transform: uppercase;
                    }

                    &.grey {
                        .etat, .bullet {
                            background-color: $grey-midlighter;
                        }
                    }

                    &.orange {
                        .etat, .bullet {
                            background-color: $orange2;
                        }
                    }

                    &.blue {
                        .etat, .bullet {
                            background-color: $blue-otc;
                        }
                    }

                    &.printemps {
                        .etat, .bullet {
                            background-color: $printemps-otc;
                        }
                    }

                    &.ete {
                        .etat, .bullet {
                            background-color: $ete-otc;
                        }
                    }

                    &.automne {
                        .etat, .bullet {
                            background-color: $hiver-otc;
                        }
                    }

                    &.implantation {
                        .etat, .bullet {
                            background-color: $implantation-otc;
                        }
                    }


                    &.past {
                        .etat, .bullet {
                            background-color: $grey-midlighter;
                        }
                    }

                    &.current {
                        .etat, .bullet {
                            background-color: $orange2;
                        }
                    }

                    &.future {
                        .etat, .bullet {
                            background-color: $blue-otc;
                        }
                    }
                }

                &::after {
                    z-index: 0;
                    display: block;
                    content: ' ';
                    position: absolute;
                    width: 87%;
                    left: 13%;
                    top: 55%;
                    height: 1px;
                    background-color: $grey-lighter;
                }
            }
        }
    }
}

.hc-block-indicateurs {
    .hc-block-indicateurs-otc {
        .hc-block-engagez {
            @media screen and (max-width: 1302px) and (min-width: 1176px) {
                background-size: auto 150%;
            }
            @media screen and (max-width: 1175px) and (min-width: 1002px) {
                background-size: auto 100%;

                .hc-block-bubble {
                    padding: 7px 4px;
                    margin: -15px 10px;
                }
            }
        }
    }
}

/****** right col ****/
.dashboard-rightcol {
    .header-with-ic.header-otc {
        .header-with-ic-icon {
            background-color: $blue-dark4;

            img {
                width: 40px;
                height: auto;
                margin: 0 auto;
            }
        }
    }
}

.pharmacien-otc {
    .otc__ventes {
        padding-top: 25px;
        border-top: 1px solid $grey-light;

        h2 {
            color: $blue-dark4;
            font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
            font-size: 21px;
            margin: 25px 0 15px;
        }

        h3 {
            color: $grey-blue-light2;
            font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
            font-size: 14px;
        }

        p.label {
            font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
            text-transform: uppercase;
            letter-spacing: 0.12em;
            font-size: 1.2em;
            color: $blue-dark4;
            padding: 20px 0 25px 0;
            margin-bottom: 0;
        }

        .ventes__livraison {
            margin: 30px 0;
            display: flex;
            max-width: 80%;
            justify-content: flex-start;

            span {
                margin-right: 30px;
            }

            .label-ventes-livraison {
                font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
                color: $blue-dark4;
                text-transform: uppercase;
                letter-spacing: 0.12em;
                font-size: 1.2em;
            }

            div > .livraison__saison {
                border: 1px solid $blue-light2;
                padding: 2px 15px;
                text-align: center;
                color: $grey-blue-light2;
                font-size: 13px;

                &.active {
                    color: $blue-otc;
                    background: $grey-bodybg;
                }

                &:first-child {
                    border-radius: 5px 0 0 5px;
                }

                &:last-child {
                    border-radius: 0 5px 5px 0;
                }
            }
        }

        //Tableaux
        .contratlist-row {
            li {
                width: 8%;

                &:last-child {
                    border: 1px solid #e5eaed;
                    color: $blue-otc;
                }
            }

            .cell-cata-pic {
                width: 10%;
            }

            .cell-cata-nom {
                width: 20%;
            }

            .cell-cata-remise {
                color: $pink-base;
            }
        }
    }

    .tf_otc_head_ref_container {
        display: flex;
        align-items: flex-end;
        justify-content: space-evenly;
    }

    .tf_otc_head_ref {
        color: white;
        background-color: #5567AC;
        height: 35px;
        padding: 10px;
        text-align: center;
        font-size: 18px;
        text-decoration: none;

        &--active {
            height: 45px;
            padding: 10px;
            font-size: 24px;
            background-color: #263981;
        }

        &--borderTLR {
            border-top-left-radius: 5px 5px;
        }

        &--borderTRR {
            border-top-right-radius: 5px 5px;
        }

        a {
            color: white;
        }
    }

    .tf_otc_alert_container {
        background-color: #f5f5f5;
        text-align: center;
        color: #4d6771;

        .title {
            margin-top: 15px;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;

            span {
                color: #5567AC;
            }
        }

        .sub-titile {
            font-size: 23px;
            font-weight: 500;
        }

        .otc-commitment {
            &-box {
                transform: rotate(-1deg);
                margin: 15px 0 25px 0;
            }

            &-title {
                background-color: #263981;
                color: white;
                padding: 5px;
                border-radius: 5px;
                text-transform: uppercase;
                font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                font-size: 14px;
            }
        }

        .context {
            line-height: inherit;
            padding: 10px;

            &--text1 {
                color: #a9bbbe;
                font-size: 18px;
            }

            &--reg {
                font-size: 17px;
                margin-left: -3px;
            }

            &--text2 {
                color: #a9bbbe;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .outils-container {
            display: flex;
            justify-content: space-around;
            align-items: start;
            text-align: center;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .outil-img {
                line-height: inherit;
                margin: 0 0 0 0;
            }

            .text-title {
                font-weight: 600;
            }
        }

        .footer {
            padding: 20px;
            margin-top: 30px;
            background-color: #5567ac;
            color: white;
            text-align: center;

            h3 {
                font-size: 18px;
                font-weight: 400;
                color: white;
            }

            .footer-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .footer-icon {
                    font-size: 34px;
                }

                .footer-text {
                    font-size: 16px;
                    padding: 5px;
                    font-weight: 500;
                }
            }
        }
    }
}

.saison__container {
    height: 52px;
    margin-bottom: 40px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    .saison__button-previous,
    .saison__button-next {
        width: 50px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grey-blue3;
        transition: all 0.4s ease;

        i {
            font-size: 24px;
            color: $white;
            transition: all 0.4s ease;
        }

        &:hover, &:active, &:focus {
            background-color: darken($grey-blue3, 10%);

            i {
                margin: 0 -10px 0 0;
            }
        }

        &.last {
            background-color: $blue-light2;
        }
    }

    .saison__button-previous {

        margin: 0 15px 0 0;
        transform: scaleX(-1);
    }

    .saison__button-next {
        margin: 0 0 0 15px;
    }

    .saison__button-bubble {
        border-radius: 5px 0 0 5px;
        text-transform: uppercase;
        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
        font-size: 22px;
        color: $white;
        text-align: left;
        width: 270px;
        padding: 0 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        img {
            margin: 0 10px 0 0;
            width: 28px;
        }

        i {
            width: 28px;
            color: #ffffff;
        }

        &::before, &::after {
            content: "";
            position: absolute;
            border: 0;
            display: block;
            width: 0;
            border-style: solid;
            bottom: -20px;
            left: 30px;
        }

        &::before {
            border-left-width: 40px;
            border-bottom-width: 20px;
        }

        &::after {
            border-left-width: 15px;
            border-bottom-width: 20px;
            border-color: transparent $white;
        }
    }

    .saison__button-content {
        font-family: 'bentonsans-condensedregular', Arial, Helvetica, sans-serif;
        font-size: 16px;
        color: $white;
        padding: 0 15px;
        margin: 0 2px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 50%;
    }

    .saison__button-print {
        border-radius: 0 5px 5px 0;
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        background-color: $blue-light2;

        img {
            margin: 0 5px
        }
    }

    .saison__button-bubble,
    .saison__button-content {
        &.hiver, &.saison4 {
            background-color: $hiver-otc;

            &::before {
                border-color: transparent $hiver-otc;
            }
        }

        &.ete, &.saison3 {
            background-color: $ete-otc;

            &::before {
                border-color: transparent $ete-otc;
            }
        }

        &.printemps, &.saison2 {
            background-color: $printemps-otc;

            &::before {
                border-color: transparent $printemps-otc;
            }
        }

        &.implantation, &.saison1 {
            background-color: $implantation-otc;

            &::before {
                border-color: transparent $implantation-otc;
            }
        }
    }
}

.otc__footer {
    margin: 50px 0;
    width: 100%;
    display: flex;
    height: 70px;
    justify-content: stretch;
    align-items: stretch;

    .otc__footer__edit-order {
        flex-basis: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $blue-otc;
        font-family: 'bentonsans-condensedregular', Arial, Helvetica, sans-serif;
        font-size: 22px;
        border-radius: 10px;
        position: relative;
        margin-right: 1%;
        padding: 15px;

        &::before, &::after {
            content: "";
            position: absolute;
            border: 0;
            display: block;
            width: 0;
            border-style: solid;
            bottom: -20px;
            left: 30px;
        }

        &::before {
            border-left-width: 40px;
            border-bottom-width: 20px;
            border-color: transparent $blue-otc;

        }

        &::after {
            border-left-width: 15px;
            border-bottom-width: 20px;
            border-color: transparent $white;
        }

    }

    .otc__footer__conseiller {
        display: flex;
        flex-basis: 44%;
        border: 1px solid $blue-light2;
        border-radius: 5px;

        > div {
            padding: 15px;
            display: flex;
            flex-basis: 50%;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;


            &.footer__conseiller--title {
                border-right: 1px dashed $blue-light2;
                font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
                font-size: 17px;
                text-transform: uppercase;
                color: $grey-litige;
                line-height: 1.2em;

                > span {
                    color: $blue-otc;
                }
            }

            &.footer__conseiller--infos {
                font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
                font-size: 12px;
                color: $blue-otc;
            }
        }
    }
}
.block-manage-pharmacy-otc-not-engaged-header-icon{
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: #E53838 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
.fas{
    color:white;
  }
}
