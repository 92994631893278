.commande-flash-popin-success {
    .popup-base {
        width: 596px;
        background: #FFFFFF;
    }

    .popup-title {
        background: #FFFFFF;
        color: #485E68;
        text-transform: uppercase;
        margin: auto 35px;
        padding: 10px 1px;
        font-size: 18px;
        font-family: bentonsans-bold, sans-serif;
        display: flex;
        border-bottom: 0.5px solid #5b8b9a;

        img {
            margin: unset;
        }

        &-text {
            margin-top: 8px;
            margin-left: 8px;
        }
    }

    .popup-commande-flash-close-container {
        width: 25%;
    }

    .popup-commande-flash-close {
        float: right;
        margin-top: 6px;
    }

    .popup-commande-flash-close:hover {
        cursor: pointer;
    }

    .popup-content {
        padding: 30px 45px;
        text-align: center;
        background: #FFFFFF;
        color: #485E68;
        font-size: 14px;
        font-family: bentonsans-bold, sans-serif;
    }
}

@media screen and (max-width: 767px) {
    .commande-flash-popin-success {
        .popup-base {
            width: 100%;
        }
    }
}
