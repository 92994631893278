@charset "UTF-8";

.pagination {
    text-align: center;

    span a, span {
        @include border-radius(5px);
        display: inline-block;
        text-align: center;
        width: 46px;
        height: 46px;
        line-height: 46px;
        vertical-align: middle;
    }

    span {
        margin: 0 0 3px 0;
    }

    .page a {
        border: 1px solid $grey-lighter;
        background-color: $white;
        color: $grey-light;
    }

    .current {
        background-color: $green-base2;
        color: $white;
    }

    .previous, .next {
        position: relative;

        a::before {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -4px 0 0 -5px;
            width: 0;
            height: 0;
            border-style: solid;
        }
    }

    .previous {
        margin: 0 10px 0 0;

        a::before {
            border-width: 5px 8px 5px 0;
            border-color: transparent $grey-blue transparent transparent;
        }
    }

    .next {
        margin: 0 0 0 10px;

        a::before {
            border-width: 5px 0 5px 8px;
            border-color: transparent transparent transparent $grey-blue;
        }
    }

    .news_listing & {
        margin-top: 20px;

        .current {
            background-color: $orange-base;
        }

        .next {
            a::before {
                border-width: 5px 0 5px 8px;
                border-color: transparent transparent transparent $orange-base;
            }
        }

        .previous {
            a::before {
                border-width: 5px 8px 5px 0;
                border-color: transparent $orange-base transparent transparent;
            }
        }


        .previous {
            @include hover-focus-active {
                a::before {
                    border-color: transparent $orange-base transparent transparent;
                }
            }
        }

        .next {
            @include hover-focus-active {
                a::before {
                    border-color: transparent transparent transparent $orange-base;
                }
            }
        }
    }

    .contrat_listing &,
    .pharmacien-equipe & {
        margin-top: 20px;

        .current {
            background-color: $blue-base;
        }

        .previous {
            a::before {
                border-color: transparent $blue-base transparent transparent;
            }
        }

        .next {
            a::before {
                border-color: transparent transparent transparent $blue-base;
            }
        }
    }

    .catalogue_content & {
        margin-top: 40px;

        .current {
            background-color: $purple-base;
        }

        .previous {
            @include hover-focus-active {
                a::before {
                    border-color: transparent $purple-base transparent transparent;
                }
            }
        }

        .next {
            @include hover-focus-active {
                a::before {
                    border-color: transparent transparent transparent $purple-base;
                }
            }
        }
    }
}
