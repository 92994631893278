@charset "UTF-8";

@import "pages/promotionnal-blocks/_performance-indicator-promotional-block";
@import "pages/promotionnal-blocks/_pharmacist-tools-promotional-block";

@import "pages/promotionnal-blocks/_customer-service-promotional-block";
@import "pages/promotionnal-blocks/_invoice-promotional-block";


// Colors variables
$pink-base-light: lighten($pink-base, 25%);
$blue-base-dark: darken($blue-base, 15%);
$green-base-light: lighten($green-base, 10%);
$orange-base-light: lighten($orange-base, 25%);
$grey-blue-light: lighten($grey-blue, 25%);

$border-grey: lighten($grey-lighter, 10%);


.contrat_listing,
.pharmacien-invoices {
    .tooltipster-base {
        padding: 25px;
        background-color: #FFFFFF;
        border: 2px solid #2F485C;
        color: #464646;
        font-family: 'bentonsans-regular', sans-serif;

        .day {
            border-radius: 20px;
        }

        .date-calendar {
            padding: 5px;
            background-color: #F4F4F4;
            border-radius: 4px;
            margin-bottom: 17px;
            margin-top: 10px;
        }

        .btn-date {
            background: #2F485C 0% 0% no-repeat padding-box;
            border-radius: 4px;
            color: #FFFFFF;
            width: 79px;
            height: 33px;
            font-family: 'bentonsans-bold', sans-serif;
        }

        .title-calendar {
            font-family: 'bentonsans-bold', sans-serif;
        }

        .active {
            background-color: #2F485C !important;
        }

        .table-condensed {
            margin: auto;
        }

        thead tr:last-child {
            th {
                background-color: #D9D9D9;
                border-radius: 0px;
            }

            th:first-child {
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
            }

            th:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
}

.contrats {

    &-list-filters {
        position: sticky;
        top: 0;
        background: #F4F4F4;
        z-index: 2;
    }

    &-list-content {
        .list-content {
            tr:first-child {
                position: sticky;
                top: 71px;
                z-index: 2;
                background: #DBE5F5;
            }

            .div-centered {
                display: flex;
                justify-content: center;
            }
        }


    }

    .list-content {
        .list-line-cell:first-child {
            border-radius: unset;
            background-color: white;
        }

        .list-line-cell:last-child {
            border-radius: unset;
            background-color: white;
        }
    }

    .chapeau {
        margin: 0 0 20px 0;
    }

    .block-container-icone-arrow-desktop {
        .fa-angle-down {
            display: none;
        }

        .fa-angle-up {
            display: unset;
        }
    }
}

.contrats-list-content {

    .list-line {
        background: #FAFAFA;
    }
}


// Now, listing styles :
.contratlist-container-header {
    h2 {
        width: 60%;
        float: left;
        margin: 0;
    }
}

.contratlist-container {
    display: table;
    width: 100%;
    margin: 0 0 20px 0;
    background-color: #FFFFFF;
}

.contratlist-header {
    list-style: none;
    display: table-header-group;
    background-color: #dbe5f5;
    color: #0F52BA;
    line-height: 1em;
    height: 50px;
    font-family: 'bentonsans-bold', sans-serif;
    letter-spacing: 0px;

    &::before {
        display: none;
    }

    li {
        display: table-cell;
        vertical-align: middle;
        padding: 10px 3px;
        text-align: center;
        text-transform: uppercase;
        font-size: 11px;

        &:first-child {
            @include border-top-left-radius(3px);
            @include border-btm-left-radius(3px);
        }

        &:last-child {
            @include border-top-right-radius(3px);
            @include border-btm-right-radius(3px);
        }
    }
}

.contratlist-empty_row {
    display: table-row;
    height: 20px;
}

.contratlist-row {
    position: relative;
    list-style: none;
    display: table-row;
    background-color: #FAFAFA;
    border-radius: 4px;
    padding: 14px 0px;

    li {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        height: 50px;
        text-align: center;
        font-size: 13px;
        border-spacing: 10px 10px;
    }
}

// Cells styles
.cell-contrat-ref {
    color: $black;
    font-family: 'bentonsans-condensedregular', sans-serif;
    font-size: 17px;
}

.cell-contrat-montHt {
    font-family: 'bentonsans-bold', sans-serif;
}

.cell-contrat-dates {
    position: relative;
    padding-right: 8px;
}

.cell-contrat-datedeb {
    position: relative;
    padding-right: 8px;

    &::after {
        content: "";
        display: block;
        position: absolute;
        right: -4px;
        top: 50%;
        margin: -6px 0 0 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 6px;
        border-color: transparent transparent transparent $grey-blue;
    }
}

.cell-contrat-statut {
    span[class^="col-statut_"] {
        display: block;
        padding: 0 0 0 27px;
        -webkit-background-size: 22px 22px;
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-position: left top;
        height: 22px;
        line-height: 22px;
        text-align: left;
    }
}

.cell-contrat-lien {
    a {
        display: block;

        span[class^="ic-"] {
            display: block;
            font-size: 35px;
            text-decoration: none;
        }
    }

    .cell-contrat-signer-txt {
        text-decoration: underline;
    }
}

// Responsive add-ons
.contrat-col_title-mobile {
    display: none;
}

// Colors
.row-statut_signe {
    li:first-child {
        &::before {
            @include row-border-style($green-base-light, $green-base);
        }
    }
}

.row-statut_a_signer {
    li:first-child {
        &::before {
            @include row-border-style($pink-base-light, $pink-base);
        }
    }
}

.row-statut_en_cours li:first-child {
    &::before {
        @include row-border-style($orange-base-light, $orange-base);
    }
}

.row-statut_refuse li:first-child {
    &::before {
        @include row-border-style($grey-blue-light, $grey-blue);
    }
}

.row-statut_vide li:first-child {
    &::before {
        @include row-border-style($grey-lighter, $grey-lighter);
    }
}

.col-statut_a_signer {
    background-image: url('../img/contratAsigner.gif');
    color: $pink-base;
}

.col-statut_signe {
    background-image: url('../img/contratSigne.gif');
    color: $green-base;
}

.col-statut_en_cours {
    background-image: url('../img/contratEnCours.gif');
    color: $orange-base;
}

.col-statut_refuse {
    background-image: url('../img/contratRefuse.gif');
    color: $grey-medium;
}

.cell-contrat-lien.signer a {
    color: $pink-base;
}

.cell-contrat-lien.consulter a {
    color: $green-base;
}

// Filters boxes
.contratlist-filter {
    margin: 0 0 20px 0;
}

.contratlist-filter-col {
    display: inline-block;
    float: right;

    .selectric {
        .button {
            height: 43px;
        }
    }

    &.contratlist-filter-col-left {
        margin: 0 20px 0 0;

        .label {
            width: 70px !important;
        }
    }
}

.contratlist-filter-label {
    display: block;
    color: $grey-blue;
    font-size: 12px;
    position: relative;

    .tooltip_toggle {
        position: relative;
        top: -7px;
    }
}

.bx-wrapper-contrat-slider {
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    border: unset !important;
    background: unset !important;
    margin: 0 50px 50px;
}



///*************************************
//* Media queries
//*************************************/
@media screen and (min-width: 980px) {
    .contratlist-row {
        &:last-child li {
            border-bottom: 1px solid $border-grey;
        }
    }
}

@media screen and (max-width: 980px) {
    .contratlist-filter-col {
        &:first-child {
            margin: 0;
        }
    }

    .contratlist-container {
        display: block;
    }

    .contrat-col_title-mobile {
        display: table-cell;
        text-transform: uppercase;
        color: $grey-medium;
        font-family: 'bentonsans-regular', sans-serif;
        font-size: 12px;
    }

    .contrat-col_content-mobile {
        display: table-cell;
    }

    .contratlist-header {
        display: none;
    }

    .contratlist-row {
        display: block;
        position: relative;
        width: 49%;
        float: left;
        border-bottom: 1px solid $border-grey;
        margin-bottom: 20px;

        &:nth-child(2n+1) {
            margin-right: 2%;
        }

        li {
            display: table;
            width: 100%;
            text-align: left;
            border: none;
            padding: 10px 10px 0 12px;
            margin: 0;
            height: 50px;
            font-family: 'bentonsans-medium', sans-serif;
            color: #2F485C;
            background: #FAFAFA 0% 0% no-repeat padding-box;
            border-radius: 4px;

            &:first-child {
                @include border-top-left-radius(6px);
                @include border-top-right-radius(6px);
                @include border-btm-left-radius(0);
                border: none;

                &::before {
                    display: none;
                }
            }

            &:last-child {
                @include border-btm-right-radius(0);
                border: none;
                border-right: 1px solid $border-grey;
            }
        }

        // Cells styles
        .cell-contrat-ref {
            color: $white;
            font-size: 18px;
            padding-bottom: 10px;

            .contrat-col_title-mobile {
                color: $white;
                font-size: 13px;
            }
        }

        .cell-contrat-statut {
            padding: 15px 20px;
        }

        .cell-contrat-datedeb {
            width: 50%;
            float: left;
            border-right-width: 2px;
            border-bottom: 2px dotted $border-grey;
            padding-bottom: 9px;
            font-family: 'bentonsans-medium', sans-serif;

            &::after {
                display: none;
            }

            .contrat-col_title-mobile {
                display: block;
            }
        }

        .cell-contrat-datefin {
            width: 50%;
            float: left;
            border-bottom: 2px dotted $border-grey;
            padding-bottom: 10px;

            .contrat-col_title-mobile {
                display: block;
            }
        }

        .cell-contrat-datestatut,
        .cell-contrat-numfac,
        .cell-contrat-datefac {
            clear: both;
            color: $black;
            font-family: 'bentonsans-medium', sans-serif;

            .contrat-col_title-mobile {
                width: 50%;
            }
        }

        .cell-contrat-datefac {
            padding-bottom: 9px;
            border-bottom: 2px dotted $border-grey;
        }

        .cell-contrat-montHt,
        .cell-contrat-montTtc {
            .contrat-col_title-mobile {
                font-family: 'bentonsans-regular', sans-serif;

                &:first-child {
                    width: 50%;
                }
            }
        }

        .cell-contrat-montTtc {
            border-bottom: 1px solid $border-grey;
            padding-bottom: 10px;
        }

        .cell-contrat-lien {
            text-align: center;

            a {
                span[class^="ic-"] {
                    display: inline-block;
                    vertical-align: middle;
                }

                .cell-contrat-signer-txt {
                    font-size: 14px;
                }
            }
        }

        &:last-of-type li {
            border-bottom: none;
        }
    }
}

@media screen and (max-width: 950px) {
    .block-amounts-received-block {
        span {
            font-size: 26px;
        }
    }
}

@media screen and (max-width: 680px) {
    .contratlist-container-header h2 {
        width: 55%;
    }
}

@media screen and (max-width: 620px) {
    .contratlist-container-header h2 {
        width: 100%;
        float: none;
    }

    .contratlist-filter {
        text-align: left;
        margin: 0;
        padding: 10px;
        background-color: $sep-bg;
    }
}


.contrats {
    padding: 0 7vw 30px;

    .h4 {
        color: #000000;
    }

    &-promotional-blocks {
        margin: 0 0 25px;
        padding: 0 7vw;
        display: flex;
        gap: 15px;
        justify-content: space-between;
        width: 100%;
        min-height: 200px;

        .contrats-promotional-block {
            width: 26%;
        }

        .contrats-promotional-block.min-block {
            width: 16%;
        }

        .bxslider {
            border-radius: 8px;
            box-sizing: border-box;

            a {
                display:block;
                height: 100%;
            }
        }



        .contract-listing-block {
            background: #0F52BA 0% 0% no-repeat padding-box;
            text-align: center;
            max-width: 280px;


            h4 {
                font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                color: #FFFFFF;
                padding-top: 26px;
                width: 279px;
                height: 21px;
                margin-left: auto;
                margin-right: auto;
                letter-spacing: -0, 36px;
                font-size: 18px;
            }

            p {
                padding-top: 15px;
                margin-left: auto;
                margin-right: auto;
                width: 258px;
                height: 32px;
                text-align: center;
                font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
                color: #E7EDF5;
                opacity: 1;
                font-size: 14px;
            }

            span {
                background-color: #000000;

                img {
                    width: 42.25px;
                    height: 44.84px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }


        }

        .block-econtrat-col {

            width: 26%;
            background-color: #0F52BA;
            border-radius: 4px;
            text-align: center;
            font: normal normal normal 14px/16px BentonSans;
            letter-spacing: 0px;
            color: #E7EDF5;
            opacity: 1;
        }

        .pharmacist-tools-block {
            width: 26%;
            position: relative;
            text-align: center;

            a {
                .pharmacist-tools-text {
                    position: relative;
                    z-index: 99;
                    text-align: left;
                    color: #FFFFFF;
                    font-size: 18px;
                    font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                    letter-spacing: -0.36px;
                    line-height: 1.2;
                }

                .white-zone {
                    position: relative;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border-radius: 15px;
                    width: 88px;
                    height: 32px;
                    border: 2px solid #FFFFFF;
                    margin-top: 15px;

                    .pharmacist-tools-text-access {
                        color: #2DB4D1;
                        font-size: 14px;
                        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                        transform: translate(0px, 5px);
                    }
                }
            }
        }

        .contact-block {
            width: 16%;
            position: relative;
            text-align: center;

            a {
                .scli-text {
                    position: relative;
                    color: #FFFFFF;
                    font-size: 18px;
                    font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                    letter-spacing: -0.36px;
                    line-height: 1.2;
                }

                .white-zone {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border-radius: 15px;
                    width: 122px;
                    height: 32px;
                    border: 2px solid #FFFFFF;
                    position: relative;
                    margin: 10px auto;

                    .scli-text-number {
                        color: #72BBFF;
                        font-size: 14px;
                        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                        transform: translate(0px, 5px);
                    }
                }

                .scli-text-price {
                    color: #FFFFFF;
                    font-size: 10px;
                    font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
                    width: 75%;
                    position: relative;
                    margin: 5px auto;
                }
            }
        }
    }


    $colorText: #0F52BA;
    $listLineColor: #2F485C;
    $backgroundHeadColor: #0F52BA26;
    $backgroundCell: #FAFAFA;
    $backgroundCellSelected: #0F52BA0D;
    $borderSelectedColor: #0F52BA26;

    @import '../block/ged_table';


    .no-invoice {
        padding: 36px;
        text-align: center;

        &-header {
            font-size: 22px;
            font-family: BentonSans-Medium, sans-serif;
            color: #2DB4D1;
        }

        &-content {
            margin: 50px auto;
            font-size: 14px;
            font-family: bentonsans-regular, sans-serif;
            color: #000000;

            a {
                color: #2DB4D1;
                text-decoration: underline;
                text-decoration-color: #2DB4D1;
            }
        }

        &-btn {
            font-size: 14px;
            font-family: Bentonsans-bold, sans-serif;

            button {
                padding: 15px 20px;
                background: #CB2F7B;
                color: #FFFFFF;
                border-radius: 4px;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .contrats {

        &-promotional-blocks {
            .bxslider {
                padding: 20px;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .bx-viewport {
        max-width: 320px;
        margin: 0 auto;
    }

    .contrats {

        &-promotional-blocks {
            .bxslider {
                padding: 20px;
                max-width: 320px;

                &.contact-block{
                    max-width: 220px;
                }
            }
        }
    }
}
/*
* Responsive mobile
**/
@media screen and (max-width: 480px) {
    .list-line {
        height: 80px;
        overflow: hidden;


        .fa-angle-up {
            display: none;
        }

        .fa-angle-down {
            display: block;
        }

        &.open {
            height: auto;

            .block-container-icone-arrow-desktop {
                .fa-angle-up {
                    display: unset;
                }
            }

            .fa-angle-down {
                display: none;
            }

            .fa-angle-up {
                display: block;
            }

            .block-statut-a-cacher {
                display: none;
            }
        }
    }

    .contrats {

        &-promotional-blocks {
            .contract-listing-block,
            .pharmacist-tools-block {
                a {
                    .pharmacist-tools-text {
                        font-size: 14px;
                        left: 20px;
                    }

                    .white-zone {
                        left: 20px;
                    }

                    .pharmacist-tools-text-access {
                        margin-top: -1px;
                    }
                }
            }

            .contact-block {
                a {
                    .scli-text {
                        width: 47%;
                        margin-left: 19%;
                    }
                }
            }
        }

        &-list-content {
            .list-content {
                tr:first-child {
                    background: unset;
                }
            }
        }
    }

    .tooltipster-base {
        height: unset !important;
        width: 100%;
    }

    .date_start_end_content {
        flex-direction: column;
    }
}
