@charset "UTF-8";

.modal-backdrop {
    z-index: 10040;
}

.modal {
    z-index: 10050;
}

.close {
    font-size: 3rem;
}

#demoVideoModal{

    .modal-dialog{
        width: auto;

        @media handheld, screen and (max-width: 640px) {
            .modal-body{
                padding: 0 15px 3rem;
            }
        }
    }
}

.modal-content iframe{
    margin: 0 auto;
    display: block;
}

div.modal-header {
    border-bottom: 0 !important;
    padding-bottom: 0;
}

div.modal-footer {
    border-top: 0 !important;
    text-align: center;
}

div.simotc-ospharm-modal {
    &.modal {
        text-align: center;
        padding: 0;
    }

    @media screen and (min-width: 768px) {
        &.modal:before {
            display: inline-block;
            vertical-align: middle;
            content: " ";
            height: 100%;
        }
    }

    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        width: 550px;

        @media handheld, screen and (max-width: 768px) {
            max-width: calc(100% - 20px);
        }

        .modal-header {
            border-bottom: none;
        }

        .modal-body {
            padding: 0 5rem 5rem;
            text-align: center;

            @media handheld, screen and (max-width: 640px) {
                padding: 0 3rem 3rem;
            }

            .ospharm-logo {
                width: 100px;
                margin: 0 auto 3rem;
            }

            .ospharm-description, .ospharm-service-description, .ospharm-redirection-warning {
                margin-bottom: 2rem;
            }

            .ospharm-description {
                color: $simotc-grey-blue;
                font-size: 16px;
            }

            .ospharm-service-description {
                color: $grey-midlighter;
                font-size: 12px;
            }

            .ospharm-redirection-warning {
                color: $pink-base;
                font-size: 10px;
            }

            .btn-ospharm {
                color: $white;
                font-size: 10px;
                padding: 8px 34px;
            }
        }
    }
}

div.list-add-modal {
    .header {
        text-align: center;
    }
    .flex-container {
        @extend %display-flex;
        flex-direction: row;
        justify-content: space-between;

        .btn-save {
            color: $white;
            font-size: 10px;
            padding: 8px 34px;

        }
    }
    .list-add-product-container {
        @extend %display-flex;
        height: 100%;
        div {
            @extend %display-flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            text-align: center;
            padding: 0 5px;
        }
        div:not(:last-child) {
            border: {
                right: 1px solid $simotc-light-grey;
            }
        }
        #ppttc {
            color: #192452;
        }
        .small {
            font-size: 11px;
        }
        .add-modal-product-name {
            font-weight: bolder;
            flex: 2;
        }
        .add-modal-product-dosage {
            flex: 1;
        }
        .add-modal-product-boitage {
            flex: 1;
            text-transform: uppercase;
        }
        .add-modal-product-image {
            flex: 2;
            #image {
                display: block;
                width: 100%;
                max-width: 50px;
                height: auto;
                margin: auto;
            }
        }
        .add-modal-product-ppttc {
            flex: 2
        }
    }
}
