@charset "UTF-8";

.imi_prehome {
    /********** Left col***************/
    .ph-left-col {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 0 30% 0 0;
        background-image: linear-gradient(to right bottom, #d7dfe5, #d7dfe5);
    }

    .ph-h1 {
        height: 30px;
        margin: 20px 0 0 25px;
    }

    .header-desktop {
        &-top {
            display: flex;
            justify-content: space-between;

            .img-right-top-1 {
                position: absolute;
                width: 22%;
                right: 0;
                top: 0;
                z-index: 1000;
                margin: 0px;
            }

            .img-right-top-2 {
                position: absolute;
                width: 20%;
                right: -7rem;
                top: -5rem;
                z-index: 1001;
                margin: 0px;
            }
        }

        &-middle {
            display: flex;
            justify-content: center;

            .img-top-center {
                width: 12%;
                margin: 0px;
            }
        }

        &-left {
            .img-top-left {
                width: 28%;
                margin: 0px;
            }
        }
    }

    .block-center {
        display: flex;
        flex-direction: column;
        margin: 0px auto;

        .title {
            font-family: bentonsans-book, sans-serif;
            text-transform: uppercase;
            font-weight: bolder;
            margin: 0px auto;
            font-size: 16px;
            color: black;
        }

        .subtitle {
            font-family: bentonsans-book, sans-serif;
            display: table;
            font-size: 13px;
            color: black;
            margin: 0px auto;
            font-weight: bold;
        }

        &-img-text {
            display: flex;
            justify-content: center;
            align-content: flex-end;
            padding: 0px;

            .img-container {
                display: flex;
                justify-content: center;
                align-content: flex-end;
                margin: 0px;

                .img {
                    &-1 {
                        width: 150px;
                        height: 300px;
                        align-self: flex-end;
                        margin: 0px 15px 0 0;
                    }

                    &-2 {
                        width: 140px;
                        height: 290px;
                        display: flex;
                        align-self: flex-end;
                        margin: 0px 30px 0 0;
                    }

                    &-3 {
                        width: 200px;
                        height: 100px;
                        align-self: flex-end;
                        margin: 0px 15px 0px 30px;
                        height: fit-content;
                        align-self: flex-end;
                    }

                    &-4 {
                        width: 95px;
                        height: 300px;
                        align-self: flex-end;
                        margin: 0px;
                    }
                }
            }

            .info-text {
                align-self: flex-end;
                margin: 0px 10px;
                font-size: 12px;
            }

            .text-invisable {
                color: transparent;
            }
        }

        &-product-subtext {
            display: flex;
            justify-content: center;
            font-size: 12px;
            margin: 10px;

            .text-1 {
                color: #510f82;
                margin-right: 10%;
            }

            .text-2 {
                color: #dc0569;
                margin-right: 5%;
            }

            .text-3 {
                color: #dc0569;
            }
        }

        &-product-text {
            text-align: center;
            font-weight: bold;

            .text-1 {
                color: #510f82;
                margin-right: 60px;
            }

            .text-2 {
                color: #dc0569;
                margin-left: 60px;
            }
        }

        &-market {
            display: flex;
            justify-content: flex-end;

            .text-left {
                font-size: 10px;
                text-align: center;
                padding: 10px 50px;
            }

            .text-right {
                writing-mode: tb-rl;
                transform: rotate(-180deg);
                margin-top: -40px;
                margin-right: 15px;
            }
        }

        &-connect-btn {
            text-align: center;
            color: #62197b;
            font-size: 22px;
            margin-top: -15px;

            span {
                border-bottom: 1px solid #62197b;
            }
        }
    }

    &-ph-footer {
        background-color: transparent;
        display: flex;
    }

    &-ftr-pink {
        align-self: flex-end;
    }

    /*********** Mobile header ******************/
    .header-mobile {
        display: none;
        background-color: $white;
        padding: 15px 20px;
    }

    .ph-h1-mobile {
        @include bgimg('mobile/hdr-logo-mobile.png');
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
        width: 150px;
        height: 34px;
        float: left;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .ph-connexion_link-mobile {
        display: block;
        float: right;
        background-color: $pink-base;
        padding: 10px 15px;
        @include border-radius(25px);
        color: $white;
        font-size: 12px;

        .ic-userlog {
            font-size: 25px;
            vertical-align: middle;
            margin: 0 0 0 5px;
        }

        @include hover-focus-active {
            background-color: darken($pink-base, 10%);
            color: $white;
        }
    }

    /*************** Right col ****************/
    .ph-right-col {
        position: absolute;
        right: 0;
        top: 0;
        width: 30%;
        height: 100%;
        background-color: $white;
        color: $grey-light;

        &:before {
            display: block;
            content: "";
            position: absolute;
            left: -13px;
            top: 10%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7.5px 13.0px 7.5px 0;
            border-color: transparent #ffffff transparent transparent;
        }
    }

    .ph-right-col-scrollbox {
        padding: 0 20%;
        overflow: auto;
        height: 100%;

        &.login {
            padding: 0 20%;
            overflow: auto;

            .flash-msg {
                margin: 0 -36%;
            }
        }

        &.register, &.contact, &.mentions-legales {
            padding: 0 10%;

            .flash-msg {
                margin: 0 -13.5%;
            }
        }

        &.resetting-check-email {
            min-height: 0;
        }

        &.resetting-request {
            min-height: 0;
        }

        &[class*="resetting-reset"] {
            min-height: 0;

            .back_link-pillshaped {
                left: -26%;
            }

            .flash-msg {
                margin: 0 -34%;
            }
        }

        &.mentions-legales {
            .chapeau {
                font-size: 14px;
            }
        }
    }

    .ph-footer {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        border: none;
        padding: 0;

        .ftr-pink {
            width: 70%;
            float: left;
        }
    }

    .ph-right-col-footer {
        float: right;
        border: none;
        height: 80px;
        line-height: 40px;
        background-color: $white;
        padding-left: 0;

        ul {
            list-style-type: none;
            text-align: center;

            li {
                display: inline-block;
                margin: 0 10px;

                a {
                    color: $grey-light;
                    @include hover-focus-active {
                        color: $grey-blue;
                    }
                }
            }
        }
    }

    /********** Media queries *****************/
    @media screen and (max-width: 600px) {
        .ph-left-col, .ph-right-col {
            position: static;
            margin: 0;
        }

        .ph-left-col {
            display: none;

            &.login {
                display: block;
            }
        }

        .ph-right-col {
            width: 100%;
            padding: 0 0 20px 0;
        }

        .header-mobile {
            display: block;
        }

        .ph-right-col-scrollbox.register .flash-msg,
        .ph-right-col-scrollbox.contact .flash-msg {
            margin: 10px -12% !important;
        }

        .ph-right-col-scrollbox.login .flash-msg {
            margin: 10px -28%;
        }

        .ph-footer {
            position: static;
            margin: 0;
            border-top: 3px solid $grey-lighter;
        }

        .ph-right-col-footer {
            float: none;
            width: 100%;
        }

        .header-desktop {
            &-top {
                .img-right-top-1 {
                    display: none;
                }

                .img-right-top-2 {
                    display: none;
                }
            }

            &-middle {
                .img-top-center {
                    display: none;
                }
            }

            &-left {
                .img-top-left {
                    display: none;
                }
            }
        }

        .block-center {
            display: none;
        }

        .imi_prehome-ftr-pink {
            height: auto;
        }
    }

    @media screen and (min-width: 601px) and (max-width: 767px) {
        .ph-left-col, .ph-right-col {
            position: static;
            margin: 0;
        }

        .ph-left-col {
            display: none;

            &.login {
                display: block;
            }
        }

        .ph-right-col {
            width: 100%;
            padding: 0 0 20px 0;
        }

        .header-mobile {
            display: block;
        }

        .ph-right-col-scrollbox.register .flash-msg,
        .ph-right-col-scrollbox.contact .flash-msg {
            margin: 10px -12% !important;
        }

        .ph-right-col-scrollbox.login .flash-msg {
            margin: 10px -28%;
        }

        .ph-footer {
            position: static;
            margin: 0;
            border-top: 3px solid $grey-lighter;
        }

        .ph-right-col-footer {
            float: none;
            width: 100%;
        }

        .header-desktop {
            &-top {
                .img-right-top-1 {
                    display: none;
                }

                .img-right-top-2 {
                    display: none;
                }
            }

            &-middle {
                .img-top-center {
                    display: none;
                }
            }

            &-left {
                .img-top-left {
                    display: none;
                }
            }
        }

        .block-center {
            display: none;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 815px) {
        .ph-left-col, .ph-right-col {
            position: static;
            margin: 0;
        }

        .ph-left-col {
            display: none;

            &.login {
                display: block;
            }
        }

        .ph-right-col {
            width: 100%;
            padding: 0 0 20px 0;
        }

        .header-mobile {
            display: block;
        }

        .ph-right-col-scrollbox.register .flash-msg,
        .ph-right-col-scrollbox.contact .flash-msg {
            margin: 10px -12% !important;
        }

        .ph-right-col-scrollbox.login .flash-msg {
            margin: 10px -28%;
        }

        .ph-footer {
            position: static;
            margin: 0;
            border-top: 3px solid $grey-lighter;
        }

        .ph-right-col-footer {
            float: none;
            width: 100%;
        }

        .ph-footer {
            bottom: 80px;
        }
        .ph-right-col-scrollbox {
            height: 85%;
        }
        .header-desktop {
            &-top {
                display: none;
            }

            &-middle {
                display: none;
            }

            &-left {
                display: none;
            }
        }

        .block-center {
            display: none;
        }
    }

    @media screen and (min-width: 816px) and (max-width: 1000px) {
        .ph-left-col, .ph-right-col {
            position: static;
            margin: 0;
        }

        .ph-left-col {
            display: none;

            &.login {
                display: block;
            }
        }

        .ph-right-col {
            width: 100%;
            padding: 0 0 20px 0;
        }

        .header-mobile {
            display: block;
        }

        .ph-right-col-scrollbox.register .flash-msg,
        .ph-right-col-scrollbox.contact .flash-msg {
            margin: 10px -12% !important;
        }

        .ph-right-col-scrollbox.login .flash-msg {
            margin: 10px -28%;
        }

        .ph-footer {
            position: static;
            margin: 0;
            border-top: 3px solid $grey-lighter;
        }

        .ph-right-col-footer {
            float: none;
            width: 100%;
        }

        .ph-right-col-scrollbox {
            height: 90%;
        }
        .header-desktop {
            &-top {
                display: none;
            }

            &-middle {
                display: none;
            }

            &-left {
                display: none;
            }
        }

        .block-center {
            display: none;
        }
    }

    @media screen and (min-width: 1001px) and (max-width: 1100px) {
        .header-desktop {
            &-top {
                .img-right-top-1 {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 25%;
                }

                .img-right-top-2 {
                    position: absolute;
                    right: -5rem;
                    top: -3rem;
                }
            }

            &-middle {
                .img-top-center {
                    width: 15%;
                }
            }

            &-left {
                .img-top-left {
                    width: 28%;
                    margin-top: -10rem;
                    margin-left: -9rem;
                }
            }
        }
        .block-center {
            margin: -7rem auto;

            .title {
                margin: 0px auto;
                font-size: 16px;
            }

            .subtitle {
                margin: 0px auto;
                font-size: 13px;
            }

            &-img-text {
                .img-container {
                    .img {
                        &-1 {
                            width: 95px;
                            height: 220px;
                            align-self: flex-end;
                            margin: 0px 15px 0 0;
                        }

                        &-2 {
                            width: 100px;
                            height: 210px;
                            align-self: flex-end;
                            margin: 0px 30px 0 0;
                        }

                        &-3 {
                            width: 130px;
                            height: 80px;
                            align-self: flex-end;
                            margin: 0px 15px 0px 30px;
                            align-self: flex-end;
                        }

                        &-4 {
                            width: 70px;
                            height: 220px;
                            align-self: flex-end;
                            margin: 0px;
                        }
                    }
                }

                .info-text {
                    font-size: 10px;
                }
            }

            &-product-subtext {
                .text-1 {
                    font-size: 10px;
                    margin-right: 15%;
                }

                .text-2 {
                    font-size: 10px;
                    margin-right: 5%;
                }

                .text-3 {
                    font-size: 10px;
                }
            }

            &-product-text {
                margin-top: 0px;

                .text-1 {
                    margin-right: 60px;
                }

                .text-2 {
                    margin-left: 60px;
                }
            }

            &-market {
                .text-left {
                    font-size: 10px;
                    padding: 10px 50px;
                }

                .text-right {
                    font-size: 12px;
                    margin-top: -10px;
                    margin-right: 15px;
                }
            }

            &-connect-btn {
                text-align: center;
                font-size: 22px;
            }
        }
    }

    @media screen and (min-width: 1101px) and (max-width: 1249px) {
        .header-desktop {
            &-top {
                .img-right-top-1 {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 25%;
                }

                .img-right-top-2 {
                    position: absolute;
                    right: -5rem;
                    top: -3rem;
                }
            }

            &-middle {
                .img-top-center {
                    width: 15%;
                }
            }

            &-left {
                .img-top-left {
                    width: 28%;
                    margin-top: -11rem;
                    margin-left: -10rem;
                }
            }
        }
        .block-center {
            margin: -9rem auto;

            .title {
                margin: 0px auto;
                font-size: 16px;
            }

            .subtitle {
                font-size: 13px;
                margin: 0px auto;
            }

            &-img-text {
                .img-container {
                    .img {
                        &-1 {
                            width: 110px;
                            height: 230px;
                            align-self: flex-end;
                            margin: 0px 15px 0 0;
                        }

                        &-2 {
                            width: 110px;
                            height: 220px;
                            align-self: flex-end;
                            margin: 0px 30px 0 0;
                        }

                        &-3 {
                            width: 150px;
                            height: 100px;
                            align-self: flex-end;
                            margin: 0px 15px 0px 30px;
                            align-self: flex-end;
                        }

                        &-4 {
                            width: 70px;
                            height: 230px;
                            align-self: flex-end;
                            margin: 0px;
                        }
                    }
                }

                .info-text {
                    font-size: 11px;
                }
            }

            &-product-subtext {
                .text-1 {
                    font-size: 11px;
                    margin-right: 7%;
                    margin-left: 5%;
                }

                .text-2 {
                    font-size: 11px;
                    margin-right: 3%;
                    margin-left: 8%;
                }

                .text-3 {
                    font-size: 11px;
                }
            }

            &-product-text {
                margin-top: 10px;

                .text-1 {
                    margin-right: 70px;
                }

                .text-2 {
                    margin-left: 70px;
                }
            }

            &-market {
                .text-left {
                    font-size: 10px;
                    padding: 10px 50px;
                }

                .text-right {
                    margin-top: -30px;
                    margin-right: 15px;
                    font-size: 13px;
                }
            }

            &-connect-btn {
                text-align: center;
                font-size: 23px;
                margin-top: 0px;
            }
        }
        .ph-right-col-scrollbox {
            height: 90%;
        }
    }

    @media screen and (min-width: 1250px) and (max-width: 1366px) {
        .header-desktop {
            &-top {
                .img-right-top-1 {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 26%;
                }

                .img-right-top-2 {
                    position: absolute;
                    right: -7rem;
                    top: -4rem;
                }
            }

            &-middle {
                .img-top-center {
                    width: 14%;
                }
            }

            &-left {
                .img-top-left {
                    width: 28%;
                    margin-top: -13rem;
                    margin-left: -13rem;
                }
            }
        }
        .block-center {
            margin: -10rem auto;

            .title {
                margin: 0px auto;
                font-size: 18px;
            }

            .subtitle {
                font-size: 14px;
                margin: 5px auto 0px auto;
            }

            &-img-text {
                .img-container {
                    padding: 0px 15px;
                    margin-top: 0px;

                    .img {
                        &-1 {
                            width: 110px;
                            height: 240px;
                            align-self: flex-end;
                            margin: 0px 15px 0 0;
                        }

                        &-2 {
                            width: 125px;
                            height: 230px;
                            align-self: flex-end;
                            margin: 0px 10px 0 0;
                        }

                        &-3 {
                            width: 200px;
                            height: 120px;
                            align-self: flex-end;
                            margin: 0px 15px 0px 30px;
                            align-self: flex-end;
                        }

                        &-4 {
                            width: 75px;
                            height: 240px;
                            align-self: flex-end;
                            margin: 0px;
                        }
                    }
                }

                .info-text {
                    font-size: 11px;
                }
            }

            &-product-subtext {
                .text-1 {
                    font-size: 11px;
                    margin-right: 8%;
                    margin-left: 5%;
                }

                .text-2 {
                    font-size: 11px;
                    margin-right: 4%;
                    margin-left: 6%;
                }

                .text-3 {
                    font-size: 11px;
                }
            }

            &-product-text {
                margin-top: 5px;

                .text-1 {
                    margin-right: 80px;
                }

                .text-2 {
                    margin-left: 80px;
                }
            }

            &-market {
                .text-left {
                    font-size: 10px;
                    padding: 10px 50px;
                }

                .text-right {
                    margin-top: -20px;
                    margin-right: 15px;
                    font-size: 12px;
                }
            }

            &-connect-btn {
                text-align: center;
                font-size: 23px;
            }
        }
        .ph-right-col-scrollbox {
            height: 90%;
        }
    }

    @media screen and (min-width: 1367px) and (max-width: 1440px) {
        .header-desktop {
            &-top {
                .img-right-top-1 {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 28%;
                }

                .img-right-top-2 {
                    position: absolute;
                    right: -5rem;
                    top: -4rem;
                }
            }

            &-middle {
                .img-top-center {
                    width: 15%;
                }
            }

            &-left {
                .img-top-left {
                    width: 30%;
                    margin-top: -15rem;
                    margin-left: -15rem;
                }

            }
        }
        .block-center {
            margin: -11rem auto;

            .title {
                margin: 0px auto;
                font-size: 18px;
            }

            .subtitle {
                font-size: 16px;
                margin: 10px auto 0px auto;
            }

            &-img-text {
                display: flex;
                justify-content: flex-end;
                align-content: flex-end;

                .img-container {
                    margin: 0;

                    .img {
                        &-1 {
                            width: 110px;
                            height: 260px;
                            align-self: flex-end;
                            margin: 0px 25px 0 0;
                        }

                        &-2 {
                            width: 120px;
                            height: 250px;
                            align-self: flex-end;
                            margin: 0px 40px 0 0;
                        }

                        &-3 {
                            width: 220px;
                            height: 125px;
                            align-self: flex-end;
                            margin: 0px 25px 0px 40px;
                            align-self: flex-end;
                        }

                        &-4 {
                            width: 80px;
                            height: 260px;
                            align-self: flex-end;
                            margin: 0px;
                        }
                    }

                }

                .info-text {
                    font-size: 13px;
                }
            }

            &-product-subtext {
                .text-1 {
                    font-size: 13px;
                    margin-right: 8%;
                    margin-left: 5%;
                }

                .text-2 {
                    font-size: 13px;
                    margin-right: 6%;
                    margin-left: 6%;
                }

                .text-3 {
                    font-size: 13px;
                }
            }

            &-product-text {
                margin-top: 10px;

                .text-1 {
                    margin-right: 12rem;
                }

                .text-2 {
                    margin-left: 12rem;
                }
            }

            &-market {
                .text-left {
                    font-size: 10px;
                    padding: 10px 50px;
                }

                .text-right {
                    margin-top: -30px;
                    margin-right: 15px;
                }
            }

            &-connect-btn {
                text-align: center;
                font-size: 23px;
            }
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1680px) {
        .header-desktop {
            &-top {
                .img-right-top-1 {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 30%;
                }

                .img-right-top-2 {
                    position: absolute;
                    right: -6rem;
                    top: -4rem;
                }
            }

            &-middle {
                .img-top-center {
                    width: 15%;
                }
            }

            &-left {
                .img-top-left {
                    width: 30%;
                    margin-top: -15rem;
                    margin-left: -16rem;
                }

            }
        }
        .block-center {
            margin: -13rem auto;

            .title {
                margin: 0px auto;
                font-size: 20px;
            }

            .subtitle {
                font-size: 18px;
                margin: 10px auto 0px auto;
            }

            &-img-text {
                display: flex;
                justify-content: center;
                align-content: flex-end;

                .img-container {
                    margin: 0;

                    .img {
                        &-1 {
                            width: 120px;
                            height: 300px;
                            align-self: flex-end;
                            margin: 0px 30px 0px 0px;
                        }

                        &-2 {
                            width: 140px;
                            height: 290px;
                            align-self: flex-end;
                            margin: 0px 50px 0px 0px;
                        }

                        &-3 {
                            width: 80%;
                            height: 80%;
                            align-self: flex-end;
                            margin: 0px 30px 0px 50px;
                            height: fit-content;
                            align-self: flex-end;
                        }

                        &-4 {
                            width: 100px;
                            height: 300px;
                            align-self: flex-end;
                            margin: 0px;
                        }
                    }

                }

                .info-text {
                    margin: 0px 15px;
                    font-size: 13px;
                }
            }

            &-product-subtext {
                .text-1 {
                    font-size: 13px;
                    margin-right: 8%;
                    margin-left: 3%;
                }

                .text-2 {
                    font-size: 13px;
                    margin-right: 5%;
                    margin-left: 12%;
                }

                .text-3 {
                    font-size: 13px;
                }
            }

            &-product-text {
                margin-top: 10px;

                .text-1 {
                    margin-right: 14rem;
                }

                .text-2 {
                    margin-left: 14rem;
                }
            }

            &-market {
                .text-left {
                    font-size: 10px;
                    padding: 10px 50px;
                    max-width: 1200px;
                }

                .text-right {
                    margin-top: -35px;
                    margin-right: 15px;
                }
            }

            &-connect-btn {
                text-align: center;
                font-size: 24px;
            }
        }
    }

    @media screen and (min-width: 1681px) {
        .header-desktop {
            &-top {
                .img-right-top-1 {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 30%;
                }

                .img-right-top-2 {
                    position: absolute;
                    right: -7rem;
                    top: -6rem;
                }
            }

            &-middle {
                .img-top-center {
                    width: 15%;
                }
            }

            &-left {
                .img-top-left {
                    width: 30%;
                    margin-top: -20vh;
                    margin-left: -10vw;
                }

            }
        }
        .block-center {
            margin: -12rem auto;

            .title {
                margin: 0px auto;
                font-size: 20px;
            }

            .subtitle {
                font-size: 16px;
                margin: 10px auto 0px auto;
            }

            &-img-text {
                display: flex;
                justify-content: center;
                align-content: flex-end;

                .img-container {
                    margin: 0;

                    .img {
                        &-1 {
                            width: 100%;
                            height: 100%;
                            align-self: flex-end;
                            margin: 0px 30px 0px 30px;
                        }

                        &-2 {
                            width: 200px;
                            height: 385px;
                            align-self: flex-end;
                            margin: 0px;
                        }

                        &-3 {
                            width: 80%;
                            height: 80%;
                            align-self: flex-end;
                            margin: 0px 30px 0px 60px;
                            height: fit-content;
                            align-self: flex-end;
                        }

                        &-4 {
                            width: 80%;
                            height: 100%;
                            align-self: flex-end;
                            margin: 0px 10px 0px 0px;
                        }
                    }

                }

                .info-text {
                    margin: 0px 20px;
                    font-size: 14px;
                }
            }

            &-product-subtext {
                .text-1 {
                    font-size: 14px;
                    margin-right: 8%;
                    margin-left: 3%;
                }

                .text-2 {
                    font-size: 14px;
                    margin-right: 5%;
                    margin-left: 12%;
                }

                .text-3 {
                    font-size: 14px;
                }
            }

            &-product-text {
                margin-top: 10px;
                font-size: 16px;

                .text-1 {
                    margin-right: 14rem;
                }

                .text-2 {
                    margin-left: 14rem;
                }
            }

            &-market {
                .text-left {
                    font-size: 12px;
                    padding: 15px 60px;
                }

                .text-right {
                    margin-top: -30px;
                    margin-right: 15px;
                }
            }

            &-connect-btn {
                text-align: center;
                font-size: 26px;
            }
        }
    }
}
