@charset "UTF-8";

///*************************************
//* @_00
//*************************************/

.btn {
    position: relative;
    padding: 18px;
    border:none;
    font: normal 12px/1.3em bentonsans-medium, sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: $white;
    .ph-right-col & {
        width: 100%;
    }
    @include btnHoverEffect();
    .ph-right-col &{
        //line-height:50px;
    }
}

.btn-blue {
    @include transition-ease-in-out();
    background-color: $blue-base;
    @include hover-focus-active {
        color: $white;
    }
    &::before {
        background-color: darken($blue-base, 10%);
    }
}

.btn-pink {
    @include transition-ease-in-out();
    background-color: $pink-base;
    @include hover-focus-active {
        color: $white;
    }
    &::before {
        background-color: darken($pink-base, 10%);
        content: none;
    }
}
