@charset "UTF-8";

// Colors variables
//$pink-base-light: lighten($pink-base, 25%);
$orange-base-dark: darken($orange-base, 15%);
$green-base-light: lighten($green-base, 10%);
$orange-base-light: lighten($orange-base, 25%);
$grey-blue-light: lighten($grey-blue, 25%);
$grey-light : lighten($grey-medium,25%);

$border-grey: lighten($grey-lighter, 10%);


.row-pharmacien-equipe {
    li:first-child {
        &::before {
            @include row-border-style($orange-base-light, $orange-base);
        }
    }
    li:last-child {
        border-right: 1px solid $border-grey;
    }

    &.editer-on{
        li{
            border-top-color: $pink-base;

            input[type="text"], input[type="email"], select{
                padding: 10px 0;
                margin: 0 10%;
                width: 80%;
                &:focus, &:active, &:hover{
                    border-bottom-color: $pink-base;
                }
            }
            .selectric-wrapper{
                width: 80%;
                li{
                    border-radius: 0;
                    border: 0 none;
                    border-bottom: 1px solid $grey-blue-light;
                    &:hover{
                        background:$grey-really-lighter;
                    }
                }
                .selectric{
                    .button::after{
                        border-top-color: $pink-base;
                    }
                    > .label{
                        padding: 6px 25px 6px 5px;
                    }
                }
                &.selectric-hover{
                    .selectric{
                        > .label{
                            border-bottom-color: $pink-base;
                        }
                    }
                }
            }
        }
        li:first-child{
            //border-left-color: $pink-base;
            &::before {
                @include row-border-style($pink-base-light, $pink-base);
            }
        }
        li:last-child{
            border-right-color: $pink-base;
        }
        + .row-pharmacien-equipe{

            li{
                border-top-color: $pink-base;
            }
        }
        &:last-child{
            li{
                border-bottom-color: $pink-base;
            }
        }


        .selectric-items{
            li{
                &:before{
                    display: none;
                }
                height: 40px;
                font-size: 11px;
                padding: 10px;
                color: $grey-litige;
            }
        }
    }


}

.row-pharmacien-equipe-invite {
    li:first-child {
        &::before {
            @include row-border-style($grey-light, $grey-medium);
        }
    }
    li:last-child {
        border-right: 1px solid $border-grey;
    }


}

// Responsive add-ons
.pharmacien-equipe-col_title-mobile {
    display: none;
}

// Now, listing styles :
.pharmacien-equipe-list-container-header {
    h2 {
        width: 60%;
        float: left;
        margin:0;
    }
}

.pharmacien-equipe-list-container {
    display: table;
    width: 100%;
    margin: 0 0 20px 0;
}

.pharmacien-equipe-list-header {
    list-style: none;
    display: table-header-group;
    background-color: $grey-blue;
    color: $white;
    line-height: 1em;
    &::before {
        display: none;
    }
    li {
        display: table-cell;
        vertical-align: middle;
        padding: 10px 3px;
        text-align: center;
        text-transform: uppercase;
        font-size: 11px;
        &:first-child {
            @include border-top-left-radius(3px);
            @include border-btm-left-radius(3px);
        }
        &:last-child {
            @include border-top-right-radius(3px);
            @include border-btm-right-radius(3px);
        }
    }
}

.pharmacien-equipe-list-empty_row {
    display: table-row;
    height: 20px;
}

.pharmacien-equipe-list-row {
    position: relative;
    list-style: none;
    display: table-row;
    background-color: $white;
    &:last-of-type li{
        border-bottom: 1px solid $border-grey;
    }
    li {

        position: relative;
        display: table-cell;
        vertical-align: middle;
        height: 81px;
        padding: 30px 3px;
        border-top: 1px solid $border-grey;
        text-align: center;
        font-size: 13px;
        &:first-child {
            @include border-top-left-radius(5px);
            @include border-btm-left-radius(5px);
            padding-left: 10px;
            border-left: 1px solid $border-grey;
            position: relative;
        }
        &:last-child {
            @include border-top-right-radius(5px);
            @include border-btm-right-radius(5px);
            padding: 10px;
            border-left: 1px solid $border-grey;
            border-right: 1px solid $border-grey;
        }
    }


    .blank,
    .annuler,
    .valider,
    .field-edit {
        display: none;
    }

    &.editer-on {
        .value,
        .editer,
        .renvoyer,
        .supprimer {
            display: none;
        }

        .blank,
        .annuler,
        .valider {
            display: table-cell;
        }

        .field-edit {
            display: block;
        }
    }
}

// Cells styles
.cell-pharmacien-equipe-ref {
    color: $black;
    font-family: 'bentonsans-condensedregular', sans-serif;
    font-size: 17px;
}

.cell-pharmacien-equipe-montHt {
    font-family: 'bentonsans-bold', sans-serif;
}

.cell-pharmacien-equipe-datedeb {
    position: relative;
    padding-right: 8px;
    &::after {
        content: "";
        display: block;
        position: absolute;
        right: -4px;
        top: 50%;
        margin: -6px 0 0 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 6px;
        border-color: transparent transparent transparent $grey-blue;
    }
}

.cell-pharmacien-equipe-statut {
    span[class^="col-statut_"] {
        display: block;
        padding: 0 0 0 27px;
        -webkit-background-size: 22px 22px;
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-position: left top;
        height: 22px;
        line-height: 22px;
        text-align: left;
    }
}

.cell-pharmacien-equipe-lien {
    a {
        display: block;
        color: $grey-litige;
        transition: all 0.4s ease;
        &:hover, &:active, &:focus{
            color:$light-grey-litige;
        }

        span[class^="ic-"] {
            display: block;
            font-size: 25px;
            text-decoration: none;
            margin-bottom: 5px;
        }
        span.ic-mail3{
            font-size: 18px;
            margin-bottom: 12px;
        }
    }
    .cell-pharmacien-equipe-signer-txt {
        text-decoration: underline;
    }

    &.annuler{
        a{
            color: $sep-border;
        }
    }
    &.valider{
        a{
            color: $pink-base;
        }
    }
}

// Responsive add-ons
.pharmacien-equipe-col_title-mobile {
    display: none;
}

// Colors
.row-statut_signe {
    li:first-child {
        &::before {
            @include row-border-style($green-base-light, $green-base);
        }
    }
}

.row-status-1 {
    li:first-child {
        &::before {
            @include row-border-style($pink-base-light, $pink-base);
        }
    }
}

.row-status-2 li:first-child {
    &::before {
        @include row-border-style($orange-base-light, $orange-base);
    }
}

.row-status-3 li:first-child {
    &::before {
        @include row-border-style($grey-blue-light, $grey-blue);
    }
}

.row-statut_vide li:first-child {
    &::before {
        @include row-border-style($grey-lighter, $grey-lighter);
    }
}

.col-status-1 {
    background-image: url('../img/contratAsigner.gif');
    color: $pink-base;
}

.col-status-4 {
    background-image: url('../img/contratSigne.gif');
    color: $green-base;
}

.col-status-2 {
    background-image: url('../img/contratEnCours.gif');
    color: $orange-base;
}

.col-status-3 {
    background-image: url('../img/contratRefuse.gif');
    color: $grey-medium;
}

.cell-pharmacien-equipe-lien.signer a {
    color: $pink-base;
}

.cell-pharmacien-equipe-lien.consulter a {
    color: $green-base;
}

// Filters boxes
.pharmacien-equipe-list-filter {
    margin: 0 0 20px 0;
}

.pharmacien-equipe-list-filter-col {
    display: inline-block;
    float: right;
    .selectric{
        .button{
            height:43px;
        }
    }
    &.pharmacien-equipe-list-filter-col-left{
        margin:0 20px 0 0;
        .label{
            width:70px !important;
        }
    }
}

.pharmacien-equipe-list-filter-label {
    display: block;
    color: $grey-blue;
    font-size: 12px;
    position: relative;
    .tooltip_toggle {
        position: relative;
        top: -7px;
    }
}

///*************************************
//* Media queries
//*************************************/
@media screen and (min-width: 980px) {
    .pharmacien-equipe-list-row {
        &:last-child li {
            border-bottom: 1px solid $border-grey;
        }
    }
}
@media screen and (max-width: 980px) {
    .pharmacien-equipe-list-filter-col {
        &:first-child{
            margin:0;
        }
    }
    .pharmacien-equipe-list-container {
        display: block;
    }
    .pharmacien-equipe-col_title-mobile {
        display: table-cell;
        text-transform: uppercase;
        color: $grey-medium;
        font-family: 'bentonsans-regular', sans-serif;
        font-size: 12px;
    }
    .pharmacien-equipe-col_content-mobile{
        display: table-cell;
    }
    .pharmacien-equipe-list-header {
        display: none;
    }
    .pharmacien-equipe-list-row {
        display: block;
        position: relative;
        width: 49%;
        float: left;
        border-bottom: 1px solid $border-grey;
        margin-bottom: 20px;
        &:nth-child(2n+1) {
            margin-right: 2%;
        }
        li {
            display: table;
            width: 100%;
            text-align: left;
            border: none;
            padding: 10px 10px 0 12px;
            margin: 0;
            height: auto;
            border-right: 1px solid $border-grey;
            font-family: 'bentonsans-medium', sans-serif;
            color: $black;
            &:first-child {
                @include border-top-left-radius(6px);
                @include border-top-right-radius(6px);
                @include border-btm-left-radius(0);
                border: none;
                &::before {
                    display: none;
                }
            }
            &:last-child {
                @include border-btm-right-radius(0);
                border: none;
                border-right: 1px solid $border-grey;
            }
        }
        // Cells styles
        .cell-pharmacien-equipe-ref {
            color: $white;
            font-size: 18px;
            padding-bottom: 10px;
            .pharmacien-equipe-col_title-mobile {
                color: $white;
                font-size: 13px;
            }
        }
        .cell-pharmacien-equipe-statut {
            padding: 15px 20px;
        }
        .cell-pharmacien-equipe-datedeb {
            width: 50%;
            float: left;
            border-right-width: 2px;
            border-bottom: 2px dotted $border-grey;
            padding-bottom: 9px;
            font-family: 'bentonsans-medium', sans-serif;
            &::after {
                display: none;
            }
            .pharmacien-equipe-col_title-mobile {
                display: block;
            }
        }
        .cell-pharmacien-equipe-datefin {
            width: 50%;
            float: left;
            border-bottom: 2px dotted $border-grey;
            padding-bottom: 10px;

            .pharmacien-equipe-col_title-mobile {
                display: block;
            }
        }
        .cell-pharmacien-equipe-datestatut,
        .cell-pharmacien-equipe-numfac,
        .cell-pharmacien-equipe-datefac {
            clear: both;
            color: $black;
            font-family: 'bentonsans-medium', sans-serif;
            .pharmacien-equipe-col_title-mobile {
                width: 50%;
            }
        }
        .cell-pharmacien-equipe-datefac {
            padding-bottom: 9px;
            border-bottom: 2px dotted $border-grey;
        }
        .cell-pharmacien-equipe-montHt, .cell-pharmacien-equipe-montTtc {
            .pharmacien-equipe-col_title-mobile {
                font-family: 'bentonsans-regular', sans-serif;
                &:first-child {
                    width: 50%;
                }
            }
        }
        .cell-pharmacien-equipe-montTtc {
            border-bottom: 1px solid $border-grey;
            padding-bottom: 10px;
        }
        .cell-pharmacien-equipe-lien {
            text-align: center;
            a {
                span[class^="ic-"] {
                    display: inline-block;
                    vertical-align: middle;
                }
                .cell-pharmacien-equipe-signer-txt {
                    font-size: 14px;
                }
            }
        }
        &:last-of-type li{
            border-bottom: none;
        }
    }

    // Colors
    .row-status-4 {
        &::before {
            @include row-border-style($green-base-light, $green-base);
        }
        .cell-pharmacien-equipe-ref {
            background-color: $green-base-light;
        }
        .cell-pharmacien-equipe-statut {
            border-bottom: 1px solid $green-base-light;
        }
    }
    .row-status-1 {
        &::before {
            @include row-border-style($pink-base-light, $pink-base);
        }
        .cell-pharmacien-equipe-ref {
            background-color: $pink-base-light;
        }
        .cell-pharmacien-equipe-statut {
            border-bottom: 1px solid $pink-base-light;
        }
    }
    .row-status-2 {
        &::before {
            @include row-border-style($orange-base-light, $orange-base);
        }
        .cell-pharmacien-equipe-ref {
            background-color: $orange-base-light;
        }
        .cell-pharmacien-equipe-statut {
            border-bottom: 1px solid $orange-base-light;
        }
    }
    .row-status-3 {
        &::before {
            @include row-border-style($grey-blue-light, $grey-blue);
        }
        .cell-pharmacien-equipe-ref {
            background-color: $grey-blue-light;
        }
        .cell-pharmacien-equipe-statut {
            border-bottom: 1px solid $grey-blue-light;
        }
    }
    .row-statut_vide {
        &::before {
            @include row-border-style($grey-lighter, $grey-lighter);
        }
        .cell-pharmacien-equipe-ref {
            background-color: $grey-lighter;
        }
        .cell-pharmacien-equipe-statut {
            border-bottom: 1px solid $grey-lighter;
        }
    }
}
@media screen and (max-width: 950px) {
    .block-amounts-received-block {
        span {
            font-size: 26px;
        }
    }
}

@media screen and (max-width: 680px) {
    .pharmacien-equipe-list-container-header h2 {
        width: 55%;
    }
}

@media screen and (max-width: 620px) {
    .pharmacien-equipe-list-container-header h2 {
        width: 100%;
        float: none;
    }
    .pharmacien-equipe-list-filter {
        text-align: left;
        margin: 0;
        padding: 10px;
        background-color: $sep-bg;
    }
}

@media screen and (max-width: 530px) {
    .pharmacien-equipe-list-row {
        display: block;
        position: relative;
        width: 100%;
        float: none;
        border-bottom: 1px solid $border-grey;
        &:nth-child(2n+1) {
            margin: 0 0 20px 0;
        }
    }
}



/*----------- POPIN ----------*/
.popin-equipe{
    background: $white;
    padding: 40px 60px 7px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    text-align: center;
    h1{
        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
        font-size: 11px;
        text-transform: uppercase;
        color: $grey-blue;
        margin: 0 auto 30px;
        span{
            color: $orange-base;
        }
    }

    h2{

        font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
        font-size: 24px;
        line-height: 31px;
        color: $grey-blue;
        margin: 0 auto 15px;
        span{
            color: $orange-base;
        }
    }

    p{
        color: $grey-popin-litige;
        font-family: 'bentonsans-light', Arial, Helvetica, sans-serif;
        font-size: 18px;
        line-height: 24px;
        small{
            color: $grey-medium;
            font-family: inherit;
            display: block;
            font-size: 12px;
            line-height: 13px;
        }
    }

    .popin-emailinvite{
        width: 60%;
        max-width: 230px;
        position: relative;
        margin: 10px auto;
        input{
            padding-left: 30px;
            color:$grey-light;
            &:focus, &:active, &:hover{
                border-bottom-color: $pink-base;
            }
        }
        label{
            &.ic-mail3:before{
                font-size: 14px;
                position: absolute;
                top: 7px;
                left: 3px;
            }
        }
    }

    #popin-addbutton{
        width: 60%;
        max-width: 230px;
        margin: 20px auto;
        button{
            background: transparent;
            font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
            font-size: 12px;
            position: relative;
            text-align:left;
            display: block;
            color: $orange2;
            padding-left: 15px;
            &:before{
                content: "+";
                display: block;
                position: absolute;
                color: $orange2;
                left: 0;
            }
            &:after{
                content: " ";
                position: absolute;
                display: block;
                border-bottom: 1px solid $orange2;
                bottom: 0;
                left: 0;
                width: 100%;
                transition: all 0.4s ease;
            }
            &:hover, &:focus{
                color: $orange-base;
                &:after {
                    left: 100%;
                    width: 0;
                }
            }
        }
    }

    .popin-submit{
        button{
            width: 190px;
            font-size: 12px;
            margin: 20px auto;
            background: $orange2;
            position: relative;
            &:before{
                background-color: $orange-base;
            }
        }
    }
}
