$color-primary: #4e8be5;
$color-white: #ffffff;
$color-footer: #9bc2fc;
$card-width: 280px;
$card-height: 400px;

///////////////////////////////////////////////////////////
////////////carousel for minute pharma/////////////////////
.minutePharmaCarouselContainer {
    background-color: #dcedfe !important;
    margin-left: 0;
}

@media screen and (max-width: 992px) {
    .minutePharmaCarouselContainer .bx-wrapper,
    .minutePharmaCarouselContainer .bx-wrapper .bx-viewport .listFormationsCategory {
        background-color: #dcedfe !important;
    }
}

.mpf {
    max-width: 1219px !important;
    padding-left: 0;

    h3 {
        font-size: 20px;
        color: black;
    }

    .bx-wrapper {
        max-width: 1025px !important;
    }

    .bx-prev, .bx-next {
        background-color: #4e8be5 !important;
        height: 50px !important;
    }

    .bx-pager {
        a {
            background-color: #4e8be5 !important;
        }

        a.active {
            background-color: #003a90 !important;
        }
    }

    .mp__question {
        margin-top: 20px;
        padding: 0;
        width: 100%;
    }

    .mp__question--answer {
        height: 135px;
        overflow: auto;
    }

    .simplebar-content {
        margin-right: 10px;
        font-family: bentonsans-medium, Arial, Helvetica, sans-serif;
        transform: translate3d(0px, 0px, 0px);
    }
}

.cmp {
    &__article {
        float: left;
        width: 275px;
        display: table;
        margin: 5px auto;
        table-layout: fixed;
        border: 0 none;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-collapse: collapse;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &__article_module_blocked {
        opacity: 0.6;
    }

    &__carousel-minute-pharma {
        //height: 450px !important;
    }

    &__container {
        position: relative;
        width: 100%;
        height: $card-height;
        background-color: $color-primary;
        color: $color-white;
        font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
    }

    &__module-title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: $color-primary;
        margin: 10px auto;
        letter-spacing: 1px;
    }

    &__header-photo {
        width: $card-width;
        margin: 0 0 10px 0;
    }

    ////////////question card/////////////////////
    &__question_card {
        background-color: $color-primary;
        color: $color-white;
        text-align: left !important;
        margin-top: 0px;
        padding-left: 10px;
    }

    &__module-content-question-btn {
        font-size: 13px;
        padding: 3px 35px;
        margin-top: 2px;
    }

    &__question-module-container {
        margin-right: 0px !important;
        font-size: 13px;
    }

    &__question-module-footer {
        padding: 5px 0px 15px 0px !important;
    }

    &__question-module-footer-text {
        font-size: 13px;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    ////////////module standyby card/////////////////////
    &__module_standby_card {
        background-color: $color-primary;
        color: $color-white;
        height: 350px;

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &--underlined {
                margin: 20px auto;
            }

            &--info {
                font-size: 13px;
                margin: 0px auto;
            }
        }

        &__icon_container {
            margin: 20px auto 0px auto;
            position: relative;

            &--icon {
                width: 90%;
            }

            &--text {
                position: absolute;
                top: 55%;
                left: 15%;
                color: #4e8be5;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    //////////////////module valid card////////////////////////
    &__module_valid_card {
        background-color: #A6C5F3;
        color: $color-white;
        height: 350px;

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__icon_container {
            margin: 0px auto;
            position: relative;

            &--icon {
                width: 90%;
            }

            &--text1 {
                position: absolute;
                top: 65%;
                left: 25%;
                color: $color-primary;
                font-size: 16px;
                font-weight: 600;
            }

            &--text2 {
                position: absolute;
                top: 80%;
                left: 26%;
                color: #224f92;
                font-size: 18px;
                font-weight: 800;
                text-transform: uppercase;
            }
        }

        &__btn {
            border: 2px solid $color-white;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            padding: 4px 30px;
            text-transform: uppercase;
            background-color: #A6C5F3;
            cursor: pointer;
            text-align: center;
            margin: 30px auto 0px auto;
            box-shadow: 1px 1px 0px 0px $color-white;
            text-decoration: none;
            color: $color-white;
        }
    }

    &__infobulle {
        position: relative; /*les .picto-item deviennent référents*/
        cursor: help;
    }

    /* on génère un élément :after lors du survol et du focus :*/
    &__infobulle:hover:after,
    &__infobulle:focus:after {
        content: attr(aria-label); /* on affiche aria-label */
        position: absolute;
        bottom: -43px;
        left: 50%;
        transform: translateX(-50%); /* on centre horizontalement  */
        z-index: 1; /* pour s'afficher au dessus des éléments en position relative */
        padding: 3px 12px;
        background: #4e8be5;
        text-transform: none;
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: $color-white;
    }

    @media (min-width: 1900px) {
        &__question--title {
            margin: 0px 15px;
        }
    }
}

.mpfa {
    font-family: Bentonsans-book, Arial, Helvetica, sans-serif;
    padding-top: 0 !important;
    width: 460px !important;

    h3 {
        color: black;
    }

    .content-block {
        border: 2px solid #dbe2e6;
    }

    .ic-services {
        font-size: 30px;
        background-color: $green-base;
        color: white;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 15px;
    }

    .header-text {
        font-family: Bentonsans-book, Arial, Helvetica, sans-serif;
        font-size: 18px;
        position: absolute;
        width: 114px;
        line-height: 24px;
        margin-left: 10px;
        margin-top: -7px;
    }

    .content-text {
        margin-top: 28px;
        margin-left: 22px;
        color: $green-base;
        line-height: 22px;
    }

    .arrow-right {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid white;
        display: inline-block;
        margin-right: 12px;
    }

    .button-block {
        text-align: right;
    }

    .button-access {
        background-color: $green-base;
        color: white;
        padding: 3px 20px;
    }
}
