.pharmacien-invoices {
    background: #F4F4F4;
}

.invoices {
    position: relative;
    margin-bottom: 30px;
    padding: 0 7vw;

    &-list-filters {
        position: sticky;
        top: 0;
        background: #F4F4F4;
        z-index: 2;
    }

    &-list-content {
        .list-content {
            tr:first-child {
                position: sticky;
                top: 71px;
                z-index: 2;
                background: #E3F1FF;
            }
        }
    }

    &-promotional-blocks {
        margin: 0 0 25px;
        padding: 0 7vw;
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-height: 150px;

        .invoice-block {
            width: 26%;
        }

        .invoice-block.min-block {
            width: 16%;
        }

        .promotional-block {
            padding: 0 10px;
        }

        .global-block {
            width: 26%;
            position: relative;
            text-align: center;

            a {
                .global-text {
                    position: absolute;
                    text-align: left;
                    color: #FFFFFF;
                    top: 26px;
                    left: 31px;
                    font-size: 18px;
                    font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                    letter-spacing: -0.36px;
                    width: 279px;
                    line-height: 1.2;
                }

                .white-zone {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 2px solid #FFFFFF;

                    .global-text-access {
                        color: #2DB4D1;
                    }
                }

                .blue-zone {
                    background: #0F52BA;
                    border: 2px solid #0F52BA;

                    .global-text-access {
                        color: #fff;
                    }
                }

                .white-zone, .blue-zone {
                    position: absolute;
                    top: 91px;
                    left: 36px;
                    border-radius: 15px;
                    width: 88px;
                    height: 32px;

                    .global-text-access {

                        font-size: 14px;
                        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                        transform: translate(0px, 5px);
                    }
                }
            }
        }

        .contact-block {
            width: 16%;
            position: relative;
            text-align: center;

            a {
                .scli-text {
                    position: absolute;
                    color: #FFFFFF;
                    top: 58px;
                    left: 29px;
                    font-size: 18px;
                    font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                    letter-spacing: -0.36px;
                    width: 75%;
                    line-height: 1.2;
                }

                .white-zone {
                    position: absolute;
                    top: 94px;
                    left: 50%;
                    transform: translate(-50%);
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border-radius: 15px;
                    width: 122px;
                    height: 32px;
                    border: 2px solid #FFFFFF;

                    .scli-text-number {
                        color: #72BBFF;
                        font-size: 14px;
                        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                        transform: translate(0px, 5px);
                    }
                }

                .scli-text-price {
                    position: absolute;
                    color: #FFFFFF;
                    top: 133px;
                    left: 50%;
                    transform: translate(-50%);
                    font-size: 10px;
                    font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
                    width: 75%;
                }
            }
        }

        img {
            margin: unset;
            width: 100%;
            height: 160px;
        }
    }

    &-loading {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;

        img {
            margin: unset;
            width: 100px;
        }
    }

    $colorText: #72BBFF;
    $listLineColor: #2F485C;
    $backgroundHeadColor: #72BBFF33;
    $backgroundCell: #FAFAFA;
    $backgroundCellSelected: #72BBFF0D;
    $borderSelectedColor: #72BBFF80;
    @import 'block/ged_table';

    .no-invoice {
        padding: 36px;
        text-align: center;

        &-header {
            font-size: 22px;
            font-family: BentonSans-Medium, sans-serif;
            color: #2DB4D1;
        }

        &-content {
            margin: 50px auto;
            font-size: 14px;
            font-family: bentonsans-regular, sans-serif;
            color: #000000;

            a {
                color: #2DB4D1;
                text-decoration: underline;
                text-decoration-color: #2DB4D1;
            }
        }

        &-btn {
            font-size: 14px;
            font-family: Bentonsans-bold, sans-serif;

            button {
                padding: 15px 20px;
                background: #CB2F7B;
                color: #FFFFFF;
                border-radius: 4px;
            }
        }
    }
}

/*
* Responsive mobile
**/
@media screen and (max-width: 480px) {
    .invoices {
        padding: 0 2vw;

        &-list-filters {
            padding: 0 5vw;
        }

        &-promotional-blocks {

            .contract-listing-block,
            .pharmacist-tools-block {
                a {
                    .pharmacist-tools-text {
                        font-size: 14px !important;
                        left: 20px;
                    }

                    .white-zone {
                        left: 20px !important;
                    }

                    .pharmacist-tools-text-access {
                        margin-top: -1px;
                    }
                }
            }

            .contact-block {
                a {
                    .scli-text {
                        width: 47%;
                        margin-left: 19%;
                    }
                }
            }
        }
    }

    .bx-wrapper-invoice-slider {
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow: unset !important;
        box-shadow: unset !important;
        border: unset !important;
        background: unset !important;
    }
}

@media screen and (max-width: 1300px) {
    .invoices {
        &-promotional-blocks {
            .pharmacist-tools-block {
                a {
                    .pharmacist-tools-text {
                        width: 228px;
                        font-size: 16px;
                    }

                    .white-zone {
                        width: 80px;
                    }

                    .pharmacist-tools-text-access {
                        margin-top: -1px;
                    }
                }
            }

            .contact-block {
                a {
                    .scli-text {
                        left: 18px;
                        font-size: 16px;
                    }
                }
            }

            .invoice-block {
                a {
                    .scli-text {

                        left: 18px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1301px) and (max-width: 1375px) {
    .invoices {
        &-promotional-blocks {

            .contact-block {
                a {
                    .scli-text {
                        top: 20px;
                    }
                }
            }
        }
    }
}

/*
* Responsive ecran 1500px
**/
@media screen and (min-width: 1368px) and (max-width: 1899px) {
    .invoices-promotional-blocks {
        .invoice-block {
            max-width: 370px;
        }
    }
}

/*
* Responsive ecran grand ecran
**/
@media screen and (min-width: 1900px) {
    .invoices-promotional-blocks {
        .invoice-block {
            width: 400px;
        }
    }
}

@media screen and (max-width: 1188px) {
    .invoices {
        &-list-content {
            .list-content {
                tr:first-child {
                    top: 90px;
                }
            }
        }
    }
}

@media screen and (min-width: 950px) and (max-width: 983px) {
    .invoices {
        &-list-content {
            .list-content {
                tr:first-child {
                    top: 105px;
                }
            }
        }
    }
}

@media screen and (max-width: 949px) {
    .invoices {
        &-list-content {
            .list-content {
                tr:first-child {
                    top: 123px;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .invoices {
        &-list-content {
            .list-content {
                tr:first-child {
                    background: unset;
                }
            }
        }
    }
}


.xhr-loader {
    background-image: none;
    width: 20px;
    height: 20px;
    position: absolute;
}

.xhr-loader::before {
    content: '';
    position: absolute;;
    z-index: 1;
    top: -120%;
    left: -120%;
    display: block;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-image: url('../img/bx_loader.gif');
}

.cell-yes {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 5px 5px 10px;
    gap: 8px;
    width: 65px;
    height: 26px;
    background: #FFFFFF;
    /* Green 3 */
    border: 1px solid #6FCF97;
    box-shadow: 0px 0px 10px rgba(111, 207, 151, 0.1);
    border-radius: 20px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
}

.cell-yes-content {
    /* Oui */
    width: 26px;
    height: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* Gray 2 */
    color: #000000;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.cell-yes-circle {
    width: 16px;
    height: 16px;
    /* Gray 5 */
    background: #6FCF97;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    border-radius: 50%;
}

.cell-no {
    content: 'nooooo';
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px 5px 5px;
    gap: 8px;
    width: 65px;
    height: 26px;
    background: #FFFFFF;
    /* Gray 5 */
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
}

.cell-no-content {
    /* Non */
    width: 26px;
    height: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* Gray 2 */
    color: #4F4F4F;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.cell-no-circle {
    width: 16px;
    height: 16px;
    /* Gray 5 */
    background: #E0E0E0;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 50%;
}

