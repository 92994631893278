.commande-flash-headband-container {
    background: #01BDDE;

    .block-img-text {
        display: flex;

        &-img-top-heading {
            display: flex;
            padding: 10px;
            margin: 0;
        }

        &-img-top-right-heading {
            position: absolute;
            width: 35%;
            right: 0;
            top: 55px;
            margin: 0;
        }

        &-texte-top-heading {
            color: #FFFFFF;
            font-family: "bentonsans-condensedbold", sans-serif;
            text-align: center;
            margin: 30px auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-block-1 {
                margin: auto;
            }

            &-block-2 {
                margin-bottom: 9px;
            }

            .offre-achat {
                font-size: 20px;
            }

            .remise {
                font-size: 40px;
            }

            .offre-validite {
                font-family: bentonsans-medium, sans-serif;
                font-size: 11px;
            }
        }
    }
}

@media screen and (min-width: 770px) {
    .block-img-text {
        &-img-top-heading {
            width: 50%;
        }
    }
}

@media screen and (min-width: 1500px) {
    .commande-flash-headband-container {
        .block-img-text {
            &-texte-top-heading {
                .offre-achat {
                    font-size: 28px;
                }

                .remise {
                    font-size: 50px;
                }

                .offre-validite {
                    font-size: 12px;
                }
            }
        }
    }
}
