.block-mes-actualites {
    border-left: 3px solid #f09a04;

    &-header {
        background: #f09a04;

        &-icon-plus{
            margin: auto 0 auto auto;
            font-size: 21px;
            a {
                color: white;
            }
        }
    }

    #bloc-news > li:first-child{
        //display: none;
        // padding-bottom: 20px;
    }

    #bloc-news .hr-sub {
        border-bottom: 1px solid #eeeeee;
        margin: 0 20px 0 20px;
    }

    #bloc-news .hr-sub:last-child {
        display: none;
    }

    #bloc-news > li:last-child a{
        border-bottom: none !important;
    }

    .icon-text--flexbox {
        display: flex;
        align-items: center;
        margin:auto;

        span {
            font-family: 'bentonsans-condensedregular', sans-serif;
            font-size: 15px;
            color: #849299;
        }

        i {
            font-size: 22px;
            text-align: center;
            color: white;
            background: #f09a04;
            width: 40px;
            height: 40px;
            margin: 15px;
            padding: 10px;

        }

        .fas.fa-plus.icon-style {
            display: none;
        }

        &:hover {
            .fas.fa-plus.icon-style {
                display: block;
                background: #cb2f7b;
            }

            i:first-child {
                display: none;
            }
        }
    }

    .hl-bloc-news {
        position: relative;
        color: black;
        padding: 20px 20px 10px 20px;

        img {
            min-height: 50px;
        }
    }

    .titleHl-bloc-news {
        padding: 10px 15px;
        line-height: 1.2em;
        font-family: 'bentonsans-condensedregular', sans-serif;
        font-size: 15px;
        color: #849299;
    }

    .block-news-a {

    }

    #block-news {
        padding-top: 30px;
    }

    .block-news-sp {
        font-size: 15px;
    }
}
