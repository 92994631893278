@charset "UTF-8";

.popin-base {
  background: $white;
  padding: 40px 60px 7px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  text-align: center;
  color: $grey-light;
}


.popin-otc {
  letter-spacing: 1px;
  color:$grey-light;
  h1 {
    font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: $grey-blue;
    margin: 0 auto 30px;
    font-weight: bold;
    padding-top:40px;
  }

  & >p {
    font-size: 17px;
    line-height: 23px;
    padding-bottom:10px;
  }

  h2 {
    font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
    font-size: 24px !important;
    color: $grey-blue;
    margin: 0 auto 30px;
  }

  div.stickers{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items:baseline;
    justify-content:space-between;

    div {
      display:flex;
      flex-direction: column;
      align-items:center;
    }

    p {
      font-size:14px;
      color:$grey-blue;
    }
  }


}

div.popin-otc-pmp {
  font-weight: bold;
  text-align: center;
  width:100%;
  color: $grey-blue;
  background-color: lighten($blue-base3, 50%);

  h3{
    padding-top:20px;
    font-size: inherit;
    color: $blue-dark4;
  }

  div.pmpLine {
    display:flex;
    align-items: center;
    justify-content:center;
    padding-bottom:15px;
    color:$blue-dark4;
    font-weight:bold;
    font-size: 12px;

    .ic-delegue, .ic-phone{
      font-size: 30px;
      color:$blue-base;
    }
  }
}