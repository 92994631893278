@charset "UTF-8";

.chapeau {
    font-style: normal;
}

.servicespatients-content {
    position: relative;
    .selectric-wrapper {
        display: none;
    }

    .content_leftcol-menu {
        li {
            a {
                > span.hover {
                    color: $green-base;
                }
            }
        }
    }

    .service-patient-link {
        text-decoration: underline;
        color: #0097A7 !important;
        font-weight: 700;
    }
}

/*
#servicespatients-menu-mobile{
  display: none;
}*/
#services-accordion {

    div.panel-default {

        border-radius: 5px;
        margin-bottom: 15px;

        > .panel-heading {
            background-color: $white;
            height: 230px;
            border-radius: 3px;

            .content {
                background-color: transparent;
                &:before{
                    display: none;
                    position: relative;
                }

                padding: 25px;

                .year {
                    font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
                    color: $grey-light;
                    font-size: 14px;
                    display: block;
                    margin: -15px 0 0;
                }
                h2 {
                    font-family: 'bentonsans-condensedregular', Arial, Helvetica, sans-serif;
                    font-size: 20px;
                    color: $grey-blue2;
                    font-weight: 400;
                    margin: 15px 0 5px 0;
                }
                h3, p {
                    font: 14px/19px 'bentonsans-book', Arial, Helvetica, sans-serif;
                    margin: 0 0 15px 0;
                    color: $grey-medium;
                }
                p.small {
                    font-size: 11px;
                    color: $grey-blue2;

                }

            }
            .imgcontainer {
                border-radius: 5px;
                height: 232px;
                position: absolute;
                top: -11px;

                width: 100%;
                z-index: 1;
                background-size: cover;

            }

        }

        button.more, a.externalink {
            font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
            font-size: 10px;
            color: $white;
            padding:0 20px;
            position: absolute;
            z-index: 1;
            text-transform: uppercase;
            //background-color: $grey-blue;
            transition: all 0.4s ease;
            span{
                position: relative;
            }
        }

        button.more {
            @include transition-ease-in-out();
            top: 191px;
            opacity:0;
            width:auto;
            height:30px;
            white-space: nowrap;
            &.collapsed{
                opacity:1;
            }
        }
        a.externalink {
            right: -16px;
            bottom: -16px;
        }

        .panel-body {
            position: relative;
            .close {
                position: absolute;
                z-index: 2;
                right: 10px;
                top: 10px;
                cursor: pointer;
                font-size: 25px;
            }
            img {
                position: absolute;
                top: -10px;
                //right: -16px;
            }
            a.sp-link-store{
                position: absolute;
                display: block;
                width: 90px;
                height: 30px;
                margin-top: 270px;
                &.sp-link-appstore{
                    margin-left: 30px;
                }
                &.sp-link-playstore{
                    margin-left: 130px;
                }
                > img{
                    position: relative;
                    top: unset;
                }
            }

            h2, h3, h4, p, ul {
                padding: 0 60px;
            }
            h2 {
                color: $grey-blue;
                font-size: 23px;
                line-height: 22px;
                padding-top: 20px;
                padding-bottom: 15px;

            }

            h3 {
                font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                font-size: 14px;
                font-weight: 400;
                padding-bottom: 15px;
            }

            h4 {
                font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                font-size: 12px;
                font-weight: 400;
                color: $grey-blue;
                text-transform: uppercase;
                padding-top: 25px;
                padding-bottom: 10px;
            }

            p {
                a {
                    color: $grey-blue;
                    word-wrap: break-word;
                }
                strong, b {
                    color: $grey-blue;
                }
            }
            ul {
                list-style-type: none;
                li {
                    padding:0 0 0 15px;
                    position: relative;
                    &::before {
                        content: "◦";
                        font-size: 24px;
                        position: absolute;
                        top: -8px;
                        left:0;
                    }
                }
            }
        }

        #triangle {
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: #007bff transparent transparent transparent;
        }

        &.odd {

            .imgcontainer, button.more {
                right: -16px;
            }
            #triangle {
                right: 30%;
            }
        }
        &.even {
            .imgcontainer, button.more {
                left: -16px;
            }

            #triangle {
                left: 30%;
            }
        }

        //---------------------------
        // Color variations : purple
        //---------------------------
        &.purple {

            .content h3, .panel-body h3 {
                color: $purple-base;
            }

            ul li::before {
                color: $purple-base;
            }
            /*button.more, a.externalink {
                &:hover, &:active, &:focus {
                    background-color: $purple-base;
                }
            }*/
            #triangle {
                border-color: $purple-base transparent transparent transparent;
            }
            button.arrow::before {
                border-left-color: $purple-base;
            }
            &.odd {
                .panel-heading {
                    border-left: 2px solid $purple-base;
                }
            }
            &.even {
                .panel-heading {
                    border-right: 2px solid $purple-base;
                }
            }
        }
        //---------------------------
        // Color variations : blue
        //---------------------------
        &.blue {
            .content h3, .panel-body h3 {
                color: $blue-base;
            }

            ul li::before {
                color: $blue-base;
            }
            /*button.more, a.externalink {
                &:hover, &:active, &:focus {
                    background-color: $blue-base;
                }
            }*/
            #triangle {
                border-color: $blue-base transparent transparent transparent;
            }
            button.arrow::before {
                border-left-color: $blue-base;
            }
            &.odd {
                .panel-heading {
                    border-left: 2px solid $blue-base;
                }
            }
            &.even {
                .panel-heading {
                    border-right: 2px solid $blue-base;
                }
            }
        }

        //---------------------------
        // Color variations : pink
        //---------------------------
        &.pink {

            .content h3, .panel-body h3 {
                color: $pink-base;
            }

            ul li::before {
                color: $pink-base;
            }
            /*button.more, a.externalink {
                &:hover, &:active, &:focus {
                    background-color: $pink-base;
                }
            }*/
            #triangle {
                border-color: $pink-base transparent transparent transparent;
            }
            button.arrow::before {
                border-left-color: $pink-base;
            }
            &.odd {
                .panel-heading {
                    border-left: 2px solid $pink-base;
                }
            }
            &.even {
                .panel-heading {
                    border-right: 2px solid $pink-base;
                }
            }
        }
        //---------------------------
        // Color variations : orange
        //---------------------------
        &.orange {

            .content h3, .panel-body h3 {
                color: $orange-base;
            }

            ul li::before {
                color: $orange-base;
            }
            /*button.more, a.externalink {
                &:hover, &:active, &:focus {
                    background-color: $orange-base;
                }
            }*/
            #triangle {
                border-color: $orange-base transparent transparent transparent;
            }
            button.arrow::before {
                border-left-color: $orange-base;
            }

            &.odd {
                .panel-heading {
                    border-left: 2px solid $orange-base;
                }
            }
            &.even {
                .panel-heading {
                    border-right: 2px solid $orange-base;
                }
            }
        }
        //---------------------------
        // Color variations : green
        //---------------------------
        &.green {

            .content h3, .panel-body h3 {
                color: $green-base;
            }

            ul li::before {
                color: $green-base;
            }
            /*button.more, a.externalink {
                &:hover, &:active, &:focus {
                    background-color: $green-base;
                }
            }*/
            #triangle {
                border-color: $green-base transparent transparent transparent;
            }
            button.arrow::before {
                border-left-color: $green-base;
            }
            &.odd {
                .panel-heading {
                    border-left: 2px solid $green-base;
                }
            }
            &.even {
                .panel-heading {
                    border-right: 2px solid $green-base;
                }
            }
        }


        &#kelmed{

            a.sp-link-store{
                margin-top: 150px;
            }
        }
    }

    div.panel-collapse {
        background-color: $blue-light2;
    }

}

button.arrow {
    position: absolute;
    background-color: transparent;
    display: none;
    right: 0px;
    top: 0px;
    transition: all 0.4s ease 0s;
    width: 80px;
    height: 186px;

    &:after, &:before {
        transition: all 0.4s ease 0s;
        left: 10px;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(136, 183, 213, 0);
        border-left-color: $white;
        border-width: 30px;
        margin-top: -30px;
    }
    &:before {
        border-color: rgba(194, 225, 245, 0);
        border-width: 36px;
        margin-top: -36px;
    }

    &:hover, &:active, &:focus {

        &:after, &:before {
            left: 30px;
        }
    }

    &:not(.collapsed) {
        height: 110px;
        top: 40px;
        transform: rotate(90deg);
    }
}

///************************************************************
// *  @_02 : Responsive
// ************************************************************/

@media screen and (max-width: 1200px) {

    #services-accordion {
        div.panel-default {

            > .panel-heading {
                p {
                    display: none;
                }

                button.more {
                    display: none
                }
                button.arrow {
                    display: block;
                }

                .content h2, .content h3, span.year {
                    padding-left: 30px;
                    padding-right: 80px;
                }
            }

            //----- BORDERS

            &.odd, &.even {
                .panel-heading {
                    height: 205px;
                    .imgcontainer {
                        background-position: center center;
                        height: 207px;
                        max-width: 205px;
                        width: 122%;
                    }

                }

                &.purple {
                    .panel-heading {
                        border-right: 2px solid $purple-base;
                        border-left: 0 none;
                    }
                }
                &.blue {
                    .panel-heading {
                        border-right: 2px solid $blue-base;
                        border-left: 0 none;
                    }
                }
                &.pink {
                    .panel-heading {
                        border-right: 2px solid $pink-base;
                        border-left: 0 none;
                    }
                }
                &.orange {
                    .panel-heading {
                        border-right: 2px solid $orange-base;
                        border-left: 0 none;
                    }
                }
                &.green {
                    .panel-heading {
                        border-right: 2px solid $green-base;
                        border-left: 0 none;
                    }
                }
            }

            .panel-body {

                img {
                    margin: 10px auto;
                    position: relative;
                    width: 50%;
                }

                a.sp-link-store{
                    position: relative;
                    margin-top: 10px;
                    display: inline-block;
                    margin-bottom: 15px;
                    &.sp-link-appstore{
                        margin-left: calc(50% - 100px);
                    }
                    &.sp-link-playstore{
                        margin-left: 30px;
                    }
                    > img{
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }

            &#kelmed{

                a.sp-link-store{
                    margin-top: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    #services-accordion {

        div.panel-default.odd, div.panel-default.even {
            > .panel-heading {
                .imgcontainer {
                    left: -13%;
                }
            }
        }
        div.panel-default.odd, div.panel-default.even {
            > .panel-heading {
                .imgcontainer {
                    width: 140%;

                }

                button.arrow {
                    display: block;
                    right: -20px;
                    transform: scale(0.6);
                    &:not(.collapsed) {
                        transform: scale(0.6) rotate(90deg);
                    }
                }

                .content h2, .content h3, span.year {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

            #triangle {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 767px) {

    .servicespatients-rightcol,
    .servicespatients-leftcol {
        display: block;
        float: none;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        background-color: $grey-really-lighter;
    }

    #services-accordion {

        div.panel-default {
            > .panel-heading {
                height: 205px;
                /*.imgcontainer{
                  width: 140%;
                }*/
            }

            &.odd, &.even {
                border: 0 none;
            }

            .panel-body {
                padding-bottom: 50px;
                img {
                    margin: 0 auto;
                    max-width: 50%;
                    position: relative;
                    right: 0;
                    top: 0;
                }

                h2, h3, h4, p, ul {
                    padding: 0;
                }

                a.externalink {
                    bottom: -50px;
                }
            }
        }
    }

    ul.servicespatients-menu {
        display: none;
    }
    /* menu */
    #servicespatients-menu-mobile {
        // display: block;
    }
    .servicespatients-content {

        .selectric-wrapper {
            display: block;
            width: 100%;
            margin-top: 30px;
            .selectric {
                padding: 20px 0 0 0;
                .label {
                    padding: 20px;
                    background-color: $white;
                    border: 1px solid $grey-lighter;
                }
                .button {
                    right: 10px;
                    top: 34px;
                    &::after {
                        border-color: $green-base transparent transparent;
                    }
                }
            }

            .selectric-items {
                ul {
                    list-style-type: decimal !important;
                }
                li {
                    list-style-type: decimal;
                    color: $grey-lighter;
                    &:hover, &.highlighted, &.selected {
                        color: $grey-blue2;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    #services-accordion {
        div.panel-default.odd, div.panel-default.even {
            > .panel-heading {
                .content {
                    padding: 25px 10px;
                    span.year, h2, h3 {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    span.year {
                        margin: -20px 0 -10px;
                        font-size: 12px;
                    }
                    h2 {
                        //font-size: 15px;
                    }
                    h3 {
                        font-size: 12px;
                    }
                }
                button.arrow {
                    right: -40px;
                    transform: scale(0.4);
                    &:not(.collapsed) {
                        transform: rotate(90deg) scale(0.4);
                    }
                }
                .imgcontainer {
                    left: -23%;
                }
            }
        }
    }
}
