%tooltip {
    border: 1px solid $simotc-dark-blue;
    border-radius: 5px;
    background-color: $simotc-tooltip-background;
    padding: 2rem 3rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-top: -3.5rem;

    &.in {
        opacity: 1;
    }

    .tooltip-arrow {
        border: 0;
    }

    .tooltip-inner {
        opacity: 1;
        max-width: 450px;
        background-color: $simotc-tooltip-background;
        color: $simotc-dark-blue;
        font-size: 12px;
        font-family: 'bentonsans-regular', sans-serif;
    }

}

.simotc-calculator-compare-select-help + .tooltip {
    @extend %tooltip;
}

.simotc-calculator-add + .tooltip {
    @extend %tooltip;
}

.simotc-calculator-list-btn + .tooltip {
    @extend %tooltip;
}
