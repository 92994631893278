@charset "UTF-8";

.cke_editable{
    padding:20px;
    blockquote{
        margin-left:0;
    }
}
.cke_panel_list{
    width:600px;
    li a * {
        margin: inherit;
        padding: inherit;
        border: none;
        color: inherit;
        font-size: 13px;
        line-height:1em;
        position: static;
    }
}
