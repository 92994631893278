///************************************************************
// *  @_Project : (Project name)
// *  @_Date : (DD/MM/YY)
// *  @_Author : German Pichardo
// *
// *  ---------------
// *  @_Description :
// *  ---------------
// *
// *  File name
// *
// ************************************************************/
//
///************************************************************
// *  ------------
// *  - Summary -
// *  ------------
// *
// *  @_01 Colors project :
// *  @_02 Font Weight :
// *  @_03 Image-Path :
// *
// ************************************************************/

///************************************************************
// *  @_01 : Colors
// ************************************************************/

$white: #ffffff;
$black: #000000;

$orange-base: #f09a04;
$blue-base: #2db4d1;
$blue-base2: #4e8be5;
$blue-base3: #00a1e4;
$blue-base4: #2FB4D1;
$blue-link: #337ab7;
$green-base:#92b90d;
$green-base2: #4ab893;
$purple-base: #782fcb;
$pink-base: #cb2f7b;
$pink-base-light: #FF79B7;

$red: #ef2b4b;

$grey-blue: #495b69;
$grey-blue2: #363e44; //Titre service patients
$grey-blue3: #718098; //RDP
$grey-blue-light: #88a2a7;
$grey-blue-light2: #7c8ca5; //texte FAQ
$grey-blue-light3: #636b6d; //trait FAQ
$grey-blue-light4: #97b5c4; //trait FAQ

$grey-medium: #858d93;
$grey-light: #a7afb5;
$grey-lighter: #c7d1d8;
$grey-really-lighter: #f1f1f1;
$grey-bodybg: #e9edef;
$grey-catabg: #f9f7f6;
$grey-popin-litige: #a8babd;
$grey-midlighter: #a8babd;
$border-grey: lighten($grey-lighter, 10%);

$blue-light: #f5fafb;
$blue-light2: #e3eaee; //services patients
$blue-light3: #dcedfe; // slider detail formation

$blue-dark: #0b89a4;
$blue-dark2: #657FD4;

$orange2: #f07904;
$orange3: #f0bb04;

//Litiges
$light-pink: #faeaf2; //notif
$light-pink2: #fbf0f6; //bg litige
$grey-litige: #485e68; //title,p, select
$light-grey-litige: #92a9ae; //checkbox, mentions

//Simulateur OTC
$simotc-grey-blue: #485e68;
$simotc-light-grey: #E4E4EA;
$simotc-light-grey-ligten: #CBCBD1;
$simotc-blue: #4091df;
$simotc-blue-darken: #2778C6;
$simotc-dark-blue: #263981;
$simotc-dark-blue-ligten: #40539B;
$simotc-grey-selectric-li: #ABACAF;
$simotc-light-blue-icon: #8EC4F8;
$simotc-tooltip-background: #D5DDFA;
$simotc-light-blue-ppttc: #99BEE2;

// separateur engagement
$sep-border: #c8dfe9;
$sep-bg: #f3f6fd;

$green-newsHl1: #289c98;
$green-newsHl2: #bac008;
$green-dashboard-light: #b7da42;
$green-dashboard-dark: #749600;

//ajout page formations
$green-formation: #40bfa0;
$green-formation-slider: #0b8aad;
$green-formation-dark: #36a388; //hr
$green-formation-light: #cffef3; //txt clair
$green-formation-light-bg: #d9f2ec;
$green-formation-light-bg2: #effefa;

$indigo-formation: #707fd6;
$indigo-formation-slider: #5b1cad;
$indigo-formation-dark: #5f6cb6; //hr
$indigo-formation-light: #d3d9ff; //txt clair
$indigo-formation-light-bg: #e2e5f7;
$indigo-formation-light-bg2: #eff1ff;

// RDP
$rdpBlueDark: #173261;
$rdpBlueLight: #92c5ff;



//OTC
$blue-dark3: #535f99;
$blue-dark4: #263981;
$blue-otc: #2f52a0;
$blue-otc-sub: #7991EF;
$otc-primary-default: #6C6B6E;
$otc-tram-default: #CDCBD1;

$hiver-otc: $blue-otc;
$ete-otc: #F49926;
$printemps-otc: #F298B4;
$implantation-otc: $blue-base;

///************************************************************
// *  @_02 : Font-Weight
// ************************************************************/

$light: 300;
$regular: 400;
$semiBold: 600;
$bold: 700;
$extraBold: 800;

$menuWidth: 6vw;

%display-flex{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
