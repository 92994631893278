.popin-authorize-download {
    .popin-authorize-download-container {
        background-color: #FFFFFF;
        text-align: center;
        width: 465px;
        position: relative;

        .leave {
            position: absolute;
            top: 10px;
            right: 14px;
            color: #A7AFB5;

            img {
                margin: unset;
                width: 12px;
            }
        }

        .leave:hover {
            cursor: pointer;
        }

        &-title {
            padding: 64px 39px 22px 39px;
            color: #2F485C;
            text-transform: uppercase;
            font-family: 'bentonsans-condensedmedium', sans-serif;
            font-size: 20px;
        }

        &-body {
            font-size: 16px;
            color: #292929;
            font-family: 'bentonsans-regular', sans-serif;
            line-height: normal;
            padding: 0 20px 0 20px;
        }

        .block-btn {
            padding: 35px 0 70px 0;

            .btn-understand {
                background: #CB2F7B 0 0 no-repeat padding-box;
                color: #FFFFFF;
                border-radius: 4px;
                font: normal normal 900 14px/16px;
                font-family: Bentonsans-bold, sans-serif;
                width: 125px;
                height: 43px;

                button {}
            }
        }
    }
}

// Responsive mobile

@media screen and (max-width: 420px) {
    .popin-authorize-download {
        .popin-authorize-download-container {
            width: unset;
        }
    }
}