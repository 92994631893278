#popinBiogEtVous {
    .popin-base {
        width: 568px;
        padding: 0;
        background-color: #fbfcfd;

        .popin-biog-et-vous {
            &-title {
                width: 100%;
                font-size: 11px;
                font-family: bentonsans-regular, sans-serif;
                color: $white;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 1.1px;
                padding: 7px 0;
                background-color: #C80072;
            }

            &-close {
                margin: 0 10px 0 -30px;
                float: right;

                img {
                    margin: unset;
                    display: unset;
                    width: 65%;
                }
            }

            &-close:hover {
                cursor: pointer;
            }

            &-block1et2 {
                width: 100%;
                display: flex;
                justify-content: space-between;
                position: relative;
            }

            &-card-r {
                width: 233px;
                background-color: #c7d1d8;

                a {
                    font-family: bentonsans, sans-serif;
                    color: #CB2F7B;
                    font-size: 12px;
                    font-weight: bold;
                    text-decoration: none;
                    width: 181px;
                    padding: 4px;
                    border: 2px solid #CB2F7B;
                }


                &-img {
                    img {
                        width: 60%;
                        //       margin: 25px 10px 10px 11px;
                    }
                }

                &-img-2 {
                    height: 57%;
                    img {
                        width: 90%;
                        margin-top: 20px;
                        margin-bottom: 5px;
                        margin-left: 5px;
                    }
                }
            }

            &-card {
                width: 327px;

                &-title {
                    margin-top: 5px;
                    font-family: bentonsans-regular, sans-serif;
                    font-size: 12px;
                    color: #00347D;
                    text-align: cente;

                    span {
                        color: #00347D;
                        font-family: bentonsans-bold, sans-serif;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                &-texte {
                    margin: 0 15px;
                    font-family: bentonsans-medim, sans-serif;
                    font-size: 10px;
                    color: #00347D;
                    text-align: left;
                }

                &-img {
                    img {
                        width: 290px;
                        margin: auto;
                        margin-bottom: 5px;
                    }
                }

                &-bilan {
                    margin: 15px;
                    font-family: bentonsans-medim, sans-serif;
                    font-size: 14px;
                    color: #CB2F7B;
                    text-align: center;
                    width: 87%;
                    font-weight: bold;
                }

                &-btn {
                    font-family: bentonsans-regular, sans-serif;
                    color: #E72E8A;
                    padding: 10px 20px;
                    display: block;
                    width: 203px;
                    margin: auto;
                    font-weight: bold;
                    border: 2px solid #CB2F7B;

                    img:hover {
                        cursor: pointer;
                    }
                }

                &-po {
                    font-size: 10px;
                    font-family: Bentonsans, sans-serif;
                    color: #00347D;
                }
            }
        }
    }
}
