@charset "UTF-8";

/*@TODO : faire le ménage dans les classes quand la page profil sera terminée*/

body.profil {
    .main-content {
        .h1-header-pmp {
            margin: 0 0 20px 0;

            h1 {
                background-color: $orange-base;
            }
        }
    }
}

.profil {
    margin: 20px 0;

    .cell-centered {
        text-align: center;
    }

    &-block {
        margin: 0 0 25px;
        padding: 0 7vw;
        display: flex;
        justify-content: space-between;
        width: 100%;

        // Start
        // Invisible block desktop
        &-probleme-mobile {
            display: none;
        }

        &-aide-mobile {
            display: none;
        }

        // End

        &-list {
            width: 35%;

            &-option {
                padding: 20px;
                margin-bottom: 20px;
                height: 185px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #8484840D;
                border-radius: 8px;
                opacity: 1;
            }

            &-probleme {
                border-radius: 8px;
                margin-top: 20px;
            }

            &-aide {
                border-radius: 8px;
            }
        }

        &-tab {
            width: 60%;
        }
    }
}

ul.profil-block-list-inline {
    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 5px;

        a {
            font: normal 20px/23px bentonsans-ultracondensed, sans-serif;
            text-transform: uppercase;
            letter-spacing: 0px;
            color: #989898;
            opacity: 1;

            a:visited {
                color: blue;
            }
        }

        span.first-text {
            font: normal 20px/23px bentonsans-ultracondensed, sans-serif;
            text-transform: uppercase;
            letter-spacing: 0px;
            color: #989898;
            opacity: 1;

            &:visited {
                color: #F09A04;
            }
        }

        span.second-text {
            font: normal 24px bentonsans-ultracondensed, sans-serif;
            text-transform: uppercase;
            letter-spacing: 0px;
            color: #989898;
            opacity: 1;
            padding-left: 8px;
            font-weight: bold;
        }

        span.icon-profil {
            background-color: #989898;
        }

        span.ic-profil-user {
            padding: 5px 7px;
            margin: 0 10px 0 0;

            @include border-radius(50%);
            color: $white;
            font-size: 13px;
            position: relative;
            top: -1px;
            height: 25px;
            display: inline-block;
            width: 25px;
        }

        span.ic-profil-store {
            padding: 5px 5px;
            margin: 0 10px 0 0;
            @include border-radius(50%);
            color: $white;
            font-size: 13px;
            position: relative;
            top: -1px;
            height: 25px;
            display: inline-block;
            width: 25px;
        }

        span.ic-profil-users-three {
            padding: 5px 5px;
            margin: 0 10px 0 0;
            @include border-radius(50%);
            color: $white;
            font-size: 13px;
            position: relative;
            top: -1px;
            height: 25px;
            display: inline-block;
            width: 25px;
        }

        span.ic-profil-lock {
            padding: 4px 6px;
            margin: 0 10px 0 0;
            @include border-radius(50%);
            color: $white;
            font-size: 13px;
            position: relative;
            top: -1px;
            height: 25px;
            display: inline-block;
            width: 25px;
        }
    }
}

.profil-block-mon_compte {
    border-left: 2px solid $orange2;
    padding: 0 0 10px 0;
    margin: 0;

    .hc-block-header-ic {
        background-color: $orange2;
    }

    .hc-block-title {
        span {
            color: $orange2;
        }
    }

    .hc-block-content {
        margin: 10px 69px 20px 69px;
    }

    .validation-conditions {
        padding: 0 0 0 20px;
        font-size: 12px;
        margin-top: 30px;
    }

    .userlogin-display {
        @include border-radius(5px);
        font-size: 13px;
        padding: 10px;
        background-color: #fdf3e2;
        text-align: center;

        span {
            color: $grey-blue;
        }
    }

    /*ul {
        list-style-type: none;
        overflow: hidden;
        li {
            margin: 0 0 15px 0;
        }
        span {
            display: inline-block;
            width: 30%;
            color: $orange2;
        }
        .profil-block-mon_compte-nom,
        .profil-block-mon_compte-prenom {
            font-size: 15px;
            color: $grey-blue;
        }
        .profil-block-mon_compte-optin {
            position: relative;
            padding: 0 0 0 30%;
            span {
                width: 90%;
                color: $grey-light;
            }
        }
    }*/
}

.profile-block-change_password {
    border-left: 2px solid $orange2;
    padding: 0 0 50px 0;

    .hc-block-header {
        margin-left: -2px;
    }

    .hc-block-header-ic {
        background-color: $orange3;
        width: 58px;
    }

    .hc-block-title {
        span {
            color: $orange3;
        }
    }

    .hc-block-content {
        margin: 10px 69px 20px 69px;
    }
}

.profil-block-mes_donnees_perso {
    border-left: 2px solid $orange2;
    padding: 0 0 50px 0;

    .hc-block-header {
        margin-left: -2px;
    }

    .hc-block-header-ic {
        background-color: $orange3;
        width: 58px;
    }

    .hc-block-title {
        span {
            color: $orange3;
        }
    }

    .hc-block-content {
        margin: 10px 69px 20px 69px;
    }

    .validation-conditions {
        padding: 0 0 0 20px;
        font-size: 12px;
        margin-top: 30px;
    }
}

body.profil {
    .hc-block-pharmacien-equipe {
        .hc-block-buttons {
            height: 30px;

            .hc-block-footerlink {
                min-width: 30%;
            }

            button.hc-block-footerlink {
                right: 32%;
                bottom: 0;
            }
        }
    }
}

// Officines
.profile-block-officines {
    border-left: 2px solid $orange-base;
    padding: 0 0 40px 0;

    .hc-block-content {
        margin: 10px 30px 20px 30px;
    }

    .hc-block-header {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        [class^="ic-"],
        [class*=" ic-"] {
            background-color: $orange-base;
        }
    }

    .hc-block-title {
        span {
            color: $orange-base;
        }
    }

    .hc-block-declare-grpt {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 30px;

        &-btn {
            text-align: center;
            padding: 7px 11px;
            background: #FAEAF2 0 0 no-repeat padding-box;
            border: 1px solid #CB2F7B;
            border-radius: 30px;
            font-size: 12px;
            font-family: Bentonsans Condensed, sans-serif;
            color: #CB2F7B;
        }

        &-btn-mobile {
            display: none;
        }

        &-btn-mobile-2 {
            display: none;
        }
    }

    @media screen and (max-width: 1200px) {
        .hc-block-declare-grpt {
            margin-right: 15px;
        }
    }
}

.profile-block-officines-detail {
    @include border-radius(5px);
    border: 1px solid $grey-lighter;
    overflow: hidden;
    height: 63px;
    min-height: 63px;

    .profile-block-officines-wrapper {
        margin: 0;
    }

    .pb-officines-header {
        width: 100%;
        padding: 10px 10px 25px 10px;
        margin: 0;
        display: table;
        cursor: pointer;

        div {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &.open {
        height: auto;
        /*.pb-officines-header{
            cursor: initial;
        }*/
    }

    .pb-officines-header-ic {
        @include border-radius(5px);
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid lighten($orange2, 40%);
        background-color: lighten($orange2, 45%);
        color: $orange2;
        font-size: 35px;
        text-align: center;
    }

    .pb-officines-header-txt {
        //position: relative;
        padding: 0 0 0 10px;
        color: $grey-blue;
        font-size: 16px;
        font-weight: bold;

        &:before {
            content: "";
            display: block;
            height: 1px;
            width: 24px;
            position: absolute;
            left: 10px;
            bottom: -11px;
            background-color: $grey-blue;
        }
    }

    .pb-officines-header-arrow {
        @include transition-ease-in-out(0.4s);
        width: 10px;
        padding: 20px;
        @include bgimg("accordion_yellowarrow.png", no-repeat, 50%, -27px);
        -webkit-background-size: 10px auto;
        background-size: 10px auto;
    }

    &.open .pb-officines-header-arrow {
        //background-position: 50% 18px;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .pb-officines-content-line {
        margin: 0;
        padding: 0 10px;

        div {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
        }
    }

    .pb-officines-content-ic {
        width: 35px;
        color: $orange2;
        font-size: 25px;
    }

    .pb-officines-content-txt {
        padding: 0 0 0 10px;
    }

    .pb-officines-footer {
        @include border-btm-left-radius(6px);
        @include border-btm-right-radius(6px);
        margin: 30px 0 0 0;
        border-top: 2px dotted $grey-lighter;
        background-color: $blue-light;
        display: table;
        width: 100%;

        .pb-officines-footer-leftcol,
        .pb-officines-footer-rightcol {
            width: 40%;
            margin: 0;
            display: table-cell;
            vertical-align: middle;
            padding: 15px 10px;
        }

        .pb-officines-footer-rightcol {
            width: 60%;
        }

        .pb-officines-footer-leftcol {
            div {
                display: inline-block;
                vertical-align: middle;
                margin: 0;
            }

            .pb-officines-footer-ic {
                @include border-radius(5px);
                width: 40px;
                height: 40px;
                line-height: 35px;
                border: 1px solid lighten($blue-base, 35%);
                background-color: lighten($blue-base, 40%);
                color: $blue-base;
                font-size: 25px;
                text-align: center;
            }

            .pb-officines-header-txt {
                position: relative;
                padding: 0 40px 0 10px;
            }

            .pb-officines-footer-txt {
                padding: 0 0 0 10px;
                font: normal 14px/1.3em 'bentonsans-condensedregular', sans-serif;
                text-transform: uppercase;
                color: $grey-blue;

                span {
                    display: block;
                    color: $blue-base;
                }
            }
        }

        .pb-officines-footer-rightcol {
            border-left: 1px solid $grey-lighter;

            .pb-officines-footer-nom_pmp {
                margin: 0 0 0 25px;
                font-size: 13px;
                color: $grey-blue;
            }

            .pb-officines-footer-nb_pmp {
                margin: 0;

                span,
                a {
                    display: inline-block;
                    vertical-align: middle;
                }

                span {
                    color: $blue-base;
                    font-size: 20px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

//popin groupement litige
.profil-popup-litige {
    background-color: white;
    margin: auto;
    padding: 45px 90px;
    text-align: center;

    span {
        margin: 0 0 10px 0;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font: 11px/1em 'bentonsans-medium', sans-serif;
    }

    .grey-span {
        color: $grey-litige;
        text-decoration: none !important;
    }

    .pink-span {
        color: $pink-base;
        text-decoration: none;
    }

    .dashboard-popup-title {
        text-align: center;
        font-size: 20px;
        margin: 25px 0 30px 0;
        color: $grey-litige;
        text-decoration: underline;
    }

    p {
        color: $grey-popin-litige;
        padding-bottom: 25px;
    }

    img {
        display: block;
        margin-left: auto;
        margin-right: auto
    }

    .btn {
        font: normal 9px/1.3em bentonsans-medium, sans-serif;
        padding: 13px 46px;

        .hoverEffect {
            margin: 0;
            padding: 0;
        }
    }
}

// Fin Officines

span.notifLitige .icon-notif {
    background: #F09A04;
}

.litigeForm .icon-notif {
    background: #F09A04;
}

span.icon-notif {
    border-radius: 3px;
    background: $pink-base;
    color: $white;
    padding: 3px 7px;
    font-size: 10px;
    margin: 0 6px 0 3px;
    top: -1px;
    position: relative;
}

.profile-block-officines-detail {
    &.open {
        .pb-officines-header-txt {
            span.notifLitige {
                top: 7px;
                margin-right: 28px;
                margin-top: 26px;
                right: 0;
                transition: top 0.4s ease;
            }
        }
    }

    span.notifLitige {
        position: absolute;
        background: #FEEDDB;
        padding: 5px 10px;
        border-radius: 30px;
        font-size: 12px;
        transition: top 0.4s ease;
        right: 85px;
        border: solid 1px #F09A04;


        span.text-notif {
            //color: $grey-really-lighter // bg h4
            color: #F09A04; // bg h4
        }
    }
}

/* .profile-block-officines-detail {
    &-open {
        .pb-officines-header-txt {
            span.notifLitige {
                span.notifLitige {
                    position: absolute;
                    top: 7px;
                    right: 0;
                    background: #FEEDDB;
                    padding: 5px 10px;
                    border-radius: 30px;
                    font-size: 12px;
                    transition: top 0.4s ease;
                    margin-right: 28px;
                    margin-top: 26px;

                    span.text-notif {
                        //color: $grey-really-lighter // bg h4
                        color: #F09A04; // bg h4
                    }
                }
            }
        }
    }
} */

.officine-block-details.hasLitiges {
    padding-bottom: 20px;

    .groupement-autocomplete-container {
        width: 100%;
        height: 85px;
        padding: 7px;
        border: 1px solid #CB2F7B;
        border-radius: 5px;
        background: white;
        font-size: 12px;
        font-family: 'bentonsans-medium', sans-serif;
        overflow: auto;

        .groupement-element {
            &:hover {
                cursor: pointer;
                color: #CB2F7B;
            }

            &-code {
                display: none;
            }
        }
    }

    .date-joining-group {
        position: relative;
        color: #F09B1A;
        font-size: 11px;
        font-weight: 700
    }
}

.current-joining-year {
    color: #495b69;
    padding-left: 22px;
    font-weight: normal;
    line-height: 38px;
    font-size: 10px
}

.lign-current-joining-year {
    padding-left: 30px;
    margin: -32px 0 0 22px;
    padding: 5px 0 25px 0px;
    border-bottom: 1px solid #a7afb5
}

.officine-block-details {
    display: flex;

    >div {
        flex-basis: 48%;
    }

    .pb-officines-footer {
        display: block;
        width: 84%;
        margin: 25px 8%;
        border-top: none;
        background-color: transparent;

        .bloc-pmp-contrat {
            border-color: $orange-base;

            .bloc-pmp-contrat-col {
                color: $orange-base;

                .ic-delegue {
                    margin-top: 40%;

                    &::before {
                        font-size: 3em;
                    }

                }

                .hc-block-title {
                    color: $orange-base;
                }

                .hc-block-delegue-name {
                    color: $grey-litige;
                }

                .hc-block-delegue-tel a {
                    color: $orange-base;
                }
            }
        }

    }
}

.profile-block-officines {
    .litigeForm {
        background-color: #FEEDDB;
        border-radius: 0;
        margin: 0 10px 10px 0;

        >div {
            display: flex;
            flex-direction: column;
            padding: 30px 30px 0;
            align-items: flex-start;
            position: relative;
        }

        .align-self-right {
            align-self: flex-end;
            font-size: 0.8em;
        }

        .align-self-stretch {
            align-self: stretch;
        }

        h4 {
            background-color: $white;
            border-radius: 3px;
            padding: 10px 0px 2px;
            font-size: 1.4em;
            color: $grey-litige;
            font-family: 'bentonsans-ultracondensed', sans-serif;
            text-transform: uppercase;
            width: 100%;

            span {
                color: #F1A822;
            }

            span.icon-notif {
                margin: 0 10px;
                color: $white;
                top: -6px;
            }
        }

        p,
        select,
        option {
            color: $grey-litige;
        }

        p.header-litige {
            padding-top: 15px;
            font-size: 0.95em;
        }

        label {
            position: relative;
            color: #F09B1A;
            font-size: 11px;

            &.text-hide {
                display: none;
            }

            >span {
                color: $light-grey-litige;
                font-size: 12px;
            }
        }

        .selectric-items {
            li {

                &:hover,
                &.highlighted,
                &.selected {
                    color: $pink-base;
                }

                &:hover {
                    background-color: lighten($pink-base-light, 23%);
                }
            }
        }

        .selectric {

            &.selectric-hover,
            &:hover,
            &:active,
            &:focus {
                .label {
                    border-bottom-color: #F09A04;
                }
            }

            span.label {
                color: $grey-blue;
                padding: 0 0 9px;
            }

            .button {
                top: 4px;

                &::after {
                    border-top-color: #F09A04;
                }
            }
        }

        p.light,
        input.light,
        div.light {
            color: $light-grey-litige;
        }

        p.litige-footer {
            font-size: 0.8em;
        }

        div.form-group {
            align-self: stretch;
        }

        .add-flex {
            display: flex;

            >div:not(.icheckbox) {
                width: 100%;
            }

            >.icheckbox,
            p {
                position: relative;
                margin: 0 5px 0 auto;
                padding-left: 15px;
            }

            >.icheckbox {
                flex-basis: auto;
                min-width: 18px;
                background-color: white;

                >input {
                    width: 18px;
                }

                .iCheck-helper {
                    width: 18px !important;
                    position: relative;
                }
            }
        }

        .icheckbox.checked {
            @include bgimg('check_mark_white.png');
        }

        .selectric-wrapper {
            display: block;
        }

        div.flex-wrap {
            >div {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;

                >label {}
            }
        }

        div[id^='pharmacien_profil_officineLitiges_'] {

            display: flex;
            justify-content: space-between;

            div.selectric-wrapper:first-of-type {
                display: none;
            }

            div.selectric-wrapper {
                margin: 0 5px 0 0;
                flex-basis: 44%;
            }
        }

        button[type="submit"] {
            float: right;
            margin: 5px 0 15px 0;
            font-family: 'bentonsans-ultracondensed', sans-serif;
            padding: 8px 34px;
            letter-spacing: 0px;
            font-size: 1.3em;
            font-weight: bold;

            background-color: #F09A04;
            border: solid 1px #989898;

            &:hover,
            &:focus,
            &:active {
                background: darken(#F09A04, 15%);
            }

            &::before {
                background: transparent;

                &:hover,
                &:focus,
                &:active {
                    background: transparent;
                }
            }
        }
    }
}

//grid css
@supports (display: grid) {

    /* TODO : ne pas utiliser le name dynamique en référence */
    form[name="pharmacien_equipe_membre_profil"] {
        display: -ms-grid;
        display: grid;

        -ms-grid-columns: 1fr 2fr;
        -ms-grid-rows: auto auto;

        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto auto;
        grid-gap: 30px 30px;
        margin-left: 30px;
        margin-top: 30px;

        .grid-1 {
            -ms-grid-column: 1;
            -ms-grid-column-span: 1;
            /*the gutter is a column track!*/
            grid-column: 1 / 2;

            -ms-grid-row: 1;
            -ms-grid-row-span: 1;
            grid-row: 1/2;

            width: 100%;
            margin: 0;
        }

        .grid-2 {
            -ms-grid-column: 2;
            grid-column: 2/3;

            -ms-grid-row: 1;
            -ms-grid-row-span: 3;
            grid-row: 1/3;
            width: 100%;
            margin: 0;
        }

        .grid-3 {
            -ms-grid-column: 1;
            grid-column: 1 / 2;

            -ms-grid-row: 2;
            grid-row: 2/3;
            width: 100%;
            margin: 0;
        }
    }
}

@supports not (display: grid) {

    /* TODO : ne pas utiliser le name dynamique en référence */
    form[name="pharmacien_equipe_membre_profil"],
    form[name="pharmacien_profil"] {
        .grid-1 {}

        .grid-2 {
            width: 60%;
        }

        .grid-3 {
            width: 34.54%;
        }

    }
}

@media screen and (max-width: 1200px) {
    .profil-block-mon_compte {
        ul {
            span {
                display: block;
            }

            .profil-block-mon_compte-optin {
                padding: 0 0 0 20px;
            }
        }
    }

    .profile-block-officines {
        .hc-block-content {
            margin: 15px;
        }
    }

    .profile-block-officines-detail {
        .pb-officines-header-txt span.notifLitige span.text-notif {
            display: none;
        }
    }

    .officine-block-details .pb-officines-footer {
        margin: 20px 3%;
    }
}

@media screen and (max-width: 1250px) and (min-width: 767px) {
    .profile-block-officines-detail .pb-officines-footer {
        display: block;

        // text-align: center;
        .pb-officines-footer-leftcol,
        .pb-officines-footer-rightcol {
            display: inline-block;
            width: 100%;
            padding: 10px;
        }

        .pb-officines-footer-rightcol {
            border: 0;
            padding-top: 0;
        }
    }
}

@media screen and (max-width: 900px) {
    .officine-block-details {
        flex-direction: column;

    }

    .profile-block-officines-detail {
        .pb-officines-footer {
            width: 94%;
        }

    }

    .profile-block-officines {
        .litigeForm {
            margin-left: 10px;

            .selectric .button {
                top: 0;
            }
        }
    }

}

@media screen and (max-width: 767px) {
    .profil-block-mon_compte {
        ul {
            span {
                display: inline-block;
                width: 20%;
            }

            .profil-block-mon_compte-optin {
                padding: 0 0 0 20%;
            }
        }
    }

    .profil .hc-col:last-of-type {
        width: 100%;
    }

    /* TODO : ne pas utiliser le name dynamique en référence */
    form[name="pharmacien_equipe_membre_profil"],
    form[name="pharmacien_profil"] {
        display: block;
        margin: 20px 5%;

        .grid-1,
        .grid-2,
        .grid-3 {

            width: 100%;
        }

    }

}

@media screen and (max-width: 600px) {
    .profil .main-content h1 {
        font-size: 28px;
        //margin-left: -100px;
    }
}

@media screen and (max-width: 420px) {
    .profil-block {
        display: block;

        &-tab {
            width: 100%;
        }

        &-list {
            .profil-block-probleme {
                display: none;
            }

            .profil-block-aide {
                display: none;
            }
        }

        &-probleme-mobile {
            display: block;
        }

        &-aide-mobile {
            display: block;
        }
    }

    ul.profil-block-list-inline {
        .list-inline {
            padding: initial;
        }
    }

    .officine-block-details {
        margin-bottom: 50px;

        .pb-officines-footer {
            .bloc-pmp-contrat {
                .bloc-pmp-contrat-col {
                    .ic-delegue {
                        margin-top: 35%;
                        margin-left: 20%;
                    }
                }
            }
        }
    }

    .profil-block-list-option {
        height: 195px;
    }

    .profil-block-list {
        width: 100%;
    }

    .hc-block-scli-pb {
        .hc-block-scli-pb-container {

            .hc-block-scli-nb {
                font-size: 10px;
            }

            .hc-block-scli-txt {
                padding: 0 5px;
            }
        }
    }

    .hc-block-formations {
        .hc-block-footerlink {
            width: 100%;
        }
    }

    .profile-block-officines {
        .hc-block-declare-grpt {

            &-btn {
                display: block;
                border-radius: 30px;
                background: #FAEAF2 0 0 no-repeat padding-box;
                border: 1px solid #CB2F7B;
                color: #CB2F7B;
                padding: 5px 7px;
                font-size: 10px;
                right: -10px;
                bottom: 25px;
                position: relative;
                text-align: center;
                font-family: Bentonsans Condensed, sans-serif;
            }
        }

        .hc-block-content {
            margin: 3px;
        }
    }

    .profile-block-change_password {
        .hc-block-content {
            margin: 10px 5px 20px 1px;

            .required {
                top: 0px;
            }

            .form-group {
                input {
                    font-size: 10px;
                }
            }
        }
    }

    .profil-block-mes_donnees_perso {
        .validation-conditions {
            margin-left: 10%;
        }
    }

    .profil-block-mon_compte {
        .hc-block-content {
            margin: 20px 5px 20px 20px;
            padding: 0 5px 0px 0px;

            .icheckbox {}
        }

        ul {
            span {
                display: block;
            }

            .profil-block-mon_compte-optin {
                padding: 0 0 0 25px;
            }
        }
    }

    .profile-block-officines-detail .pb-officines-footer {
        display: block;

        // text-align: center;
        .pb-officines-footer-leftcol,
        .pb-officines-footer-rightcol {
            display: inline-block;
            width: 100%;
            padding: 10px;
        }

        .pb-officines-footer-rightcol {
            border: 0;
            padding-top: 0;
        }
    }
}

.divider {
    border-bottom: 1px solid $grey-lighter;
    margin: 10px 20px;
}

.supprimer-compte {
    text-align: center;

    a {
        text-decoration: underline;
        font-weight: bold;
        font-size: 12px;
        color: $grey-light;
        font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
        text-underline-position: under;
    }

}

.popin-supprimer-compte {
    text-align: center;
    font-family: arial;
    font-size: 12px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 15px;

    .popin-image {
        width: 90%;
        margin-bottom: 10px;
    }

    .popin-title {
        text-transform: uppercase;
        color: #CB2F7B;
        font-weight: bold;
        font-size: 22px;
    }

    .popin-subtitle {
        color: #485E68;
        line-height: 23px;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
    }

    p.bold {
        color: #485E68;
        text-align: justify;
        width: 77%;
        margin: 0 auto;
        margin-bottom: 10px;

    }

    ul.bullet-puce {
        list-style-image: url('../img/puce.png');
        text-align: justify;
        width: 70%;
        margin: 0 auto;
        margin-bottom: 10px;

        li {
            list-style-position: outside;
        }
    }

    button {
        background-color: #cb2f7b;
        margin: 0px 10px 10px 10px;
        width: 30%;
        position: relative;
        padding: 15px;
        border: none;
        font: normal 10px/1.3em bentonsans-medium, sans-serif;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #ffffff;
        height: auto;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
    }

    .popin-close {
        display: flex;
        align-self: flex-end;
        font-size: 30px;
        font-family: 'bentonsans-ultracondensed', sans-serif;
        margin-top: -20px;
    }

    .version-number {
        margin: 5px 0 0px 0;
    }
}

.itineraryPanelItem,
.itineraryPanel {
    display: none;
}
