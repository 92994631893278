.delivery-promotional-block {
    height: 100%;
    border-radius: 8px;
    background-image: url("../img/promotional-block/delivery-note.jpg");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'bentonsans-medium', sans-serif;
    color: #FFFFFF;

    &-text {
        text-align: left;
        margin: 0 20px;
        color: #fff;
    }

    a {
        .bloc-container {
            height: 40%;
            width: 100%;
            justify-content: center;
            display: flex;
        }
    }

    &-button {
        border-radius: 20px;
        margin-top: 70px;
        margin-left: 50px;
        color: #fff;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background-color: #0F52BA;
        font-family: BentonSans-Medium, sans-serif;
    }

    @media screen and (max-width: 1367px) {
        &-text {
            font-size: 11px;
        }
    }
}

/*
* Responsive mobile
**/
@media screen and (max-width: 480px) {
    .csp-promotional-block {
        height: 150px;
    }
}
