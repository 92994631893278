@charset "UTF-8";

.generic-content{
    padding:40px 0 30px 10vw;
    width:80vw;
    max-width:1050px;
}
.h1-container{
    overflow: hidden;
}
.chapeau, .chapeau-contact{
    margin:0 0 40px 0;
    font-size:18px;
    p{
        line-height:27px;
    }
}

.chapeau-contact {
    font-style: italic;
}



@media screen and (max-width: 1200px) {
    .generic-content{
        padding:40px 0 30px 4vw;
        width:86vw;
        max-width:1050px;
    }
}
@media screen and (max-width: 767px) {
    .breadcrumb{
        padding:15px 10px 10px 10px;
    }
    .generic-content{
        padding:40px 20px 30px 20px;
        width:100%;
    }
    .chapeau{
        font-size:16px;
    }
}
@media screen and (max-width: 600px) {
    .page .main-content h1{
        /*font-size:28px;
        margin-left:-70px;*/
        /*.ic-actus2{
            width:150px;
            font-size:140px;
        }*/
    }
}
