.block-vision-patient {
    padding: 14px 20px;
    border-left: 3px solid #4585F8;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-logo {
        img {
            margin: unset;
            width: 75%;
        }
    }

    &-btn {
        &-access {
            background: #FFFFFF;
            padding: 7px 11px;
            border: 1px solid #4585F8;
            font-size: 11px;
            font-family: bentonsans-bold, sans-serif;
            color: #4585F8;
            text-transform: uppercase;
        }
    }
}
