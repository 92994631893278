@media print {
  a[href]:after {
    content: none;
  }

  a {
    text-decoration: none;
  }

  li.cell-cata-pic{
    width: 200px;
  }

  .prod-content {
    padding:0;
  }

  .main-content {
    padding :0 !important;
  }

  div.prod-slider{
    max-height:8cm;
    overflow:hidden;
  }

  div.bx-wrapper {
    height:8cm;
  }

  div#prod-slider-nav {
    display:none;
  }

  div#prod-slider-main img {
    max-width: 150px;
  }
}
