&-icon {
    margin-right: 12px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 14px;
}

.no-marging {
    margin-right: 0px;
}

.disabled,
button[disabled],
button:disabled {
    background-color: #7F96A8 !important;
    cursor: default !important;
    opacity: 0.3;
    color: white;
}

.fas .fa-pen {
    background-color: black;
}

&-icon:hover {
    .bounceAnimation {
        animation: bounce 2s ease 0s infinite normal none;
    }
}


@keyframes bounce {
    0% {
        animation-timing-function: ease-in;
        opacity: 1;
        transform: translateY(0px);
    }

    24% {
        transform: translateY(-1px);
        opacity: 1;
    }

    40% {
        animation-timing-function: ease-in;
        transform: translateY(-2px);
    }

    65% {
        animation-timing-function: ease-in;
        transform: translateY(-4px);
    }

    82% {
        animation-timing-function: ease-in;
        transform: translateY(-5px);
    }

    93% {
        animation-timing-function: ease-in;
        transform: translateY(-4px);
    }

    25%,
    55%,
    75%,
    87% {
        animation-timing-function: ease-out;
        transform: translateY(0px);
    }

    100% {
        animation-timing-function: ease-out;
        opacity: 1;
        transform: translateY(0px);
    }
}

.icon-lightgreen {
    background: #DBE7B3;
    color: #92B90D;
}

.icon-lightred {
    background: #FF0000;
    color: #FFFFFF;
}

.icon-grey, .icon-grey-more {
    background: #7F96A8;
    color: #FFFFFF;
}

.icon-lightgrey {
    background: #D3DDE5;
    color: #FFFFFF;
}

.icon-grey-more {
    font-size: 4px;
}

.closeAction {
    display: none;
}

.groupAction {
    display: none;
}

.mobileButtons {
    display: none;
}

@media screen and (max-width: 480px) {

    #btn-en-savoir-plus {
        display: none;
    }
    .normalAction {
        display: none;
    }
    .mobileButtons {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-right: 10px;
    }

    .list-line-cell-icon {
        margin-right: 0px !important;
    }

    .btn-libelle-container{
        color: #2F485C;
        font-size: 10px;
        font-family: bentonsans-regular;
    }
}
