%simotc-title {
    font-size: 22px;
    font-family: bentonsans-ultracondensed;
}

.simotc-container {
    margin: 0 7.5vw 2rem;

    @media screen and (max-width: 1200px) {
        margin: 0 4vw 2rem;
    }

    @media handheld, screen and (max-width: 640px) {
        margin: 0 4vw 1.5rem;
    }

    .btn {
        height: 40px;

        span.hoverEffect {
            @extend %display-flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            i.simotc-icon, i.fab, i.fas, i.far {
                font-size: 18px;
            }

        }

    }

    .simotc-actions {
        margin-bottom: 1rem;

        .btn-pink, .btn-darkblue {
            padding: 1rem;
            font-size: 10px;

            span.hoverEffect {
                i.simotc-icon:first-of-type, i.fab:first-of-type, i.fas:first-of-type, i.far:first-of-type {
                    margin-right: 0.5rem;
                }

                i.simotc-icon:last-of-type, i.fab:last-of-type, i.fas:last-of-type, i.far:last-of-type {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    @media handheld, screen and (max-width: 768px) {
        .simotc-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 2rem;

            .btn-pink, .btn-darkblue {
                max-width: calc(50% - 0.5rem);
                white-space: normal;

                span.hoverEffect {
                    text-align: initial;
                }
            }

            .btn-pink {
                margin-right: 0.5rem;
            }

            .btn-darkblue {
                margin-left: 0.5rem;
            }
        }
    }

    .simotc-calculator, .simotc-responsability, .simotc-asterisk {
        margin-bottom: 2rem;
    }

    .simotc-calculator {

        .simotc-calculator-products {
            @extend %display-flex;
            flex-direction: row;

            .simotc-calculator-product {
                @extend %display-flex;
                flex-direction: column;
                align-items: flex-start;
                border-left: 0.725rem solid $white;
                border-top-left-radius: 5px;

                .simotc-calculator-product-title {
                    text-transform: uppercase;
                    padding: 0.725rem 0.725rem 0.725rem 0;
                    border-radius: 0 5px 5px 0;
                    @extend %display-flex;
                    justify-content: center;
                    align-items: center;
                }

                .simotc-calculator-product-content {
                    @extend %display-flex;
                    flex-direction: row;
                    height: 100%;
                    align-items: center;
                    width: 100%;
                    font-size: 16px;
                }

            }

        }

        .simotc-calculator-product.simotc-calculator-product-bgr {
            width: 60%;
            border-color: $simotc-blue;

            @media handheld, screen and (max-width: 768px) {
                width: 50%;

                .simotc-calculator-product-title {
                    margin-right: 1rem;
                }
            }

            .simotc-calculator-product-title {
                background-color: $simotc-blue;
                color: $white;
                @extend %simotc-title;
            }

            .simotc-calculator-product-content {
                color: $simotc-blue;

                .selectric-wrapper {
                    width: 100%;

                    .selectric {
                        padding: 1.5rem;

                        .label {
                            color: $simotc-blue;
                            border-bottom: 0;
                            padding: 0 calc(32px + 1.5rem) 0 0;
                            min-height: 50px;
                            overflow: hidden;
                            font-size: 24px;
                            line-height: 24px;
                            font-weight: normal;
                            font-family: 'bentonsans-condensedmedium';

                            span {
                                color: $simotc-blue;
                            }

                            .simotc-product-select-name {
                                font-size: 24px;
                                line-height: 24px;
                                font-weight: normal;
                                font-family: 'bentonsans-condensedmedium';
                                width: 30%;
                            }

                            .simotc-product-select-dosage, .simotc-product-select-boitage {
                                font-size: 16px;
                                line-height: 16px;
                                font-weight: bold;
                                font-family: 'bentonsans-condensedregular';
                                width: 20%;
                            }

                            .simotc-product-select-img {
                                @extend %display-flex;
                                justify-content: center;
                                flex-direction: row;
                                font-weight: normal;
                                width: 30%;
                            }

                            @media handheld, screen and (max-width: 768px) {
                                font-family: 'bentonsans-condensedmedium';

                                .simotc-product-select-name, .simotc-product-select-dosage, .simotc-product-select-boitage {
                                    width: 100%;
                                    margin-bottom: 0.25rem;
                                }

                                .simotc-product-select-img {
                                    display: none;
                                }

                            }

                        }

                        b.button {
                            background-color: $simotc-light-grey;
                            right: 1.5rem;
                            height: 32px;
                            top: calc(50% - 16px);

                            @media handheld, screen and (max-width: 768px) {
                                top: inherit;
                                bottom: 1.5rem;
                            }
                        }

                    }

                    .selectric-items {
                        border: 0;

                        .selectric-scroll {
                            height: calc(100% - 3rem);
                            margin: 1.5rem;
                            padding-right: 32px;

                            @media handheld, screen and (max-width: 768px) {
                                font-family: 'bentonsans-condensedbold';
                                padding-right: 0;
                            }

                            ul {
                                li {
                                    color: darken($simotc-grey-selectric-li, 10%);
                                    padding: 1rem 0;
                                    font-size: 14px;
                                    height: calc(50px + 1rem);
                                    border-bottom: 1px solid $simotc-light-grey;

                                    @media handheld, screen and (max-width: 768px) {
                                        padding-right: 0;
                                    }

                                    &:last-of-type {
                                        border-bottom: 0;
                                    }

                                    &.selected, &.highlighted, &:focus, &:hover {
                                        color: $simotc-blue;
                                    }

                                    span {
                                        width: 25%;
                                        font-weight: bold;

                                        @media handheld, screen and (max-width: 768px) {
                                            width: 100%;
                                        }
                                    }

                                    .simotc-product-select-name {
                                        font-size: 13px;
                                    }

                                    .simotc-product-select-dosage, .simotc-product-select-boitage {
                                        font-size: 13px;
                                    }

                                    .simotc-product-select-img {
                                        @extend %display-flex;
                                        justify-content: center;
                                        flex-direction: row;

                                        img {
                                            max-height: 40px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .selectric .label, .selectric-items .selectric-scroll ul li {
                        @extend %display-flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        .simotc-product-select-img {
                            img {
                                margin: 0;
                                max-height: 50px;
                            }
                        }

                        @media handheld, screen and (max-width: 768px) {
                            flex-direction: column;
                            align-items: stretch;
                            justify-content: inherit;

                            .simotc-product-select-img {
                                display: none;
                            }
                        }

                    }

                }

            }

        }

        .simotc-calculator-product.simotc-calculator-product-otc {
            width: 40%;
            border-color: $simotc-light-grey;

            @media handheld, screen and (max-width: 768px) {
                width: 50%;
            }

            .simotc-calculator-product-title {
                background-color: $simotc-light-grey;
                @extend %simotc-title;
            }

            .simotc-calculator-product-content {
                padding: 1.5rem;

                #simotc-calculator-product-otc-name {
                    margin-right: 0.5rem;
                    font-family: bentonsans-condensedregular;
                    font-size: 24px;

                    @media handheld, screen and (max-width: 768px) {
                        font-size: 23px;
                    }
                }

            }

            .simotc-calculator-product-title, .simotc-calculator-product-content {
                color: $simotc-grey-blue;
            }
        }

        .simotc-calculator-block {
            @extend %display-flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 0.725rem;

            @media handheld, screen and (max-width: 768px) {
                margin-bottom: 2rem;
            }

            .simotc-calculator-block-title {
                padding: 1.5rem;
                @extend %display-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: $white;
                z-index: 10;
                font-family: bentonsans-bold;
                border-radius: 5px 5px 0 0;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                font-size: 19px;
                background-color: $simotc-dark-blue;
                i.simotc-icon, i.fas, i.fab, i.far {
                    margin-right: 1rem;
                    font-size: 25px;
                    color: $simotc-light-blue-icon;
                }

                img {
                    height: 30px;
                    margin: 0 1rem 0.5rem 0;
                }

                @media handheld, screen and (max-width: 768px) {
                    i.simotc-icon, i.fas, i.fab, i.far, img {
                        display: none;
                    }
                }
            }

            .simotc-calculator-block-content {
                @extend %display-flex;
                flex-direction: row;

                .simotc-calculator-block-content-left {
                    width: 60%;
                    padding: 1.5rem;
                    color: $white;
                    border-bottom-left-radius: 5px;
                    background-color: $simotc-blue;
                    @extend %display-flex;
                    flex-direction: column;
                    justify-content: space-around;

                    @media handheld, screen and (max-width: 768px) {
                        width: 50%;
                        padding: 1rem;
                    }

                    .simotc-input-group {

                        .simotc-input-symbol-euro {

                            input, &::before {
                                background-color: $white;
                            }

                            &#simotc-input-pfht-bgr {
                                background-color: $simotc-blue;

                                input, &::before {
                                    background-color: $simotc-blue;
                                    font-family: 'bentonsans-light';
                                    color: $white;
                                }

                            }

                            &#simotc-input-pna-bgr {

                                input, &::before {
                                    font-family: 'bentonsans-medium';
                                }

                            }

                        }

                    }

                }

                .simotc-calculator-block-content-right {
                    width: 40%;
                    padding: 1.5rem;
                    border-bottom-right-radius: 5px;
                    background-color: $simotc-light-grey;
                    color: $simotc-dark-blue;
                    @extend %display-flex;
                    flex-direction: column;
                    justify-content: space-around;

                    @media handheld, screen and (max-width: 768px) {
                        width: 50%;
                        padding: 1rem;
                        justify-content: end;
                    }

                    .simotc-input-group {

                        .simotc-input-label {
                            color: $simotc-dark-blue;
                        }

                        .simotc-input-symbol-euro {
                            color: $simotc-grey-blue;

                            input, &::before {
                                background-color: $white;
                                color: $simotc-grey-blue;
                                font-family: 'bentonsans-light';
                            }

                        }

                    }

                }

            }

        }

        .simotc-calculator-block.simotc-calculator-optimisation .simotc-calculator-block-content .simotc-calculator-block-content-left .simotc-input-group .simotc-input-symbol-euro.simotc-input-btn-group::before,
        .simotc-calculator-block.simotc-calculator-margin .simotc-calculator-block-content .simotc-calculator-block-content-left .simotc-input-group .simotc-input-symbol-euro.simotc-input-btn-group::before {
            font-family: bentonsans-medium;
        }

        .simotc-calculator-block.simotc-calculator-optimisation {

            .simotc-calculator-block-content .simotc-calculator-block-content-right {
                color: $simotc-grey-blue;

                .simotc-calculator-priceline .simotc-calculator-priceline-arrow .priceline-current {
                    background-color: $simotc-grey-blue;
                    color: $white;
                    &::after {
                        border-top-color: $simotc-grey-blue;

                        @media handheld, screen and (max-width: 768px) {
                            border-bottom-color: $simotc-grey-blue;
                        }
                    }
                }
            }

            .simotc-calculator-compare-blank, .simotc-calculator-compare {
                padding: 1rem;
                width: 86.366%;
                margin: 2rem 0 2rem 43.183%;
                height: 80px;

                @media handheld, screen and (max-width: 640px) {
                    margin-left: 5%;
                    width: 80vw;
                    height: 100px;
                }

                @media handheld, screen and (min-width: 640px) and (max-width: 768px) {
                    margin-left: 5%;
                    width: calc(80vw - 43px);
                    height: 100px;
                }

            }

            .simotc-calculator-compare {
                background-color: $white;
                border-radius: 5px;
                color: $simotc-dark-blue;
                z-index: 10;
                @extend %display-flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

                .simotc-calculator-compare-input-group {
                    @extend %display-flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1rem;

                    @media handheld, screen and (max-width: 768px) {
                        flex-direction: column;
                    }

                    .simotc-calculator-compare-input-label {
                        font-family: bentonsans-bold;
                        font-size: 15px;

                        @media handheld, screen and (max-width: 768px) {
                            margin-bottom: 1rem;
                        }
                    }

                    .simotc-calculator-compare-select-container {
                        @extend %display-flex;
                        flex-direction: row;
                        align-items: center;

                        .selectric-wrapper {
                            margin-right: 0.5rem;
                            margin-left: 2rem;

                            .selectric {
                                padding: 0.5rem;
                                background-color: $simotc-light-grey;
                                height: 26px;
                                @extend %display-flex;
                                flex-direction: row;
                                align-items: center;

                                .label {
                                    color: $simotc-dark-blue;
                                    border-bottom: 0;
                                    font-size: 15px;
                                    height: calc(26px - 1rem);
                                    padding: 0 calc(26px + 0.5rem) 0 0;

                                    i.fas, i.fab, i.far {
                                        margin-right: 0.5rem;
                                    }

                                }

                                b.button {
                                    width: 26px;
                                    height: 26px;
                                    background-color: $simotc-dark-blue;

                                    &::after {
                                        border-top-color: $white;
                                    }

                                }

                            }

                            .selectric-items {
                                border: 0;

                                .selectric-scroll {
                                    margin: 0.5rem;
                                    padding-right: 26px;

                                    @media handheld, screen and (max-width: 768px) {
                                        padding-right: 0;
                                    }

                                    ul {

                                        li {
                                            color: $simotc-grey-selectric-li;
                                            padding: 0.5rem 0;
                                            font-size: 14px;
                                            height: calc(16px + 1rem);
                                            i.fas, i.fab, i.far {
                                                margin-right: 0.5rem;
                                            }

                                            &.selected, &.highlighted, &:focus, &:hover {
                                                color: $simotc-dark-blue;
                                            }

                                        }

                                    }

                                }

                            }

                            .selectric .label, .selectric-items .selectric-scroll ul li {
                                @extend %display-flex;
                                flex-direction: row;
                                align-items: center;
                            }

                        }

                    }

                }

                .simotc-calculator-compare-info {
                    font-size: 12px;
                    color: $simotc-grey-blue;
                }

            }

        }

        .simotc-calculator-purchasedata .simotc-calculator-block-title {
            border-radius: 0;
        }

        .simotc-calculator-purchasedata.simotc-calculator-block .simotc-calculator-block-content .simotc-calculator-block-content-left {
            flex-direction: row;

            @media handheld, screen and (max-width: 768px) {
                flex-direction: column;

                .simotc-input-group:nth-child(2) {
                    margin-bottom: 1.5rem;

                    .simotc-input-label{
                        margin-right: 1rem;
                    }
                }
            }
        }

        .simotc-calculator-resume {
            @extend %display-flex;
            flex-direction: row;
            margin-bottom: 2rem;

            @media handheld, screen and (max-width: 768px) {
                flex-direction: column;
            }

            .simotc-calculator-price {
                width: 60%;
                @extend %display-flex;
                flex-direction: column;

                @media handheld, screen and (max-width: 768px) {
                    width: 100%;
                }

                .simotc-calculator-price-value, .simotc-calculator-price-charts {
                    @extend %display-flex;
                    flex-direction: row;
                }

                .simotc-calculator-price-value {
                    height: 40%;

                    @media handheld, screen and (max-width: 768px) {
                        flex-direction: column;
                        height: inherit;
                    }

                    .simotc-calculator-price-value-label {
                        background-color: $simotc-dark-blue;
                        color: $white;
                        @extend %display-flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: bold;
                        font-size: 18px;
                        font-family: bentonsans-bold;
                        position: relative;
                        padding-left: 5%;
                        width: 40%;
                        border-top-left-radius: 5px;
                        &::after {
                            content: '';
                            position: absolute;
                            left: 100%;
                            top: calc(50% - 15px);
                            width: 0;
                            height: 0;
                            z-index: 10;
                            border-bottom: 0 solid transparent;
                            border-top: 15px solid transparent;
                            border-left: 30px solid $simotc-dark-blue;
                            clear: both;
                        }

                        @media handheld, screen and (max-width: 768px) {
                            border-top-right-radius: 5px;
                            width: 100%;

                            &::after {
                                top: inherit;
                                bottom: -15px;
                                border-left: 0;
                                left: 20%;
                                border-bottom: 4px solid transparent;
                                border-right: 40px solid #263981;
                                transform: rotate(245deg);
                                border-top: 20px solid transparent;
                            }
                        }
                    }

                    .simotc-calculator-price-value-counter {
                        background-color: $simotc-light-grey;
                        width: 60%;
                        @extend %display-flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0;
                        font-size: 4rem;
                        color: $simotc-dark-blue;
                        border-top-right-radius: 5px;

                        @media handheld, screen and (max-width: 768px) {
                            border-top-right-radius: 0;
                            width: 100%;
                            background-color: $simotc-light-blue-ppttc;
                        }

                        .char {
                            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
                        }

                        .counter-ppttc {
                            color: $simotc-dark-blue;
                            font-size: 10px;
                            text-transform: uppercase;
                            @extend %display-flex;
                            align-self: self-start;
                            margin-left: 1rem;

                            @media handheld, screen and (max-width: 768px) {
                                color: $white;
                            }
                        }

                    }

                }

                .simotc-calculator-price-charts, .simotc-calculator-price-value-counter, .simotc-calculator-price-value-label {
                    padding: 2rem 1.5rem 1.5rem 1.5rem;
                }

            }

            .simotc-calculator-actions {
                width: 40%;
                padding-left: 5vw;
                padding-right: 5vw;
                text-align: center;
                padding-top: 2rem;

                @media handheld, screen and (max-width: 640px) {
                    width: 100%;
                    padding-left: 7.5vw;
                    padding-right: 7.5vw;
                }

                @media handheld, screen and (min-width: 640px) and (max-width: 768px) {
                    width: 100%;
                    padding-left: 15vw;
                    padding-right: 15vw;
                }

                button.btn {
                    padding: 1rem 2rem 1rem 1rem;
                    font-size: 10px;
                    margin-bottom: 0.725rem;
                    display: block;
                    width: 100%;
                    span.hoverEffect {
                        i.simotc-icon, i.fas, i.fab, i.far {
                            font-size: 18px;
                            text-align: left;
                            width: 100%;
                        }
                    }

                    @media handheld, screen and (max-width: 640px) {
                        padding: 1rem;
                    }
                }

                img.ospharm-logo {
                    margin: 2rem 0 0.725rem;
                    width: 100px;
                    display: inline-block;
                }

                div.ospharm-indication {
                    color: $simotc-grey-blue;
                    font-size: 12px;
                }

            }

        }

    }

    .simotc-asterisk {
        font-size: 10px;
    }

    .simotc-responsability {
        font-size: 12px;
    }

    .simotc-asterisk, .simotc-responsability {
        color: $grey-midlighter;
    }

}
