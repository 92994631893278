@charset "UTF-8";

body.home,
body.profil {
    background-color: $grey-bodybg;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
    /*.page &{
        padding-right:0;
    }*/
}
#return-to-top {
    position: fixed;
    bottom: 153px;
    right: 7px;
    background: #01afed;
    background: #01afed;
    width: 46px;
    height: 46px;
    display: block;
    text-decoration: none;
    display: none;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    -webkit-background-clip: padding-box;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 10;
    padding: 15px;
    &:hover, &:active, &:focus{
        &::before{
            top: 45%;
        }
    }
}
#return-to-top::before{
    border-width: 5px 8px 5px 0;
    border-color: transparent #FFFFFF transparent transparent;
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -4px 0 0 -5px;
    width: 0;
    height: 0;
    border-style: solid;
    transform: rotate(90deg);
    transition: all 0.4s ease;
}

/* Media queries */
@media screen and (max-width: 767px) {
    .container-fluid {
        padding-left: 10px;
        padding-right: 10px;
        .page & {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
