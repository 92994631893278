// mp = minute-pharm
// prefix of class name wille be mp_
// ex mp_container
// modifier name will be --name of modifier eg: mp_header--photo

$color-primary: #4e8be5;
$color-white: #ffffff;
$color-footer: #9bc2fc;
$card-height: 400px;
.mp {
    &__hc_block_page_home {
        padding: 0px !important;
    }

    &__container {
        position: relative;
        width: 100%;
        height: $card-height;
        background-color: $color-primary;
        color: $color-white;
        font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
        padding: 0;

        .header-background {
            background-color: #f6f9fe;
            height: 57px;
            display: inline-block;
            width: 100%;
        }
    }

    &__row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    &__header {
        &--photo {
            margin: 0 0 5px 0;
        }
    }

    &__question {
        width: 100%;
        margin: 60px auto 5px auto;

        &--title {
            background-color: $color-white;
            color: $color-primary;
            padding: 3px 10px;
            font-size: 14px;
            border-radius: 15px;
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
        }

        &--content {
            margin-left: 5px;
            margin-right: 5px;
        }

        &--answer {
            margin: 0;
            height: 185px;
            overflow: auto;
        }

        &--source {
            font-size: 13px;
            overflow-wrap: break-word;
        }

        .simplebar-content {
            margin-right: 10px;
            font-family: bentonsans-medium, Arial, Helvetica, sans-serif;
        }

        &--reminder {
            margin: 0 0 10px;
            font-family: Bentonsans-book, Arial, Helvetica, sans-serif;
        }

        &--btnzone {
            text-align: center;
        }
    }

    &__button {
        border: 2px solid $color-white;
        display: inline-block;
        font-size: 14px;
        text-transform: uppercase;
        background-color: $color-primary;
        background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $color-white 50%);
        background-size: 220%;
        transition: all .3s;
        padding: 2px 40px;
        margin-top: 10px;
        font-family: bentonsans-bold, Arial, Helvetica, sans-serif;

        &:hover,
        &:active {
            background-position: 100%;
            color: $color-primary;
            transform: translateX(0);
        }
    }

    &__responsezone {
        border: 2px solid $color-white;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        padding: 4px 30px;
        text-transform: uppercase;
        background-color: $color-primary;
        cursor: pointer;
        width: 100%;
        text-align: center;

        span {
            margin-right: 5px;
        }
    }

    &__result {
        background-color: $color-white;
        color: $color-primary;
        padding: 3px 15px;
        font-size: 14px;
        display: inline-block;
        text-transform: uppercase;
        font-family: bentonsans-bold, Arial, Helvetica, sans-serif;
    }

    &__footer {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 5px;
        background-color: $color-footer;
        bottom: 0;
        width: 100%;
        position: absolute;
        margin-top: 15px;

        h4 {
            font-size: 14px;
            font-family: Bentonsans-book, Arial, Helvetica, sans-serif;
        }

        &--dot {
            height: 14px;
            width: 14px;
            background-color: $color-white;
            border-radius: 50%;
            display: inline-block;
        }

        &--circule {
            height: 14px;
            width: 14px;
            border: 1px solid $color-white;
            border-radius: 50%;
            display: inline-block;
        }

        &--dash {
            position: relative;
            top: -7px;
            font-weight: 600;
            width: 20px;
            margin: 0px 2px;
            border-bottom: 2px solid;
            display: inline-block;
        }
    }

    &__bx-loading {
        opacity: 0.5;
        position: absolute;
        left: 45%;
        top: 50%;
        width: 15px;
        height: 15px;
        display: none;
        color: $color-white;

        &--img {
            margin: 0 auto;
        }
    }

    &__pbar_outerdiv {
        margin-top: 10px;
        height: 3px;
        z-index: 1;
        position: relative;
        background-color: white;
        width: 100%;
        box-sizing: content-box;
    }

    &__pbar_innerdiv {
        background-color: #1f5fa5;
        z-index: 2;
        height: 100%;
        width: 0%;
        box-sizing: content-box;
    }

    &__link {
        width: 100%;
        display: inline-block;
        font-size: 13px;
        text-transform: uppercase;
        background-color: #485e68;
        margin-top: 10px;
        position: absolute;
        bottom: 0;
        letter-spacing: 2px;
        font: normal 10px/30px bentonsans-medium, sans-serif;
        box-sizing: border-box;
        cursor: pointer;

        &--right-side {
            width: auto;
            right: 0;
            padding-right: 20px;
            padding-left: 20px;
            width: 70%;
            white-space: nowrap;
        }
    }

    &__score {
        text-align: center;

        &--title {
            margin: 30px 15px 10px 15px;
            text-transform: uppercase;
            font-size: 18px;
        }

        &--note {
            margin: 50px 15px 10px 15px;
            font-size: 50px;
            position: relative;

            &-footer {
                margin-top: 30px;
            }

            span {
                font-size: 20px;
                position: absolute;
                top: -10px
            }
        }

        hr {
            margin: 0 auto;
            width: 50%;
        }
    }

    &__image_text {
        position: relative;

        &--centered-bottom {
            position: absolute;
            bottom: 1%;
            left: 50%;
        }
    }

    &__modules {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 15px;

        &--title {
            margin: 20px 15px 5px 15px;
            text-transform: uppercase;
            font-size: 16px;
            font-family: bentonsans-light, Arial, Helvetica, sans-serif;
        }

        &--content {
            margin: 5px 15px 10px 15px;
            font-family: bentonsans-regular, Arial, Helvetica, sans-serif;
        }

        &--img {
            display: inline-block;
            margin: 5px 20px 10px 0;
        }

        &--btn {
            cursor: pointer;
            text-decoration: none;
            text-align: center;
        }

        &__image_text {
            position: relative;
            text-align: center;

            &--centered-bottom {
                position: absolute;
                bottom: 10px;
                font-family: bentonsans-regular, Arial, Helvetica, sans-serif;
                color: #4e8be5;
                font-size: 11px;
                left: 15px;
                letter-spacing: 1px;
            }
        }
    }

    &__alert {
        padding: 20px;
    }

    ////////////////////////////////////////////////////////////////////
    ////////////////////////Mdiea query////////////////////////////////
    @media (min-width: 200px) and (max-width: 360px) {
        &__container {
            height: auto;
        }
        &__score {
            min-height: 330px;
        }
        &__modules--img {
            width: 70%;
        }
        &__question {
            height: 300px;
        }
        &__responsezone {
            padding: 4px 20px;
            width: 80%;
        }
        &__pbar_outerdiv {
            width: 80%;
        }
        &__modules__image_text {
            &--centered-bottom {
                bottom: 10px;
                left: 17px;
            }
        }
        &__button {
            padding: 2px 30px;
        }

        &__link {
            position: relative !important;
            width: 100% !important;
        }
    }
    @media (min-width: 361px) and (max-width: 600px) {
        &__container {
            height: auto;
        }

        &__modules {
            margin: 30px
        }

        &__question {
            height: 300px;
        }

        &__modules--img {
            margin: 15px;
        }

        &__modules__image_text {
            &--centered-bottom {
                bottom: 18px;
                left: 30px;
            }
        }
        &__link {
            position: relative !important;
            width: 100% !important;
        }
    }
    @media (min-width: 490px) and (max-width: 600px) {
        &__modules {
            margin: 50px
        }
        &__modules--img {
            margin: 20px;
        }
        &__modules__image_text {
            &--centered-bottom {
                bottom: 22px;
                left: 32px;
            }
        }
    }
    @media (min-width: 601px) and (max-width: 767px) {
        &__container {
            height: auto;
        }

        &__modules {
            margin: 40px
        }
        &__modules--img {
            margin: 30px;
        }
        &__modules__image_text {
            &--centered-bottom {
                bottom: 30px;
                left: 45px;
            }
        }
        &__question {
            height: 300px;
        }
        &__link {
            position: relative !important;
            width: 100% !important;
        }
    }
    @media (min-width: 671px) and (max-width: 767px) {
        &__container {
            height: auto;
        }

        &__modules {
            margin: 50px
        }
        &__modules--img {
            margin: 40px;
        }
        &__modules__image_text {
            &--centered-bottom {
                bottom: 40px;
                left: 50px;
            }
        }
        &__link {
            position: relative !important;
            width: 100% !important;
        }
    }
    @media (min-width: 768px) and (max-width: 1035px) {
        &__container {
            height: auto;

            .header-background {
                background-color: #4e8be5;
            }
        }
        &__modules {
            margin: 15px auto 45px auto;
        }
        &__header {
            &--photo {
                width: 100%;
            }
        }
        &__question {
            height: 340px;
            width: 100%;
            margin: 25px auto 15px auto;

            &--title {
                margin: 10px;
            }

            &--content {
                margin: 10px;
            }

            &--btnzone {
                text-align: center;
            }
        }
        &__score {
            min-height: 330px;
        }
        &__modules--img {
            display: inline-block;
            margin: 1px;
            padding: 5px;
            width: 80%;
        }
        &__modules__image_text {
            &--centered-bottom {
                bottom: 7px;
                left: 18px;
            }
        }
        &__button {
            padding: 2px 25px;
        }

        &__link {
            position: relative !important;
            width: 100% !important;
            font-size: 10px;
        }
    }
    @media (min-width: 1036px) and (max-width: 1139px) {
        &__container {
            height: auto;
        }
        &__modules {
            margin-bottom: 40px;
        }
        &__score {
            min-height: 330px;
        }
        &__modules--img {
            display: inline-block;
            margin: 1px;
            padding: 5px;
            width: 80%;
        }
        &__modules__image_text {
            &--centered-bottom {
                bottom: 7px;
                left: 18px;
            }
        }
        &__header {
            &--photo {
                width: 100%;
            }
        }
        &__question {
            height: 300px;
            width: 100%;
            margin: 25px auto 15px auto;

            &--title {
                margin: 10px;
            }

            &--content {
                margin: 10px;
            }

            &--btnzone {
                text-align: center;
            }
        }
        &__button {
            padding: 2px 25px;
        }
        &__link {
            width: 100% !important;
            font-size: 10px;
        }
    }
    @media (min-width: 1140px) and (max-width: 1199px) {
        &__question {
            width: 100%;
            margin: 25px auto 15px auto;

            &--title {
                margin: 10px;
            }

            &--content {
                margin: 10px;
            }

            &--btnzone {
                text-align: center;
            }
        }
        &__button {
            padding: 2px 30px;
        }

        &__modules {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 2px;
        }

        &__modules--img {
            display: inline-block;
            margin: 5px;
            width: 75%;
        }

        &__modules__image_text {
            &--centered-bottom {
                bottom: 3px;
            }
        }
        &__responsezone {
            padding: 4px 20px;
            width: 80%;
        }
        &__pbar_outerdiv {
            width: 80%;
        }
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
        &__modules {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 2px;
        }

        &__modules--img {
            display: inline-block;
            margin: 3px;
            width: 75%;
        }
        &__modules__image_text {
            &--centered-bottom {
                bottom: 3px;
                left: 13px;
            }
        }

        &__container {
            height: 450px;

            .header-background {
                background-color: #4e8be5;
            }
        }

        &__header {
            &--photo {
                width: 100%;
            }
        }

        &__question {
            width: 100%;
            margin: 30px auto 15px auto;

            &--title {
                margin: 10px;
            }

            &--content {
                margin: 10px;
            }

            &--btnzone {
                text-align: center;
            }
        }

        &__button {
            padding: 2px 40px;
        }
    }
    @media (min-width: 1400px) and (max-width: 1499px) {
        &__modules {
            display: flex;
            flex-wrap: wrap;
            margin: 5px;
            justify-content: center;
        }

        &__modules--img {
            display: inline-block;
            margin: 8px;
            width: 70%;
        }

        &__modules__image_text {
            &--centered-bottom {
                left: 20px;
            }
        }
        &__question {
            width: 100%;
            margin: 30px auto 15px auto;

            &--title {
                margin: 15px;
            }

            &--content {
                margin: 15px;
            }
        }
        &__container {
            height: 450px;
        }
    }
    @media (min-width: 1500px) {
        &__container {
            height: 430px;
        }
        &__modules {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 10px
        }

        &__modules--img {
            display: inline-block;
            margin: 10px;
            width: 75%;
        }

        &__modules__image_text {
            &--centered-bottom {
                bottom: 13px;
                left: 25px;
            }
        }
        &__question {
            width: 100%;
            margin: 35px auto 20px auto;

            &--title {
                margin: 15px;
            }

            &--content {
                margin: 15px;
            }
        }
    }
    @media (min-width: 1900px) {
        &__container {
            height: 430px;
        }
        &__modules {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 20px
        }
        &__modules--img {
            display: inline-block;
            margin: 15px;
            width: 70%;
        }

        &__modules__image_text {
            &--centered-bottom {
                bottom: 15px;
                left: 25px;
            }
        }
        &__question {
            width: 100%;
            margin: 35px auto 25px auto;

            &--title {
                margin: 15px;
            }

            &--content {
                margin: 15px;
            }
        }
    }
}

