@charset "UTF-8";

.detail-commande {
    padding: 0 7vw;

    &-header {
        display: flex;
        gap: 20px;

        &-bloc1 {
            position: relative;
            padding: 18px 56px 20px 27px;
            display: flex;
            background-color: #568BC9;
            border-radius: 8px;

            &-container {
                display: flex;
                flex-direction: column;
                gap: 17px;
                margin-right: 60px;
            }

            &-contenu {
                i {
                    color: #BEDCEB;
                    width: 25px;
                    text-align: center;
                }

                span {
                    color: #FFFFFF;
                    font-family: BentonSans-Medium, sans-serif;
                    font-size: 14px;
                    margin-left: 5px;
                }
            }

            &-image {
                display: flex;
                align-items: flex-end;

                img {
                    margin: unset !important;
                }
            }

            &-favorite-icon {
                margin: unset;
                position: absolute;
                top: 10px;
                right: 22px;
                display: flex;
                justify-content: center;

                i {
                    width: 29px;
                    height: 29px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #E7EDF5 0 0 no-repeat padding-box;
                    color: #FFFFFF;
                }

                i:hover {
                    cursor: pointer;
                }
            }

            &-favorite-icon.favorite {
                i {
                    background: #E7EDF5 0 0 no-repeat padding-box;
                    color: #CB2F7B;
                }
            }
        }

        &-bloc2 {
            padding: 16px 30px;
            display: flex;
            flex: 2;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            background-color: #FFFFFF;
            color: #568BC9;
            border-radius: 8px;

            &-commande-annulee {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-family: BentonSans-Medium, sans-serif;
                color: #568BC9;
                letter-spacing: 0;
                font-size: 20px;
            }

            .tasks-progress {
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                padding-right: 30px;
            }

            .task {
                width: 1.875em;
                height: 1.875em;
                border-radius: 100%;
            }

            .task.passed {
                border: .4em solid #DEEAB5;
            }

            .task.pending {
                border: .4em solid #F4F4F4;
            }

            .progress {
                width: calc(34% - 2.5em);
                height: .6em;
                padding: .2em 0;
                position: relative;
            }

            .progress > div {
                height: .2em;
                left: 0;
                right: -.4em;
                position: absolute;
            }

            .pending {
                background: #D9D9D9;
            }

            .passed {
                background: #92B90D;
            }

            .progress-passed {
                background: #DEEAB5;
            }

            .progress-pending {
                background: #F4F4F4;
            }

            &-commande-valide {
                &-date {
                    color: #568BC9;
                    font-size: 20px;
                    font-family: BentonSans-Medium, sans-serif;

                    span {
                        font-family: Bentonsans-bold, sans-serif;
                    }
                }

                &-contenu {
                    display: flex;
                    justify-content: space-between;
                    padding-right: 15px;
                    text-align: center;

                    div {
                        color: #2F485C;
                        font-family: bentonsans-regular, sans-serif;
                        font-size: 14px;
                    }
                }
            }

            &-boutons {
                display: flex;
                gap: 25px;
                margin-top: 20px;
                margin-bottom: 20px;

                &-facture {
                    i {
                        margin-right: 10px;
                    }

                    display: flex;
                    background-color: #568BC9;
                    color: #FFFFFF;
                    padding: 12px 44px;
                    box-shadow: 0 3px 6px #8484840D;
                    border-radius: 8px;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-family: BentonSans-Medium, sans-serif;
                }

                &-facture:hover {
                    background-color: #064997;
                }
            }

            &-container {
                display: flex;
                gap: 10px;
                background-color: #ffffff;
                margin-bottom: 20px;

                &-table::-webkit-scrollbar {
                    width: 5px;
                    height: 18px;
                    margin-top: 10px;
                    background-color: #ffffff; // or add it to the track
                }

                // Add a thumb
                &-table::-webkit-scrollbar-thumb {
                    background: #568BC9;
                    width: 10px;
                }

                &-table {
                    width: 80%;
                    padding: 0 15px 0 15px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    height: 100%;

                    #cip13, #quantite, #statut {
                        display: none;
                    }

                    thead {
                        color: #2F485C;
                        letter-spacing: 0;
                        font-family: BentonSans-Medium, sans-serif;
                        font-size: 12px;

                        tr th {
                            vertical-align: middle;
                            border-bottom: unset !important;
                        }
                    }

                    tbody {
                        tr td {
                            border-top: unset !important;
                            color: #2F485C;
                            font-size: 14px;
                            font-family: BentonSans, sans-serif;
                        }
                    }

                    .line-list {
                        background-color: #FAFAFA;
                        border-radius: 10px;
                    }

                    .list-margin {
                        height: 10px;
                    }

                    .designation-and-img {
                        display: flex;

                        img {
                            max-height: 75px;
                            max-width: 100px;
                            margin: 0 5px 0 0;
                            object-fit: cover;
                        }
                    }
                }

                @import "promotionnal-blocks/_my-documents-order-details-promotionnal-blocks";

                &-promotional-block {
                    background-color: #F4F4F4;

                    .contact-block {
                        height: 240px;
                        padding: 0 10px;
                        margin-bottom: 15px;
                    }

                    img {
                        margin: unset;
                    }

                    .pharmacist-tools-block {
                        padding-left: 10px;
                        padding-right: 10px;

                        img {
                            border-radius: 8px;
                            object-fit: cover;
                            width: 320px;
                            height: 240px;
                        }
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .bx-wrapper-commande-slider {
        -webkit-box-shadow: unset !important;
        box-shadow: unset !important;
        border: unset !important;
        background: unset !important;
    }

    .detail-commande-header {
        flex-direction: column;

        &-bloc1 {
            justify-content: space-between;
            padding: 30px 10px 30px 10px;

            &-contenu {
                margin-bottom: 10px;
            }

            &-image {
                display: none;
            }

            &-imagefavori {
                img {
                    right: 10px;
                }
            }
        }

        &-bloc2 {
            .tasks-progress {
                padding-left: 24px;
            }

            &-commande-annulee {
                font-size: 14px;
            }

            &-commande-valide {
                padding-left: 10px;
                padding-bottom: 20px;

                &-date {
                    padding-left: 10px;
                    flex-direction: column;
                }

                &-contenu {
                    div {
                        font-size: 12px;
                        padding-right: unset;
                    }
                }
            }

            &-boutons {
                flex-direction: column;
            }

            &-container {
                flex-direction: column;
                background-color: inherit;

                &-table {
                    margin-bottom: 10px;
                    width: unset;
                    background-color: #ffffff;
                    height: 460px;

                    table {
                        margin-top: 15px;

                        #cip13, #quantite, #statut {
                            display: block;
                            font-weight: bold;
                        }

                        thead {
                            display: none;
                        }

                        tr {
                            display: block;
                            padding-top: 5px;
                            padding-bottom: 5px;
                        }

                        td {
                            display: flex;
                            align-items: center;
                            padding-top: 0 !important;
                        }

                        .order-details-table-body:nth-child(2), .order-details-table-body:nth-child(4), .order-details-table-body:nth-child(6) {
                            padding-bottom: 0;
                        }

                        .order-details-table-body:first-child {
                            margin-top: 10px;
                        }
                    }
                }
            }

            &-promotional-block {
            }
        }
    }
}
