.simulateur-maintenance {
    &--container {
        padding: 15vh 5vh 5vh 5vh;
        margin: 0 7.5vw 2rem;
        border: 2px solid #2f2f97;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 5px 0px 5px 0px grey;
    }

    &--title {
        text-transform: uppercase;
        font-size: 16px;
    }

    &--img-container {
        text-align: center;
    }

    &--img {
        margin: 0 auto;
        box-sizing: border-box;
        width: 100%;
    }

    @media (min-width: 768px) {
        &--img {
            max-width: 400px;
        }
    }
}
