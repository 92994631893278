@charset "UTF-8";

.editorial-content table,
.cke_editable table{
    width:100%;
    margin:0 0 25px 0;
    thead{
        background-color: $grey-blue;
        border:none;
        color: $white;
        text-transform: uppercase;
        font-size:12px;
    }
    th{
        padding:10px 20px;
        &:first-child{
            border-left:1px solid $grey-blue;
        }
        &:last-child{
            border-right:1px solid $grey-blue;
        }
    }
    tbody{
        td{
            padding:13px 20px;
            border-top:1px solid $grey-lighter;
            border-bottom:1px solid $grey-lighter;
            &:first-child{
                font-family:'bentonsans-ultracondensed';
                font-size:18px;
                color: $black;
            }
            &:last-child{
                @include border-top-right-radius(5px);
                @include border-btm-right-radius(5px);
                border-right:1px solid $grey-lighter;
            }
        }
        tr:nth-child(2n){
            background-color: $blue-light;
            td:first-child{
                position: relative;
                border-left:1px solid $grey-lighter;
                &::before{
                    content:"";
                    display: block;
                    width:4px;
                    height:100%;
                    position: absolute;
                    left:-1px;
                    top:-1px;
                }
            }
        }
        tr{
            td:first-child{
                position: relative;
                border-left:1px solid $grey-lighter;
                &::before{
                    content:"";
                    display: block;
                    width:4px;
                    height:100%;
                    position: absolute;
                    left:-1px;
                    top:-1px;
                }
            }
        }
        tr.spacer_row{
            td{
                border: none;
            }
        }
    }
}
