@charset "UTF-8";

.content_leftcol {
    //@include transition-ease-in-out(0.01s);
    width: 200px;
    float: left;
    // max-width: 200px;
}

.content_rightcol {
    width: 69%; //73%
    float: left;
    max-width: 900px;
    padding: 40px 0 40px 40px;
    border-left: 1px solid $grey-lighter;
}

.content_leftcol-menu{
    counter-reset: section;
    list-style-type: none;
    padding: 50px 15px 0 15px;
    li {
        counter-increment: section;
        margin: 0 0 30px 30px;
        position: relative;
        text-transform: uppercase;
        font: normal 12px/1.8em "bentonsans-medium", sans-serif;
        &:before {
            content: counter(section) "_";
            position: absolute;
            left: -25px;
            font-size: 13px;
        }
        a {
            color: $grey-blue;
            display: block;
            transition: color 0.3s ease 0s;
            &:hover,
            &:focus,
            &:active{
                color: transparent;
            }
            &::before{
                content: attr(data-hover);
                left: 0;
                max-width: 0;
                overflow: hidden;
                padding: 10px 0;
                position: absolute;
                top: -10px;
                transition: max-width 0.5s ease 0s;
                white-space: nowrap;

            }
            &:hover::before,
            &:focus::before,
            &:active::before{
                max-width: 100%;

            }
            &.preline{
                &::before {
                    white-space: pre-line;
                }
            }

            > span.hover{
                left: 0;
                max-width: 0;
                overflow: hidden;
                position: absolute;
                color: $black;
                transition: max-width 0.5s ease 0s;
            }
            &:hover,
            &:focus,
            &:active{
                > span.hover {
                    max-width: 100%;
                }
            }
        }
    }
}
