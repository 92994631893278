.block-manage-pharmacy-sub-block-header-title a{
    color:$blue-base;
}

.block-manage-pharmacy-rdp-excluded {
    font-family: bentonsans-regular, sans-serif;
    color: #485E68;
    text-align: center;

    &-title {
        margin: 35px auto;
        font-size: 14px;
    }

    &-content {
        margin: auto 50px;
        font-size: 12px;
    }

    &-icon {
        img {
            margin: 25px auto;
        }
    }
}
