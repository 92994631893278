.block-manage-pharmacy-otc {
    &-line {
        display: flex;
        margin: 15px 0;

        &-icon {
            margin: auto 5px;

            img {
                margin: unset;
                width: 30px;
            }
        }

        &-content {
            margin: 0 5px;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 14px;
                font-family: Bentonsans-bold, sans-serif;
                text-transform: uppercase;

                .is-otc {
                    color: $green-base;
                }

                .is-not-otc {
                    color: #E53838;
                }
            }

            &-text {
                font-size: 12px;
                font-family: bentonsans-regular, sans-serif;
                color: #485E68;
            }
        }
    }

    &-separator {
        width: 100%;
        border: 1px solid #DDE3EB;
    }
}
