@charset "UTF-8";
.newsDetails-content {
  color: #525252;
  p {
    line-height: 25px;
    color: #525252;
  }
  .chapeau, h2, span, h2.generic-surtitle{
    color: #525252;
  }
}

.news_details-img {
  width: 100%;
  padding: 0 0 35% 0;
  margin: 0 0 40px 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;
}

.moreNewsTitle {
  font-size: 19px;
  color: #000;
  font-family: 'bentonsans-medium';
  margin-top: 65px;
}

.moreNewsList {
  border-top: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
  margin-bottom: 50px;
  ul {
    padding: 20px 0;
    margin: 0;
  }
  li {
    display: inline-block;
    vertical-align: top;
    padding: 0 85px 0 35px;
    width: 33%;
    .news_details &:before {
      color: $grey-medium;
    }
    a {
      //color: $green-base2;
    }
  }
}

@media screen and (max-width: 767px) {
  .moreNewsList {
    li {
      width: 100%;
      float: none;
    }
  }
}
