@charset "UTF-8";

@import 'utilities/_variables.scss';

@import "vendors/bootstrap/_bootstrap_custom_variables";
@import "vendors/bootstrap/_bootstrap_custom_config";
@import "vendors/_fontawesome";
@import "vendors/_lity";
@import "vendors/_aos";
@import "vendors/_bxslider";
@import "vendors/_g361-cc";
@import "vendors/_simplebar.scss";
@import 'utilities/_mixin.scss';


@import "fonts/_fonts.scss";
@import "fonts/_icons.scss";
@import "fonts/_simotc-icons.scss";

@import 'elements/_elements.scss';

@import 'layout/_layout.scss';

@import 'minute_pharma/minute_pharma.scss';
@import 'minute_pharma/_minute_pharma_formation_listing.scss';
@import 'minute_pharma/_minute_pharma_formation_detail.scss';

@import 'pages/_pages';

@import 'helpers/_helpers.scss';

@import 'responsive/_responsive.scss';
@import 'layout/_checkBrowser.scss';

@import 'plugins/image2/image2';


