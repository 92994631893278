.btn-darkblue {
    @include transition-ease-in-out();
    @include hover-focus-active {
        color: $white;
    }
    color: $white;
    background: $simotc-dark-blue;
    &::before {
        background-color: $simotc-dark-blue-ligten;
    }
}

.btn-lightblue {
    @include transition-ease-in-out();
    @include hover-focus-active {
        color: $white;
    }
    color: $white;
    background: $simotc-blue;
    &::before {
        background-color: $simotc-blue-darken;
    }
}

.btn-lightgray {
    @include transition-ease-in-out();
    @include hover-focus-active {
        color: $simotc-dark-blue;
    }
    color: $simotc-dark-blue;
    background: $simotc-light-grey;
    &::before {
        background-color: $simotc-light-grey-ligten;
    }
}

.btn-white {
    @include transition-ease-in-out();
    @include hover-focus-active {
        color: $simotc-blue;
    }
    color: $simotc-blue;
    background: $white;
    &::before {
        background-color: $simotc-light-grey-ligten;
    }

    &.selected {
        @extend .btn-darkblue;
    }
}
