@charset "UTF-8";

.register_box-form {
    margin: 35px 0 50px 0;
    position: relative;
    .back_link-pillshaped {
        left: -8%;
        top: -20px;
    }
    form {
        position: relative;
        padding: 25px 35px;
        border: 1px solid $grey-lighter;
        @include border-radius(13px);
        fieldset {
            position: relative;
        }
        fieldset:first-of-type {
            legend {
                position: absolute;
                width: 70px;
                left: 50%;
                top: -35px;
                margin: 0 0 0 -35px;
            }
        }
        legend {
            background-color: $white;
            border: none;
            font: normal 20px/1em bentonsans-ultracondensed, sans-serif;
            text-transform: uppercase;
            color: $pink-base;
            text-align: center;
        }
        legend.register_box-form-legend {
            padding-top: 20px;
            margin-bottom: 12px;
        }
        .contact-submit {
            margin-bottom: 20px;
        }
    }
}

.registration-form-validation {
    .form-group {
        padding: 0 0 0 20px;
    }
    input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
    }
    .validation-conditions {
        font-size: 12px;
        margin: 15px 0;
        a {
            color: $blue-base;
            text-decoration: underline;
            @include hover-focus-active {
                color: $grey-blue;
            }
        }
    }
}

.register_box-form-step_container {
    display: table;
    margin: 15px 0;
}

.register_box-form-step_col {
    display: table-cell;
    vertical-align: middle;
    p {
        margin: 0 0 0 20px;
        font: normal 13px/1.3em bentonsans-medium, sans-serif;
        line-height: 1.4em;
        span {
            display: block;
            color: $grey-blue;
            font-size: 14px;
        }
    }
}

.register_box-form-step {
    display: block;
    width: 28px;
    height: 28px;
    background-color: $blue-base3;
    @include border-radius(50%);
    color: $white;
    text-align: center;
    font: normal 14px/30px bentonsans-medium, sans-serif;
    margin-left: -15px;
    .register_box-form form & {
        margin-left: -50px;
        float: left;
    }
}

/* Media queries */

@media screen and (max-width: 1100px) {
    .register_box-form form {
        padding: 25px 20px;
        .register_box-form-step {
            margin-left: -35px;
        }
    }
}

@media screen and (max-width: 767px) {
    .register_box-form {
        margin-top: 0;
        .back_link-pillshaped {
            top: 6px;
            left: -11%;
        }
        h3.connexion_box-style-title-noic {
            padding: 0;
            .connexion_box-style-title-txt {
                margin: 0 -9.5vw 30px -9.5vw;
            }
        }
    }

}
