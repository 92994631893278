.block-a-ne-pas-manquer {
    border-left: 3px solid #485E68;

    &-header {
        padding: 5px 10px !important;
        justify-content: space-between;
        background: #485E68;

        &-icon-plus {
            margin: auto 0 auto auto;
            font-size: 21px;

            a {
                color: #ffffff;
            }

            a:hover {
                color: #ffffff;
            }
        }
    }

    &-content {
        font-size: 12px;

        span {
            font-size: 28px;
            font-family: 'bentonsans-book';
            font-weight: bold;
            vertical-align: middle;
        }
    }

    /* Slideshow container */
    .slideshow-container {
        position: relative;
    }

    /* Slides */
    .anpmSlides {
        display: none;
        padding: 0;
    }
    .anpmSlides p {
        font-family: "Benton Sans", sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        color: #485E68;
        margin-left: 15px;
    }

    /* Next & previous buttons */
    .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 108%;
        width: auto;
        margin-top: -30px;
        padding: 16px;
        color: #485E68;
        font-weight: bold;
        font-size: 20px;
        border-radius: 0 3px 3px 0;
        user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
        position: absolute;
        right: 0;
        border-radius: 3px 0 0 3px;
    }

    /* The anpmDot/bullet/indicator container */
    .anpmDot-container {
        text-align: center;
    }

    /* The anpmDots/bullets/indicators */
    .anpmDot {
        cursor: pointer;
        height: 10px;
        width: 10px;
        margin: 0 2px;
        background-color: #E6EAEE;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
    }

    /* Add a background color to the active anpmDot/circle */
    .active, .anpmDot:hover {
        background-color: #717171;
    }

    .anpmSlides {
        &-img {
            position: relative;

            img {
                width: 100%;
            }

            span {
                position: absolute;
                bottom: -4%;
                left: 15px;
                background: #4E8BE5;
                padding: 5px;
                font-size: 12px;
                color: white;
            }
        }
    }
}

.home-block-content.block-a-ne-pas-manquer-content {
    color: #485E68;
}

.flex-parent-element {
    display: flex;
}
.flex-child-element {
    flex: 1;
    margin: 10px;
    color: #485E68;
}
.flex-child-element:first-child {
    margin-right: 20px;
}

.block-a-ne-pas-manquer-header {
    .home-block-header-icon {
        font-size: 25px;
        padding: 5px 0 5px 0;
    }
}


.block-manage-a-ne-pas-manquer {
    &-header {
        background: #485E68;
    }

    &-sub-block {
        margin: 10px 0;
        padding: 7px;
        background: #F2F4F6;

        &-header {
            margin-bottom: 10px;
            padding-bottom: 2px;
            border-bottom: 2px solid ;
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            color: #485E68;

            &-title {
                margin: auto 0;
            }

            &-icon-plus {
                font-size: 19px;

                a {
                    color: #485E68;
                }

                a:hover {
                    color: #485E68;
                }
            }
        }
    }

    &-rdp {
        &-text {
            padding: 0 2px;
            font-size: 12px;

            a {
                color: #485E68;
                text-decoration: underline;
            }
        }

        &-header {
            margin: 15px 0;
        }

        &-content {
            border-left: 1px solid #E7EBEE;
            margin: 0 10px;
            padding: 10px;

            &-line {
                margin-bottom: 20px;

                &-header {
                    margin-top: -10px;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                &-content {

                }
            }
        }

        &-icon {
            margin-left: -18px;
            margin-right: 5px;
        }

        .icon-red {
            color: #E65455;
        }

        .icon-green {
            color: $green-base;
        }
    }

    &-contract {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;

        &-number {
            font-size: 35px;
            font-weight: 700;
        }

        &-text {

        }
    }

    &-order {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;

        &-number {
            font-size: 35px;
            font-weight: 700;
        }

        &-text {}

        &-link {
            a {
                color: #485E68;
                text-decoration: underline;
            }
        }
    }

    &-otc {
        &-line {
            display: flex;
            margin: 15px 0;

            &-icon {
                margin: auto 5px;

                img {
                    margin: unset;
                    width: 30px;
                }
            }

            &-content {
                margin: 0 5px;
                display: flex;
                flex-direction: column;

                &-title {
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;

                    .is-otc {
                        color: $green-base;
                    }

                    .is-not-otc {
                        color: #E53838;
                    }
                }

                &-text {
                    font-size: 12px;
                }
            }
        }

        &-separator {
            width: 100%;
            border: 1px solid #DDE3EB;
        }
    }
}

/* Slideshow container */
.slideshow-container {
    position: relative;
}

/* Slides */
.anpmSlides {
    display: none;
    padding: 0;
}
.anpmSlides p {
    font-family: "Benton Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0px;
    color: #485E68;
}

/*
/* Next & previous buttons
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 108%;
    width: auto;
    margin-top: -30px;
    padding: 16px;
    color: #485E68;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

/* Position the "next button" to the right
.next {
    position: absolute;
    right: 0;
    border-radius: 3px 0 0 3px;
}*/

/* The anpmDot/bullet/indicator container */
.anpmDot-container {
    text-align: center;
}

/* The anpmDots/bullets/indicators */
.anpmDot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: #E6EAEE;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.anpmSlides img {
    width: 100%;
}

.anpmSlides span {
    //background: #4E8BE5;
    padding: 5px;
    font-size: 12px;
    color: white;
    position: absolute;
    bottom: 32%;
    left: 2%;
}
