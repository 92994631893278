.contrat-listing-promotional-block {
    background: #0f52ba;
    box-shadow: 0 3px 6px #8484840D;
    text-align: left;
    color: #FFFFFF;
    align-items: center;
    letter-spacing: -0.36px;
    font-size: 18px;
    font-family: 'bentonsans-condensedregular', sans-serif;

    .email-phone {
        font-size: 12px;
    }
}
