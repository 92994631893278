@charset "UTF-8";

.engagements-content {
    .chapeau {
        font-style: normal;
    }

    .content_leftcol-menu {
        li {
            a {
                > span.hover {
                    color: $pink-base;
                }
            }
        }
    }
}

@-webkit-keyframes rotateIn {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@-moz-keyframes rotateIn {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@-o-keyframes rotateIn {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes rotateIn {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.ec-list_container {
    border-left: 2px solid $grey-lighter;
    margin: 60px 0 0 10px;

    #couverture {
        padding-top: 0;
    }
}

.ec-list_item-leftcol {
    float: left;
    width: 70%;
    padding: 0 70px 0 0;
}

.ec-list_item-rightcol {
    position: relative;
    float: left;
    width: 30%;
    padding: 10px 50px 20px 20px;
    &:before {
        @include border-radius(2px);
        content: "";
        position: absolute;
        left: 0;
        top: 13px;
        width: 4px;
        height: 100%;
        background-color: $sep-bg;
        border: 1px solid $sep-border;
    }
    &:after {
        @include bgimg('engagement-leftarrow.png');
        content: "";
        position: absolute;
        left: -18px;
        top: 50%;
        margin: -9px 0 0 0;
        width: 19px;
        height: 19px;
    }
}

.ec-list_item {
    padding: 25px 0 25px 40px;
    margin: 0 0 40px 0;
    #servicesutiles + &, #disponibilite + & {
        background-color: lighten($blue-base3, 50%);
    }
}

.ec-list_item-content {
    line-height: 1.8em;
    color: $grey-blue;
}

.ec-list_item-ic {
    @include border-radius(50%);
    background-color: $blue-base3;
    width: 63px;
    height: 63px;
    line-height: 63px;
    font-size: 40px;
    text-align: center;
    color: $white;
    .ec-list_item2 & {
        font-size: 52px;
    }
}

.ec-list_item-number {
    margin: 30px 0 15px 0;
    color: $blue-base3;
    font: normal 33px/1em 'bentonsans-regular', sans-serif;
    white-space: nowrap;
    sup {
        font-size: 11px;
        position: relative;
        top: -20px;
    }
}

.ec-list_item-legend {
    font-size: 13px;
    margin-top: -50px;
    padding-top: 50px;
}

.ec-notation {
    background-color: $blue-base2;
    padding: 30px 85px;
    text-align: center;
    margin: 50px 0;
}

.ec-notation-nb {
    margin: 0 0 15px 0;
    font: normal 42px/1em 'bentonsans-regular', sans-serif;
    color: $white;
    sup {
        font-size: 12px;
        position: relative;
        top: -25px;
    }
}

.ec-notation-txt {
    color: lighten($blue-base2, 25%);
    font-size: 13px;
}

.ec-list-legend {
    counter-reset: legends;
    border-top: 1px solid $grey-lighter;
    list-style-type: none;
    padding: 40px 0 0 50px;
    margin: 0 0 0 -40px;
    font-size: 11px;
    li {
        counter-increment: legends;
        position: relative;
        &:before {
            position: absolute;
            left: -15px;
            content: "(" counter(legends) ")";
            margin: 0 20px 0 -20px;
        }
    }
}

.svg-anim svg {

    margin: 10px 0 0;
    padding-right: 1px;
    padding-top: 5px;
    transform: scale(1.4);
}

.svg-anim path {
    stroke: #ffffff;
    fill: none;
    stroke-width: 0.019em;
    /* fill: white;
     stroke: transparent;*/
}

.svg-anim #svgPill, .svg-anim #svgCup {
    stroke-width: 0.03em;
}

.svg-anim #svgClock {
    margin: 0;
    padding-top: 0;
    transform: scale(1.5);
}

/*
.svg-anim #svgStar path {
    fill: #fff;
}
*/
.svg-anim #svgSilhouette {
    margin-top: 16px;
    padding-top: 0;
    transform: scale(1.7);
}

.svg-anim #svgSilhouette path {
    /* fill: white;
     stroke: transparent;*/
}

/* ajout slider */

.engagement_slider {
    float: right;
    margin: 15px 5% 0 0;
    position: relative;
    transition: all 0.4s ease 0s;
    width: 26%;
    .bx-wrapper {
        margin:0;
        padding: 20px 0 20px 35px;
        min-height:190px;
        border-left: 2px solid $pink-base;
        background-color: $white;
        img {
            margin: 0 auto;
            height: auto;
        }
        .bx-controls {
            left: 0;
            position: absolute;
            top: 15px;
            width: 52px;
        }
        .bx-controls-direction {
            height: 90px;
            margin: 50px 0 0 -16px;
            position: relative;
            width: 34px;
            a.bx-next, a.bx-prev {
                display: block;
                left: 0;
                position: absolute;
                background-color: $pink-base;
            }
            a.bx-next {
                border-top: 1px solid lighten($pink-base, 30%);
                top: 62px;
            }
            a.bx-prev {
                top: 20px;
            }
        }
    }
    .ic-ul-arrow {
        width: 49px;
        height: 63px;
        cursor: pointer;
        position: absolute;
        left: -20px;
        background-color: $pink-base;
        color: $white;
        text-align: center;
        line-height: 63px;
        font-size: 23px;
        &#engagement_slider-prev {
            top: 108px;
            &:before {
                transform: rotate(180deg);
                display: inline-block;
            }
        }
        &#engagement_slider-next {
            top: 44px;
        }
        a {
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

/*-- AOS ajout durée delai (limité à 3000 ---*/
// Generate Duration && Delay
[data-aos] {
    @for $i from 60 through 200 {
        body[data-aos-duration='#{$i * 50}'] &,
        &[data-aos][data-aos-duration='#{$i * 50}'] {
            transition-duration: #{$i * 50}ms;
        }

        body[data-aos-delay='#{$i * 50}'] &,
        &[data-aos][data-aos-delay='#{$i * 50}'] {
            transition-delay: 0;

            &.aos-animate {
                transition-delay: #{$i * 50}ms;
            }
        }
    }
}

@media screen and (min-width: 767px) {
    // Gestion menu
    .absolutemenu {
        position: fixed;
        top: 0;
        & + .engagements-content-rightcol {
            position: relative;
            left: 200px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .ec-list_item-leftcol {
        width: 65%;
        padding: 0 25px 0 0;
    }
    .ec-list_item-rightcol {
        width: 35%;
        padding: 0 10px 0 20px;
    }
    .engagement_slider {
        width: 35%;
    }
    .ec-list_item-number {
        font-size: 22px;
        sup {
            top: -10px;
        }
    }
    .ec-list_item {
        h3 {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width: 950px) {
    .engagement_slider {
        width: 50%;
    }
}

@media screen and (max-width: 767px) {

    .engagements-content-leftcol {
        display: none;
    }
    .engagements-content-rightcol {
        border: none;
        padding: 40px 20px 0 18px;
        width: 100%;
    }

    .engagement_slider {
        /*width: 100%;
        float: none;*/
    }
    .ec-list_container {
        margin: 0 0 0 30px;
    }
    .ec-list_item {
        h3 {
            margin-bottom: 10px;
        }
    }
    .ec-list-legend {
        margin: 0;
        padding: 30px 0 30px 35px;
    }

    .engagements-content-rightcol .ec-list_item .ec-list_item-titre-nb {
        width: 55px;
        height: 55px;
        &::before {
            width: 55px;
            height: 55px;
            background-size: 55px auto;
        }
    }
    .svg-anim {

        #svgPill {
            margin-top: 7px;
        }
        #svgClock, #svgSilhouette {
            margin: 11px 0 0 0;
            transform: scale(1.4);
        }
        #svgCup {
            margin-top: 8px;
        }
        #svgStar {
            margin-top: 6px;
        }
    }
}

@media screen and (max-width: 550px) {
    .ec-list_item {
        margin: 0;
        h3 {
            font-size: 20px;
        }
    }
    .ec-list_item-ic {
        font-size: 30px;
        width: 53px;
        height: 53px;
        line-height: 53px;
        .ec-list_item2 & {
            font-size: 35px;
        }
    }
    .ec-list_item-leftcol {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }
    .engagement_slider {
        width: 100%;
        padding-left:23px;
    }
    #engagement_slider-prev,
    #engagement_slider-next{
        left:0;
    }
    .ec-list_item-rightcol {
        width: 100%;
        float: none;
        display: table;
        &:before {
            height: 100%;
            top: 0;
        }
        &:after {
            display: none;
        }
        .ec-list_item-mobilleftcol {
            display: table-cell;
            vertical-align: top;
            width: 53px;
        }
        .ec-list_item-mobilrightcol {
            display: table-cell;
            padding: 0 0 0 15px;
            .ec-list_item-number {
                margin: 0 0 5px 0;
            }
        }
    }
    .ec-notation {
        padding: 30px;
    }
}
