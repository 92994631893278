@charset "UTF-8";

.block-col {
  width: 177px;
  padding: 0 0 0 10px;
  box-sizing: content-box;
}

.bloc-pmp-contrat-col {
  display: block;
  float: left;
  vertical-align: top;
  &:first-child {
    width: 50px;
  }
  + .bloc-pmp-contrat-col {
    width: 123px;
  }
  p {
    margin: 0;
  }
  .main-content .h1-header-pmp & [class^="ic-"] {
    font-size: 38px;
    width: auto;
    margin: 24px 0 0 0;
    text-align: center;
  }
}

.bloc-pmp-contrat {
  @include border-radius(5px);
  width: 100%;
  position: relative;
  border-style: solid;
  border-width: 1px;
  height: 91px;
  &:first-child {
    margin-bottom: 4px;
  }
  .hc-block-delegue-name {
    color: #ffffff;
    font-size: 12px;
  }
  .hc-block-title {
    font-size: 19px;
    font-weight: bold;
    line-height: 1.2em;
    padding: 13px 0 0px 0;
    text-align: left;
    span {
      color: $white;
    }
  }
  .hc-block-delegue-tel {
    line-height: 0.4em;
    padding-bottom: 10px;
    a {
      //color: #aee4f0;
      font-size: 13px;
    }
    span.small {
      font-size: 9px;
      color: $white;
      display: block;
      margin-top: 3px;
    }
  }
}

.bloc-pmp-mobile {
  display: none;
}

.page h1 [class^="ic-"].bloc-pmp-close {
  @include transition-linear(0.3s);
  position: absolute;
  right: 10px;
  top: 16px;
  width: auto;
  font-size: 29px;
  opacity: 0;
}

///*************************************
//* Media queries
//*************************************/

@media screen and (max-width: 767px) {
  .main-content {
    .h1-header-pmp {
      .h1-soustitre {
        display: none;
      }
      .bloc-pmp-contrat-col {
        display: inline-block;
        float: none;
        [class^="ic-"] {
          font-size: 42px;
          margin: 0 0 0 0;
        }
        .hc-block-delegue-name,
        .hc-block-delegue-tel {
          display: none;
        }
        .hc-block-title {
          margin: 0 0 0 -8px;
          display: block;
        }
        + .bloc-pmp-contrat-col {
          width: 90px;
        }
      }

      h1 {
        position: relative;
        padding-bottom: 95px;
      }
      .block-col {
        display: block;
        width: 100%;
        margin: 0 -10px;
        position: absolute;
        left: 0;
        bottom: 0;
        border-top-style: solid;
        border-top-width: 1px;
        .bloc-pmp-contrat {
          @include transition-ease-in-out(0.3s);
          cursor: pointer;
          z-index: 3;
          -webkit-border-radius: 0;
          border-radius: 0;
          -moz-border-radius: 0;
          -o-border-radius: 0;
          -ms-border-radius: 0;
          -webkit-background-clip: padding-box;
          height: 100%;
          width: 50%;
          float: left;
          margin: 0;
          padding: 10px 0;
          text-align: center;
          &:first-child {
            border: none;
            border-right-style: solid;
            border-right-width: 1px;
          }
          &:last-child {
            border: none;
            border-top-style: solid;
            border-top-width: 1px;
          }
          .bloc-pmp-contrat-col:last-child {
            text-align: left;
            width: auto;
          }
          &.bloc-pmp-contrat-right {
            left: 0;
            border: none;
            &.masked {
              left: 50%;
              //z-index:1;
            }
          }
          &.bloc-pmp-contrat-left {
            left: 0;
            &.masked {
              left: -50%;
            }
          }
          .bloc-pmp-close.active {
            display: block;
            opacity: 1;
          }
        }
        .bloc-pmp-mobile {
          display: block;
          @include transition-linear(0.3s);
          position: absolute;
          z-index: 2;
          top: 0;
          height: 100%;
          white-space: nowrap;
          width: 50%;
          &.bloc-pmp-mobile-left {
            left: 4px;
            &.active {
              left: calc(50% + 5px);
            }
            &.masked {
              left: -50%;
            }
          }
          &.bloc-pmp-mobile-right {
            right: -5px;
            .hc-block-delegue-tel {
              text-align: left;
              margin: 23px 0 0 32px;
              a {
                font-size: 18px;
                font-family: 'bentonsans-medium', sans-serif;
              }
            }
            &.active {
              right: calc(50% - 5px);
            }
            &.masked {
              right: -50%;
            }
          }
          p, a {
            font-size: 13px;
            line-height: 20px;
            text-align: left;
            color: $white;
          }
          p {
            margin: 0 0 0 15px;
            &:first-child {
              margin-top: 12px;
              font-size:12px;
            }
          }

        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .main-content .h1-header-pmp .block-col .bloc-pmp-contrat {
    text-align: left;
  }
}
