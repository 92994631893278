@charset "UTF-8";
body.homepage header {
    width: calc(100vw - 7px);
}

header {
    background-color: $white;
    padding-left: calc(#{$menuWidth} + 17px);
    margin: 0 0 20px 0;

    .page & {
        margin: 0px;
    }
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 10px 25px 5px 5px;

    &-item-right {
        display: flex;
    }
}

.hdr-usr_box-desktop {
    margin-right: 15px;

    [class^="ic-"], [class*=" ic-"] {
        color: $blue-base3;
        font-size: 30px;
    }

}

.hdr-menu_toggle,
.hdr-profile_toggle {
    display: none;
    cursor: pointer;
}

header .icon-bell {
    background: none transparent;
    transition: all 0.6s ease;
    margin-right: 15px;

    > span {
        font-size: 16px;
        transition: all 0.2s ease;
        font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;

    }

    &:hover, &:active, &:focus {
        transform: rotate(-15deg);

        > span {
            transform: scale(1.2) rotate(15deg);
        }
    }
}


.hdr-logo {
    width: 293px;
    height: 26px;
    float: left;
    margin: 12px 0 0 0;
    @include bgimg('hdr-logo.png');
}

.hdr-user_box {
    float: right;
    list-style-type: none;
    margin: 0;

    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 20px 0 0;
    }

    .ic-logout {
        color: $grey-lighter;
        font-size: 30px;
        line-height: 50px;
        @include hover-focus-active {
            color: $grey-medium;
        }
    }

    .logout-mobile-txt {
        display: none;
    }

    .hdr-usr_box-logout-mobile-header {
        display: none;
    }
}

.hdr-usr_box-hello {
    color: $grey-light;
    position: relative;

    span {
        color: $grey-blue;
    }
}

.hdr-usr_box-profile {
    a {
        color: $grey-blue;
        text-decoration: underline;
    }
}

.hdr-user_box-login {
    line-height: 50px;
}

.hdr-logo {
    float: none;
}

.hdr-user_box {
    float: none;
}

.hdr-profile_toggle {
    display: inline-block;
    vertical-align: middle;
    font-size: 40px;
    color: $black;
}

/* User box */
.hdr-user_box {
    @include transition-ease-in-out();
    position: fixed;
    top: 0;
    right: -50vw;
    z-index: 1;
    width: 50vw;
    background-color: $blue-base3;
    float: right;
    list-style-type: none;
    margin: 0;
    padding: 0 0 45px 0;
    max-width: 250px;

    li {
        display: block;
        margin: 0 20px 0 0;
    }

    .ic-logout {
        color: $grey-lighter;
        font-size: 30px;
        line-height: 50px;
        @include hover-focus-active {
            color: $grey-medium;
        }
    }

    .logout-mobile-txt {
        display: block;
    }

    .ic-logout {
        display: none;
    }

    .hdr-usr_box-logout-mobile-header {
        display: table;
        table-layout: fixed;
        width: 100%;

        div {
            display: table-cell;
            vertical-align: middle;
        }

        .ic-userlog {
            background-color: $grey-blue;
            width: 40px;
            height: 60px;
            font-size: 38px;
            color: $blue-base3;
        }

        .logout-mobile-header-txt {
            padding: 0 0 0 15px;
            font: normal 25px/28px bentonsans-ultracondensed, sans-serif;
            text-transform: uppercase;
            color: $white;
            text-align: left;
        }

        .ic-cross {
            color: $white;
            font-size: 25px;
            width: 50px;
            cursor: pointer;
        }
    }

    .hdr-usr_box-hello,
    .hdr-usr_box-profile,
    .hdr-usr_box-select {
        padding: 15px 15px 0 15px;
        color: $white;
        text-align: left;
        font-size: 16px;
    }

    .hdr-usr_box-hello span,
    .hdr-usr_box-profile a {
        color: $white;
    }

    .hdr-usr_box-logout {
        margin: 0;

        a {
            display: block;
            background-color: $grey-blue;
            color: $white;
        }
    }
}

.hdr-user_box.hdr-user_box-mobile-open {
    right: 0;
    z-index: 100;
}

@media screen and (max-width: 767px) {
    header {
        text-align: center;
        padding: 10px 20px;
        margin: 0 -10px 10px -10px;
        height: auto;
    }

    .hdr-logo {
        display: inline-block;
        vertical-align: bottom;
        @include bgimg('mobile/hdr-logo-mobile.png');
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
        width: 150px;
        height: 34px;
        float: left;
        margin-left: 7.5%;
    }
    .hdr-menu_toggle {
        display: inline-block;
        vertical-align: middle;
        font-size: 40px;
        float: left;
        color: $black;
    }

    .header-container {
        margin: 0px;
    }

    .hdr-usr_box-desktop {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .hdr-usr_box-moible {
        display: none !important;
    }
}

@media screen and (max-width: 400px) {
    .hdr-user_box {
        width: 70vw;
        right: -70vw;
    }
}

@media screen and (max-width: 767px) {
    body.homepage header {
        width: calc(100% + 20px);
    }
}

.header-breadcrumb {
    padding: 25px 7vw;
    font-size: 14px;
    font-family: bentonsans-regular, sans-serif;
    color: #2F485C;

    &-link {
        color: #2F485C;
    }

    &-sep {
        margin: auto 10px;
        width: 6px;
        height: 10px;
    }

    &-current {
        font-family: Bentonsans-bold, sans-serif;
        color: #000000;
    }
}
