@charset "UTF-8";

.prod-content {
    padding: 0 0 30px 5vw;
    width: 80vw;
    max-width: 1200px;
}

.prod-content-uprow, .prod-content-bottomrow {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.prod-slider,
.prod-tfr-etc {
    display: table-cell;
    width: 35%;
}

.prod-descriptif,
.prod-tarif {
    display: table-cell;
    width: 65%;
    vertical-align: top;
}

.prod-slider {
    border-left: 1px solid $grey-lighter;
    border-right: 1px solid $grey-lighter;
    border-bottom: 1px solid $grey-lighter;
    max-height: 360px;
}

.prod-descriptif {
    border-bottom: 1px solid $grey-lighter;
}

.prod-tfr-etc {
    border-left: 1px solid $grey-lighter;
    border-right: 1px solid $grey-lighter;
    border-bottom: 1px solid $grey-lighter;
}

.prod-tarif {
    border-right: 1px solid $grey-lighter;
    border-bottom: 1px solid $grey-lighter;
    position: relative;
}

.productlist-container {
    display: table;
    width: 100%;
    margin: 20px 0 0 0;
}

/********* Slider */

#prod-slider-nav {
    position: absolute;
    bottom: 0;
    padding: 0.5%;
    text-align: center;

    a {
        position: relative;
        display: inline-block;
        width: 32%;
        border: 1px solid $grey-lighter;

        &.active::before {
            content: "";
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba($black, 0.2);
        }
    }
}

.prod-slider {
    position: relative;

    &:not(.prod-no-image) {
        padding-bottom: 130px;

        &.otc {
            padding-bottom: 0px;
        }
    }

    .bx-wrapper {
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        margin: 0;
        background: transparent;
    }

    img {
        width: 100%;
        margin: 0;
        display: none;
    }

    .bx-controls {
        display: none;
    }

    .bx-wrapper {
        .bx-controls-direction a {
            width: 24px;
            height: 49px;
            -webkit-background-size: 24px;
            background-size: 24px;
            @include hover-focus-active {
                background-position: 0 0;
            }
        }

        .bx-prev {
            @include bgimg('sliderProduit/sliderProduit_prev.png');
        }

        .bx-next {
            @include bgimg('sliderProduit/sliderProduit_next.png');
        }
    }

    &.prod-no-image {
        vertical-align: middle;

        img {
            display: block;
        }
    }

    .prod-slider-wrapper {
        position: relative;

        .prod-slider-bg-otc {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 50%;
            background-color: $otc-tram-default;
            z-index: -1;
        }
    }
}

/********* Medicament descriptif header */
.prod-descriptif-header {
    padding: 25px 15px;

    .product-tag {
        position: static;
        display: inline-block;
        margin: 0 0 10px 0;
    }
}

.prod-soustitre {
    color: lighten($grey-medium, 10%);
    font-size: 17px;
}

.prod-descriptif-header {
    border-bottom: 1px solid $grey-lighter;
}

.prod-descriptif-header-leftcol {
    float: left;
    width: 73%;
    padding: 0 2% 0 0;
}

.prod-descriptif-header-printic {
    display: inline-block;
    vertical-align: middle;
    width: 44px;
    height: 44px;
    margin: 0 0 0 15px;
    background: url('../img/print_ic.png') center top no-repeat;
    -webkit-background-size: 44px 44px;
    background-size: 44px 88px;
    transition: background-color 0.4s ease;
    border-radius: 30px;

    &:hover, &:active, &:focus {
        background-color: $blue-base3;
        background-position: bottom center;
    }
}

.prod-descriptif-header-rightcol {
    position: relative;
    float: left;
    width: 27%;
    text-align: right;
}

.prod-cartouche {
    @include border-radius(15px);
    position: relative;
    clear: both;
    border: 1px solid $purple-base;
    padding: 7px 10px 7px 10px;
    color: $purple-base;
    font-size: 12px;
    font-family: "bentonsans-medium", sans-serif;
    display: inline-block;
    float: left;

    span {
        color: lighten($purple-base, 30%);
    }
}

/********* Medicament descriptif colonne gauche */
.prod-descriptif-table {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
}

/* Only for imi template */
.prod-descriptif-imi {
    border-bottom: 1px solid $grey-lighter;
    position: relative;
    display: table-cell;
    width: 65%;
    vertical-align: top;
}

.prod-descriptif-table-imi {
    position: absolute;
    bottom: 0;
    display: table;
    width: 100%;
    table-layout: fixed;
}

/* Only for gel hydro template */
.prod-descriptif-gel-hydro {
    border-bottom: 1px solid $grey-lighter;
    position: relative;
    display: table-cell;
    width: 65%;
    vertical-align: top;
}

.prod-descriptif-table-gel-hydro {
    position: absolute;
    bottom: 0;
    display: table;
    width: 100%;
    table-layout: fixed;
}

.prod-descriptif-leftcol {
    display: table-cell;
    padding: 25px 15px;
    width: 50%;
}

.prod-descriptif-rightcol {
    display: table-cell;
    vertical-align: top;
    padding: 25px 15px;
    width: 50%;
    border-left: 1px solid $grey-lighter;
}

.prod-ic-container {
    margin: 0 0 19px 0;
}

.prod-ic-container-leftcol {
    display: inline-block;
    vertical-align: top;

    img {
        width: 21px;
        margin: 0;
    }

    .dmdv-icon {
        width: 45px;
    }

    .fat-ic & img {
        width: 25px;
        margin: 0 0 0 -3px;
    }
}

.prod-ic-container-rightcol {
    display: inline-block;
    margin: 0 0 0 5px;
    text-transform: uppercase;
    font-size: 11px;
    font-family: 'bentonsans-medium', sans-serif;
    color: $grey-blue;
    letter-spacing: 1px;

    span {
        display: block;
        letter-spacing: 0;
        text-transform: none;
        color: lighten($grey-medium, 10%);
        font-family: 'bentonsans-regular', sans-serif;
        font-size: 14px;

    }

}

.composition-lib {
    font-size: 30px;
}

.prod-ic-container-rightcol.lib-dosage {
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: none;
}

.lib-saphenol {
    margin: 0 auto;
    width: 60%;
    background-color: #f9f9f9;
    padding: 5px;
}

.prod-ic-container-rightcol.pca-composition {
    font-weight: bold;
    padding: 4px;
}

.pca-composition.libelle-composition {
    float: left;
    width: 50%;
}

.pca-composition.dosage-composition {
    position: relative;
    float: left;
    width: 30%;
    text-align: right;
    text-transform: none;
}

.pca-composition.pointille {
    position: relative;
    float: left;
    width: 10%;
    text-align: right;
}

.prod-ic-container-rightcol.tab-compo {
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        margin-top: 5px;
    }

    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
}

.prod-descriptif-header.noerka {
    padding: 10px 15px;
}

.prod-descriptif-ic-bottom {
    list-style-type: none;
    //display: table;
    width: 100%;
    margin: 8px 0 0 0;

    li {
        display: inline-block;
        padding: 0 5px;
        text-align: center;
        font-size: 11px;
        color: lighten($grey-medium, 10%);

        img {
            margin: 0;
            display: inline-block;
            margin: 0 0 8px 0;
            height: 42px;
        }
    }
}

/********* Medicament icones TFR etc (sous le slider) */
.prod-tfr-etc {
    list-style-type: none;
    padding: 20px 0 15px 0;
    text-align: center;
    margin: 0;

    li {
        display: inline-block;
        padding: 0 3%;
        margin: 0;

        img {
            width: 63px;
            margin: 0;
        }
    }
}

/********* Produit OTC : Liste des miniature */
.prod-tfr-etc-otc {
    border-left: 1px solid $grey-lighter;
    border-right: 1px solid $grey-lighter;
    border-bottom: 1px solid $grey-lighter;

    #prod-slider-nav {
        position: relative;
        text-align: left;
        min-height: 113px;
        display: flex;

        a {
            width: 33%;
            display: block;
            margin: 1px;
            border: 1px solid $grey-lighter;

            img {
                margin: 0;
                max-width: 100%;
                max-height: 100px;
                padding: 10px 0;
                margin: auto;
            }

            &.no-img {
                height: 106px;
            }
        }
    }
}

/********* Tarif */
.prod-tarif-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.prod-tarif-price {
    width: 50%;
    height: 100%;
    min-height: 113px;
    float: left;
    background-color: $purple-base;
    padding: 30px;
    color: $white;
    font-size: 36px;
    line-height: 25px;

    span {
        display: block;
        font-size: 14px;
        line-height: 14px;
        margin: 0 0 4px 0;
        color: lighten($purple-base, 20%);
    }

    &.otc {
        background-color: $otc-primary-default;

        > span {
            color: $otc-tram-default;
        }

        > div {
            color: $white;
        }
    }
}

/* Only for gel hydro template */
.prod-tarif-price-gel-hydro {
    width: 50%;
    height: 100%;
    min-height: 113px;
    float: left;
    background-color: $purple-base;
    padding: 30px;
    color: $white;
    font-size: 36px;
    line-height: 25px;

    span {
        display: block;
        font-size: 14px;
        line-height: 14px;
        margin: 0 0 4px 0;
        color: lighten($purple-base, 20%);
    }
}

.prod-tarif-details {
    width: 50%;
    height: 100%;
    min-height: 113px;
    float: left;
    padding: 20px 30px;
    text-align: center;
    background-color: lighten($purple-base, 40%);
    color: $grey-blue;
    font-size: 13px;
    display: table;

    &.otc {
        background-color: $otc-tram-default;
        color: $white;
    }
}

.prod-tarif-details-leftcol {
    width: 50%;
    //float: left;
    display: table-cell;
    vertical-align: middle;
}

.prod-tarif-details-rightcol {
    width: 50%;
    border-left: 1px solid lighten($purple-base, 30%);

    //float: left;
    display: table-cell;
    vertical-align: middle;

    span {
        display: block;
        font-size: 10px;
        color: lighten($grey-blue, 20%);
    }

    &.otc {
        border-left: 1px solid $otc-primary-default;

        span {
            color: $white;
        }
    }
}

.prod-tarif-details-title {
    margin: 0 0 5px 0;
    color: $purple-base;
    font-size: 16px;
    font-family: "bentonsans-medium", sans-serif;

    &.otc {
        color: $otc-primary-default;
    }
}

.prod-ic-container-rightcol {
    position: relative;
    width: 80%;
}

#prod-remboursement-graph {
    width: 55px;
    height: 55px;
}

#prod-remboursement-graph-legend {
    position: absolute;
    left: .5px;
    top: 40px;
    color: #9f999d;
    width: 55px;
    text-align: center;
}

/******* */
.prod-content {
    .productlist-container-header {
        padding: 10px;
        text-transform: uppercase;
        font-family: 'bentonsans-ultracondensed', sans-serif;
        font-size: 30px;
        line-height: 35px;

        span {
            display: block;
            color: $purple-base;
        }
    }
}

div.breadcrumb-with-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


div.breadcrumb-right {
    display: flex;
    justify-self: flex-end;
    align-items: center;
}

div.breadcrumb-right img {
    margin: 0 5px 0 5px;
}

///*************************************
//* Media queries
//*************************************/
@media screen and (max-width: 1300px) {
    .prod-content {
        padding: 0 2vw 30px 2vw;
        width: 87vw;
    }
}

@media screen and (max-width: 1210px) and (min-width: 980px) {

    .prod-slider {
        &:not(.prod-no-image) {
            padding-top: 50px;
        }
    }

    .prod-tarif-details {
        padding: 20px 0;
    }
    .lib-saphenol {
        width: 65%;
    }
}

@media screen and (max-width: 980px) {
    .prod-slider {
        &:not(.prod-no-image) {
            max-height: unset;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .prod-content-uprow,
    .prod-content-bottomrow {
        display: block;
    }
    .prod-slider, .prod-tfr-etc,
    .prod-descriptif, .prod-tarif {
        display: block;
        width: 100%;
    }
    #prod-slider-nav {
        display: none;
    }
    .prod-tarif-container {
        display: table;
        position: static;
        width: 100%;
    }
    .prod-tarif-price,
    .prod-tarif-details {
        display: table-cell;
        float: none;
        vertical-align: top;
    }
    .prod-tarif-price {
        width: 33%;
    }
    .prod-tarif-details {
        width: 66%;
    }

    .prod-tarif-details-leftcol, .prod-tarif-details-rightcol {
        float: none;
        width: 100%;
        border: none;
        text-align: left;

        &.otc {
            border-left: 0;
        }
    }

    .prod-slider {
        .bx-controls {
            display: block;
        }

        .bx-wrapper {
            padding: 0 45px;
        }
    }

    .prod-content {
        padding: 0 2vw;
        width: 100%;

        .contratlist-container {
            background-color: $grey-catabg;
            width: auto;
            margin: 0 -2vw;
            padding: 0 2vw;
        }
    }
}

@media screen and (max-width: 767px) {
    .prod-content {
        width: 100%;
        padding: 0 20px;

        .contratlist-container {
            margin: 0 -20px;
            padding: 0 20px;
        }
    }
    .prod-slider {
        border: none;
        padding-bottom: 0;
        max-height: none;
    }
    .prod-descriptif-header-leftcol, .prod-descriptif-header-rightcol {
        float: none;
        width: 100%;
    }
    .prod-descriptif-header-rightcol {
        text-align: left;
        margin: 0 0 15px 0;
    }
    .prod-soustitre {
        margin: 0 0 15px 0;
    }
    .prod-descriptif-table,
    .prod-descriptif-leftcol,
    .prod-descriptif-rightcol {
        display: block;
        width: 100%;
        float: none;
        border: none;
    }
    .prod-descriptif-leftcol {
        border-bottom: 1px solid $grey-lighter;
    }
    .prod-tfr-etc {
        border-left: none;
        border-right: none;
    }
    .prod-tfr-etc-otc {
        display: none;
    }
}

@media screen and (max-width: 600px) {

    .prod-tarif-price {
        font-size: 28px;
        vertical-align: middle;
        padding: 20px;
    }
    .prod-tarif-details {
        padding: 20px;
    }
    .prod-tarif-details-leftcol,
    .prod-tarif-details-rightcol {
        float: none;
        width: 100%;
        border: none;
        text-align: left;
    }
    .prod-tarif-details-leftcol {
        margin: 0 0 10px 0;
        padding-right: 15px;
    }
    .prod-tarif-details-rightcol {

        span {
            display: inline;
            color: $grey-blue;
            font-size: 13px;
        }
    }
    .prod-content {
        .contratlist-container {
            background-color: $grey-catabg;
        }
    }
    .prod-tarif-price, .prod-tarif-details {
        width: 50%;
    }
    .prod-tarif-details-leftcol, .prod-tarif-details-rightcol {
        float: left;
    }

    .lib-saphenol {
        width: 45%;
    }
}
