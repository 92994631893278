@charset "UTF-8";

.back_link-pillshaped {
  display: table;
  height: 33px;
  position: absolute;
  color: $grey-medium;
  @include hover-focus-active {
    .ic-leftarrow {
      background-color: $grey-blue;
    }
    .backlink {
      background-color: $grey-lighter;
    }
  }
  div {
    display: table-cell;
    vertical-align: middle;
  }
  .ic-leftarrow {
    @include transition-ease-in-out();
    @include border-top-left-radius(15px);
    @include border-btm-left-radius(15px);
    width: 30px;
    text-align: center;
    background-color: $grey-light;
    color: $grey-really-lighter;
    font-size: 20px;
  }
  .backlink {
    @include transition-ease-in-out();
    @include border-top-right-radius(15px);
    @include border-btm-right-radius(15px);
    background-color: $grey-really-lighter;
    padding: 0 15px;
    font-size: 11px;
    text-align: center;
  }
}

// FOUT

.wf-loading {
  .main_menu,
  .hc-block-title {
    @include opacity(0);
  }
}

.wf-active {
  .main_menu,
  .hc-block-title {
    @include transition-ease-in-out(0.5s);
    @include opacity(1);
  }
}

img {
  margin: 0 0 35px 0;
  display: block;
}

.block-mise_en_avant {
  border: 0;
  padding: 40px;
  margin: 0 0 30px 0;
  font-size: 13px;
  line-height: 2em;
}

blockquote {
  position: relative;
  width: 70%;
  margin: 0 0 30px -10%;
  padding: 40px 0;
  text-align: center;
  font-family: 'bentonsans-condensedregular', sans-serif;
  font-size: 27px;
  line-height: 1.2em;
  border-left: 0;
  border-top: 1px solid $grey-lighter;
  border-bottom: 1px solid $grey-lighter;
  p {
    line-height: inherit;
  }
  &::after, &::before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 14%;
    height: 3px;
  }
  &::before {
    top: -1px;
  }
  &::after {
    bottom: -1px;
  }
  .blockquote-author {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
  }
}

.generic-surtitle {
  display: block;
  margin: 0 0 7px 0;
  text-transform: uppercase;
  font-size: 10px;
}

.generic-subtitle {
  display: block;
  margin: 7px 0 0 0;
  font-size: 12px;
  color: $grey-light;
}

.image-legend {
  display: inline-block;
  position: relative;
  top: -10px;
  margin: 0 0 20px 0;
  padding: 0 20px 10px 0;
  border-bottom: 1px solid $grey-lighter;
  font-size: 13px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    display: block;
    width: 70px;
    height: 2px;
  }
}

.mentionslegales-pleinepage {
  .container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

div.flash-msg.flash-groupement-litige {
  margin: -20px 0 20px 0;
  .flash-color {
    padding: 10px 25px;
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    background-color: #fbf0f6;
    font-family: 'bentonsans-ultracondensed', sans-serif;
    display: none;

    span.left{
      letter-spacing: 0.04em;
      font-size: 1.2em;
    }

    span.icon-notif{
      top: -3px;
      padding: 5px 7px 3px 8px;
      font-weight: bold;
    }

    .btn {
      padding: 8px 34px;
      letter-spacing: 0px;
      font-size: 1.3em;
      font-weight: bold;
      font-family: 'bentonsans-ultracondensed', sans-serif;
      letter-spacing: 0.05em;
      font-size: 1.2em;

      &::before {
        background-color: transparent;
      }
      &:active, &:hover, &:focus {
        background-color: darken($pink-base, 15%);

        &::before {
          background-color: transparent;
        }
      }
    }
  }
}

.homepage div.flash-msg {
  margin: -10px 0 20px 0;
}

@media screen and (max-width: 1200px) {
  blockquote {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {

  div.flash-msg.flash-groupement-litige {
    margin: 0 0 20px 0;
    .flash-color {
      flex-direction: column;
      .left {
        margin-bottom: 10px;
      }
    }
  }
}