.liste-produit-biogaran-container {
    overflow: hidden;

    img {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }

    width: 90%;
    margin: auto;

    #title {
        color: #5159a4;
    }
    .titleicon {
        color: #c3c5c7;
        margin-right: 10px;
        margin-bottom: 5px;
        font-size: 30px;
    }
    .color_grey {
        color: #7c8ca5;
    }
    .color_red {
        color: #cb2f7b;
    }
    .color_ppttc {
        color: #263981;
    }
    .flex_product {
        -webkit-justify-content: space-around !important;
        justify-content: space-around !important;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
    }
    .delete_button {
        background-color: transparent;
    }
    .name_product {
        color: #363e44;
        text-decoration: underline;
    }
    .flex-container {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-content: space-between;
        -ms-flex-line-pack: justify;
        align-content: space-between;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;

        @media handheld, screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    .flex-container > .flex-item:nth-child(1) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
    }

    .flex-container > .flex-item:nth-child(2) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;

        @media handheld, screen and (max-width: 768px) {
            -webkit-align-self: flex-end;
            -ms-flex-item-align: flex-end;
            align-self: flex-end;
        }
    }

    .products-list-biogaran-table-container {
        width: 100%;
        overflow-x: auto;
        margin-bottom: 2rem;

        .liste-produit-biogaran {
            width: 100%;
            text-align: center;
            border-collapse: separate;

            tbody tr > td:nth-child(1) {
                border-radius: 5px 0 0 5px;
            }
            tbody tr > td:nth-last-child(1) {
                border-radius: 0 5px 5px 0;
                border-right: 2px solid #f3f4f7;
            }
            tbody tr > td:nth-last-child(2) {
                border-right: 2px solid #f3f4f7;
                border-left: 2px solid #f3f4f7;
            }
            tbody tr:nth-last-child(1) > td {
                border-bottom: 2px solid #f3f4f7;
            }
            tbody:before {
                content: "-";
                display: block;
                line-height: 1em;
                color: transparent;
            }
            td {
                border-top: 2px solid #f3f4f7;
                font-weight: bolder;
            }
            th {
                font-weight: lighter;
                width: 11%;
                border-top: 1px solid #788991;
                border-bottom: 1px solid #788991;
            }
            .left {
                text-align: left;
                padding-left: 5px;
            }
            th:nth-child(1) {
                padding: 20px 0 20px 0;
                border-radius: 5px 0 0 5px;
                border-left: 1px solid #788991;
                width: 12% !important;
            }

            th:nth-child(2) {
                width: 20% !important;
            }
            th:nth-child(3) {
                width: 8% !important;
            }

            th:nth-last-child(1) {
                border-radius: 0 5px 5px 0;
                border-right: 1px solid #788991;
                width: 5% !important;
            }

            th {
                text-align: center;
                background-color: #485e68;
                color: white;
                text-transform: uppercase;

                @media handheld, screen and (max-width: 768px) {
                    font-size: 12px;
                }
            }

        }
    }

    .products-list-biogaran-actions {

        .back-to-sim-otc {
            float: right;
            padding: 1rem;
            font-size: 10px;
            margin-bottom: 20px;
        }

        .empty-list {
            @extend .back-to-sim-otc;
            float: left;
        }

        @media handheld, screen and (max-width: 640px) {
            display: flex;
            flex-direction: column;
            justify-content: stretch;

            .empty-list{
                width: 100%;
                float: none;
            }
        }

    }

    .cursor-pointer {
        cursor: pointer;
    }
}
