@charset "UTF-8";

.pharmacien-dashboard {
    background-color: $grey-bodybg;
}

.dashboard-content {
    position: relative;
    padding: 100px 0 0 3vw;
}

.dashboard-content-bottom {
    padding-top: 0;
}

.dashboard-table-2col {
    display: table;
    position: relative;
}

.dashboard-row {
    display: table-row;
}

.dashboard-col {
    display: table-cell;
    width: 45%;

    h3 {
        margin: 0 0 20px 0;
        text-transform: uppercase;
        color: $grey-blue;
        font: 17px/1em 'bentonsans-condensedregular', sans-serif;

        span {
            display: block;
            margin: 15px 0 0 0;
            text-transform: none;
            color: $grey-light;
            font: 13px/1em 'bentonsans-regular', sans-serif;
        }
    }

    h3.contrat-title {
        margin-top: 40px;
    }

    .subtitle-with-ic {
        padding: 0 0 0 20px;

        h3 {
            display: inline-block;
            width: 75%;
        }

        img {
            float: right;
            margin: 0;
            width: 56px;
        }
    }
}

.dashboard-spacercol {
    display: table-cell;
    width: 4%;
}

.dashboard-container-with-border {
    padding: 20px 20px 0 20px;
    margin: 0 0 20px 0;
    border: 1px solid $grey-lighter;
}

.col-tag {
    @include border-radius(5px);
    display: inline-block;
    position: absolute;
    top: -65px;
    left: 0;
    padding: 10px;
    font: normal 25px/28px bentonsans-ultracondensed, sans-serif;
    color: $white;
    text-transform: uppercase;
    text-align: center;
}

.dashboard-bordercol {
    position: relative;
    background-color: $white;
    border: 1px solid $grey-lighter;
    padding: 0 20px;
    vertical-align: top;
    margin-bottom: 25px;
}

.header-with-ic {
    display: table;
    width: calc(100% + 20px);
    border-bottom: 1px solid $grey-lighter;
    margin: 0 0 20px -20px;

    .header-with-ic-icon {
        display: table-cell;
        width: 68px;
        vertical-align: middle;
        padding: 25px 5px;

        img {
            width: 58px;
            margin: 0;
        }
    }
}

.counter {
    position: relative;
    margin: 0 0 20px 0;
    padding: 15px 30px 15px 20px;
    display: table;
    vertical-align: middle;
    width: 100%;

    .counter-title {
        display: table-cell;
        vertical-align: middle;
        margin: 0;
        width: 50%;
        font: 16px/1em 'bentonsans-condensedregular', sans-serif;
        color: $grey-blue;

        span {
            display: block;
            font: 14px/1em 'bentonsans-condensedregular', sans-serif;
            color: $grey-light;
            text-transform: none;
        }
    }

    .counter-anim {
        display: table-cell;
    }

    .splitflap {
        margin: 0 auto;

        -webkit-perspective-origin: top center;
        -moz-perspective-origin: top center;
        -ms-perspective-origin: top center;
        perspective-origin: top center;

        -webkit-perspective: 900px;
        -moz-perspective: 900px;
        -ms-perspective: 900px;
        perspective: 900px;
    }

    .upper, .lower {
        -moz-box-shadow: 0px 2px 4px 0px #98adb1;
        -webkit-box-shadow: 0px 2px 4px 0 #98adb1;
        -o-box-shadow: 0px 2px 4px 0px #98adb1;
        box-shadow: 0px 2px 4px 0px #98adb1;
    }

    .counter-ht {
        display: block;
        position: absolute;
        right: 10px;
        top: 10px;
        text-transform: uppercase;
        font-size: 11px;
    }

    &.counter-rembourse .counter-ht {
        color: $green-base2;
    }

    &.counter-non-rembourse .counter-ht {
        color: $indigo-formation;
    }

    .counter-ttc {
        margin: 10px 0 0 0;
        color: $indigo-formation;
        font-size: 13px;
    }
}

// Feux tricolores
.feux_tricolores {
    @include border-radius(16px);
    position: relative;
    float: right;
    width: 33px;
    height: 90px;
    background-color: $grey-lighter;
    margin: 0 10px 20px 0;

    .feu {
        @include border-radius(8px);
        width: 16px;
        height: 16px;
        position: absolute;
        left: 8px;

        &.feu_rouge {
            background-color: $pink_base;
            top: 10px;
        }

        &.feu_orange {
            background-color: $orange-base;
            top: 37px;
        }

        &.feu_vert {
            background-color: $green-base;
            top: 64px;
        }
    }

    .curseur {
        position: absolute;
        right: -6px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3.5px 6px 3.5px 0;
        border-color: transparent #485e68 transparent transparent;
    }

    &.rouge_ok {
        .feu_rouge {
            -moz-box-shadow: 0px 0px 6px 2px $pink_base;
            -webkit-box-shadow: 0px 0px 6px 2px $pink_base;
            -o-box-shadow: 0px 0px 6px 2px $pink_base;
            box-shadow: 0px 0px 6px 2px $pink_base;
        }

        .curseur {
            top: 15px;
        }
    }

    &.orange_ok {
        .feu_orange {
            -moz-box-shadow: 0px 0px 6px 2px $orange-base;
            -webkit-box-shadow: 0px 0px 6px 2px $orange-base;
            -o-box-shadow: 0px 0px 6px 2px $orange-base;
            box-shadow: 0px 0px 6px 2px $orange-base;
        }

        .curseur {
            top: 41px;
        }
    }

    &.vert_ok {
        .feu_vert {
            -moz-box-shadow: 0px 0px 6px 2px $green-base;
            -webkit-box-shadow: 0px 0px 6px 2px $green-base;
            -o-box-shadow: 0px 0px 6px 2px $green-base;
            box-shadow: 0px 0px 6px 2px $green-base;
        }

        .curseur {
            top: 68px;
        }
    }
}

// Tables
.dashboard-table-style1 {
    display: table;
    width: 100%;
    margin: 0 0 20px 0;
    font-size: 12px;

    .table-header {
        display: table-header-group;
        color: $white;
        font-weight: bold;

        div {
            display: table-cell;
            padding: 5px 0;
            text-align: center;
            //width: 33%;
            &.col1 {
                @include border-top-left-radius(5px);
                background-color: $green-dashboard-light;
            }

            &.col2 {
                background-color: $green-base;
            }

            &.col3 {
                //@include border-top-right-radius(5px);
                background-color: $green-dashboard-dark;
            }

            &.colTotalRdp {
                @include border-top-right-radius(5px);
                background-color: $grey-blue-light4;
            }
        }
    }

    .table-row {
        display: table-row;

        div {
            display: table-cell;
            padding: 5px 0;
            border-top: 1px solid $grey-bodybg;
            text-align: center;
            color: $grey-blue;
            border-bottom: 1px solid $grey-bodybg;

            &:first-child {
                border-left: 1px solid $grey-bodybg;
                padding-left: 15px;
            }

            &:last-child {
                border-right: 1px solid $grey-bodybg;
            }

            &.col1 {
                @include border-btm-left-radius(5px);
            }

            &.col2 {
                //@include border-bottom;
            }

            &.col3 {
                @include border-btm-right-radius(5px);
            }
        }

        &.first-row div:first-child {
            @include border-top-left-radius(6px);
        }

        &.last-row div {
            border-bottom: 1px solid $grey-bodybg;

            &:first-child {
                @include border-btm-left-radius(6px);
            }

            &:last-child {
                @include border-btm-right-radius(6px);
            }
        }
    }
}

.previousYear {
    display: none;
}

.previousYear.showData {
    display: table;
}

.previousYear.isVisible {
    display: table;
}

.showCurrent {
    display: none;
}

.showRdpYearDetails {
    color: $grey-midlighter;
    position: relative;
    padding-bottom: 15px;

    &::before {
        content: " ";
        width: 8px;
        height: 10px;
        display: block;
        -webkit-background-size: 8px 10px;
        background-size: 8px 10px;
        top: 3px;
        position: absolute;

    }
}

.showPrevious {
    padding-left: 14px;
    display: block;

    &::before {
        background: url(../img/dashboard/mini-blue-arrow.png) no-repeat left top;
        left: 0;
    }
}

.showCurrent {
    padding-right: 14px;
    float: right;

    &::before {
        background: url(../img/dashboard/mini-blue-arrow-right.png) no-repeat left top;
        right: 0;
    }
}

.dashboard-table-style2 {
    display: table;
    width: 100%;
    margin: 0 0 20px 0;

    .table-header {
        display: table-header-group;
        color: $white;

        div {
            display: table-cell;
            padding: 25px 15px;
            width: 50%;
            font: 16px/1em 'bentonsans-regular', sans-serif;

            &::before {
                content: "";
                display: block;
                float: left;
                width: 43px;
                height: 43px;
                margin: -5px 15px 0 0;
            }

            &.col1 {
                background-color: $indigo-formation;
                position: relative;

                &::before {
                    background: url('../img/dashboard/percent_ic.png') no-repeat left top;
                    -webkit-background-size: 43px 43px;
                    background-size: 43px 43px;
                }
            }

            &.col2 {
                background-color: lighten($indigo-formation, 10%);

                &::before {
                    background: url('../img/dashboard/calculator_ic.png') no-repeat left top;
                    -webkit-background-size: 43px 43px;
                    background-size: 43px 43px;
                }
            }
        }
    }

    .table-row {
        display: table-row;

        div {
            display: table-cell;
            padding: 25px 0;
            border-bottom: 1px solid $grey-bodybg;
            text-align: center;
            color: $grey-blue;
            font-size: 23px;

            span {
                display: block;
                color: $grey-light;
                font-size: 14px;
            }

            &:first-child {
                border-left: 1px solid $grey-bodybg;
                border-right: 1px solid $grey-bodybg;
                @include border-btm-left-radius(6px);
            }

            &:last-child {
                border-right: 1px solid $grey-bodybg;
                @include border-btm-right-radius(6px);
            }
        }
    }
}

.dashboard-table-style3 {
    display: table;
    width: 100%;
    margin: 0 0 20px 0;
    border: 1px solid $grey-lighter;
    @include border-radius(6px);
    overflow: hidden;

    .table-header {
        display: table-header-group;
        color: $white;
        text-align: center;

        div {
            display: table-cell;
            padding: 7px;
            width: 33%;
            font: 14px/1em 'bentonsans-regular', sans-serif;

            span::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 14px;
                height: 14px;
                margin: 0 5px 0 0;
            }

            &.col1 {
                &.signe {
                    background-color: $green-base;

                    span::before {
                        background: url('../img/dashboard/check_ic.png') no-repeat left top;
                        -webkit-background-size: 14px 14px;
                        background-size: 14px 14px;
                    }
                }

                &.asigner {
                    background-color: $pink-base;

                    span::before {
                        background: url('../img/dashboard/pen_ic.png') no-repeat left top;
                        -webkit-background-size: 14px 14px;
                        background-size: 14px 14px;
                    }
                }
            }

            &.col2 {
                &.encours {
                    background-color: $orange-base;

                    span::before {
                        background: url('../img/dashboard/chrono_ic.png') no-repeat left top;
                        -webkit-background-size: 14px 14px;
                        background-size: 14px 14px;
                    }
                }

                &.refuse {
                    background-color: $grey-blue-light4;

                    span::before {
                        background: url('../img/dashboard/cross_ic.png') no-repeat left top;
                        -webkit-background-size: 14px 14px;
                        background-size: 14px 14px;
                    }
                }
            }
        }
    }

    .table-row {
        display: table-row;

        div {
            display: table-cell;
            padding: 10px 0;
            text-align: center;
            color: $grey-blue;
            font-size: 13px;
        }
    }
}

.encart {
    //display: table;
    width: 100%;
    background-color: #73d1e6;
    color: $white;

    .encart-illu {
        max-width: 440px;
    }

    img {
        margin: 0;
    }

    div {
        //display: table-cell;
        vertical-align: middle;

        span {
            text-transform: uppercase;
            display: block;
        }

        .titre1 {
            font: 27px/1em 'bentonsans-ultracondensed', sans-serif;
            padding-bottom: 10px;
        }

        .titre2 {
            margin: 0 0 10px 0;
            font: 27px/1em 'bentonsans-ultracondensed', sans-serif;
            color: #0089a7;
        }

        .para1 {
            color: $indigo-formation;
            text-transform: none;
            font-size: 16px;
        }
    }

    .encart-txt {
        //padding: 15px 35px 15px 0;
        margin-top: -110px;
        text-align: center;
        margin-bottom: 7px;
        padding-bottom: 22px;
    }
}

/* POPIN SERIOUS GAME */
#popinSeriousGame, #popinCompteDemo {
    .popin-base {
        width: 100%;
        padding: 0;

        div {
            width: 100%;

            &.popin-top {
                background-color: $orange-base;
                color: $white;
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.2em;
                letter-spacing: 3px;
                padding: 7px 0;

                .popin-seriousgame-close {
                    float: right;
                    color: $white;
                    margin: 0 10px 0 0;
                }

                &.world2 {
                    background-color: $blue-base3;
                }
            }

            &.popin-banner {
                background-image: url('../img/dashboard/popin-seriousgame/popin-seriousgame-image-world3.jpg');
                background-size: cover;
                height: 267px;
            }

            &.popin-logo {
                img {
                    margin: auto;
                    margin-top: -47px;
                }
            }

            &.popin-subtitle {
                font-size: 1.4em;
                font-weight: bold;
                color: $grey-blue-light3;
                letter-spacing: 2px;
                margin: 20px 0;
            }

            &.popin-content {
                margin-bottom: 15px;

                &.world2 {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

            &.popin-button {
                margin: 20px;

                a {
                    background-color: $orange-base;
                    color: $white;
                    padding: 10px 40px;
                }

                &.world2 {
                    a {
                        background-color: $blue-base3;
                    }
                }
            }

            &.popin-footer {
                &.world2 {
                    a {
                        color: $grey-light;
                        text-decoration: underline;
                        display: inline-block;
                        margin: 0 0 20px 0;
                    }
                }
            }
        }

        &.popin-compte-demo {
            color: $grey-blue2;

            .popin-top {
                .popin-comptedemo-close {
                    float: right;
                    color: $white;
                    margin: 0 10px 0 0;
                }
            }

            .popin-subtitle {
                text-align: left;
                padding-left: 30px;
            }

            .popin-content {
                text-align: left;
                padding-left: 30px;
            }

            .popin-footer {
                a {
                    color: $grey-light;
                    text-decoration: underline;
                    display: inline-block;
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}

/* Popin Engagement Conseil */
#popinEngagementConseil {
    .popin-base {
        width: 100%;
        padding: 0;

        div {
            width: 100%;

            &.popin-engagement-title {
                color: $white;
                text-align: center;
                text-transform: uppercase;
                font: Condensed Book 15px/28px BentonSans;
                letter-spacing: 0.75px;
                padding: 7px 0;

                .popin-close {
                    float: right;
                    color: $white;
                    margin: 0 10px 0 0;
                }

                &.biogaran-blue-popin {
                    background-color: $blue-base4;
                }
            }
        }

        .engagement-conseil-card {
            .card-container {
                color: $blue-base4;
                text-align: left;
                font-size: 13px;
                display: flex;
                justify-content: space-between;
                align-content: center;

                .card-panel-container {
                    padding: 15px;
                    align-self: center;
                }

                .card-panel-content {
                    display: flex;
                }

                .card-img-primary {
                    width: 230px;
                    margin: 0px;
                    padding: 15px;
                }

                .card-img-top {
                    width: 60px;
                    height: 90px;
                    margin-right: 15px;
                }

                .card-text {
                    line-height: 20px;
                }

                .card-group {
                    color: #485E68;
                    letter-spacing: 0;

                    .card-title {
                        font-weight: bold;
                    }

                    .card-text {
                        margin-top: -20px;
                    }
                }

                .btn-engagement {
                    display: block;
                    background-color: #495b69;
                    font: normal 10px/30px bentonsans-medium, sans-serif;
                    min-width: 50%;
                    width: 280px;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    color: #ffffff;
                    height: auto;
                    padding: 2px;
                }
            }

            .card-text-code {
                color: #485E68;
                font-size: 12px;
                float: right;
                margin: 0px 15px 15px 0px;
            }
        }
    }
}

/* Popin MarketingInfos */
#popinMarketingInfos {
    width: 80vw;

    .popin-base {
        width: 100%;
        padding: 0;

        div {
            width: 100%;

            &.popin-marketing-infos-title {
                color: $black;
                text-align: center;
                text-transform: uppercase;
                font: Condensed Book 15px/28px BentonSans;
                letter-spacing: 0.75px;
                padding: 7px 0;

                .popin-close {
                    float: right;
                    color: $white;
                    margin: 0 10px 0 0;
                }
            }
        }
    }

    p {
        color: white;
    }
}


/* Popin Engagement Conseil */
#popinImi {
    .popin-base {
        width: 100%;
        padding: 0;

        div {
            width: 100%;

            &.popin-imi-title {
                color: $white;
                text-align: center;
                text-transform: uppercase;
                font: Condensed Book 15px/28px BentonSans;
                letter-spacing: 0.75px;
                padding: 7px 0;
                background-color: #573636;

                .popin-close {
                    float: right;
                    color: $white;
                    margin: 0 10px 0 0;
                }
            }
        }

        .popin-imi-card {
            text-align: center;

            .img-top {
                width: 700px;
            }

            .block-center-img-text {
                display: flex;
                justify-content: center;
                margin-top: -58rem;

                .img-center {
                    width: 550px;
                    margin: 0px 0px 0px 6rem;
                }

                .info-text {
                    align-self: flex-end;
                    font-size: 10px;
                    margin-bottom: 7rem;
                    margin-left: -4rem;
                }
            }

            .block-center {
                &-market {
                    display: flex;
                    justify-content: flex-end;

                    .text-left {
                        font-size: 10px;
                        text-align: center;
                        padding: 10px 50px;
                    }

                    .text-right {
                        writing-mode: tb-rl;
                        transform: rotate(-180deg);
                        margin-bottom: 4rem;
                        margin-right: 15px;
                        font-size: 11px;
                    }
                }

                &-text-btn {
                    font-family: bentonsans-book, sans-serif;
                    text-align: center;
                    margin-top: -7rem;
                    margin-bottom: 2rem;
                    z-index: 1001;

                    span {
                        color: #62197b;
                        font-weight: bolder;
                        font-size: 18px;
                    }

                    strong {
                        text-transform: uppercase;
                        color: #62197b;
                        font-weight: 800;
                        font-size: 20px;
                    }

                    a {
                        display: block;
                        background-color: #495b69;
                        font: normal 10px/30px bentonsans-medium, sans-serif;
                        width: 280px;
                        text-align: center;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        color: #ffffff;
                        padding: 2px;
                        margin: 15px auto 0 auto;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}


/* Popin CSP */
.popin-csp {
    .popup-base {
        width: 630px;
        background: transparent url('/assets/img/commandes/popin-bg.png') 0 0 no-repeat padding-box;
    }

    &-title {
        background: #2EB4D1;
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
    }

    &-close {
        float: right;
    }

    &-close:hover {
        cursor: pointer;
    }

    &-content {
        text-align: center;

        .content {
            &-title {
                padding: 25px;
                font-family: bentonsans-bold, sans-serif;
                font-size: 26px;
                text-transform: uppercase;
                color: #FFFFFF;
            }

            &-img {
                img {
                    margin: unset;
                    display: unset;
                    width: 450px;
                }
            }

            &-btn {
                button {
                    background: #2EB4D1;
                    color: #FFFFFF;
                    padding: 8px 20px;
                    font-family: bentonsans-bold, sans-serif;
                    font-size: 16px;
                    text-transform: uppercase;
                }

                a {
                    text-decoration: none;
                    color: #FFFFFF;
                }
            }

            &-footer {
                padding: 10px;
                font-family: Bentonsans, sans-serif;
                font-size: 10px;
                color: #FFFFFF;
            }
        }
    }
}

/* Popin bottle */
.popin-bottle {
    .popup-base {
        width: 630px;
        background: #84C2C8
    }

    &-title {
        background: #00347D;
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
    }

    &-close {
        float: right;
    }

    &-close:hover {
        cursor: pointer;
    }

    &-content {
        display: flex;
        width: 100%;

        .content-left {
            width: 40%;

            img {
                margin: unset;
                display: unset;
                margin-top: -40px;
                height: 400px;
            }
        }

        .content-right {
            width: 60%;

            img {
                margin: unset;
                display: unset;
                margin-top: 20px;
                width: 360px;
                height: 235px;
            }

            button {
                background: #CB2F7B;
                color: #FFFFFF;
                padding: 8px 20px;
                text-transform: uppercase;

                a {
                    text-decoration: none;
                    color: #FFFFFF;
                    font-family: bentonsans-bold, sans-serif;
                    font-size: 12px;
                }
            }

            &-content-button {
                text-align: center;
                margin: 10px 0px 30px 0px;
            }

            &-content-po {
                font-family: Bentonsans;
                color: #00347D;
                text-align: right;
                margin-right: 10px;
            }

        }
    }
}

/* Popin biog&vous*/
.popin-biog-et-vous {
    .popup-base {
        width: 630px;
        background: #84C2C8
    }

    &-title {
        background: #CB2F7B;
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
    }

    &-close {
        float: right;

        img {
            margin: unset;
        }
    }

    &-close:hover {
        cursor: pointer;
    }

    &-content {
        display: flex;
        width: 100%;

        .content-left {
            width: 60%;
            color: #00347D;
            font-family: bentonsans-bold, sans-serif;
            padding: 15px;
            background-color: #FFFFFF;

            &-title {
                font-size: 12px;
            }

            &-subtitle {
                font-size: 16px;
            }

            &-video {
                video {
                    width: 345px;
                }
            }

            img {
                margin: 10px 0px 10px 0px;
            }

            &-texte {
                font-size: 16px;
                font-family: Arial;
                font-style: italic;
                margin-bottom: 5px;
            }

            &-content-po {
                font-size: 10px;
                font-family: Arial;
                text-align: center;
            }

        }

        .content-right {
            width: 40%;
            font-family: bentonsans-bold;
            color: #CB2F7B;
            padding: 15px;
            background-color: #E4E4E4;

            img {
                margin: 0 0 20px 0;
            }

            &-title {
                text-transform: uppercase;
                font-size: 14px;
                margin-bottom: 8px;
            }

            &-button {
                font-size: 11px;

                button {
                    border: 2px solid #CB2F7B;
                    padding: 6px 12px;
                    text-transform: uppercase;
                    font-size: 12px;

                    a {
                        text-decoration: none;
                        color: #CB2F7B;
                        font-family: bentonsans-bold, sans-serif;
                        font-size: 12px;
                    }
                }

            }

        }
    }
}

/* Popin2 biog&vous*/
.popup-base.popin2 {
    width: 480px;
}

.popin2-biog-et-vous {
    background-color: white;
    color: #00347D;
    padding: 20px 30px 5px 30px;

    &-title {
        font-size: 16px;
        margin-bottom: 20px;
        font-family: bentonsans-bold, sans-serif;
    }

    &-texte {
        font-size: 12px;
        margin-bottom: 15px;
        font-family: Arial, sans-serif;
        font-style: italic;
    }

    img {
        margin: 0 0 15px 0;
        width: 100%;
    }

    &-po {
        font-size: 10px;
        text-align: center;
        font-family: Arial, sans-serif;
    }
}

/* Popin Noereka*/
.popin-noereka {
    .popup-base {
        width: 550px;
        background-image: url('../img/noereka/background.png');
        background-size: cover;
    }

    &-title {
        background: #009380;
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
    }

    &-close {
        float: right;

        img {
            margin: unset;
        }
    }

    &-close:hover {
        cursor: pointer;
    }

    &-content {
        text-align: center;

        &-logo {
            margin: 5px 0 10px 0;
            img {
                max-height: 40px;
                margin: 0 auto;
            }
        }

        &-title, &-subtitle {
            font-size: 16px;
            font-family: bentonsans-bold;
            color: #009380;
        }

        &-imgNoereka {
            img {
                margin: -35px auto 0 auto;
                height: 375px;
            }
        }

        &-noereka {
            &-texte {
                color: #262926;
                font-family: bentonsans-bold;
                font-size: 12px;
                width: 90%;
                margin: auto;
            }

            &-btn {
                a {
                    font-family: "Benton Sans", sans-serif;
                    text-transform: uppercase;
                    background: #000000;
                    color: #FFFFFF;
                    font-size: 12px;
                    padding: 10px 15px;
                    border-radius: 25px;
                    letter-spacing: 2px;
                }

                margin: 15px 0;
            }

            &-texte2, &-po {
                font-size: 10px;
            }

            &-texte2 {
                color: #009380;
                font-family: bentonsans-regular;
                width: 90%;
                margin: auto;
                padding: 0 10px 0 10px;
            }

            &-po {
                font-family: bentonsans-regular, sans-serif;
                color: #FFFFFF;
                margin: 7px 0 7px 0;
            }
        }

    }
}

.card-bg-1 {
    background-color: dodgerblue;
}

.card-bg-2 {
    background-color: red;
}

/****** Left col ****/
.dashboard-leftcol {
    .col-tag {
        background-color: $green-base2;
    }

    // H2
    .header-with-ic {
        .header-with-ic-icon {
            background-color: $green-base2;
        }

        .header-with-ic-title {
            color: $grey-blue;

            span {
                color: $green-base2;
            }
        }

        .header-with-ic-legend {
            display: table-cell;
            position: relative;
            vertical-align: bottom;
            padding: 0 25px 15px 0;
            width: 120px;
            text-align: right;
            font-size: 12px;

            .tooltip_toggle, .data-lity-toggle {
                bottom: 25px;
                right: 0;
                background-color: #ebf8f5;
                border-color: #dae0e9;
                color: #179778;
                webkit-border-radius: 50%;
                border-radius: 50%;
                -moz-border-radius: 50%;
                -o-border-radius: 50%;
                -ms-border-radius: 50%;
                -webkit-background-clip: padding-box;
                display: inline-block;
                width: 17px;
                height: 17px;
                line-height: 17px;
                position: absolute;
                border-style: solid;
                border-width: 1px;
                -webkit-background-size: 100% auto;
                background-size: 100% auto;
                cursor: pointer;
                font-size: 11px;
                font-family: 'bentonsans-condensedregular', sans-serif;
                text-align: center;
            }
        }
    }

    .counter {
        background-color: #ebf8f5;
    }

    .eligib_prochain_quad {
        h3 {
            width: 70%;
            float: left;
        }
    }

    .dashboard-container-with-border:last-of-type {
        margin-bottom: 20px;
    }

    .hc-block-footerlink {
        position: relative;
        float: right;
        width: 80%;
        margin: -20px 0 20px 0;
    }

    .btn-deploy-rdp-disabled {
        margin: -20px 0 30px 0;
        display: block;
        position: relative;
        float: right;
        width: 80%;
        height: auto;
        background-color: #495b69;
        font: normal 9px/18px bentonsans-medium, sans-serif;
        min-width: 50%;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.5;
    }
}

/****** right col ****/
.dashboard-rightcol {
    .dashboard-bordercol {
        padding-bottom: 30px;
    }

    .col-tag {
        background-color: $indigo-formation;
    }

    // H2
    .header-with-ic {
        .header-with-ic-icon {
            background-color: $indigo-formation;
        }

        .header-with-ic-title {
            color: $grey-blue;

            span {
                color: $indigo-formation;
            }
        }
    }

    .counter {
        background-color: #e9f2fe;

        .counter-title {
            text-transform: uppercase;
        }
    }

    .hc-block-footerlink {
        position: relative;
    }
}

/******* Nouveautés produits */
.dashboard-bloc-nouveautes {
    background-color: $white;
    margin: 30px 0 0 0;

    [class^="ic-"] {
        background-color: $purple-base;
    }

    .hc-block-header {
        width: 100%;
        margin: 0 0 20px 0;

        p {
            display: table-cell;
            vertical-align: middle;
            padding: 0 10px;
            font-size: 12px;
        }
    }

    .hc-block-title {
        color: $grey-blue;

        span {
            color: $purple-base;
            display: block;
        }
    }

    .dashboard-tableaux-nouveautes {
        padding: 0 4% 20px 8%;
    }

    .dashboard-tableaux-nouveautes .catalogue-row_wrapper li:last-child {
        border-left: 1px solid $border-grey;
        color: $purple-base;
        font-size: 16px;

        a {
            color: $purple-base;
            font-family: 'bentonsans-medium', sans-serif;
            text-decoration: underline;
        }
    }
}

/******* Encart catalogue */
.encart-catalogue-container {
    background-color: white;
    padding: 0 4% 20px 8%;
    margin: 20px 0 0 0;
    width: 100%;

    .encart-catalogue {
        display: table;
        background-color: #ebe2f5;
        width: 100%;
        margin: 0 0 30px 0;

        div {
            display: table-cell;
            vertical-align: middle;
        }

        .encart-catalogue-img {
            img {
                margin: 0 0 -25px -46px;
            }
        }

        .encart-catalogue-txt {
            color: $grey-blue;

            span {
                display: block;
                color: $purple-base;
            }
        }

        .encart-catalogue-link {
            padding: 0 25px 0 0;

            .hc-block-footerlink {
                position: relative;
                right: auto;
                bottom: auto;
                padding: 0 70px;
            }
        }
    }
}

/******* Popup rdp */
.dashboard-popup-body {
    background-color: white;
    margin: auto;
    padding: 65px;

    .dashboard-popup-title {
        text-align: center;
        font-size: 20px;
        margin: 0 0 65px 0;

        span {
            display: block;
            margin: 0 0 10px 0;
            color: $grey-blue;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            font: 11px/1em 'bentonsans-medium', sans-serif;
        }
    }

    .table-header {
        text-transform: uppercase;

        .col1 {
            background-color: $green-base;
            font-size: 13px;
        }

        .col2 {
            background-color: $green-base2;
            font-size: 13px;
        }

        .col3 {
            background-color: $pink-base;
            font-size: 13px;
        }
    }

    .table-row {
        div {
            border-bottom: 1px solid $grey-lighter;

            &:first-child {
                @include border-btm-left-radius(6px);
            }

            &:last-child {
                @include border-btm-right-radius(6px);
            }
        }

        &:nth-child(2n+1) div {
            background-color: #f3f8fb;
        }

        &:last-child div {
            border-bottom: none;
        }
    }

    h2 {
        margin: 25px 0 5px 0;
        font: 19px/1em 'bentonsans-medium', sans-serif;
        color: $grey-blue;
    }

    p, ul {
        line-height: 1.7em;
        margin: 0;
    }

    li {
        margin: 0;
    }
}

/******* Popup rdp */
#undeployed-rdp {
    display: block;
    position: relative;
}

#deploy-rdp {
    margin-bottom: 35px;
    padding-bottom: 0px;
}

#deployed-rdp {
    float: none;
    display: none;
    margin-top: -15px;

    //height: 0;
    position: relative;
    //width: 0;
    //bottom:0;
    .rdp-container {
        opacity: 1;
        background: $white; // rgba(255,0,0,0.4);
        margin: 0px 20px 10px 25px;
        padding: 20px 40px 40px 75px;
    }

    p.footer-rdp {
        padding-left: 25px;
        padding-right: 20px;
        color: #495b69;
        font-size: 11px;
    }

    header {
        text-align: center;
        padding: 40px 20px 40px 0px;

        h4 {
            color: $grey-blue;
            font-size: 11px;
            line-height: 1.3em;
            font-weight: normal;
            font-family: 'bentonsans-medium', Arial, Helevtica, sans-serif;
            text-transform: uppercase;
            letter-spacing: 4px;
        }

        p {
            font-family: 'bentonsans-light', Arial, Helevtica, sans-serif;
            font-style: italic;
            font-size: 18px;
            line-height: 31px;
            color: $grey-blue-light3;
            text-align: left;
            line-height: 27px;
            padding-top: 25px;
            margin-bottom: 0;
        }
    }

    .deploy-rdp-toggle {
        color: #a8babd;
        float: right;
        padding-bottom: 17px;
        position: relative;
    }

    .deploy-rdp-toggle::before {
        content: " ";
        width: 8px;
        height: 10px;
        display: block;
        -webkit-background-size: 8px 10px;
        background-size: 8px 10px;
        top: 3px;
        position: absolute;
    }

    .rdp-toggle-last-year::before {
        background: url(../img/dashboard/mini-blue-arrow.png) no-repeat left top;
        left: -14px;
    }

    .rdp-toggle-this-year::before {
        background: url(../img/dashboard/mini-blue-arrow-right.png) no-repeat left top;
        right: 0;
    }

    .rdp-toggle-this-year {
        padding-right: 17px;
    }

    .dl-pdf-rdp {
        background: url(../img/dashboard/picto-dl.png) no-repeat top;
        background-size: 16px 19px;
        padding-top: 22px;
        color: #363e44;
    }

    section {
        padding: 20px 20px 20px 0px;

        .cartouche {
            background-color: #fefefe;
            margin: 20px auto;
            width: 100%;
            padding: 20px;
            border: 1px solid $grey-lighter;
            border-radius: 3px;
            display: flex;
            justify-content: space-between;

            > div {
                font-family: 'bentonsans-medium', Arial, Helevtica, sans-serif;
                color: $blue-base;
                text-transform: uppercase;
                font-size: 12px;
                line-height: 21px;
                align-self: center;

                > span {
                    color: $grey-blue2;
                    display: block;
                }
            }

            .btn-dl {
                display: block;
                background: transparent;
                font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 12px;
                color: $black;
                font-weight: 400;
                transition: all 0.4s ease;

                span {
                    display: block;
                    width: 16px;
                    height: 19px;
                    background: url('../img/dashboard/arrow_dl_2x.png') no-repeat top center;
                    background-size: 16px 18px;
                    background-position: center 0;
                    border-bottom: 2px solid $blue-base3;
                    margin: 10px auto 5px auto;
                    padding: 10px;
                    transition: all 0.4s ease;

                }

                &:hover, &:active, &:focus {
                    font-weight: 700;

                    span {
                        background-position: center 3px;
                    }
                }
            }
        }
    }

    .contratlist-row {
        li {
            padding-top: 10px;
            padding-bottom: 10px;
            height: 46px;
            text-align: right;
            color: $grey-blue-light2;
            font-size: 14px;
            font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;

            &.cell-contrat-ref {
                text-align: left;
                border-left: 3px solid $grey-blue3;
                color: $grey-blue-light2;
                font-family: 'bentonsans-condensedregular', Arial, Helvetica, sans-serif;
                font-size: 13px;
            }

            &.cell-contrat-spe {
                text-align: left;
                color: $grey-blue;
                font-family: 'bentonsans-condensedregular', Arial, Helvetica, sans-serif;
                font-size: 14px;
            }

            &:last-child {
                border-left: 0;
            }
        }
    }
}

// RDP - PDA
.hc-block-indicateurs.dashboard {
    border-left: none;
    padding: 0;
    margin: 0;
    min-height: 0;
    margin-bottom: 15px;
}

.hc-block-indicateurs.dashboard .conditions-row {
    margin-top: 20px;
}

.hc-block-indicateurs.dashboard .hc-block-indicateurs-content {
    margin: 0;
}

.hc-block-content-bottom a {
    color: $blue-base;
    text-decoration: underline
}

.mr-10 {
    margin-right: 10px;
}

.eligibility-text {
    color: #af2323;
    font-size: 18px;
    font-weight: 800;
    font-family: Bentonsans Condensed, sans-serif
}

.cadenas-container img {
    float: right;
    margin-bottom: 0;
}

.hands-container {
    margin-bottom: 20px;
}

.hands-container img {
    margin: auto;
    -webkit-filter: invert(1) grayscale(1);
    filter: invert(1) grayscale(1);
}

.specialities-container {
    background: #eee;
    padding: 10px;
    float: left;
    border-radius: 10px;
}

.conditions-container {
    margin-top: 20px;
}

.conditions-container .hc-block-footerlink {
    position: relative;
    float: right;
    padding: 5px;
    margin-right: 15px;
}

.dashboard-legend {
    padding: 10px 0;
    margin: 0;
    color: $grey-blue;
    font-size: 12px;

    a {
        color: $purple-base;
        text-decoration: underline;
    }
}

// Media queries
@media screen and (max-width: 1000px) {
    .dashboard-rightcol .hc-block-footerlink {
        width: 100%;
    }
}

@media screen and (max-width: 980px) {
    #deployed-rdp {
        padding: 20px 20px 40px 20px;

        .contratlist-row {
            border-left: 3px solid $grey-blue3;
            border-radius: 7px 0 0px 0px;

            li {
                &.cell-contrat-ref {
                    border-left: 0 none;
                    background-color: $grey-blue3;
                    color: $white;
                    border-radius: 3px 3px 0 0px;
                }

                text-align: right;

                span {
                    text-align: left;
                }
            }
        }
    }
}

@media screen and (min-width: 767px) and (max-width: 1000px) {
    .encart {
        min-height: 300px;

        .encart-txt {
            margin-top: -10%;
        }

        div .para1 {
            padding: 0 15px;
        }
    }
}

@media screen and (max-width: 980px) {
    .dashboard-bloc-nouveautes .dashboard-tableaux-nouveautes {
        padding: 0 2% 20px 2%;

        div.catalogue-row_wrapper li:last-child {
            @include border-radius(6px);

            .contrat-col_content-mobile {
                text-align: center;
            }
        }

        .cell-cata-spe_pfht {
            padding-bottom: 10px;
        }
    }
    .pharmacien-dashboard .productlist-container div.catalogue-row_wrapper:nth-of-type(2n-1) li:last-child {
        color: $white;

        a {
            color: $white;
        }
    }
    .encart-catalogue-container {
        padding: 0;
        margin: 0;

        .encart-catalogue {
            display: block;
            width: 100%;

            div {
                display: block;
                text-align: center;
            }

            .encart-catalogue-img {
                padding: 10px 0 0 0;

                img {
                    display: inline-block;
                    margin: 0;
                }
            }

            .encart-catalogue-txt {
                padding: 5px 0 15px 0;
                font-size: 16px;
            }

            .encart-catalogue-link {
                padding: 0;

                .hc-block-footerlink {
                    width: 100%;
                }
            }
        }
    }

}

@media screen and (max-width: 900px) {
    .dashboard-leftcol .hc-block-footerlink {
        width: 100%;
        letter-spacing: 1px;
    }
}

@media screen and (max-width: 767px) {
    .dashboard-content {
        padding: 30px 20px 30px 20px;
        width: 100%;
    }

    .dashboard-table-2col {
        display: block;
        padding: 40px 0 30px 0;
    }
    .dashboard-row {
        display: block;
    }
    .dashboard-col {
        display: block;
        width: 100%;
    }
    .dashboard-spacercol {
        display: none;
    }
    .dashboard-leftcol {
        margin: 60px 0 20px 0;

        .hc-block-footerlink {
            width: 80%;
        }
    }
    .dashboard-rightcol {
        margin-top: 110px;

        .hc-block-footerlink {
            margin: 0 0 20px 0;
        }
    }
    .col-tag {
        left: 50%;

        .dashboard-leftcol & {
            width: 108px;
            margin-left: -54px;
        }

        .dashboard-rightcol & {
            width: 140px;
            margin-left: -70px;
        }
    }
}

@media screen and (max-width: 450px) {
    .dashboard-table-style2 .table-header div {
        font-size: 14px;

        &.col1, &.col2, &.col3 {
            text-align: center;

            &::before {
                width: 30px;
                height: 30px;
                -webkit-background-size: 30px 30px;
                background-size: 30px 30px;
                float: none;
                margin: 0 auto 7px auto;
            }

        }
    }
    .dashboard-content {
        padding: 0px 10px 30px 10px;
    }

    .encart .encart-txt {
        margin-top: -80px;
    }
}

@media screen and (max-width: 370px) {
    .dashboard-leftcol {
        .hc-block-footerlink {
            width: 100%;
        }
    }
    .dashboard-col .subtitle-with-ic h3 {
        width: 70%;
    }
    .dashboard-rightcol .hc-block-footerlink {
        bottom: 10px;
    }
}

