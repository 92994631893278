.popin-commande-flash-imi-dashboard {
    .popup-base {
        width: 596px;
        background: #FFFFFF;
    }

    .popup-title {
        background: #2C5140;
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
    }

    .popup-commande-flash-close {
        float: right;
    }

    .popup-commande-flash-close:hover {
        cursor: pointer;
    }

    .popup-content-container {
        background: #DBF0EC;
        text-align: center;

        img {
            margin: unset;
        }

        &-image {
            //parceque l'image source a de la marge
            position: relative;
            top: -30px;
        }

        .popup-content {
            &-text {
                font-family: Bentonsans-book, sans-serif;
                font-size: 18px;
                color: #2C5140;

                &-brand {
                    font-family: bentonsans-bold, sans-serif;
                }
            }

            &-btn {
                margin-top: 24px;

                button {
                    background: #C71D7E;
                    color: #FFFFFF;
                    padding: 8px 12px;
                    font-family: bentonsans-bold, sans-serif;
                    font-size: 16px;
                    text-transform: uppercase;
                }

                a {
                    text-decoration: none;
                    color: #FFFFFF;
                }
            }
        }

        .popup-footer {
            padding: 12px;
            font-family: Arial, sans-serif;
            font-size: 10px;
            color: #2A3F30;
        }
    }
}

@media screen and (max-width: 767px) {
    .popin-commande-flash-imi-dashboard {
        .popup-base {
            width: 100%;
        }

        .popup-content-container {
            img {
                width: 100%;
            }
        }
    }
}
