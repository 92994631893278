@charset "UTF-8";

/*
* Left col
*/
.covid19 {
    .ph-left-col {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 0 30% 0 0;
        @include bgimg('prehome/ph-bg.jpg', no-repeat, 20%, 50%);
        -webkit-background-size: cover;
        background-size: cover;
    }

    &-title {
        text-transform: uppercase;
        font-weight: bolder;
        margin: 3vh 0 2vh 0;
        text-align: center;
        color: $white;
        text-align: center;
        font-family: bentonsans-book, sans-serif;
        font-size: 28px;
    }

    &-subtitle {
        font-family: bentonsans-book, sans-serif;
        text-transform: uppercase;
        color: $white;
        display: table;
        margin: 0px auto;
        background-color: #f09a04;
        padding: 6px 10px;
        border-radius: 3px;
        font-size: 26px;
    }

    &-connect-btn {
        color: $white;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-family: 'bentonsans-regular', sans-serif;
        background-color: #f09a04;
        padding: 10px 15px;
        display: table;
        margin: 0px auto;
        font-size: 9px;
        text-align: center;
        margin-top: 4vh;
    }

    &-bloc {
        &-container {
            display: flex;
            width: 55%;
            margin: 3vh auto;
        }

        &-img {
            &-1 {
                width: 190px;
                margin: 0px;
            }

            &-2 {
                width: 35px;
                align-self: center;
                z-index: 999;
                margin-left: -20px;
                margin-right: -20px;
            }
        }

        &-content {
            background-color: $white;
            padding: 5rem 1rem 0rem 5rem;

            &-text {
                &-1 {
                    color: $black;
                    font-family: bentonsans-book, sans-serif;
                    font-size: 18px;
                }

                &-2 {
                    color: $black;
                    font-family: bentonsans-book, sans-serif;
                    font-size: 12px;
                    font-weight: bold;
                }
            }
        }
    }

    &-code {
        text-align: center;
        margin-top: 4rem;
        font-size: 12px;
    }

    &-ph-footer {
        background-color: transparent;
        display: flex;
    }

    &-ftr-pink {
        align-self: flex-end;
    }

    /* Mobile header */
    .header-mobile {
        display: none;
        background-color: $white;
        padding: 15px 20px;
    }

    .ph-h1-mobile {
        @include bgimg('mobile/hdr-logo-mobile.png');
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
        width: 150px;
        height: 34px;
        float: left;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .ph-connexion_link-mobile {
        display: block;
        float: right;
        background-color: $pink-base;
        padding: 10px 15px;
        @include border-radius(25px);
        color: $white;
        font-size: 12px;

        .ic-userlog {
            font-size: 25px;
            vertical-align: middle;
            margin: 0 0 0 5px;
        }

        @include hover-focus-active {
            background-color: darken($pink-base, 10%);
            color: $white;
        }
    }

    /*
    * Right col
    */
    .ph-right-col {
        position: absolute;
        right: 0;
        top: 0;
        width: 30%;
        height: 100%;
        background-color: $white;
        color: $grey-light;
        //overflow-y: auto;
        &:before {
            display: block;
            content: "";
            position: absolute;
            left: -13px;
            top: 10%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7.5px 13.0px 7.5px 0;
            border-color: transparent #ffffff transparent transparent;
        }
    }

    .ph-right-col-scrollbox {
        padding: 0 20%;
        overflow: auto;
        height: 100%;

        &.login {
            padding: 0 20%;
            overflow: auto;

            .flash-msg {
                margin: 0 -36%;
            }
        }

        &.register, &.contact, &.mentions-legales {
            padding: 0 10%;

            .flash-msg {
                margin: 0 -13.5%;
            }
        }

        &.resetting-check-email {
            min-height: 0;
        }

        &.resetting-request {
            min-height: 0;
        }

        &[class*="resetting-reset"] {
            min-height: 0;

            .back_link-pillshaped {
                left: -26%;
            }

            .flash-msg {
                margin: 0 -34%;
            }
        }

        &.mentions-legales {
            .chapeau {
                font-size: 14px;
            }
        }
    }

    .ph-footer {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        border: none;
        padding: 0;

        .ftr-pink {
            width: 70%;
            float: left;
        }
    }

    .ph-right-col-footer {
        float: right;
        border: none;
        height: 80px;
        line-height: 40px;
        background-color: $white;
        padding-left: 0;

        ul {
            list-style-type: none;
            text-align: center;

            li {
                display: inline-block;
                margin: 0 10px;

                a {
                    color: $grey-light;
                    @include hover-focus-active {
                        color: $grey-blue;
                    }
                }
            }
        }
    }

    /*
    * Media queries
    */
    @media screen and (max-width: 1000px) {
        .ph-left-col {
            margin: 0 35% 0 0;
        }
        .ph-right-col {
            width: 35%;
        }
        .ph-right-col-scrollbox,
        .ph-right-col-scrollbox.login {
            padding: 0 18%;
        }
    }

    @media screen and (max-width: 767px) {
        .ph-left-col,
        .ph-right-col {
            position: static;
            margin: 0;
        }

        .ph-left-col {
            display: none;

            &.login {
                display: block;
            }
        }

        .ph-right-col {
            width: 100%;
            padding: 0 0 20px 0;
        }

        .header-mobile {
            display: block;
        }

        .ph-right-col-scrollbox.register .flash-msg,
        .ph-right-col-scrollbox.contact .flash-msg {
            margin: 10px -12% !important;
        }

        .ph-right-col-scrollbox.login .flash-msg {
            margin: 10px -28%;
        }

        .ph-footer {
            position: static;
            margin: 0;
            border-top: 3px solid $grey-lighter;
        }

        .ph-right-col-footer {
            float: none;
            width: 100%;
        }
    }

    @media screen and (max-width: 600px) {
        .ph-right-col-scrollbox, .ph-right-col-scrollbox.login {
            padding: 0 10vw;
        }
        .covid19-bloc-container {
            display: none;
        }

        .covid19-ftr-pink {
            height: auto;
        }
    }


    @media (min-width: 768px) and (max-width: 815px) {
        .ph-footer {
            bottom: 80px;
        }
        .ph-right-col-scrollbox {
            height: 85%;
        }
    }

    @media (min-width: 816px) and (max-width: 1747px) {
        .ph-right-col-scrollbox {
            height: 90%;
        }
    }
}

/*
* Media queries
*/
@media (min-width: 1400px) {
    .covid19 {
        &-title {
            margin: 5vh 0 4vh 0;
            font-size: 32px;
        }

        &-subtitle {
            padding: 6px 10px;
            border-radius: 3px;
            font-size: 28px;
        }

        &-connect-btn {
            padding: 10px 15px;
            font-size: 10px;
            margin-top: 4.5vh;
        }

        &-bloc {
            &-container {
                display: flex;
                width: 60%;
                margin: 4vh auto;
            }

            &-img {
                &-1 {
                    width: 210px;
                    margin: 0px;
                }

                &-2 {
                    width: 40px;
                    align-self: center;
                    z-index: 999;
                    margin-left: -20px;
                    margin-right: -20px;
                }
            }

            &-content {
                background-color: $white;
                padding: 7rem 2rem 0rem 7rem;

                &-text {
                    &-1 {
                        color: $black;
                        font-family: bentonsans-book, sans-serif;
                        font-size: 20px;
                    }

                    &-2 {
                        color: $black;
                        font-family: bentonsans-book, sans-serif;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
        }

        &-code {
            text-align: center;
            margin-top: 4rem;
            font-size: 14px;
        }
    }
}
