.block-manage-pharmacy-otc-not-engaged {
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-text {
            font-size: 14px;
            font-family: bentonsans-bold, sans-serif;
            color: #E53838;
        }

        &-icon {
            img {
                margin: unset;
            }
        }
    }

    &-content {
        margin: 10px 0;
        font-size: 12px;
        font-family: bentonsans-regular, sans-serif;
        color: #485E68;
    }

    &-btn {
        text-align: center;

        &-discover {
            background: $blue-base;
            border-radius: 2px;
            padding: 7px 13px;
            font-size: 11px;
            font-family: bentonsans-regular, sans-serif;
            color: #FFFFFF;

            .btn-discover-icon {
                margin-left: 11px;
            }
        }
    }
}
