@font-face {
  font-family: 'simotc-icons';
  src: url('./../fonts/icons/simotc-icons.eot?70172471');
  src: url('./../fonts/icons/simotc-icons.eot?70172471#iefix') format('embedded-opentype'),
       url('./../fonts/icons/simotc-icons.woff2?70172471') format('woff2'),
       url('./../fonts/icons/simotc-icons.woff?70172471') format('woff'),
       url('./../fonts/icons/simotc-icons.ttf?70172471') format('truetype'),
       url('./../fonts/icons/simotc-icons.svg?70172471#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

 [class^="simotc-icon-"]:before, [class*=" simotc-icon-"]:before {
  font-family: "simotc-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  font-size: 120%;
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  //text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}
 
.simotc-icon-tag:before { content: '\e800'; }
.simotc-icon-reset:before { content: '\e801'; }
.simotc-icon-location:before { content: '\e802'; }
.simotc-icon-graph:before { content: '\e803'; }
.simotc-icon-caddie:before { content: '\e804'; }
.simotc-icon-book:before { content: '\e805'; }
