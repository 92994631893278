///************************************************************
// *  @_Project : (Project name)
// *  @_Date : (DD/MM/YY)
// *  @_Author : German Pichardo
// *
// *  ---------------
// *  @_Description :
// *  ---------------
// *
// *  File name
// *
// ************************************************************/
//
///************************************************************
// *  ------------
// *  - Summary -
// *  ------------
// *
// *  @_04 Transition :
// *  @_05 Border-Radius : (left, right, bottom, top)
// *  @_06 Box-Shadow :
// *  @_07 Text-Shadow :
// *  @_08 Opacity :
// *  @_09 : Paddings (left, right, bottom, top)
// *  @_10 : Margins (left, right, bottom, top)
// *  @_11 : Borders (left, right, bottom, top)
// *  @_12 : Font-Size
// *  @_13 : Text-Align
// *  @_14 : Utilities
// *
// ************************************************************/

///************************************************************
// *  @_04 : Transitions
// ************************************************************/

@mixin transition-ease-out($time: 0.2s) {
  -webkit-transition: all $time ease-out;  /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: all $time ease-out;  /* Firefox 4-15 */
  -o-transition: all $time ease-out;  /* Opera 10.50–12.00 */
  transition: all $time ease-out;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}

@mixin transition-ease-in-out($time: 0.2s) {
  -webkit-transition: all $time ease-in-out;  /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: all $time ease-in-out;  /* Firefox 4-15 */
  -o-transition: all $time ease-in-out;  /* Opera 10.50–12.00 */
  transition: all $time ease-in-out;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}

@mixin transition-linear($time: 0.2s) {
  -webkit-transition: all $time linear;  /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: all $time linear;  /* Firefox 4-15 */
  -o-transition: all $time linear;  /* Opera 10.50–12.00 */
  transition: all $time linear;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}

///************************************************************
// *  @_05 : Border-Radius
// ************************************************************/

@mixin border-radius($value) {
  -webkit-border-radius: $value; /* Android ≤ 1.6, iOS 1-3.2, Safari 3-4 */
  border-radius: $value; /* Android 2.1+, Chrome, Firefox 4+, IE 9+, iOS 4+, Opera 10.50+, Safari 5+ */
  -moz-border-radius: $value;
  -o-border-radius: $value;
  -ms-border-radius: $value;
  -webkit-background-clip: padding-box;
}
// Single side border-radius

@mixin border-top-left-radius($value) {
  -webkit-border-top-left-radius: $value;
  border-top-left-radius: $value;
  background-clip: padding-box;
}
@mixin border-top-right-radius($value) {
  -webkit-border-top-right-radius: $value;
  border-top-right-radius: $value;
  background-clip: padding-box;
}
@mixin border-btm-right-radius($value) {
  -webkit-border-bottom-right-radius: $value;
  border-bottom-right-radius: $value;
  background-clip: padding-box;
}
@mixin border-btm-left-radius($value) {
  -webkit-border-bottom-left-radius: $value;
  border-bottom-left-radius: $value;
  background-clip: padding-box;
}

///************************************************************
// *  @_06 : Box-Shadow
// ************************************************************/

@mixin box-shadow($value) {
  -webkit-box-shadow: $value !important; /* Android 2.3+, iOS 4.0.2-4.2, Safari 3-4 */
  box-shadow: $value !important; /* Chrome 6+, Firefox 4+, IE 9+, iOS 5+, Opera 10.50+ */
  -moz-box-shadow: $value !important;
}

///************************************************************
// *  @_07 : Text-Shadow
// ************************************************************/

@mixin text-shadow($x: 0, $y: 1px, $blur: 5px, $color: rgba(0,0,0,.4)){
  text-shadow: $x $y $blur $color;
  //text-shadow:white($opacity) 0 1px 0;
}

///************************************************************
// *  @_08 : Opacity
// ************************************************************/

@mixin opacity($value){
  $valueIE:($value*100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$valueIE})"; /* IE 8 */
  filter: alpha(opacity=$valueIE); /* IE 5-7 */
  -moz-opacity: $value; /* Netscape */
  -khtml-opacity: $value; /* Safari 1.x */
  opacity: $value; /* Good browsers */
}

///************************************************************
// *  @_11 : Borders (left, right, bottom, top)
// ************************************************************/
@mixin border($border-width: 1px , $border-style: solid , $border-color: red){
  border: $border-width $border-style $border-color;
}
@mixin border-left($border-width: 1px , $border-style: solid , $border-color: red){
  border-left: $border-width $border-style $border-color;
}
@mixin border-right($border-width: 1px , $border-style: solid , $border-color: red){
  border-right: $border-width $border-style $border-color;
}
@mixin border-bottom($border-width: 1px , $border-style: solid , $border-color: red){
  border-bottom: $border-width $border-style $border-color;
}
@mixin border-top($border-width: 1px , $border-style: solid , $border-color: red){
  border-top: $border-width $border-style $border-color;
}

/************************************************************
 *  @_12 : Font-Size
 ************************************************************/

@mixin font-size($value){
  font-size: $value;
}

///************************************************************
// *  @_14 : Utilities
// ************************************************************/

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin list-style {
  margin: 0;
  padding: 0;
  list-style: none;
}

///************************************************************
//*  @_15 : Responsive Media queries
//************************************************************/
@mixin responsive($min: 0, $max: 0) {
  $type: type-of($min);

  @if $type == string {
    $class: $min;

    @if $class == xs {
      @media (max-width: 767px) { @content; }
    }

    @else if $class == sm {
      @media (min-width: 768px) { @content; }
    }

    @else if $class == md {
      @media (min-width: 992px) { @content; }
    }

    @else if $class == lg {
      @media (min-width: 1200px) { @content; }
    }

    @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
  }
  @else if $type == number {
    $query: "all" !default;
    @if $min != 0 and $max != 0 {
      $query: "(min-width: #{$min}) and (max-width: #{$max})";
    }
    @else if $min != 0 and $max == 0 {
      $query: "(min-width: #{$min})";
    }
    @else if $min == 0 and $max != 0 {
      $query: "(max-width: #{$max})";
    }
    @media #{$query} {
      @content;
    }
  }
}
// EXAMPLES:

// @include responsive($min: 321px, $max: 479px) {
// 	background-color: red;
// }

// aside.primary {
//   @include responsive(md) {
//     float: right;
//     width: 350px;
//   }
//   @include responsive(480px) {
//     display: none;
//   }
//   @include responsive($min: 640px, $max: 767px) {
//     text-align: center;
//     font-style: italic;
//   }
// }

///************************************************************
// *  @_18 : Various
// ************************************************************/

@mixin hover-focus-active {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

@mixin bgimg($img, $repeat: no-repeat, $left: left, $top: top){
  background: url('./../img/#{$img}') $repeat $left $top;
}

@mixin rnd_square_ic($border-width: 2px, $border-color: $black, $radius: 8px){
  @include border-radius($radius);
  display: block;
  width:22px;
  height:22px;
  border: #{$border-width} solid #{$border-color};
}

@mixin placeholder{
  &::-webkit-input-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::placeholder { @content; }
  &::-moz-placeholder { @content; }
}

/* Bold border for listing rows */
@mixin row-border-style($topcolor: $blue-base, $btmcolor:$grey-blue){
    content:"";
    display: block;
    width:4px;
    height:100%;
    position: absolute;
    left:0;
    top:0;
    z-index:1;
    @include border-top-left-radius(5px);
    @include border-btm-left-radius(5px);
    @include gradient-vertical($topcolor, $btmcolor);
}

/* Hover effect on buttons */
@mixin btnHoverEffect(){
    height:auto;
    & > .hoverEffect {
        z-index: 2;
        display: block;
        position: relative;
        left:0;
        top:0;
        width: 100%;
        height: 100%;
    }
    &::before {
        @include transition-ease-in-out();
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        -webkit-transform: scale(0.5, 1);
        transform: scale(0.5, 1);
        background-color: lighten($grey-blue, 10%);
    }
    &:hover::before{
        opacity: 1;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
