@charset "UTF-8";

.page.formations .main-content h1 > [class^="ic-"] {
    padding-left: 35px;
}

.page.formations .container {
    max-width: 100%;
}

.containerCategory {
    margin-bottom: 40px;
    border: 1px solid $grey-bodybg;
    background-color: $grey-catabg;

    header {
        padding: 20px;
        height: 230px;
        position: relative;
        transition: all 0.4s ease;
        h2 {
            font-size: 25px;
            line-height: 28px;
            text-transform: uppercase;
            font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
            span {
                display: block;
                color: $white;
            }
        }
        hr {
            border-color: $white;
            position: relative;
            &::before{
                position: absolute;
                left: 0;
                top: -1px;
                height: 3px;
                width: 10%;
                content: " ";
                display: block;
            }
        }

        p.chapo {
            font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
            font-size: 15px;
            line-height: 23px;
            color: $white;
            text-align: left;
        }
    }
}

.formations {
    .bx-wrapper {
        background: transparent none repeat scroll 0 0;
        border: 0 none;
        box-shadow: 0 0 0 transparent;
    }
}

.containerFormation {
    height: 535px;
    padding: 15px;
    //width: 100%;
   margin-right: 30px;
    float: left;
    max-width: 905px;
}

article.formation {
    float: left;
    width: 275px;
    height: 500px;
    display: table;
    position: relative;
    margin: 5px auto;
    table-layout: fixed;
    border: 0 none;
    background-color: $white;
    //box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
    box-shadow: 3px 0px 10px rgba(138, 166, 266, 0.3);
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-collapse: collapse;
    > div {
        text-align: center;
        position: relative;
        //display: table-row;
    }
    .headerFormation {
        height: 92px;
        h3 {
            text-align: left;
            padding: 20px 42px 20px 20px;
            margin:0;
            font-size: 15px;
            line-height: 13px;
            font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
            color: $grey-blue;
            span {
                display: block;
                font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
                font-size: 19px;
                line-height: 20px;
                margin-top: 6px;
            }
        }
        > span {
            width: 26px;
            height: 26px;
            display: block;
            background-position: center center;
            background-size: 26px 26px;
            background-repeat: no-repeat;
            position: absolute;
            top: 3px;
            right: 10px;
        }
    }
    .descFormation {
        text-align: left;
        height:218px;
        border-top: 1px solid $grey-really-lighter;
        border-bottom: 1px solid $grey-really-lighter;
        color: $grey-blue-light;
        p {
            margin: 15px 20px;
            font-size: 13px;
            line-height: 21px;
            font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
        }
    }
    .footerFormation {
        height:190px;
        > div {
            position: relative;
        }
        .nouscontacter-upper-row{
            padding:0 13px;
            >div{
                width:50%;
            }
            .nouscontacter-icon{
                span{
                    display: inline-block;
                    vertical-align: middle;
                }
                &:first-child{
                    float: left;
                    text-align: left;
                }
                &:last-child{
                    float: right;
                    text-align: right;
                }
            }
        }
        div.duo, div.carre {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            height:148px;
            > div {
                justify-content: center;
                flex-direction: column;
                flex: 1 1 50%;
                -ms-flex: 0 0 42%;
                padding:10px;
                height: 74px;
                text-align: left;
                vertical-align: middle;
                .icon {
                    width: 58px;
                    margin:auto;
                    &.icon-label-essec,
                    &.icon-label-cnop {
                        width: 120px;
                    }
                    &.icon-contact {
                        width: 26px;
                    }
                }
                span {
                    display: table-cell;
                    vertical-align: middle;
                    line-height:1em;
                    font-size:10px;
                }
                color: $grey-blue-light2;
                font-size: 11px;
                line-height: 17px;
                font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
            }
        }

        div.duo {
            display: table;
            table-layout: fixed;
            width:100%;
            >div{
                display: table-cell;
                vertical-align: middle;
                width:50%;
                height:100%;
                .icon+span{
                    display: block;
                    text-align: center;
                }
                .icon{
                    margin:auto;
                    display: block;
                }
            }
            > div:nth-child(1) {
                border-right: 1px dotted $black;
            }
            &.nostripes {
                > div:nth-child(1) {
                    border-right: 0 none;
                }
            }
            &.contactBloc{
                height:auto;
            }
        }
        div.carre {
            > div:nth-child(1) {
                border-right: 1px dotted $black;
            }
            > div:nth-child(3) {
                border-right: 1px dotted $black;
                border-top: 1px dotted $black;
            }
            > div:nth-child(4) {
                border-top: 1px dotted $black;
            }
        }

        div.contact {
            margin:0 10px 10px 10px;
            border-radius: 5px;
            padding: 5px;
            display: block;
            .duo > div {
                padding:0 10px;
                height: auto;
                span {
                    display: block;
                    margin:0 0 5px 0;
                }
                &:nth-child(1){
                    width:35%;
                }
                &:nth-child(2){
                    width:65%;
                }
            }

            h4, h5 {
                text-transform: uppercase;
                color: $grey-blue;
                font-size: 19px;
                line-height: 22px;
                margin: 0;
                font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
            }
            h6 {
                margin: 0 0 10px;
                font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
                font-size: 12px;
                line-height: 20px;
                span {
                    font-size: inherit;
                    line-height: 16px;
                    display: block;
                    &.small {
                        color: $grey-light;
                        font-size: 9px;
                        line-height: 13px;
                        margin-top: -7px;
                    }
                }

            }
        }
    }
    .extraFormation {
        height: 42px;
        a.hc-block-footerlink {
            height: 42px;
            line-height: 42px;
            padding: 0;
            width: 100%;
            .hoverEffect {
                line-height: 42px;
            }
        }
    }

    .icon {
        width: 48px;
        height: 45px;
        margin:13px 0;
        display: inline-block;
        background-size: 48px 45px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center center;
        &.icon-label-cnop {
            width: 120px;
            height: 70px;
            background-size: 120px 70px;
            background-image: url('./../img/formations/label_cnop.png');
        }
        &.icon-label-essec {
            width: 120px;
            height: 70px;
            background-size: 120px 70px;
            background-image: url('./../img/formations/label_essec.png');
        }
        &.icon-contact {
            background-size: 26px 31px;
            width: 26px;
            height: 31px;
        }
        &.icon-formation-top {
            background-size: 26px 26px;
            width: 26px;
            height: 26px;
        }
    }
}

.page-formation-details .main-content {
    overflow: hidden;
}

.formation-detail {
    .intro {
        i.ic-userprofile {
            font-size: 24px;
            border: 1px solid $blue-dark2;
            border-radius: 15px;
            color: $blue-dark2;
            margin-right: 10px;
            margin-top: 4px;
        }
        .all-team-member {
          margin-top: 10px;
            font-size: 12px;
          padding-right: 20px;
        }
        h2{
          color: $grey-blue-light3;
          font-size: 29px;
          line-height: 40px;
        }
        h3 {
            color: $blue-base2;
            text-transform: uppercase;
            font-size: 14px;
            position: relative;
            margin-top: 40px;
            margin-bottom: 20px;
            font-family: 'bentonsans-condensedregular', sans-serif;
            letter-spacing: 1px;

            &::before {
                display: block;
                width: 26px;
                height: 1px;
                background-color: $blue-base2;
                content: '';
                position: absolute;
                top: 5px;
                left: -35px;
            }
        }
        .formation-detail-objectif{
            line-height:1.8em;
          padding-right: 20px;
            & +  ul{
                list-style: none;
                li{
                    padding-left: 40px;
                    position: relative;
                    &:before{
                        display: block;
                        position: absolute;
                        content: "\e91e";
                        font-family: 'icons' !important;
                        color: $blue-base2;
                        top: 0;
                        left: 0;
                    }
                }
            }

        }
        /* Video slider */
        .videoslider-container{
            margin:0 0 70px 0;
            img{
                display: none;
                margin:0;
            }
            .bx-wrapper{
                padding:0;
                #videoslider{
                    img{
                        width:100%;
                    }
                }
            }
            .bx-wrapper-video-slider-formation{
                margin-top: -17px;
                height: 120px;
                .bx-viewport{
                    height: 120px;
                }
                .bx-controls{
                    display: block;
                    .bx-prev{
                        left: -32px;
                        background-color: #4e8be5;
                        width: 31px;
                        height: 60px;
                        margin-top: -30px;
                        z-index: 9990;
                    }
                    .bx-next{
                        right: -32px;
                        background-color: #4e8be5;
                        width: 31px;
                        height: 60px;
                        margin-top: -30px;
                        z-index: 9990;
                    }

                    .bx-prev.disabled,.bx-next.disabled{
                        display: block;
                        cursor:not-allowed;
                        background-color: $grey-really-lighter;
                    }
                }
            }
            .bx-controls{
                display: none;
            }
            #videoslider-nav{
                margin:1px 0 0 0;
                display: table;
                width: 100%;
                a{
                    display:table-cell;
                    vertical-align: middle;
                    width:33%;
                    border:1px solid #e9e9e9;
                    border-top: 4px solid $pink_base ;
                    position: relative;
                    margin:0;
                    padding:13px 15px 50px 15px;
                    background: url('./../img/videoslider_icplay_off.png') 50% 90% no-repeat;
                    -webkit-background-size: 26px auto;
                    background-size: 26px auto;
                    text-align: center;
                    background-color: $white;
                    height: 110px;
                    font-size: 13px;
                    &:last-child{
                        margin:0;
                    }
                    &.active, &:hover, &:focus, &:active{
                        border-top-color: $blue-base2;
                        background-image: url('./../img/videoslider_icplay_on.png');
                        &::before{
                            content:"";
                            display: block;
                            position: absolute;
                            left:50%;
                            top:0;
                            width: 0;
                            height: 0;
                            margin:0 0 0 -7px;
                            border-style: solid;
                            border-width: 14px 9px 0 9px;
                            border-color: $blue-base2 transparent transparent transparent;
                        }
                    }
                }

            }
        }
    }

    .video-thumbnail {
        margin-top: 117px;

        img {
            max-width: 100%;
            height: auto;
          margin-bottom: 70px;
        }
    }

    .modules .title {
        font-size: 24px;
        color: $grey-blue2;
    }

    .modules-list-container {
        margin: 1em -500em;
        padding: 1em 500em;
        background-color: $blue-light3;
        &.module-bilan-medication{
            ol.modules-list li.module{
                span.number{
                    display: none;
                }
                .moduleKeys{
                    height: auto;
                    margin: 0 0 30px;
                    li{
                        line-height: 19px;
                        padding: 0 15px
                    }
                }
                .formation-detail-footer{
                    position: relative;
                    left: unset;
                    bottom: unset;
                    height: auto;
                    padding: 0 15px;
                    .catchphrase{
                        margin-top: 35px;
                        display: block;

                        a.hc-block-footerlink{
                            position: relative;
                            background: $blue-base2;
                            color: $white;
                            padding: 12px 15px;
                            &::before{
                                background: darken($blue-base2,15%);
                            }
                        }
                    }
                }
                 &::after{
                     display: none;
                 }
            }
        }
        &.module-bilan-medication-withoutnumber{
            ol.modules-list li.module{
                span.number{
                    display: none;
                }
                .moduleKeys{
                    height: auto;
                    margin: 0 0 30px;
                    li{
                        line-height: 19px;
                        padding: 0 15px
                    }
                }
                .formation-detail-footer{
                    position: relative;
                    left: unset;
                    bottom: unset;
                    height: auto;
                    padding: 0 15px;
                    .catchphrase{
                        margin-top: 35px;
                        display: block;

                        a.hc-block-footerlink{
                            position: relative;
                            background: $blue-base2;
                            color: $white;
                            padding: 12px 15px;
                            &::before{
                                background: darken($blue-base2,15%);
                            }
                        }
                    }
                }

                .catchpictureAllWidth{
                    width: 278px;
                    margin-left:-15px;
                    img {
                        width:100%;
                    }
                }
            }
        }

        .bx-wrapper {
            padding: 0;
            margin: 0 auto;

            .bx-viewport {
                // height: 370px !important;
            }

            .bx-controls-direction {
                a.bx-prev, a.bx-next {
                    background-color: $blue-base2;
                    width: 45px;
                    height: 60px;
                    margin-top: -30px;
                    z-index:9990;
                    &.disabled{
                        display: none;
                    }
                }
                a.bx-prev {
                    left: -45px;
                }
                a.bx-next {
                    right: -45px;
                }
            }
        }
    }

    ol.modules-list {
        list-style: none;
        display: block;

        li.module {
            height: 395px;
            text-align: center;
            padding: 15px;
            display: inline-block;
            background-color: $white;
            &::after {
                display: block;
                position: absolute;
                top: 50%;
                margin-top: -10px;
                right: -20px;
                content: '';
                width: 20px;
                height: 16px;
                background: url('./../img/formations/slider_bullet.png');
            }
            &:last-child::after {
                display: none;
            }

            h4 {
                color: $grey-blue2;
                padding: 10px 0 20px;
                font-weight: bold;
                //height: 60px;
                margin: 0;
                font-size: 18px;
                line-height:25px;
            }

            span.number {
                line-height: 26px;
                display: block;
                padding-top: 10px;
                font-size: 18px;
                font-family: 'bentonsans-ultracondensed', sans-serif;
            }

            div.arrow-separator {
                margin: 15px -15px;
                border-bottom: 1px solid $grey-lighter;
            }

            .moduleKeys {
                list-style: none;
                height: 120px;
                li{
                    margin:0 0 6px 0;
                    font-size:13px;
                }
            }
            .formation-detail-footer{
                position: absolute;
                left:0;
                bottom:0;
                height:154px;
                padding:0 15px;
                width:100%;
            }

            .arrow-separator {
                position: relative;
            }
            i.ic-ul-arrow {
                top: -15px;
                left: 50%;
                margin-left: -15px;
                position: absolute;
                padding: 5px;
                font-size: 22px;
                transform: rotate(90deg);
                display: inline-block;
                background-color: $blue-base2;
                border-radius: 20px;
                color: $white;
            }

            .catchphrase {
                font-size: 13px;
                p{
                    line-height: 26px;
                    margin:0;
                }
            }

            .hc-block-footerlink{
                width:100%;
            }

            &.color-blue {
                border-bottom: 3px solid $blue-dark;

                span.number, .moduleKeys {
                    color: $blue-base2;
                }
                i.ic-ul-arrow {
                    background-color: $blue-base2;
                }
            }
            &.color-blue2 {
                border-bottom: 3px solid $blue-base;

                span.number, .moduleKeys {
                    color: $blue-base;
                }
                i.ic-ul-arrow {
                    background-color: $blue-base;
                }
            }
            &.color-green {
                border-bottom: 3px solid $green-base;

                span.number, .moduleKeys {
                    color: $green-base;
                }
                i.ic-ul-arrow {
                    background-color: $green-base;
                }
            }
            &.color-orange {
                border-bottom: 3px solid $orange-base;

                span.number, .moduleKeys {
                    color: $orange-base;
                }
                i.ic-ul-arrow {
                    background-color: $orange-base;
                }
            }
            &.color-pink {
                border-bottom: 3px solid $pink-base;

                span.number, .moduleKeys {
                    color: $pink-base;
                }
                i.ic-ul-arrow {
                    background-color: $pink-base;
                }
            }
            &.color-purple {
                border-bottom: 3px solid $purple-base;

                span.number, .moduleKeys {
                    color: $purple-base;
                }
                i.ic-ul-arrow {
                    background-color: $purple-base;
                }
            }
        }
    }

    .keyNumber {
        margin: 40px 0 20px 0;
        border: 1px solid $grey-medium;
        font-size: 22px;
        color: $blue-base2;
        padding: 30px 70px 30px 0;

        p:first-child {
            line-height: 30px;
        }
        p:last-child{
            margin:0;
        }

        .small {
            font-size: 12px;
            color: $grey-medium;
        }

        .graph-container {
            position: relative;
            padding: 0;
            canvas{
                margin:auto;// auto auto 30px;
            }
            .percent {
                position: absolute;
                left: 0px;
                top: 65px;
                width:100%;
                text-align: center;
            }
        }
    }

    .gotoformation {
        text-align: center;

        .ic-ul-arrow {
            background-color: $pink-base-light;
            padding: 5px;
            margin-right: 10px;
            color: $pink-base;
            font-size: 16px;
            font-weight: bold;
        }
    }
    a.btn-pink {
        background-color: $pink-base;
        border: none;
        font-size: 12px;
        padding: 20px;
        margin: 30px auto;
        display: inline-block;
    }

    div.scliform {
        color:inherit;

        p.title {
            padding-top:10px;
            font-size: 19px;
        }

        .lineTitle{
            font-family: 'bentonsans-ultracondensed';
            font-size: 19px;
            font-weight: normal;
            font-style: normal;
            font-stretch: ultra-condensed;
            line-height: 1.16;
            letter-spacing: normal;
            text-align: left;
            color: #485e68;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        span{
            color: $blue-base;
        }

        div.scliform-block-line {
            display:flex;
            align-items: center;
        }

        div.scliform-block {
            display:flex;
            align-items: center;
            width:400px;
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 0.3);
            border: solid 1px #e6eaee;

            + div {
                width:50px;
            }

            a {
                color:inherit;
            }

            div {
                padding:10px;
            }
        }

        div.Bg_bloc {
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 0.3);
            border: solid 1px #e6eaee;

            > div {
                padding:10px;
            }
        }

        div.height105{
            min-height:105px;
        }

        div.height168{
            height:168px;
        }


        div.dashed {
            border-left: 2px dotted $gray-lighter;
            vertical-align: center;
            height:95px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        div.dashed small{
            color: $gray-light;
        }

        ul{
            list-style: none;
            li{
                &::before{
                    //content: "→";
                    content: "\2192";
                    padding-right: 10px;
                    font-weight: bold;
                }
            }
        }

        span.dl {
            font-family: 'bentonsans-regular';
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 1px;
            text-align: left;
            color: $blue-link;
            text-transform:uppercase;
        }
    }
}

.formations {
    .bx-wrapper {
        margin:auto;
        padding: 0 50px 15px 35px;
        .bx-controls-direction a {
            height: 42px;
            background-size: 19px 12px;
            transition: all 0.4s ease;
            &.bx-prev {
                background: url('./../img/formations/bx_prev_2x.png') no-repeat 60% center / 19px 12px;
                &:hover, &:active, &:focus {
                    background-position: 40% center;
                }
            }
            &.bx-next {
                background: url('./../img/formations/bx_next_2x.png') no-repeat 40% center / 19px 12px;
                &:hover, &:active, &:focus {
                    background-position: 60% center;
                }
            }
        }
        .bx-pager{
            left:0;
            a{
              margin: 0 15px;
              padding: 6px;
            }
        }
    }
}

///*************************************
//* Variantes couleurs
//*************************************/
.containerCategory {
    //-------- GREEN
    &.green {
        header {
            background: url('./../img/formations/bg_header_green_2x.png') no-repeat 98% center;
            background-color: $green-formation;
            background-size: 270px 160px;

            .hc-block-roundedsquare {
                border-color: $green-formation-light;
            }

            h2 {
                color: $green-formation-light;
                text-align: left;

            }
            hr{
                border-color: $green-formation-dark;
                &::before{
                    background-color: $green-formation-light;
                }
            }

        }
        article.formation {
            .headerFormation {
                h3 {
                    span {
                        color: $green-formation;
                    }
                }
                > span {
                    background-image: url('./../img/formations/formation_top_green_2x.png');
                }
            }
            .descFormation {
                background-color: $green-formation-light-bg2;
            }
            .footerFormation {
                .contact {
                    border: 1px solid $green-formation-light-bg;
                }
                h5 {
                    color: $green-formation;
                }
                h6 {
                    span, a {
                        color: $green-formation;
                    }
                }
                div.carre, div.duo {
                    > div{
                        border-color: $green-formation-light-bg;
                    }
                }
            }
        }

        /* icons */
        .icon {
            &.icon-guide {
                background-image: url('./../img/formations/guide_green_2x.png');
            }
            &.icon-idea {
                background-image: url('./../img/formations/idea_green_2x.png');
            }
            &.icon-network {
                background-image: url('./../img/formations/network_green_2x.png');
            }
            &.icon-pencil {
                background-image: url('./../img/formations/pencil_green_2x.png');
            }
            &.icon-quiz {
                background-image: url('./../img/formations/quiz_green_2x.png');
            }
            &.icon-speech {
                background-image: url('./../img/formations/speech_green_2x.png');
            }
            &.icon-video {
                background-image: url('./../img/formations/video_green_2x.png');
            }
            &.icon-workshop {
                background-image: url('./../img/formations/workshop_green_2x.png');
            }
            &.icon-contact {
                background-image: url('./../img/formations/contact_green_2x.png');
            }
            &.icon-formation-top {
                background-image: url('./../img/formations/formation_top_green_2x.png');
            }
            &.icon-plateforme {
                background-image: url('./../img/formations/plateforme_green_2x.png');
            }
            &.icon-kit {
                background-image: url('./../img/formations/kit_green_2x.png');
            }
            &.icon-outils {
                background-image: url('./../img/formations/outils_green_2x.png');
            }
        }

        .bx-wrapper {
            .bx-controls-direction a {
                background-color: $green-formation-slider;
                &:hover, &:active, &:focus {
                    background-color: darken($green-formation-slider, 15%);

                }
            }
            .bx-pager {
                a {
                    background-color: $green-formation;
                    &:hover, &:active, &:focus, &.active {
                        background-color: $green-formation-slider;
                    }
                }
            }
        }
    }
    //-------- INDIGO
    &.indigo {
        header {
            background: url('./../img/formations/bg_header_indigo_2x.png') no-repeat 98% center;
            background-color: $indigo-formation;
            background-size: 270px 160px;

            .hc-block-roundedsquare {
                border-color: $indigo-formation-light;
            }

            h2 {
                color: $indigo-formation-light;
                text-align: left;
            }
            hr{
              border-color: $indigo-formation-dark;
              &::before{
                  background-color: $indigo-formation-light;
              }
            }

        }
        article.formation {
            .headerFormation {
                h3 {
                    span {
                        color: $indigo-formation;
                    }
                }
                > span {
                    background-image: url('./../img/formations/formation_top_indigo_2x.png');
                }
            }
            .descFormation {
                background-color: $indigo-formation-light-bg2;
            }
            .footerFormation {

                .contact {

                    border: 1px solid $indigo-formation-light-bg;
                }
                h5 {
                    color: $indigo-formation;
                }
                h6 {
                    span, a {
                        color: $indigo-formation;
                    }
                }
                div.carre, div.duo {
                    > div{
                        border-color: $indigo-formation-light-bg;
                    }
                }
            }
        }

        /* icons */
        .icon {
            &.icon-guide {
                background-image: url('./../img/formations/guide_indigo_2x.png');
            }
            &.icon-idea {
                background-image: url('./../img/formations/idea_indigo_2x.png');
            }
            &.icon-network {
                background-image: url('./../img/formations/network_indigo_2x.png');
            }
            &.icon-pencil {
                background-image: url('./../img/formations/pencil_indigo_2x.png');
            }
            &.icon-quiz {
                background-image: url('./../img/formations/quiz_indigo_2x.png');
            }
            &.icon-speech {
                background-image: url('./../img/formations/speech_indigo_2x.png');
            }
            &.icon-video {
                background-image: url('./../img/formations/video_indigo_2x.png');
            }
            &.icon-workshop {
                background-image: url('./../img/formations/workshop_indigo_2x.png');
            }
            &.icon-contact {
                background-image: url('./../img/formations/contact_indigo_2x.png');
            }
            &.icon-formation-top {
                background-image: url('./../img/formations/formation_top_indigo_2x.png');
            }
            &.icon-plateforme {
                background-image: url('./../img/formations/plateforme_indigo_2x.png');
            }
            &.icon-kit {
                background-image: url('./../img/formations/kit_indigo_2x.png');
            }
            &.icon-outils {
                background-image: url('./../img/formations/outils_indigo_2x.png');
            }
        }

        .bx-wrapper {
            .bx-controls-direction a {
                background-color: $indigo-formation-slider;
                &:hover, &:active, &:focus {
                    background-color: darken($indigo-formation-slider, 15%);

                }
            }
            .bx-pager {
                a {
                    background-color: $indigo-formation;
                    &:hover, &:active, &:focus, &.active {
                        background-color: $indigo-formation-slider;
                    }
                }
            }
        }
    }
}

///*************************************
//* Media queries
//*************************************/

@media screen and (max-width: 1200px) {
    .formation-detail .modules-list-container {
        .bx-wrapper {
            .bx-controls-direction {
                a.bx-prev {
                    left: 0;
                }
                a.bx-next {
                    right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .containerCategory {
        header {
            height: 380px;
            p.chapo {
                margin-top: 270px;
            }
        }

        &.green {
            header {
                background-position: center 110px;
            }
            .listFormationsCategory, .bx-wrapper {
                background-color: $green-formation;
            }
            .listFormationsCategory{
                text-align: center;
                .containerFormation{
                    display: inline-block;
                    float: none;
                }
            }
        }
        &.indigo {
            header {
                background-position: center 110px;
            }
            .listFormationsCategory, .bx-wrapper {
                background-color: $indigo-formation;
            }
        }
    }
    .formation-detail .keyNumber{
        padding:10px 0;
    }
    .graph-container{
        margin: 0 0 15px 0;
        + [class^="col-"]{
            text-align: center;
        }
    }
}

@media screen and (max-width: 767px) {
    .page.formations .main-content h1 > [class^="ic-"] {
        padding-left: 0;
    }

    .containerCategory {
        header {
            text-align: left;
            height:auto;
            padding-bottom:0;
            p.chapo {
                margin-top: 160px;
            }
        }
    }

    .formations {
        .bx-controls-direction {
            display: none;
        }
    }

    .formation-detail {
        .intro {
            h3::before {
                bottom: -5px;
                top: inherit;
                left: 0;
            }
        }

        .video-thumbnail {
            margin-top: 30px;
        }

        ol.modules-list li.module {
            margin: 10px 30px;
            &::after {
                display: none;
            }
        }

        .keyNumber {
            border: none;
            text-align: center;
            margin: 10px 0;
            padding: 0 20px;

            .graph-container {
                margin-bottom: 30px;
                canvas{
                  margin: auto;
                }
                .percent {
                    top: 65px;
                    left: 5px;
                }
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .formation-detail .intro .videoslider-container #videoslider-nav a{
        font-size:12px;
    }
}
@media screen and (max-width: 440px) {
    .formations .bx-wrapper{
        padding:0 0 15px 0;
    }
}
