@charset "UTF-8";

$border-grey: lighten($grey-lighter, 10%);
$purple-base-light: lighten($purple-base, 10%);
$purple-base-light2: #bd76f4;
$purple-base-light3: #c49bf2;

.catalogue_content {
    background-color: $grey-catabg;
}

.catalogue_content-rightcol-wrapper {
    float: left;

}

.catalogue-breadcrumb {
    border-color: #e9e9e9;
    background-color: $grey-catabg;
}

.cata_filters {
    display: table;
}

.catalogue_content-leftcol {
    background-color: $white;
    padding: 40px 20px;
    min-width: 25%;
    display: table-cell;
    float: none;
}

.catalogue_content-rightcol {
    padding: 40px 20px;
    border: none;
    background-color: $grey-catabg;
    width: 75%;
    max-width: none;
    display: table-cell;
    float: none;
}

.catalogue_content-rightcol-inside {
    max-width: 820px; //900px;
    //position: relative;
}

/******** Champ de recherche */
.catalogue_content-searchfield {
    margin: 0 0 20px 0;

    input[type="text"] {
        @include border-btm-left-radius(5px);
        @include border-top-left-radius(5px);
        padding: 25px 30px;
        border: 1px solid #dedddc;
        width: 85%;
        height: 67px;
        font-size: 12px;
    }

    button {
        @include border-btm-right-radius(5px);
        @include border-top-right-radius(5px);
        width: 15%;
        height: 67px;
        line-height: 67px;
        float: right;
        color: $white;
        background-color: $purple-base;
        font-size: 21px;
    }
}

.clearable {
    background: #fff url(http://i.stack.imgur.com/mJotv.gif) no-repeat right -10px center;
    transition: background 0.4s;
}

.clearable.x {
    background-position: right 30px center;
}

/* (jQ) Show icon */
.clearable.onX {
    cursor: pointer;
}

/* (jQ) hover cursor style */
.clearable::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

/* Remove IE default X */
.main-searchfield {
    position: relative;
    -moz-box-shadow: 0px 0px 20px 0px #e3e1e1;
    -webkit-box-shadow: 0px 0px 20px 0px #e3e1e1;
    -o-box-shadow: 0px 0px 20px 0px #e3e1e1;
    box-shadow: 0px 0px 20px 0px #e3e1e1;
}

/******** Listing */
body.pharmacien-catalogue {
    .contratlist-filter-label {
        display: inline-block;
    }

    .selectric-wrapper {
        margin: 0 0 0 15px;
    }
}

.productlist-container {
    margin: 0 0 20px 0;
}

div.catalogue-row_wrapper {
    display: table-row-group;

    &:nth-of-type(2n-1) .contratlist-row {
        .cell-cata-nom a {
            color: $purple-base;
        }

        li:first-child::before {
            @include row-border-style($purple-base-light, $purple-base);
        }
    }

    &:nth-of-type(2n) .contratlist-row {
        .cell-cata-nom a {
            color: $purple-base-light3;
        }

        li:first-child::before {
            @include row-border-style($purple-base-light2, $purple-base-light3);
        }
    }

    li:last-child {
        border-left: none;
    }
}

.contratlist-row {
    .cell-cata-pic {

        img {
            max-width: 83px;
            margin: 0 15px;
        }
    }

    .cell-cata-nom {
        text-align: left;
        font-family: 'bentonsans-condensedregular', sans-serif;
        font-size: 16px;
        width: 100%;

        .cell-cata-nom-nb {
            font-size: 15px;
            display: block;
        }

        .cell-cata-nom-famille {
            text-transform: uppercase;
            color: $purple-base;
            font-size: 12px;
            display: block;

            &::before {
                content: '';
                width: 9px;
                height: 9px;
                margin-right: 3px;
                display: inline-block;
                background-color: $purple-base;
            }
        }

        sup {
            color: $grey-blue;
        }
    }

    .cell-cata-nom-titre {
        color: $grey-blue;
        text-decoration: underline;
        text-decoration-color: #adb0b1;

        sup {
            text-decoration: none;
        }
    }

    .cell-cata-nom-titre.complement-alimentaire {
        text-decoration: none;
        text-transform: capitalize;
    }

    .call-cata-dosage {
        display: block;
        font-family: 'bentonsans-medium', sans-serif;
        text-decoration: none;
    }

    .cell-cata-forme {
        img {
            display: inline-block;
            width: 70px;
            height: 70px;
            margin: 0 0 5px 0;
        }
    }

    .cell-cata-forme-txt {
        display: block;
    }

    .cell-cata-forme-txt-mobile {
        display: none;
    }

    .cell-cata-spe_ref {
        .contrat-col_content-mobile {
            width: max-content;
        }
    }

    .cell-cata-spe_pfht {
        white-space: nowrap;
    }

    .cell-cata-cip-plus {
        display: none;
    }
}

.product-tag {
    @include border-radius(3px);
    display: block;
    position: absolute;
    left: 15px;
    top: 10px;
    padding: 5px 10px 3px 10px;
    text-transform: uppercase;
    color: $white;
    font-size: 12px;
    font-family: 'bentonsans-medium', sans-serif;
}

.product-nouveaute {
    background-color: $pink-base;

    & + .product-exclu {
        left: 115px;
    }
}

.product-exclu {
    background-color: $green-base;
}

.mobile_clearfloat {
    display: none;
}

/******** Filtres */
.contratlist-filter-col {
    &.catalogue-filter-col-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 30%;

        .selectric {
            .label {
                width: 70px !important;
            }

        }

        .selectric-items {
            text-align: left;
        }
    }

    &.catalogue-filter-col-left {
        float: left;
        width: 70%;
        text-align: left;
    }

    .catalogue-filter-facette {
        @include border-radius(20px);
        padding: 10px 15px;
        margin: 0 5px 5px 0;
        display: inline-block;
        border: 1px solid #c8c7c7;
        font-size: 12px;

        [class^="ic-"] {
            margin: 0 0 0 10px;
            vertical-align: middle;
            color: $purple-base;
            font-weight: bold;
            font-size: 11px;
            cursor: pointer;
        }
    }
}

.switchperso {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.switchperso img {
    margin: 0 5px 0 5px;
}

.catalogue-filter-col-right a > img {
    margin: 0 5px 0 5px;
}

.cata_filters {
    label,
    .icheckbox {
        position: static;
        display: inline-block;
        vertical-align: top;
    }

    label {
        width: 75%;
        margin: 0 0 0 15px;
        font-weight: normal;
        font-size: 13px;
    }

    .form-group {
        margin: 0 0 10px 0;
    }

    fieldset {
        margin: 0 0 25px 0;
    }

    .icheckbox.checked {
        // vertical-align: top;
        @include bgimg('check_mark-black.png');
    }

    .cata_filters-titre {
        color: lighten($grey-light, 5%);
        font-size: 20px;
        line-height: 1.1em;
        margin: 0 0 15px 0;
        letter-spacing: 0.015em;
    }

    .cata_filters-results {
        color: #bf85f3;
        font-size: 12px;
        margin: 0 0 40px 0;
    }

    .cata_filters-soustitre {
        margin: 0 0 15px 0;
        font-size: 17px;
        font-family: 'bentonsans-medium', sans-serif;
        color: $grey-blue;
    }

    .cata_filters-excipients-field {
        input[type="text"] {
            border: 1px solid $grey-lighter;
            @include border-radius(8px);
            padding: 10px 35px 10px 10px;
        }

        [class^="ic"] {
            position: absolute;
            right: 0;
            top: 0;
            padding: 8px;
            font-size: 22px;
            color: $purple-base;
        }
    }

    .cata_filters-excipients-listingwrapper {
        border: 1px solid $grey-lighter;
        @include border-radius(8px);
        padding: 20px 5px;
        overflow: hidden;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;

        .form-group {
            .icheckbox {
                vertical-align: top;
            }

            label {
                font-size: 12px;
                width: 70%;
                vertical-align: top;
            }
        }
    }

    .cata_filters-excipients-listing {
        height: 142px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .cata_filters-condpda-wrapper,
    .cata_filters-conddmdci-wrapper,
    .cata_filters-biogaranconseil-wrapper {
        border: 1px solid $grey-lighter;
        @include border-radius(8px);
        padding-top: 15px;
        padding-left: 5px;
        padding-bottom: 5px;
        padding-right: 10px;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    .cata_filters-condpda-listing,
    .cata_filters-conddmdci-listing,
    .cata_filters-biogaranconseil-listing {
        height: auto;

        .icheckbox {
            width: 13px;
            height: 13px;
            margin-top: 2px;
            margin-left: 7px;

            &.checked {
                background-size: 13px 13px;
            }
        }

        label {
            margin-left: 8px;
        }
    }

    .cata_filters-toggle {
        display: none;
    }

    .tooltip_toggle {
        position: absolute;
        right: 0;
    }

}

// Autocomplete
.autocomplete_container {
    position: absolute;
    left: 0;
    top: 68px;
    z-index: 3;
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: $purple-base;
    padding: 0 15px;
    @include border-btm-left-radius(5px);
    @include border-btm-right-radius(5px);

    dl {
        width: 48%;
        float: left;
        margin-bottom: 0;
        padding: 20px 0;
    }

    dt {
        color: $white;
        border-bottom: 1px solid $purple-base-light3;
        margin: 0 0 10px 0;
        padding: 0 0 5px 0;
    }

    dd {
        padding: 0 0 5px 0;
        font-size: 12px;
        color: $white;
        white-space: nowrap;
        overflow: hidden;

        &:last-child {
            padding-bottom: 0;
        }

        &[class^="autocomplete_"] {
            cursor: pointer;
        }
    }
}

.autocomplete_dci {
    margin: 0 3% 0 0;
}

.product_search-loader {
    position: absolute;
    display: none;
    left: 50%;
    margin-left: -40px;
    top: -22px;
}

.cata_filters-mobile-header {
    display: none;
}

///*************************************
//* Media queries
//*************************************/
@media screen and (max-width: 980px) {
    /******* Listing */
    .cata_filters {
        display: block;
    }
    .mobile_clearfloat {
        display: block;
        clear: both;
    }
    .catalogue_content, .pharmacien-produit, .pharmacien-dashboard {
        .productlist-container {
            .contratlist-empty_row {
                display: none;
            }

            div.catalogue-row_wrapper {
                display: block;

                &:nth-of-type(2n-1) .contratlist-row {
                    li:first-child::before {
                        display: none;
                    }
                }

                &:nth-of-type(2n) .contratlist-row {
                    li:first-child::before {
                        display: none;
                    }
                }

                &:nth-of-type(2n-1) {
                    .contratlist-row::before {
                        @include row-border-style($purple-base-light, $purple-base);
                    }

                    .cell-cata-cip, .cell-qte-facturee {
                        background-color: $purple-base;
                        color: $purple-base-light3;
                    }
                }

                &:nth-of-type(2n) {
                    .contratlist-row::before {
                        @include row-border-style($purple-base-light2, $purple-base-light3);
                    }

                    .cell-cata-cip, .cell-qte-facturee {
                        background-color: $purple-base-light3;
                        color: $purple-base;
                    }
                }
            }

            .contratlist-row {
                margin-bottom: 20px;

                li:first-child.cell-cata-pic {
                    border-top: 1px solid $border-grey;
                    border-right: 1px solid $border-grey;
                }

                li:last-child.cell-cata-cip,
                li:last-child.cell-qte-facturee {
                    @include border-top-right-radius(0);
                    padding: 20px 15px;

                    .contrat-col_title-mobile {
                        width: auto;
                        margin: 0 5px 0 0;
                        color: $white;
                    }
                }

                &:last-child li {
                    border-bottom: none;
                }
            }

            .cell-cata-nom-titre {
                font-size: 18px;
            }

            .cell-cata-pic, .cell-cata-nom {
                height: 85px;
            }

            .cell-cata-nom {
                position: relative;
            }

            .cell-cata-nom-nb {
                position: absolute;
                left: 14px;
                bottom: 0;
                font-size: 16px;
            }

            .cell-cata-qte {
                padding-top: 20px;
                height: 56px;
            }

            .cell-cata-forme-mobile {
                display: inline-block;
            }

            .cell-cata-spe_ref {
                height: 64px;
            }

            .cell-cata-forme {
                img {
                    display: inline-block;
                    margin: 0;
                }
            }

            .cell-cata-forme-txt {
                display: none;
            }

            .cell-cata-forme-txt-mobile {
                display: block;
                color: $grey-medium;
                font-family: 'bentonsans-regular', sans-serif;
                font-size: 12px;
                text-transform: none;
            }

            .cell-cata-colisage {
                padding-bottom: 10px;
            }

            .contrat-col_title-mobile {
                width: 60%;
            }

            .cell-cata-cip-plus {
                @include border-radius(50%);
                color: $white;
                border: 1px solid $white;
                padding: 4px;
                margin: -3px 0 0 0;
                display: inline-block;
                text-align: center;
                float: right;
            }
        }

    }

    /********* Filtres */
    .catalogue_content {
        position: relative;
        padding: 95px 0 0 0;
    }
    .catalogue_content-leftcol {
        float: none;
        display: block;
        width: 100%;
        padding: 0;
        position: relative;
    }
    .catalogue_content-rightcol {
        display: block;
        width: 100%;
        float: none;
        padding: 20px;
    }

    .catalogue_content-searchfield {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px;
        margin: 0;
        background-color: $grey-catabg;
    }
    .cata_filters {
        .cata_filters-titre, .cata_filters-results {
            text-align: center;
        }

        .cata_filters-mobilecol {
            float: left;
            width: 50%;
        }

        .cata_filters-results {
            margin: 0 0 10px 0;
        }

        .cata_filters-toggle {
            display: block;
            width: 20px;
            height: 20px;
            margin: auto;
            @include bgimg('cata_filters-toggle.png');
            -webkit-background-size: 49px 20px;
            background-size: 49px 20px;
        }

        .cata_filters-mobile-header.open .cata_filters-toggle {
            background-position: right top;
        }

        .cata_filters-excipients {
            clear: both;
        }

        .cata_filters-mobile-header {
            cursor: pointer;
            padding: 15px 25px;
            position: relative;
            z-index: 3;
            background-color: $white;
            display: block;
        }

        .cata_filters-header {
            display: none;
        }

        .cata_filters-mobile-content {
            display: none;
            background-color: $white;
            width: 100%;
            padding: 15px 25px;
            position: absolute;
            left: 0;
            z-index: 2;
            -moz-box-shadow: 0px 0px 10px 0px #969696;
            -webkit-box-shadow: 0px 0px 10px 0px #969696;
            -o-box-shadow: 0px 0px 10px 0px #969696;
            box-shadow: 0px 0px 10px 0px #969696;
            filter: progid:DXImageTransform.Microsoft.Shadow(color=#969696, Direction=NaN, Strength=10);
        }

        label:not([for='produit_search_identiquePrinceps']) {
            width: auto;
        }

        .tooltip_toggle {
            position: static;
            margin: 0 0 0 5px;
        }

        .main-searchfield {
            margin: 0;

            .product_search-loader {
                top: -12px;
            }
        }
    }

}

@media screen and (max-width: 767px) {
    .catalogue_content-leftcol {
        display: block;
    }
    .catalogue_content-rightcol {
        width: 100%;
    }

}

@media screen and (max-width: 620px) {
    body.pharmacien-catalogue {
        .contratlist-filter {
            background-color: transparent;
            text-align: right;
            padding: 0;
        }

        .contrat-col_content-mobile {
            text-align: center;
        }
    }
}

@media screen and (max-width: 480px) {
    .catalogue_content-searchfield {
        input[type="text"] {
            width: 80%;
        }

        button {
            width: 20%;
        }
    }
    .autocomplete_container dl {
        float: none;
        width: 100%;
        padding-bottom: 0;
    }
}

