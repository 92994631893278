@charset "UTF-8";

.pharmacien-remis {
    background-color: $grey-bodybg;

    .main-content h1 {

        background-color: $blue-base;
    }

    .breadcrumb span {
        color: $black;
    }

    .outils {
        border: 1px solid $grey-light;
        padding: 10px;
        border-radius: 5px;
        background-color: $white;
        display: table;
        vertical-align: middle;
        height: 140px;
        background-position: 7px  center;
        background-size: auto 120px;
        background-repeat: no-repeat;
        margin: 10px 0;
        width: 100%;

        h4 {
            color: $grey-blue2;
            font-family: 'bentonsans-condensedregular', Arial, Helvetica, sans-serif;
            font-size: 18px;
            font-weight: 100;
            text-transform: none;
            display: table-cell;
            vertical-align: middle;
            padding-left: 40%;
        }

        &.patient-cards {
            h4 {
                color: #485E68;
                font-size: 16px;
                padding-left: 46%;
                text-transform: capitalize;

                span {
                    font-family: 'bentonsans-condensedbold', sans-serif;
                    font-size: 20px;
                }
            }
        }

        &.odd {
            background-color: #f2f9fd;
        }

        &.colored {
            height: 101px;
            background-size: auto 100px;
            background-position: left center;
            border: 0 none;

            h4 {
                color: $white;
                padding-left: 65%;

            }

            &.medirappel {
                background-color: $pink-base;
            }

            &.mup {
                background-color: $blue-base2;
            }

            &.mdma {
                background-color: $green-base;
            }

            &.kelmed {
                background-color: $blue-base;
                height: 220px;
                background-size: auto 180px;

                div {
                    display: table-cell;
                    vertical-align: middle;
                    padding-left: 55%;
                    padding-right: 10px;
                    position: relative;
                }

                h4 {
                    display: block;
                    padding-left: 0;
                    margin-bottom: 5px;
                }

                p {
                    color: #8fd7e6;
                    font-size: 12px;
                    margin-bottom: 10px;
                }

                a {
                    display: block;
                    position: relative;
                    font-size: 9px;
                    margin: 0 auto 10px;
                    color: $white;
                    width: auto;
                    right: unset;
                    bottom: unset;padding: 0 15px;
                    letter-spacing: 1px;
                    &:focus, &:active, &:hover {
                        color: $white;
                    }

                    &.link-store {
                        margin: 0 auto;
                        display: inline-block;
                        width:120px;

                        > img {
                            margin: 0 0 10px 0;
                        }
                    }
                }
            }
        }

        &.contact {
            border-left: 3px solid $blue-base;
            text-align: center;
            min-height:230px;

            > p {
                background: url('./../img/remis/box_icon_2x.png') no-repeat center top;
                background-size: 61px 56px;
                padding-top: 70px;
                width: 70%;
                margin: 10px auto;
                font-size: 15px;
                color: $black;
            }

            > div {
                border: 1px solid $grey-lighter;
                border-radius: 5px;
                width: 100%;
                padding: 0 10px;
                display: table;
                vertical-align: middle;

                h5, p {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: left;
                    padding: 10px;

                    > span, > a {
                        color:$blue-base;
                    }
                }

                h5 {
                    font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
                    color: $grey-blue;
                    font-size: 15px;
                    text-transform: uppercase;
                    min-width: 31%;
                    border-right: 1px dotted $grey-lighter;
                }

                p {
                    font-size: 11px;
                    color: $grey-blue;

                    span.small {
                        color: #aeb6ba;
                        font-size: 10px;
                    }
                }
            }
        }
    }
}

.remis-col {
    width:45%;
    float: left;

    h3 {
        margin:0;
        text-transform: uppercase;
        color: $grey-blue;
        font:17px/1em 'bentonsans-condensedregular', sans-serif;

        span {
            display: block;
            margin:15px 0 0 0;
            text-transform: none;
            color: $grey-light;
            font:13px/1em 'bentonsans-regular', sans-serif;
        }
    }

    .subtitle-with-ic {
        padding:0 0 20px 20px;

        h3 {
            display: inline-block;
            width:75%;
        }

        img {
            float: right;
            margin:0;
            width:56px;
        }
    }
}

.remis-container-with-border {
    padding: 20px;
    margin: 0 0 20px 0;
    border: 1px solid $grey-lighter;
}

.col-tag {
    @include border-radius(5px);
    display: inline-block;
    margin:0 0 20px 0;
    padding: 10px;
    font: normal 25px/28px bentonsans-ultracondensed, sans-serif;
    color: $white;
    text-transform: uppercase;
}

.remis-insidecol {
    position: relative;
    background-color: $white;
    border: 1px solid $grey-lighter;
    padding: 0 20px;
}

.pharmacien-remis .header-with-ic {
    display: table;
    width: (100%);
    border-bottom: 1px solid $grey-lighter;
    margin: 0 0 20px 0px;
    padding: 20px 0;

    .hc-block-title {
        padding-left: 0;
    }
}

/****** Left col ****/
.remis-leftcol {
    margin-right:10px;

    .col-tag {
        background-color: $green-base;
    }

    // H2
    .header-with-ic {
        .header-with-ic-title {
            color:$grey-blue;

            span {
                color: $green-base;
            }
        }

        .header-with-ic-legend {
            display: table-cell;
            position: relative;
            vertical-align: bottom;
            padding:0 25px 15px 0;
            width:120px;
            text-align: right;
            font-size:12px;

            .tooltip_toggle {
                bottom:25px;
                right:0;
            }
        }
    }

    .remis-insidecol {
        padding-bottom:30px;
    }

    .hc-block-footerlink {
        right:20px;
        bottom:20px;
        padding:0 20px;
    }
}

/****** right col ****/
.remis-rightcol {
    margin-right:10px;

    .col-tag {
        background-color: $purple-base;
    }

    // H2
    .header-with-ic {
        .header-with-ic-icon {
            background-color: $purple-base;
        }

        .header-with-ic-title {
            color:$grey-blue;

            span {
                color: $purple-base;
            }
        }
    }
}


@media screen and (max-width: 1060px) {
    .remis-col {
        width: 100%;
        float: none;

        &.remis-rightcol {
            margin-top: 30px;
        }
    }

    .pharmacien-remis .outils.colored h4 {
        padding-left: 40%;
    }
}

@media screen and (max-width: 450px) {
    .pharmacien-remis .outils {
        //background-position: center top;
        background-size: auto 70px;

        &.colored {
            background-position: center top;
            height: 200px;

            > h4 {
                padding-left: 10px;
                text-align: center;
                padding-top: 110px;
            }

            &.kelmed {
                height: 370px;
                background-position: left top;

                > div {
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 175px;
                    display: block;
                }
            }
        }
    }
}
