.commande-flash-product-line {
    padding: 20px;
    width: 60%;
    margin: 0 auto;
    background-color: #FFFFFF;
    display: flex;

    &-img-1 {
        margin-right: 25px;
        margin-bottom: unset;
        max-width: 105px;
    }

    &-produit {
        font-family: "Bentonsans", sans-serif;
        color: #485E68;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-content {
            width: 73%;
        }

        &-cip {
            font-size: 11px;
            font-family: "Bentonsans-book", sans-serif;
        }

        &-title {
            font-size: 18px;
            font-family: "bentonsans-bold", sans-serif;
        }

        &-brand {
            font-size: 16px;
        }

        &-subtitle {
            font-family: "Bentonsans-book", sans-serif;
            font-size: 16px;
        }

        &-utilite {
            font-size: 12px;

            &-title {
                font-weight: bold;
            }
        }

        &-origine {
            font-size: 12px;
            font-weight: bold;
        }

        &-fiche {
            display: flex;

            &-icon {
                margin: 0 10px 0px 0px;
            }

            &-url {
                text-decoration: underline;
                color: #782FCB;
                font-size: 12px;
                align-self: center;
            }

            &-url:hover {
                color: #782FCB;
                text-decoration: underline;
            }
        }
    }

    &-commande {
        font-family: Bentonsans, sans-serif;

        &-pu {
            font-size: 11px;
            color: #485E68;

            &-ht {
                font-size: 14px;
                font-family: bentonsans-bold, sans-serif;
            }
        }

        &-detail {
            background-color: #E6EAEE;
            padding: 10px;

            &-libQuatite {
                font-size: 12px;
                font-weight: bold;
            }

            &-LibTotal {
                font-size: 11px;

                &-gras {
                    font-size: 12px;
                    font-weight: bold;
                }
            }

            &-price {
                &-ht {
                    color: #485E68;
                    font-size: 12px;
                    text-decoration: line-through;
                }

                &-ttc {
                    color: #002786;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            &-reduction {
                font-family: bentonsans-bold, sans-serif;
                font-size: 12px;
                color: #485E68;
                text-align: center;
            }

            &-radio {
                .form-check {
                    display: flex;
                }

                label {
                    position: relative;
                    margin-left: 3px;
                    margin-right: 20px;
                }

                input[type="radio"] {
                    position: unset !important;
                    visibility: unset !important;
                    opacity: unset !important;
                }
            }

            &-hr {
                margin: 11px auto;
                border-top: 1px solid #485E68;
                opacity: 0.33;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .commande-flash-product-line {
        width: 95%;
        flex-direction: column;

        &-img-1 {
            margin: auto;
        }
    }
}

@media screen and (min-width: 1000px) {
    .commande-flash-product-line {
        &-produit {
            width: 60%;
        }

        &-commande {
            width: 214px;
        }
    }
}

@media screen and (min-width: 770px) and (max-width: 1000px) {
    .commande-flash-product-line-produit {
        width: 205px;

        &-title {
            font-size: 14px;
        }

        &-subtitle {
            font-size: 13px;
        }

        &-utilite {
            font-size: 11px;
        }
    }

    .commande-flash-product-line-img-1 {
        margin-right: 15px;
    }
}

@media screen and (min-width: 1500px) {
    .commande-flash-product-line {
        width: 85%;
    }
}
