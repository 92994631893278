@charset "UTF-8";

/*
* Left col
*/
.mdmb {
    .ph-left-col {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 0 30% 0 0;
        @include bgimg('prehome/BG_Prehome_HD.jpg', no-repeat, 0%, 10%);
        -webkit-background-size: cover;
        background-size: cover;
    }

    .ph-h1 {
        width: 30%;
        height: 35px;
        margin: 30px 0 0 30px;
        @include bgimg('prehome/ph-logo.png');
        background-size: 100%;
    }

    /* Mobile header */
    .header-mobile {
        display: none;
        background-color: $white;
        padding: 15px 20px;
    }

    .ph-h1-mobile {
        @include bgimg('mobile/hdr-logo-mobile.png');
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
        width: 150px;
        height: 34px;
        float: left;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .ph-connexion_link-mobile {
        display: block;
        float: right;
        background-color: $pink-base;
        padding: 10px 15px;
        @include border-radius(25px);
        color: $white;
        font-size: 12px;

        .ic-userlog {
            font-size: 25px;
            vertical-align: middle;
            margin: 0 0 0 5px;
        }

        @include hover-focus-active {
            background-color: darken($pink-base, 10%);
            color: $white;
        }
    }

    // Blocs
    .ph-blocs {
        display: table;
        width: 70%;
    }

    .ph-blocs-row {
        display: table-row;
        margin: 0 0 10px 0;
    }

    .ph-bloc {
        display: table-cell;
        vertical-align: middle;
        width: 50%;
        margin: 0 0 15px;
        background-color: $white;
        border-left-style: solid;
        border-left-width: 10px;
        font: normal 30px/1.2em bentonsans-ultracondensed, sans-serif;
        text-transform: uppercase;
        font-size: 25px;
    }

    .ph-blocs-innertable {
        display: table;

        div {
            display: table-cell;
        }
    }

    .ph-bloc-ic {
        vertical-align: middle;
        font-size: 60px;
        line-height: 60px;
    }

    .ph-bloc-txt {
        vertical-align: middle;

        strong {
            font-weight: normal;
        }

        span {
            display: block;
        }
    }

    .ph-bloc-actus {
        border-left-color: $orange-base;

        .ph-bloc-ic, strong {
            color: $orange-base;
        }
    }

    .ph-bloc-indicateurs {
        border-left-color: $blue-base;

        .ph-bloc-ic, strong {
            color: $blue-base;
        }
    }

    .ph-bloc-ecat {
        border-left-color: $purple-base;

        .ph-bloc-ic, strong {
            color: $purple-base;
        }

        strong {
            white-space: nowrap;
        }

        .ph-bloc-ic {
            font-size: 65px;
        }
    }

    .ph-bloc-services {
        border-left-color: $green-base;

        .ph-bloc-ic, strong {
            color: $green-base;
        }
    }

    /*
    * Right col
    */
    .ph-right-col {
        position: absolute;
        right: 0;
        top: 0;
        width: 30%;
        height: 100%;
        background-color: $white;
        color: $grey-light;
        //overflow-y: auto;
        &:before {
            display: block;
            content: "";
            position: absolute;
            left: -13px;
            top: 10%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7.5px 13.0px 7.5px 0;
            border-color: transparent #ffffff transparent transparent;
        }
    }

    .ph-right-col-scrollbox {
        padding: 0 20%;
        overflow: auto;
        height: 100%;

        &.login {
            padding: 0 20%;
            overflow: auto;

            .flash-msg {
                margin: 0 -36%;
            }
        }

        &.register, &.contact, &.mentions-legales {
            padding: 0 10%;

            .flash-msg {
                margin: 0 -13.5%;
            }
        }

        &.resetting-check-email {
            min-height: 0;
        }

        &.resetting-request {
            min-height: 0;
        }

        &[class*="resetting-reset"] {
            min-height: 0;

            .back_link-pillshaped {
                left: -26%;
            }

            .flash-msg {
                margin: 0 -34%;
            }
        }

        &.mentions-legales {
            .chapeau {
                font-size: 14px;
            }
        }
    }

    .ph-anonymous-mentions {
        font-size: 12px;
    }

    .ph-footer {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        border: none;
        padding: 0;

        .ftr-pink {
            width: 70%;
            float: left;
        }
    }
    .ph-right-col-footer {
        float: right;
        border: none;
        height: 40px;
        line-height: 40px;
        background-color: $white;
        padding-left: 0;

        ul {
            list-style-type: none;
            text-align: center;

            li {
                display: inline-block;
                margin: 0 10px;

                a {
                    color: $grey-light;
                    @include hover-focus-active {
                        color: $grey-blue;
                    }
                }
            }
        }
    }

    /*
    * Media queries
    */
    @media (min-width: 1400px) {
        .ph-bloc-txt {
            span {
                display: inline;
            }
        }
    }
    @media screen and (max-width: 1100px) {
        .ph-right-col-footer ul li {
            margin: 0 3px;
        }
    }
    @media screen and (max-width: 1000px) {
        .ph-left-col {
            margin: 0 35% 0 0;
        }
        .ph-right-col {
            width: 35%;
        }
        .ph-right-col-scrollbox,
        .ph-right-col-scrollbox.login {
            padding: 0 18%;
        }
    }

    @media screen and (max-width: 767px) {
        .ph-left-col,
        .ph-right-col {
            position: static;
            margin: 0;
        }

        .ph-left-col {
            display: none;

            &.login {
                display: block;
            }
        }

        .ph-right-col {
            width: 100%;
            padding: 0 0 20px 0;
        }

        .header-mobile {
            display: block;
        }

        .ph-h1 {
            display: none;
        }

        .ph-blocs {
            width: 99%;
            border-spacing: 5px;
            table-layout: fixed;
        }

        .ph-bloc {
            padding: 10px 0;
            font-size: 23px;
        }

        .ph-bloc-ic {
            font-size: 50px;
            width: 60px;
        }

        .ph-bloc-ecat .ph-bloc-ic {
            font-size: 60px;
        }

        .ph-videolink {
            width: 100%;

            .ph-videolink-inside {
                width: auto;
            }

            .ic-play {
                font-size: 35px;
            }

            .ph-videolink-txt {
                font-size: 14px;
                padding: 0 0 0 10px;
            }
        }

        .ph-right-col-scrollbox.register .flash-msg,
        .ph-right-col-scrollbox.contact .flash-msg {
            margin: 10px -12% !important;
        }

        .ph-right-col-scrollbox.login .flash-msg {
            margin: 10px -28%;
        }

        .ph-footer {
            position: static;
            margin: 0;
            border-top: 3px solid $grey-lighter;
        }

        .ph-right-col-footer {
            float: none;
            width: 100%;
        }
    }

    @media screen and (max-width: 400px) {
        .ph-right-col-scrollbox, .ph-right-col-scrollbox.login {
            padding: 0 10vw;
        }
        .ph-bloc-ic {
            font-size: 40px;
            width: 50px;
        }
        .ph-bloc-ecat .ph-bloc-ic {
            font-size: 50px;
        }
    }

    .ph-logo-anticancer {
        width: 25%;
        margin: 20vh auto 5vh auto;
    }

    @media screen and (max-width: 450px) {
        .ph-logo-anticancer {
            width: 45%;
        }
    }

    @media (min-height: 1080px) {
        .ph-logo-anticancer {
            margin: 20vh auto 5vh auto;
        }
    }

    @media (max-height: 1080px) {
        @media (min-width: 1300px) {
            .ph-logo-anticancer {
                margin: 15vh auto 5vh auto;
            }
        }
        @media (min-width: 800px) and (max-width: 1299px) {
            .ph-logo-anticancer {
                margin: 7vh auto 5vh auto;
            }
        }
    }

    @media (max-height: 800px) {
        @media (min-width: 1300px) {
            .ph-logo-anticancer {
                margin: 9vh auto 5vh auto;
            }
        }
        @media (min-width: 800px) and (max-width: 1299px) {
            .ph-logo-anticancer {
                margin: 2vh auto 5vh auto;
            }
        }
        @media (max-width: 799px) {
            .ph-logo-anticancer {
                margin: 0 auto 5vh auto;
            }
        }
    }

    @media (max-height: 799px) {
        @media (min-width: 1300px) {
            .ph-logo-anticancer {
                margin: 2vh auto 5vh auto;
                width: 177px;
            }
        }
    }

    @media (max-height: 670px) {
        @media (min-width: 1300px) {
            .ph-logo-anticancer {
                margin: 0 auto 5vh auto;
                width: 177px;
            }
        }
    }

    @media (max-width: 450px) {
        .ph-logo-anticancer {
            margin: 90px auto 5vh auto;
        }
    }

    @media (max-width: 767px) {
        .ph-h4 {
            color: $white;
            font-size: 3vw;
            text-align: center;
            font-family: bentonsans-book, sans-serif;

            span {
                display: inline-block;
                font-family: bentonsans-light, sans-serif;
                font-weight: bold;
            }
        }
    }

    @media (min-width: 768px) {
        .ph-h4 {
            color: $white;
            font-size: 1.5vw;
            text-align: center;
            font-family: bentonsans-book, sans-serif;

            span {
                display: inline-block;
                font-family: bentonsans-light, sans-serif;
                font-weight: bold;
            }
        }
    }

    @media (min-width: 1200px) {
        .ph-h4 {
            color: $white;
            font-size: 1.2vw;
            text-align: center;
            font-family: bentonsans-book, sans-serif;
        }
    }

    .ph-bloc-container {
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .ph-card {
        width: 270px;
        height: 230px;
        margin: 0px 15px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.53);
    }

    .ph-card-title {
        text-transform: uppercase;
        font-size: 12px;
        margin: 5px 0px 2px 0px;
        color: $white;
        font: normal 10px/30px bentonsans-medium, sans-serif;

        &-text {
            font-weight: bold;
            padding: 6px 10px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            letter-spacing: 3px;
        }

        &-text1 {
            background-color: #d0518c;
        }

        &-text2 {
            background-color: #009cdd;
        }
    }

    .ph-card-img {
        max-width: 160px;
        margin: 0 auto -10px auto;
        -webkit-background-size: cover;
        background-size: cover;
    }

    @media screen and (max-width: 500px) {
        .logo-mdmb-mobile-resp {
            height: 142px;
            width: 137px;
        }
    }

    .ph-card-footer {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #315b8e;
        font-family: 'bentonsans-medium', sans-serif;
        margin-top: 10px;
    }

    .ph-footer-underline {
        width: 40%;
    }

    @media (max-width: 600px) {
        .ph-footer-text1 {
            font-size: 2vw;
            font-weight: bold;
            letter-spacing: 5px;
            text-transform: uppercase;
            font-family: 'bentonsans-regular', sans-serif;
        }

        .ph-footer-text2 {
            font-size: 1.8vw;
            font-family: 'bentonsans-regular', sans-serif;
        }
    }

    @media (min-width: 601px) {
        .ph-footer-text1 {
            font-size: 1vw;
            font-weight: bold;
            letter-spacing: 5px;
            text-transform: uppercase;
            font-family: 'bentonsans-regular', sans-serif;
        }

        .ph-footer-text2 {
            font-size: 1.2vw;
            font-family: 'bentonsans-regular', sans-serif;
        }
    }

    @media (min-width: 1200px) {

        .ph-footer-text2 {
            font-size: 1vw;
            font-family: 'bentonsans-regular', sans-serif;
        }
    }

    .ph-footer-text3 {
        font-size: 12px;
        margin-top: 5vh;
        font-family: 'bentonsans-regular', sans-serif;
    }

    @media (max-height: 680px) {
        .ph-footer-text3 {
            font-size: 10px;
            margin-top: 2vh;
        }
    }

    @media (min-width: 768px) and  (max-width: 815px) {
        .ph-footer {
            bottom: 80px;
        }
        .ph-right-col-scrollbox{
            height: 85%;
        }
    }

    @media (min-width:816px) and  (max-width: 1747px) {
        .ph-footer {
            bottom: 40px;
        }
        .ph-right-col-scrollbox{
            height: 90%;
        }
    }
}

