.commande-flash-popin-summary {
    .popup-base {
        width: 596px;
        height: 500px;
        background: #FFFFFF;
    }

    .popup-title {
        background: #FFFFFF;
        color: #002786;
        text-transform: uppercase;
        margin: auto 35px;
        padding: 10px 1px;
        font-size: 18px;
        font-family: bentonsans-bold, sans-serif;
        display: flex;
        border-bottom: 0.5px solid #5b8b9a;

        &-text {
            margin-top: 8px;
        }

        img {
            margin: unset;
        }
    }

    .popup-commande-flash-close {
        float: right;
        margin-top: 6px;
    }

    .popup-commande-flash-close:hover {
        cursor: pointer;
    }

    .commande-flash-popin-summary-line {
        margin: 15px 20px;
        font-family: Bentonsans-book, sans-serif;
        font-size: 14px;
        color: #485E68;

        &-product-name {
            font-family: bentonsans-bold, sans-serif;
        }

        &-price {
            font-family: bentonsans-bold, sans-serif;
            font-size: 16px;
            color: #002786;
        }

        &-price-old {
            font-family: Bentonsans-book, sans-serif;
            font-size: 12px;
            color: #485E68;
            text-decoration: line-through;
        }
    }

    .commande-flash-popin-summary-price {
        background: #CDDFE0;
        text-align: center;
        margin: auto 20%;
        padding: 13px;
        border-radius: 5px;

        &-reduction {
            font-family: bentonsans-bold, sans-serif;
            font-size: 12px;
            color: #1C4537;
        }

        &-text {
            font-family: Bentonsans-book, sans-serif;
            font-size: 12px;
            color: #485E68;
            margin: 13px auto;
        }

        &-total {
            font-family: bentonsans-bold, sans-serif;
            font-size: 16px;
            color: #002786;
        }
    }

    .commande-flash-popin-summary-conditions {
        position: relative;
        text-align: center;
        margin: 15px 20%;

        label {
            position: unset;

            .icheckbox {
                left: unset;
            }
        }

        &-text {
            margin-left: 20px;
            font-size: 11px;
            color: #485E68;
            font-family: Bentonsans-book, sans-serif;
        }

        &-text-conditions {
            text-decoration: underline;

            a {
                color: #485E68;
            }
        }
    }

    .commande-flash-popin-summary-buttons {
        margin: 30px 20%;

        &-edit button {
            float: left;
            padding: 7px 13px;
            background: #485E68;
            color: #FFFFFF;
        }

        &-confirm button {
            display: flex;
            float: right;
            padding: 11px 15px;
            background: #F09A04;
            color: #FFFFFF;

            img {
                margin: unset;
            }
        }

        &-confirm {
            .btn-disabled {
                opacity: 0.5;
            }

            &-text {
                margin-top: 4px;
                margin-left: 10px;
            }
        }
    }

    .commande-flash-popin-summary-delivery {
        text-align: center;
        margin: 20px 20%;
        font-family: Bentonsans-book, sans-serif;
        font-size: 12px;
        color: #485E68;
    }
}

@media screen and (max-width: 767px) {
    .commande-flash-popin-summary {
        .popup-base {
            width: 100%;
        }

        &-buttons {
            margin: 10px;
        }

        &-delivery {
            margin: 10px;
        }
    }
}
