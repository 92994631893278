// Engagements block
@import "engagements_bgr_block";

// My officine block
@import "mon_officine";

// Pharmacy Management block
@import "gestion_officine_block";

// Actualités
@import "mes_actualites";

// Top visites
@import "top_visites";

// Mes commandes
@import "mes_commandes";

// A ne pas manquer
@import "a_ne_pas_manquer";

// Vision Patient
@import "vision_patient_block";

// Common block style
.home-block {
    margin: 0 0 20px 0;
    background-color: $white;
    position: relative;

    &-header {
        padding: 2px 10px;
        display: flex;
        text-transform: uppercase;
        color: $white;
        font-weight: 700;

        &-icon {
            padding: 6px 0;

            img {
                margin: unset;
                width: 31px;
            }
        }

        .icon-actu, .fa-bullhorn {
            font-size: 30px;

            .fa-bullhorn {
                font-size: 25px;
            }
        }

        &-title {
            margin: auto 10px;
            font-family: bentonsans-condensedbold, sans-serif;
            font-size: 16px;
        }
    }

    &-content {
        padding: 20px;
        color: $black;
    }
}
