@charset "UTF-8";

.csp_prehome {
    /********** Left col***************/
    .ph-left-col {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 0 30% 0 0;
        @include bgimg('prehome/img-accueil.png', no-repeat, 0%, 10%);
        -webkit-background-size: cover;
        background-size: cover;
    }

    .block-before-footer {
        margin: 20px;
        color: #FFFFFF;
    }

    .ph-h1 {
        height: 30px;
        margin: 20px 0 0 25px;
    }

    .block-center {
        margin: 2rem auto;

        .title {
            font-family: bentonsans-bold;
            text-transform: uppercase;
            font-weight: bolder;
            color: #FFFFFF;
            margin-bottom: 15px;
            text-align: center;
        }

        .subtitle {
            font-family: bentonsans-bold, sans-serif;
            display: table;
            color: #FFFFFF;
            font-weight: bold;
            background: #2EB4D1;
            padding: 10px;
            margin: 0px auto;
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        &-image {
            img {
                width: 57%;
                margin: 0 auto;
            }

        }

        &-plus-infos {
            text-align: center;
            color: #FFFFFF;
            font-size: 17px;
            margin-top: 20px;

            span {
                font-family: bentonsans-book, sans-serif;
                padding: 10px;
                border: 1px solid #FFFFFF;
                text-transform: uppercase;
            }
        }
    }

    &-ph-footer {
        background-color: transparent;
        display: flex;
    }

    &-ftr-pink {
        align-self: flex-end;
    }

    /*********** Mobile header ******************/
    .header-mobile {
        display: none;
        background-color: $white;
        padding: 15px 20px;
    }

    .ph-h1-mobile {
        @include bgimg('mobile/hdr-logo-mobile.png');
        -webkit-background-size: 100% auto;
        background-size: 100% auto;
        width: 150px;
        height: 34px;
        float: left;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .ph-connexion_link-mobile {
        display: block;
        float: right;
        background-color: $pink-base;
        padding: 10px 15px;
        @include border-radius(25px);
        color: $white;
        font-size: 12px;

        .ic-userlog {
            font-size: 25px;
            vertical-align: middle;
            margin: 0 0 0 5px;
        }

        @include hover-focus-active {
            background-color: darken($pink-base, 10%);
            color: $white;
        }
    }

    /*************** Right col ****************/
    .ph-right-col {
        position: absolute;
        right: 0;
        top: 0;
        width: 30%;
        height: 100%;
        background-color: $white;
        color: $grey-light;

        &:before {
            display: block;
            content: "";
            position: absolute;
            left: -13px;
            top: 10%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7.5px 13.0px 7.5px 0;
            border-color: transparent #ffffff transparent transparent;
        }
    }

    .ph-right-col-scrollbox {
        padding: 0 20%;
        overflow: auto;
        height: 100%;

        &.login {
            padding: 0 20%;
            overflow: auto;

            .flash-msg {
                margin: 0 -36%;
            }
        }

        &.register, &.contact, &.mentions-legales {
            padding: 0 10%;

            .flash-msg {
                margin: 0 -13.5%;
            }
        }

        &.resetting-check-email {
            min-height: 0;
        }

        &.resetting-request {
            min-height: 0;
        }

        &[class*="resetting-reset"] {
            min-height: 0;

            .back_link-pillshaped {
                left: -26%;
            }

            .flash-msg {
                margin: 0 -34%;
            }
        }

        &.mentions-legales {
            .chapeau {
                font-size: 14px;
            }
        }
    }

    .ph-footer {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        border: none;
        padding: 0;

        .ftr-pink {
            width: 70%;
            float: left;
        }
    }

    .ph-right-col-footer {
        float: right;
        border: none;
        height: 80px;
        line-height: 40px;
        background-color: $white;
        padding-left: 0;

        ul {
            list-style-type: none;
            text-align: center;

            li {
                display: inline-block;
                margin: 0 10px;

                a {
                    color: $grey-light;
                    @include hover-focus-active {
                        color: $grey-blue;
                    }
                }
            }
        }
    }

    /********** Media queries *****************/
    @media screen and (max-width: 600px) {
        .ph-left-col, .ph-right-col {
            position: static;
            margin: 0;
        }

        .ph-left-col {
            display: none;

            &.login {
                display: block;
            }
        }

        .ph-right-col {
            width: 100%;
            padding: 0 0 20px 0;
        }

        .header-mobile {
            display: block;
        }

        .ph-right-col-scrollbox.register .flash-msg,
        .ph-right-col-scrollbox.contact .flash-msg {
            margin: 10px -12% !important;
        }

        .ph-right-col-scrollbox.login .flash-msg {
            margin: 10px -28%;
        }

        .ph-footer {
            position: static;
            margin: 0;
            border-top: 3px solid $grey-lighter;
        }

        .ph-right-col-footer {
            float: none;
            width: 100%;
        }

        .block-center {
            display: none;
        }

        .imi_prehome-ftr-pink {
            height: auto;
        }
    }

    @media screen and (min-width: 601px) and (max-width: 767px) {
        .ph-left-col, .ph-right-col {
            position: static;
            margin: 0;
        }

        .ph-left-col {
            display: none;

            &.login {
                display: block;
            }
        }

        .ph-right-col {
            width: 100%;
            padding: 0 0 20px 0;
        }

        .header-mobile {
            display: block;
        }

        .ph-right-col-scrollbox.register .flash-msg,
        .ph-right-col-scrollbox.contact .flash-msg {
            margin: 10px -12% !important;
        }

        .ph-right-col-scrollbox.login .flash-msg {
            margin: 10px -28%;
        }

        .ph-footer {
            position: static;
            margin: 0;
            border-top: 3px solid $grey-lighter;
        }

        .ph-right-col-footer {
            float: none;
            width: 100%;
        }
        .block-center {
            display: none;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 815px) {
        .ph-left-col, .ph-right-col {
            position: static;
            margin: 0;
        }

        .ph-left-col {
            display: none;

            &.login {
                display: block;
            }
        }

        .ph-right-col {
            width: 100%;
            padding: 0 0 20px 0;
        }

        .header-mobile {
            display: block;
        }

        .ph-right-col-scrollbox.register .flash-msg,
        .ph-right-col-scrollbox.contact .flash-msg {
            margin: 10px -12% !important;
        }

        .ph-right-col-scrollbox.login .flash-msg {
            margin: 10px -28%;
        }

        .ph-footer {
            position: static;
            margin: 0;
            border-top: 3px solid $grey-lighter;
        }

        .ph-right-col-footer {
            float: none;
            width: 100%;
        }

        .ph-footer {
            bottom: 80px;
        }
        .ph-right-col-scrollbox {
            height: 85%;
        }
        .block-center {
            display: none;
        }
    }

    @media screen and (min-width: 816px) and (max-width: 1000px) {
        .ph-left-col, .ph-right-col {
            position: static;
            margin: 0;
        }

        .ph-left-col {
            display: none;

            &.login {
                display: block;
            }
        }

        .ph-right-col {
            width: 100%;
            padding: 0 0 20px 0;
        }

        .header-mobile {
            display: block;
        }

        .ph-right-col-scrollbox.register .flash-msg,
        .ph-right-col-scrollbox.contact .flash-msg {
            margin: 10px -12% !important;
        }

        .ph-right-col-scrollbox.login .flash-msg {
            margin: 10px -28%;
        }

        .ph-footer {
            position: static;
            margin: 0;
            border-top: 3px solid $grey-lighter;
        }

        .ph-right-col-footer {
            float: none;
            width: 100%;
        }

        .ph-right-col-scrollbox {
            height: 90%;
        }

        .block-center {
            display: none;
        }
    }

    @media screen and (min-width: 1001px) and (max-width: 1100px) {
        .block-center {
            .title {
                font-size: 12px;
            }

            .subtitle {
                font-size: 20px;
            }
        }
    }

    @media screen and (min-width: 1101px) and (max-width: 1249px) {
        .block-center {
            .title {
                font-size: 14px;
            }

            .subtitle {
                font-size: 22px;
            }
        }
        .ph-right-col-scrollbox {
            height: 90%;
        }
    }

    @media screen and (min-width: 1250px) and (max-width: 1370px) {

        .block-center {
            .title {
                font-size: 16px;
            }

            .subtitle {
                font-size: 24px;
            }
        }
        .ph-right-col-scrollbox {
            height: 90%;
        }
    }

    @media screen and (min-width: 1371px) and (max-width: 1440px) {

        .block-center {
            .title {
                font-size: 17px;
            }

            .subtitle {
                font-size: 25px;
            }
        }
    }

    @media screen and (min-width: 1441px) and (max-width: 1680px) {

        .block-center {
            .title {
                font-size: 18px;
            }

            .subtitle {
                font-size: 26px;
            }
        }
    }

    @media screen and (min-width: 1681px) {

        .block-center {
            .title {
                font-size: 20px;
            }

            .subtitle {
                font-size: 28px;
            }

        }
    }
}
