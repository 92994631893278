.filters {
    &-form {
        display: flex;
        flex-wrap: wrap;
    }

    &-bloc:first-child {
        margin-left: 0 !important;
        margin-bottom: 5px;
    }

    &-bloc {
        margin: 0 5px;
        &.tooltip_date {
            min-width: 185px;
        }

        &-noLabel {
            align-items: baseline;
        }

        &-btn {
            margin-right: 0;
        }

        &-noLabel {
            margin-top: 30px;
        }

        &-label {
            margin-bottom: 6px;
            font-size: 16px;
            font-family: Bentonsans, sans-serif;
            color: #2F485C;

            label {
                margin: unset;
                display: unset;
                width: unset;
                position: unset;
                left: unset;
                top: unset;
                font-weight: unset;
            }
        }

        &-input {
            input, select {
                padding: 10px 27px 10px 15px;
                border-radius: 4px;
                background: #536C80;
                font-size: 13px;
                font-family: BentonSans-Medium, sans-serif;
                color: #FFFFFF;
                cursor: pointer;
            }

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url("/assets/img/chevron-down-solid.png");
                background-repeat: no-repeat;
                background-position-x: calc(100% - 14px);
                background-size: 13px 8px;
                background-position-y: 50%;
                max-width: 185px;
            }

            select {
                border-bottom: none;
            }

            select:hover {
                border-bottom: none;
            }
        }

        .filters-size {
            height: 34px;
            min-width: 150px;
        }

        .input-date {
            display: flex;
            padding: 10px 15px;
            border-radius: 4px;
            background: #536C80;
            font-size: 13px;
            font-family: BentonSans-Medium, sans-serif;
            color: #FFFFFF;
        }

        .btn-filter {
            border-radius: 15px;
            background: #2DB4D1 0% 0% no-repeat padding-box;
            min-width: 70px;
            max-width: 95px;
            height: 36px;
            font: normal normal 900 14px/14px;
            font-family: Bentonsans, sans-serif;
            color: #FFFFFF;
        }

        &-icons {
            position: relative;
            height: 100%;
            display: flex;
            align-items: flex-end;

            .icon-funnel {
                font-size: 24px;
                color: #536C80;
            }

            .icon-clear {
                position: absolute;
                left: 13px;
                bottom: 7px;
                border-radius: 50%;
                background: #F4F4F4;
                font-size: 12px;
                color: #F35252;
            }
        }

        .favorite-bloc {
            height: 100%;
            display: flex;
            align-items: flex-end;

            &-input {
                display: flex;

                &-label {
                    font-size: 16px;
                    font-family: Bentonsans, sans-serif;
                    color: #2F485C;
                }

                .icheckbox {
                    position: unset;
                    left: unset;
                    border: unset;
                    margin-right: 7px;
                }

                #order_filters_isFavorite {
                    position: unset !important;
                    visibility: unset !important;
                    opacity: unset !important;
                    width: 16px;
                    height: 16px;
                }

                input[type="checkbox"] {
                    margin: unset !important;
                }
            }
        }

        &-inputs {
            display: flex;
            margin: 10px;
            margin-left: 0;
            flex-wrap: wrap;
        }

        &-btn {
            display: flex;
            margin: 10px;
            margin-right: 0;
            align-self: end;
        }
    }

    &-bloc:last-child {
        margin-right: 0 !important;
    }
}

/*
* Responsive mobile
**/
@media screen and (max-width: 480px) {
    .filters {
        &-form {
            flex-wrap: wrap;
            justify-content: unset !important;
        }

        #statut-bloc {
            max-width: 30%;
            margin-left: 5px;
        }

        .filters-bloc-btn {
            width: 85%;
            margin-right: 5%;
        }

        .btn-filter {
            width: 100%;
        }

        &-bloc {
            margin: 0;
            padding: 10px 0 10px 0;
        }

        .favorite-bloc-mobile {
            width: 200px;
        }
    }

    .btn-filter {
        font-family: 'Bentonsans-bold' !important;
        font-size: 14px;
    }
    .filters-bloc-label {
        font-family: 'bentonsans-condensedregular';
    }
}
