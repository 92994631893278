#g361-cc-youtube-optout {
    padding: 2rem;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    .g361-cc-youtube-content .g361-cc-cta-yt-accept {
        display: inline-block;
        background-color: transparent;
        border: 1px solid #d42e80;
        color: #FFF;
        padding: .5rem 2rem;
        border-radius: 5rem;
        text-decoration: none;
        margin: 1rem 1rem;
        transition: all .2s;

        &:hover {
            background-color: #d42e80;
        }
    }
}

.lity-iframe-container {
    #g361-cc-youtube-optout {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
