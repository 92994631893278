@charset "UTF-8";


.pharmacien-faq {
  background-color: $grey-catabg;
  .main-content h1 {

    background-color: $blue-base3;
    .ic-speech::before{
      font-size:150px;
      padding-left: 70px;
      margin: 0px 15px 0 -5px;
    }
  }
  .breadcrumb span {
    color: $blue-base3;
  }


  .generic-content{
    padding-left: 5vw;
    .container{
      max-width: 100%;
      padding: 0;
      margin: 0 -20px;
    }
  }

  .faqCategory{
    height: 240px;
    background: $white;
    border: 1px solid $blue-light2;
    border-left: 2px solid #9aa5ab;
    border-radius: 3px;
    margin-bottom: 25px;
    > a{
      width: 100%;
      height: 100%;
      position: relative;
      padding: 20px;
      display: block;
      transition: 0.4s all ease;

      &:hover, &:active, &:focus{
        padding: 20px 10px 20px 30px;

         .hc-block-news-more{
          background-color: $grey-light;
          color: $white;
          border-color: $grey-light;
        }
      }

    }

    span.icon{
      width: auto;
      height: 45px;
      display: block;
      background-position: left center;
      background-repeat: no-repeat;
      background-size: auto 45px;

      &.icon-pill{
        background-image: url('./../img/faq/pill_2x.png');
      }
      &.icon-notes{
        background-image: url('./../img/faq/notes_2x.png');
      }
      &.icon-touch{
        background-image: url('./../img/faq/touch_2x.png');
      }
      &.icon-user{
        background-image: url('./../img/faq/user_2x.png');
      }
    }
  
    h4{
      font-family: 'bentonsans-condensedregular', Arial, Helvetica, sans-serif;
      font-size: 19px;
      color: $black;
      text-transform: none;
      margin: 10px 0 15px;
    }
    p{
      color: $grey-blue-light2;
      font-size: 13px;
      line-height: 19px;
      margin: 5px 0 20px;
      font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
    }

    .hc-block-news-more {
      @include transition-ease-in-out();
      border: 1px solid $grey-light;
      color: $grey-light;
      border-radius: 10px;
      -webkit-background-clip: padding-box;
      display: block;
      width: 50px;
      height: 30px;
      text-align: center;font-size: 19px;
      line-height: 29px;
      transition: 0.3s all ease 0.1s;

    }
    /*--------------------------------------------------------------
    variations couleurs 
    ---------------------------------------------------------------*/
    &.purple {
      border-left: 2px solid $purple-base;

      span.icon-pill {
        background-image: url('./../img/faq/pill_purple_2x.png');
      }
      span.icon-notes {
        background-image: url('./../img/faq/notes_purple_2x.png');
      }
      span.icon-touch {
        background-image: url('./../img/faq/touch_purple_2x.png');
      }
      span.icon-user {
        background-image: url('./../img/faq/user_purple_2x.png');
      }

      a:hover .hc-block-news-more,
      a:active .hc-block-news-more,
      a:focus .hc-block-news-more {
        background-color: $purple-base;
        color: $white;
        border-color: $purple-base;
      }

    }

    &.green{
      border-left: 2px solid $green-base;


      span.icon-pill{
        background-image: url('./../img/faq/pill_green_2x.png');
      }
      span.icon-notes{
        background-image: url('./../img/faq/notes_green_2x.png');
      }
      span.icon-touch{
        background-image: url('./../img/faq/touch_green_2x.png');
      }
      span.icon-user{
        background-image: url('./../img/faq/user_green_2x.png');
      }



      a:hover .hc-block-news-more,
      a:active .hc-block-news-more,
      a:focus .hc-block-news-more{
        background-color: $green-base;
        color: $white;
        border-color: $green-base;
      }
    }
    &.pink{
      border-left: 2px solid $pink-base;

      span.icon-pill{
        background-image: url('./../img/faq/pill_pink_2x.png');
      }
      span.icon-notes{
        background-image: url('./../img/faq/notes_pink_2x.png');
      }
      span.icon-touch{
        background-image: url('./../img/faq/touch_pink_2x.png');
      }
      span.icon-user{
        background-image: url('./../img/faq/user_pink_2x.png');
      }



      a:hover .hc-block-news-more,
      a:active .hc-block-news-more,
      a:focus .hc-block-news-more{
        background-color: $pink-base;
        color: $white;
        border-color: $pink-base;
      }
    }
    &.blue{
      border-left: 2px solid $blue-base3;


      span.icon-pill{
        background-image: url('./../img/faq/pill_blue_2x.png');
      }
      span.icon-notes{
        background-image: url('./../img/faq/notes_blue_2x.png');
      }
      span.icon-touch{
        background-image: url('./../img/faq/touch_blue_2x.png');
      }
      span.icon-user{
        background-image: url('./../img/faq/user_blue_2x.png');
      }



      a:hover .hc-block-news-more,
      a:active .hc-block-news-more,
      a:focus .hc-block-news-more{
        background-color: $blue-base3;
        color: $white;
        border-color: $blue-base3;
      }
    }
  }

  .infoFaq{

    border: 1px solid $blue-light2;
    background-color: $sep-bg;
    //height: 240px;
    padding-bottom: 30px;

    img{
      width: 80%;
      display: block;
      margin: 25px auto;
      height: auto;
      max-width: 190px;
    }

    h4{
      font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
      font-size: 39px;
      line-height: 40px;
      font-weight: lighter;
      text-transform: uppercase;
      color: $grey-blue-light2;
      margin: 35px 0 10px;
    }
    h5{
      font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
      font-size: 32px;
      line-height: 25px;
      color: $blue-base;
      text-transform: uppercase;
    }
    h6{
      font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
      font-size:31px;
      line-height: 36px;
      color: $grey-blue-light;
      margin: -15px 0 -5px;
    }
    span.small{
      font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
      font-size: 12px;
      line-height: 19px;
      color: $grey-blue-light;
    }
  }



  /*---------- PAGE QUESTIONS -------------------*/

  .faq-leftcol{
    width: 30%;
    float: left;

    .faqCategory{
      height: 130px;
      &.current > div.containerCat, > a{
        display: table;

        > span, > div{
          display: table-cell;
          vertical-align: middle;
        }
        > span{
          width: 30%;
          background-position: left center;
          text-align: center;
        }
        h4{
          margin: -10px 0 0;
        }
        p{
          line-height: 15px;
        }

      }
      &.current > div.containerCat{
        padding: 20px;
        position: relative;
        width: 100%;
        height: 100%;
      }

      &:not(.current){
        width: calc(100% - 20px);
      }
    }

  }
  .faq-rightcol{
    width: 70%;
    float: left;
    .infoFaq{
      //height: 270px;
    }
    .panel-heading{
      padding-left: 0;
      padding-right: 0;
    }
    .collapse{
      padding-bottom: 50px;

    }
    #faq-accordion{
      border: 1px solid $blue-light2;
      padding: 30px 60px;
      margin-left: -1px;
      border-radius: 3px;
    }
    h3.panel-title{
      font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 26px;
      color: $black;
      span{
        font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
        font-size: 25px;
        line-height: 35px;
        color: $grey-blue-light3;
        text-transform: none;
        display: block;
        margin-bottom: 50px;
      }
    }
    /*.panel{
     box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    }*/
    .panel-default{
      border: 0 none;
      
      > .panel-heading{
        border: 0 none;
        
        h2[data-toggle="collapse"]{
          font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
          font-size: 17px;
          padding: 20px 0;
          line-height: 25px;
          position: relative;
          display: block;
          height: 60px;
          margin: 0;
          cursor: pointer;
          transition: all 0.4s ease;
          > span.plus{
            display: block;
            position: absolute;
            right: 15px;
            top: 19px;
            font-size: 30px;
            transition: all 0.4s ease;
          }
          &:hover, &:active, &:focus{
            padding-left: 15px;
            > span{
              transform: rotate(90deg);
            }
          }
        }
        
      }

      div[role="tabpanel"]{

        a {
          font-weight: normal;
          text-decoration: underline;
        }
        p{
          strong{
            font-weight: normal;
          }
        }
        ul{
          list-style: none;
          li{
            &::before{
              //content: "→";
              content: "\2192";
              padding-right: 10px;
              font-weight: bold;
            }
          }
        }
        blockquote{
          margin-left: 10%;
        }
        .hc-block-scli-pb{
          width: 100%;
          margin: 15px auto;
          background-color: transparent;
          text-align: left;
            .hc-block-scli-pb-container{
              width: 50%;
            background-color: lighten(#9aa5ab, 30%);
            text-align: center;
            display: table;
            vertical-align: middle;
            border: 1px solid $grey-blue;
            p{
              color: $grey-blue;
              display: table-cell;
              padding: 10px 0;
              font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
              font-size: 12px;
              > a{
                font-size: 15px;
                transition: 0.3s all ease;
              }
              .small{
                color: $grey-light;
                font-size: 9px;
              }
            }
          }
        }

        .bloc-pmp-contrat{
          width: 50%;
          background-color: lighten(#9aa5ab, 30%);
          text-align: center;
          display: table;
          vertical-align: middle;
          border: 1px solid $grey-blue;
          padding: 15px 0px;
          margin: 0 auto 15px;
          [class^="ic-"] {
            font-size: 38px;
            width: auto;
            margin: 14px 0 0 0;
            text-align: center;
          }
          .bloc-pmp-contrat-col{
            float: none;
            display: table-cell;
            vertical-align: middle;
            &:last-child{
              border-left: 2px dotted $grey-blue;
            }
          }
          .ic-delegue{
            color: $grey-blue;
          }
          .hc-block-title{
            color: #485e68;
            > span{
              color: $grey-blue;
            }

          }
          .hc-block-delegue-name{
            color: #485e68;
            margin: 6px 0;
          }
          .hc-block-delegue-tel{
            line-height: 1em;
            color: $grey-blue;
          }
        }

      }
    }





    /*-------------------------------------------
    Variation couleurs
    -------------------------------------------*/
    .purple{


      .panel-default {
        > .panel-heading {
          h2[data-toggle="collapse"] {
            color: $purple-base;
            &:hover, &:active, &:focus {
              color: darken($purple-base, 15%);
            }
          }

        }
        div[role="tabpanel"] {

          a{
            color: $purple-base;
          }
          h1, h2, h3, h4, h5, h6, strong, em, b, i, .block-mise_en_avant{
            color: $purple-base;
          }

          ul{
            li{
              &::before{
                color: $purple-base;
              }
            }
          }

          .hc-block-scli-pb {
            .hc-block-scli-pb-container {
              background-color: lighten($purple-base, 48%);
              border-color: lighten($purple-base, 30%);
              p > a {
                color: $purple-base;
                &:active, &:hover, &:focus {
                  color: darken($purple-base, 15%);
                }
              }
            }
          }
  
          .bloc-pmp-contrat{
            background-color: lighten($purple-base, 48%);
            border-color: lighten($purple-base, 30%);

            .bloc-pmp-contrat-col{
              &:last-child{
                border-left-color: $purple-base;
              }
            }
            .ic-delegue{
              color: $purple-base;
            }
            .hc-block-title{
              > span{
                color: $purple-base;
              }

            }
            .hc-block-delegue-tel > a {
                color: $purple-base;
              font-size: 15px;
                &:active, &:hover, &:focus {
                  color: darken($purple-base, 15%);
                }
              }
            }
          }

      }
    } /* purple */

    .green{


      .panel-default {
        > .panel-heading {
          h2[data-toggle="collapse"] {
            color: $green-base;
            &:hover, &:active, &:focus {
              color: darken($green-base, 15%);
            }
          }

        }
        div[role="tabpanel"] {

          a{
            color: $green-base;
          }
          h1, h2, h3, h4, h5, h6, strong, em, b, i, .block-mise_en_avant{
            color: $green-base;
          }

          ul{
            li{
              &::before{
                color: $green-base;
              }
            }
          }

          .hc-block-scli-pb {
            .hc-block-scli-pb-container {
              background-color: lighten($green-base, 48%);
              border-color: lighten($green-base, 30%);
              p > a {
                color: $green-base;
                &:active, &:hover, &:focus {
                  color: darken($green-base, 15%);
                }
              }
            }
          }

          .bloc-pmp-contrat{
            background-color: lighten($green-base, 48%);
            border-color: lighten($green-base, 30%);

            .bloc-pmp-contrat-col{
              &:last-child{
                border-left-color: $green-base;
              }
            }
            .ic-delegue{
              color: $green-base;
            }
            .hc-block-title{
              > span{
                color: $green-base;
              }

            }
            .hc-block-delegue-tel > a {
                color: $green-base;
              font-size: 15px;
                &:active, &:hover, &:focus {
                  color: darken($green-base, 15%);
                }
              }
            }
          }

      }
    } /* green */

    .blue{


      .panel-default {
        > .panel-heading {
          h2[data-toggle="collapse"] {
            color: $blue-base;
            &:hover, &:active, &:focus {
              color: darken($blue-base, 15%);
            }
          }

        }
        div[role="tabpanel"] {
          h2, h3, h4, h5, h6{
            color:$grey-blue2;
          }
          a{
            color: $blue-base;
          }
          h1, h2, h3, h4, h5, h6, strong, em, b, i, .block-mise_en_avant{
            color: $blue-base;
          }

          ul{
            li{
              &::before{
                color: $blue-base;
              }
            }
          }

          .hc-block-scli-pb {
            .hc-block-scli-pb-container {
              background-color: lighten($blue-base, 48%);
              border-color: lighten($blue-base, 30%);
              p > a {
                color: $blue-base;
                &:active, &:hover, &:focus {
                  color: darken($blue-base, 15%);
                }
              }
            }
          }

          .bloc-pmp-contrat{
            background-color: lighten($blue-base, 48%);
            border-color: lighten($blue-base, 30%);

            .bloc-pmp-contrat-col{
              &:last-child{
                border-left-color: $blue-base;
              }
            }
            .ic-delegue{
              color: $blue-base;
            }
            .hc-block-title{
              > span{
                color: $blue-base;
              }

            }
            .hc-block-delegue-tel > a {
                color: $blue-base;
              font-size: 15px;
                &:active, &:hover, &:focus {
                  color: darken($blue-base, 15%);
                }
              }
            }
          }

      }
    } /* blue*/

    .pink{


      .panel-default {
        > .panel-heading {
          h2[data-toggle="collapse"] {
            color: $pink-base;
            &:hover, &:active, &:focus {
              color: darken($pink-base, 15%);
            }
          }

        }
        div[role="tabpanel"] {

          a{
            color: $pink-base;
          }
          h1, h2, h3, h4, h5, h6, strong, em, b, i, .block-mise_en_avant{
            color: $pink-base;
          }

          ul{
            li{
              &::before{
                color: $pink-base;
              }
            }
          }

          .hc-block-scli-pb {
            .hc-block-scli-pb-container {
              background-color: lighten($pink-base, 48%);
              border-color: lighten($pink-base, 30%);
              p > a {
                color: $pink-base;
                &:active, &:hover, &:focus {
                  color: darken($pink-base, 15%);
                }
              }
            }
          }

          .bloc-pmp-contrat{
            background-color: lighten($pink-base, 48%);
            border-color: lighten($pink-base, 30%);

            .bloc-pmp-contrat-col{
              &:last-child{
                border-left-color: $pink-base;
              }
            }
            .ic-delegue{
              color: $pink-base;
            }
            .hc-block-title{
              > span{
                color: $pink-base;
              }

            }
            .hc-block-delegue-tel > a {
                color: $pink-base;
              font-size: 15px;
                &:active, &:hover, &:focus {
                  color: darken($pink-base, 15%);
                }
              }
            }
          }

      }
    } /* pink */






  }

  #faq-accordion{
    background: $white;
    .panel-default{
      
      > .panel-heading{
        background: $white;
      } 

    }
  }




}


@media screen and (min-width: 767px) and (max-width: 1040px) {
  
  .faqCategory{
    height: 270px;
  }

  .pharmacien-faq{
    .infoFaq{
      //height: 290px;
      h4{
        font-size: 36px;
      }
      h5{
        font-size: 28px;
      }
      h6{
        font-size: 30px;
      }
    }
    .faq-leftcol .faqCategory{
      min-height: 130px;
      height: auto;
    }
  }
}
@media screen and (min-width:767px) and (max-width: 900px) {
  .pharmacien-faq .faq-leftcol .faqCategory.current > div.containerCat > span, .pharmacien-faq .faq-leftcol .faqCategory.current > div.containerCat > div, .pharmacien-faq .faq-leftcol .faqCategory > a > span, .pharmacien-faq .faq-leftcol .faqCategory > a > div{
    display:block;
  }
  .pharmacien-faq .faq-leftcol .faqCategory > a > div, .pharmacien-faq .faq-leftcol .faqCategory.current > div.containerCat > span, .pharmacien-faq .faq-leftcol .faqCategory.current > div.containerCat > div, .pharmacien-faq .faq-leftcol .faqCategory > a > span, .pharmacien-faq .faq-leftcol .faqCategory > a > div{
    margin-top:15px;
  }
}
@media screen and (max-width: 767px) {
  .pharmacien-faq{

    .generic-content{
      padding-left: 3vw;
    .infoFaq{
      //height: 480px;
    }


    .faq-rightcol, .faq-leftcol {
      width: 100%;
      float: none;
    }

    .faq-leftcol{
      div.faqCategory:not(.current){
        display: none;
      }
    }
    .faq-rightcol{
      .panel-default div[role="tabpanel"] {

        .hc-block-scli-pb .hc-block-scli-pb-container,
        .bloc-pmp-contrat {
          width: 90%;
        }
      }

      .infoFaq{
        //height: 440px;
      }
    }
    }
  }
}