@charset "UTF-8";

.init-member {

	h5 {
		background-color: $white;
		border: none;
		font: normal 20px/1em bentonsans-ultracondensed, sans-serif;
		text-transform: uppercase;
		color: $pink-base;
		text-align: center;
	}

	.validation-conditions {
		p {
			font-size: 12px;
			margin: 15px 0px 15px 25px;
			a {
				color: $blue-base;
				text-decoration: underline;
				@include hover-focus-active {
					color: $grey-blue;
				}
			}
		}
	}

	.required {
		color: #000;
	}

	.notices {
		margin-top: 10px;
		font-size: 11px;
		span {
			color: #000;
			vertical-align: -3px;
			margin-right: 4px;
		}
	}
}