.contact-promotional-block {
    padding: 38px 29px 15px;
    background: #385165;
    box-shadow: 0 3px 6px #8484840D;
    border-radius: 8px;
    text-align: center;
    font-family: BentonSans-Medium, sans-serif;
    color: #FFFFFF;

    a { color: #fff; }

    &-head {
        font-size: 18px;
        letter-spacing: -0.36px;
    }

    &-num {
        margin: 25px auto;

        .contact-num {
            padding: 10px 16px;
            background: #FFFFFF;
            border: 2px solid #FFFFFF;
            border-radius: 15px;
            color: #72BBFF;
        }
    }

    &-info {
        font-size: 10px;
        font-family: bentonsans-regular, sans-serif;
    }
}
