.lity {
    z-index: 9990;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    background: #0b0b0b;
    background: rgba(29, 29, 33, 0.9);
    outline: none !important;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}
.lity.lity-opened {
    opacity: 1;
}
.lity.lity-closed {
    opacity: 0;
}
.lity * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.lity-wrap {
    z-index: 9990;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    outline: none !important;
}
.lity-wrap:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
}
.lity-loader {
    z-index: 9991;
    color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -0.8em;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}
.lity-loading .lity-loader {
    opacity: 1;
}
.lity-container {
    z-index: 9992;
    position: relative;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    white-space: normal;
    max-width: 100%;
    max-height: 100%;
    outline: none !important;
}
.lity-content {
    z-index: 9993;
    width: 100%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: -o-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease, -o-transform 0.3s ease;
}
.lity-loading .lity-content,
.lity-closed .lity-content {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
}
.lity-content:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.lity-close {
    z-index: 9994;
    width: 35px;
    height: 35px;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-appearance: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-family: Arial, Baskerville, monospace;
    line-height: 35px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    border: 0;
    background: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.lity-close::-moz-focus-inner {
    border: 0;
    padding: 0;
}
.lity-close:hover,
.lity-close:focus,
.lity-close:active,
.lity-close:visited {
    text-decoration: none;
    text-align: center;
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-family: Arial, Baskerville, monospace;
    line-height: 35px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    border: 0;
    background: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.lity-close:active {
    top: 1px;
}
/* Image */
.lity-image img {
    max-width: 100%;
    display: block;
    line-height: 0;
    border: 0;
}
/* iFrame */
.lity-iframe .lity-container,
.lity-youtube .lity-container,
.lity-vimeo .lity-container,
.lity-facebookvideo .lity-container,
.lity-googlemaps .lity-container {
    width: 100%;
    max-width: 964px;
}
.lity-iframe-container {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: auto;
    pointer-events: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-overflow-scrolling: touch;
}
.lity-iframe-container iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}
.lity-hide {
    display: none;
}
