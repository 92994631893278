article.formation {
    div.mp-headerFormation {
        text-align: left;
        height: 92px;
        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
        margin-left: 20px;

        span.mp-header {
            left: 0;
            top: 18px !important;
            background-image: none !important;
            font-size: 15px;
            line-height: 13px;
            color: #495b69;
            position: absolute;
        }

        img.logo {
            position: relative;
            top: 13px;
            margin: 0;
            display: inline-block;
        }

        span.mp-header-title {
            color: #707fd6;
            font-size: 19px;
            line-height: 20px;
            left: 66px;
            position: absolute;
            top: 39px;
        }
    }

    img.mp-icon {
        margin: 0;
        display: inline-block;
    }

    span.mp-legende {
        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
        line-height: 1em;
        font-size: 10px;
        color: #7c8ca5;
    }
    div.mp-footer {
        margin-top: 38px;
        margin-bottom: 38px;
    }
}
