.block-mes-commandes
{
    border-left: 3px solid $blue-base;

    &-header {
        justify-content: space-between;
        background: $blue-base;

        &-icon-plus {
            margin: auto 0 auto auto;
            font-size: 21px;

            a {
                color: #ffffff;
            }

            a:hover {
                color: #ffffff;
            }
        }

        img {
            margin: unset;
        }
    }

    &-content {
        font-size: 12px;

        &-in-progress-orders {
            display: flex;
            align-items: center;

            &-num {
                position: absolute;
                font-size: 28px;
                font-family: 'Bentonsans-bold', sans-serif;
                font-weight: bold;
                vertical-align: middle;
            }

            &-text {
                padding-left: 25px;
                font-family: 'bentonsans-regular', sans-serif;
            }
        }

        &-link {
            display: flex;
            align-items: center;
            justify-content: center;

            &-text {
                text-decoration: underline;
                text-decoration-color: $blue-base;
                font-size: 11px;
                color: $blue-base;
            }

            img {
                margin: unset;
                display: inline;
            }
        }
    }
}

.home-block-content.block-mes-commandes-content {
    color: $blue-base;
}

.flex-parent-element {
    display: flex;
}
.flex-child-element {
    flex: 1;
    margin: 10px;
    color: #485E68;
}
.flex-child-element:first-child {
    margin-right: 20px;
}

.block-mes-commandes-header {
    .home-block-header-icon {
        font-size: 25px;
        padding: 5px 0 5px 0;
    }
}


.block-manage-mes-commandes {

    &-header {
        background: $blue-base;
    }

    &-sub-block {
        margin: 10px 0;
        padding: 7px;
        background: #F2F4F6;

        &-header {
            margin-bottom: 10px;
            padding-bottom: 2px;
            border-bottom: 2px solid ;
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            color: $blue-base;

            &-title {
                margin: auto 0;
            }

            &-icon-plus {
                font-size: 19px;

                a {
                    color: $blue-base;
                }

                a:hover {
                    color: $blue-base;
                }
            }
        }
    }

    &-rdp {
        &-text {
            padding: 0 2px;
            font-size: 12px;

            a {
                color: $blue-base;
                text-decoration: underline;
            }
        }

        &-header {
            margin: 15px 0;
        }

        &-content {
            border-left: 1px solid #E7EBEE;
            margin: 0 10px;
            padding: 10px;

            &-line {
                margin-bottom: 20px;

                &-header {
                    margin-top: -10px;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                &-content {

                }
            }
        }

        &-icon {
            margin-left: -18px;
            margin-right: 5px;
        }

        .icon-red {
            color: #E65455;
        }

        .icon-green {
            color: $green-base;
        }
    }

    &-contract {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;

        &-number {
            font-size: 35px;
            font-weight: 700;
        }

        &-text {

        }
    }

    &-order {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;

        &-number {
            font-size: 35px;
            font-weight: 700;
        }

        &-text {}

        &-link {
            a {
                color: $blue-base;
                text-decoration: underline;
            }
        }
    }

    &-otc {
        &-line {
            display: flex;
            margin: 15px 0;

            &-icon {
                margin: auto 5px;

                img {
                    margin: unset;
                    width: 30px;
                }
            }

            &-content {
                margin: 0 5px;
                display: flex;
                flex-direction: column;

                &-title {
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;

                    .is-otc {
                        color: $green-base;
                    }

                    .is-not-otc {
                        color: #E53838;
                    }
                }

                &-text {
                    font-size: 12px;
                }
            }
        }

        &-separator {
            width: 100%;
            border: 1px solid #DDE3EB;
        }
    }
}
