// Invoice promotional block
@import "invoice-promotional-block";
// Pharmacist tools
@import "pharmacist-tools-promotional-block";
// Contact
@import "contact-promotional-block";
// Contrat listing
@import "contrat-listing-promotional-block";
// Orders
@import "csp-promotional-block";
// Delivery
@import "delivery-promotional-block";
// Document
@import "document-promotional-block";
