@charset "UTF-8";

.lity {
  z-index: 10002;
}

.pharmacien-dashboard {
  .lity-close {
    position: absolute;
    right: 25px;
    top: 20px;
    color: $grey-light;
    text-shadow: none;
    font: 22px/1em 'bentonsans-regular', sans-serif;
  }
}

@media screen and (max-width: 767px) {
  .lity-iframe-container {
    padding-top: 80%;
  }
}
