@charset "UTF-8";
.catalogue-breadcrumb {
    font-family: bentonsans-regular, sans-serif;
    background-color: #e9edef;
}

.head-title {
    font-weight: bold;
}

.head-title.head-title-center {
    text-align: center;
}

.biog-et-vous {
    &-page {
        color: #00347D;
        padding: 10px 0px 20px 8%;

        &-bloc1et2 {
            display: flex;
            justify-content: space-between;
        }

        &-bloc-1 {
            width: 68%;
        }

        &-bloc-3 {
            width: 28%;
            padding-right: 30px;
        }

        &-title {
            font-size: 20px;
            font-family: bentonsans-medium, sans-serif;
            opacity: 1;
            padding-bottom: 15px;
            color: #2F485C;
        }

        &-content {
            font-family: bentonsans-regular, sans-serif;
            background-color: white;
            padding: 15px;
            color: #2F485C;
            border-radius: 8px;

            &-img {
                object-fit: cover;
                margin: 0 0 15px 0;
                height: 35px;
            }

            &-icone-texte {
                display: flex;
                align-items: start;

                img {
                    margin: unset;
                    padding-left: 15px;
                    padding-right: 10px;
                }

                p {
                    margin-bottom: 10px;

                    span {
                        font-weight: bold;
                    }
                }


                &-dernier-paragraphe, &-premier-paragraphe {
                    padding: 10px 0 10px 0;
                }
            }
        }

        &-bloc1-pubs {
            display: flex;

            img {
                width: 100%;
                margin: 0 0 15px 0;
                object-fit: cover;
            }
        }

        &-pubs-long {
            padding: 0px 5px 0px 5px;

            img {
                width: 100%;
                object-fit: cover;
                margin: 0 0 15px 0;
            }

        }

        &-bloc-presentation {
            padding: 20px;
            background-color: white;

            &-container {
                position: relative;

                &-dg {
                    font-family: bentonsans-regular, sans-serif;
                    display: block;
                    position: absolute;
                    padding: 05px 05px 0px 05px;
                    bottom: 5px;
                    right: 5px;
                    background-color: white;
                    color: #00B4C2;
                    font-size: 12px;

                    &-nom {
                        font-weight: bold;
                    }
                }
            }

            img {
                width: 100%;
                margin: 0 0 10px 0;
                object-fit: cover;
            }

            &-texte {
                color: #034C95;
                font-family: bentonsans-medium, sans-serif;
                font-size: 12px;
            }

        }

        &-bilan {
            &-container {
                padding: 30px 15px 30px 15px;
                background-color: white;
                border-radius: 8px;
                margin: 0px;
                display: flex;

                &-bloc {
                    margin-right: 15px;
                    border: 1px solid #DAE0E9;
                    padding: 10px;
                }

                &-titre {
                    text-align: center;
                    color: #034C95;
                    font-weight: bold;
                    padding: 5px 0px 5px 0px;
                    font-size: 18px;
                    font-family: Bentonsans Condensed, sans-serif;
                }

                &-image {
                    img {
                        margin: 0 auto;

                    }
                }

                &-btn {
                    font-family: bentonsans-regular, sans-serif;
                    color: #E72E8A;
                    background: white;
                    padding: 10px 20px;
                    display: block;
                    width: 203px;
                    margin: auto;
                    font-weight: bold;
                    border: 2px solid #CB2F7B;

                    a {
                        font-family: bentonsans-regular, sans-serif;
                        text-decoration: none;
                        color: #e72e8a;
                        font-size: 12px;
                    }
                }

                &-icons {
                    display: flex;
                    padding-top: 10px;
                    justify-content: center;
                    align-items: center;

                    img {
                        padding-right: 10px;
                        margin: 0;
                    }
                }
            }

            &-title {
                color: #2F485C;
                font-family: bentonsans-medium, sans-serif;
                font-size: 20px;
                padding: 25px 0 10px 0px;
            }
        }

    }
}

@media screen and (min-width: 481px) and(max-width: 880px) {
    .biog-et-vous-page {
        &-bloc1et2 {
            flex-direction: column;
        }

        &-bloc-1 {
            width: 69%;
        }

        &-bloc-3 {
            width: 69%;
            padding-top: 15px;
        }

        &-bilan-container {
            flex-direction: column;

            &-bloc {
                margin-bottom: 15px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .biog-et-vous {
        &-page {
            &-bloc1-pubs {
                display: none;
            }

            &-bloc1et2 {
                flex-direction: column;
            }

            &-bloc-1 {
                width: 95%;
            }

            &-bloc-3 {
                width: 95%;
                padding-top: 15px;
            }

            &-bilan-container {
                flex-direction: column;

                &-image {
                    margin-top: 10px;
                }

                &-bloc {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .biog-et-vous {
        &-page {
            &-title {
                font-size: 18px;
            }
        }
    }
}
