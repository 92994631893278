/* including sub blocks */
@import "sub_block/rdp_sub_block";
@import "sub_block/rdp_excluded_sub_block";
@import "sub_block/otc_sub_block";
@import "sub_block/otc_not_engaged_sub_block";

.block-manage-pharmacy {
    border-left: 3px solid $blue-base;

    &-header {
        background: $blue-base;

        &-icon {
            padding: 0 !important;
            font-size: 40px;
        }
    }

    &-sub-block {
        padding: 10px;
        width: 100%;
        background: #F2F4F6;
        border-radius: 5px;

        &-header {
            margin-bottom: 10px;
            padding-bottom: 2px;
            border-bottom: 2px solid $blue-base;
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            color: $blue-base;

            &-title {
                margin: auto 0;
                font-family: bentonsans-bold, sans-serif;
                font-size: 14px;
            }

            &-icon-plus {
                font-size: 19px;

                a {
                    color: $blue-base;
                }

                a:hover {
                    color: $blue-base;
                }
            }
        }

        &-orders-and-contracts {
            margin: 10px 0;
            display: flex;
            justify-content: space-between;
            gap: 5%;
        }

        @media screen and (max-width: 800px) {
            &-orders-and-contracts {
                flex-direction: column;
                :first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &-contract {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;
        color: #485E68;

        &-number {
            font-size: 24px;
            font-family: Bentonsans-bold, sans-serif;
        }

        &-text {
            font-size: 12px;
            font-family: bentonsans-regular, sans-serif;
        }
    }

    &-order {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;
        color: #485E68;

        &-number {
            font-size: 24px;
            font-family: Bentonsans-bold, sans-serif;
        }

        &-text {
            font-size: 12px;
            font-family: bentonsans-regular, sans-serif;
        }

        &-link {
            a {
                color: $blue-base;
                text-decoration: underline;
            }
        }
    }
}
