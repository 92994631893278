@charset "UTF-8";

$border-color: lighten($grey-lighter, 5%);

.pharmacien-equipe {
    h1 .ic-pharmacien-equipe {
        padding-left: 60px;
        font-size: 160px;
    }

    .flex-row-container {
        border-bottom: 1px solid #c7d1d8;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .chapeau {
        margin: 0 0 20px 0;

        .title {
            font-weight: bold;
        }

        .subtitle {
            line-height: 20px;
            margin-top: 10px;
            font-size: 16px;
        }

        small {
            font-size: 74%;
            line-height: 15px;
            display: block;
        }
    }

    .flex-col-container {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        align-self: flex-start;

        .menu-title {
            display: flex;
            font: normal 12px bentonsans-medium, sans-serif;
            text-transform: uppercase;
            margin-top: 10px;

            p {
                line-height: 10px;
                letter-spacing: 0px;
            }

            .text-bold {
                font-weight: bold;
                color: #5C6D75;
            }
        }

        .menus {
            display: flex;
            flex-direction: row;
            margin-top: -20px;

            .bloc {
                width: 50px;
                height: 50px;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 26px;
                margin-right: 1px;

                img {
                    margin: 0 auto;
                }
            }

            .bg-orange {
                background-color: #F09A04;
            }

            .bg-purple {
                background-color: #782FCB;
            }

            .bg-sea-blue {
                background-color: #4E8BE5;
            }

            .bg-azure-blue {
                background-color: $blue-base;
            }

            .bg-red {
                background-color: #CB2F7B;
            }

        }
    }

}

.contrat-block {
    margin: 0 0 40px 0;
}

.btn-inviter-lg {
    bottom: 0;
    right: 0;
    height: 50px;
    font: normal 12px bentonsans-medium, sans-serif;
    min-width: 45%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #ffffff;
    text-transform: uppercase;
    background: orange;
    display: block;
}

.pharmacien-equipe-list-container-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;

    h2 {
        display: flex;
        align-self: center;
        width: auto !important;

    }

    .flex-select-box {
        display: flex;
        margin-left: 5px;
    }
}

.pharmacien-equipe-invitation_en_attente {
    .cell-pharmacien-equipe-type .tooltip_toggle {
        color: #858d93;
        background: none;
        border: none;
    }
}

.member-icon {
    font-size: 38px;
    vertical-align: middle;
    display: inline-table;
}

.cell-pharmacien-equipe-nom_prenom {
    .value {
        font-weight: bold;
        font-size: 16px;
        color: #000000;
    }
}

.renvoyer, .editer {
    border-left: 1px solid #e5eaed;

}

.renvoyer, .editer, .supprimer {
    border-right: 1px solid #e5eaed;
}

.pharmacien-equipe-list-row > li:last-child, [class^="cell-pharmacien-"] {
    border-bottom: 1px solid #e5eaed;
}

.cell-pharmacien-equipe-supprimer, .cell-pharmacien-equipe-editer, .cell-pharmacien-equipe-renvoyer {
    text-decoration: underline;
}

.membre-invite-group, .membre-titutlaire-group {
    display: table;
    width: 100%;
    height: 100%;

    .icon-member {
        width: 20px;
        display: table-cell;
        vertical-align: middle;
        margin: 0;
        font-weight: bold;
    }

    p {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        padding-left: 5px;
        font-weight: bold;
    }
}

.membre-invite-group {
    img,
    p {
        opacity: 0.6;
    }

}

.cell-pharmacien-connexion, .cell-pharmacien-equipe-nb-connexions {
    color: #F6C570;
    font-weight: bold;
}

.cell-pharmacien-equipe-email {
    font-weight: bold;
}

*[data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
}

*[data-tooltip]:before,
*[data-tooltip]:after {
    position: absolute;
    bottom: 120%;
    left: 50%;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    pointer-events: none;
}

*[data-tooltip]:before {
    margin-bottom: 4px;
    margin-left: -100px;
    padding: 5px;
    width: 200px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: #495B69;
    color: #ffffff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 12px;
    line-height: 1.2;
}

*[data-tooltip]:after {
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #495B69;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
}

*[data-tooltip]:hover:before,
*[data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}
