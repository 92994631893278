@charset "UTF-8";

///*************************************
//* @_00 Styles de base typo
//*************************************/

// lissage des typos sur mac
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'bentonsans-book', Arial, Helvetica, sans-serif;
}

h1, h2, h3, h4, h5, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.8em;
    line-height: 1.2em;
}

.main-content h1 {
    display: table;
    width: calc(100% + 100px);
    margin: 0 0 0 -100px;
    padding: 20px 20px 20px 0;
    text-align: left;
    font-size: 35px;
    color: $white;

    .page & {
        [class^="ic-"], [class*=" ic-"] {
            width: 209px;
            font-size: 190px;
            color: $white;
            vertical-align: middle;
        }
    }

    .profil & {
        margin: 0 0 20px 0;
    }

    .news_listing & {
        background-color: $green-base2;
    }

    .pharmacien-services-patients & {
        width: calc(100% + 63px);
        margin: 0 0 0 -63px;
    }

    .pharmacien-engagements & {
        margin: 0 0 0 -34px;
        padding: 55px 20px 55px 0;

        [class^="ic-"], [class*=" ic-"] {
            width: 142px;
            font-size: 120px;
            color: $white;
            vertical-align: middle;
        }
    }

    .pharmacien-dashboard & {
        width: calc(100% + 60px);
        margin: 0 0 0 -60px;
    }

    .h1-col {
        display: table-cell;
        vertical-align: middle;
    }

    .h1-txt {
        display: block;
        margin: 0 0 10px 0;
    }

    .h1-soustitre {
        display: block;
        font-size: 16px;
        line-height: 1.5em;
    }

    .h1-datePublished {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 1.5em;
        color: lighten($green-base2, 40%);
        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;

        a {
            color: white;
            font-weight: bold;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    .contrat_listing &,
    .pharmacien-equipe & {
        width: calc(100% + 90px);
        margin: 0 0 0 -90px;
    }

    .h1-txt-simulateur {
        display: inline-block;
        margin: 0 0 10px 0;

        &--maintenance {
            color: orange;
            font-size: 14px;
        }
    }
}

h2 {
    .editorial-content &,
    .cke_editable & {
        margin: 0 0 20px 0;
        font-size: 19px;
        color: $black;

        span {
            display: block;
        }
    }

    .engagements-content & {
        color: $grey-blue;
        font-size: 35px;
    }

    .news_details & {
        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;;
    }

    .contrat_listing & {
        font-size: 14px;
        line-height: 1.5em;
        margin: 0 0 40px 0;

        span {
            font: 21px/24px 'bentonsans-regular', Arial, Helvetica, sans-serif;
        }

        .contrat-listing-h2-part1 {
            color: $blue-base;
        }

        .contrat-listing-h2-part2 {
            color: $grey-blue;
        }
    }

    .pharmacien-equipe & {
        font-size: 14px;
        line-height: 1.5em;
        margin: 0 0 40px 0;

        span {
            font: 21px/24px 'bentonsans-regular', Arial, Helvetica, sans-serif;
        }

        .pharmacien-equipe-listing-h2-part1 {
            color: $orange-base;
        }

        .pharmacien-equipe-listing-h2-part2 {
            color: $grey-blue;
        }
    }

    &.hltdnews-content-titre {
        font-family: 'bentonsans-condensedregular', Arial, Helvetica, sans-serif;
        color: $white;
        font-size: 18px;
        line-height: 1.3em;
        margin-bottom: 2em;
    }

    &.blocs_client-scli-title {
        font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        color: $grey_blue;
        font-size: 25px;
        margin-bottom: 0.5em;

        span {
            display: block;
            color: $blue-base;
        }
    }

    &.blocs_client-help-title {
        font-family: 'bentonsans-ultracondensed', Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        font-size: 25px;
        margin-bottom: 5px;
    }

    .prod-descriptif-header-leftcol & {
        margin: 0;
        font-size: 21px;
        color: $grey-blue;

        span {
            font-size: 17px;
            color: $purple-base;
        }
    }
}

h3, .styleH3 {
    font-size: 22px;

    .news_listing & {
        font-family: 'bentonsans-condensedregular', Arial, Helvetica, sans-serif;
        font-size: 19px;
        height: 66px;
        overflow: hidden;

        a {
            color: $black;

            &:hover {
                color: $green-base2;
            }
        }
    }

    .block-econtrat-content & {
        font-family: 'bentonsans-regular', Arial, Helvetica, sans-serif;
        font-style: italic;
        font-size: 17px;
        color: $grey-blue;
        text-align: center;
        line-height: 1.5em;

        span {
            display: block;
            color: $pink-base;
            font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
        }
    }

    .ec-list_item & {
        color: $pink-base;
        display: table;
        margin: 0 0 40px -73px;
        font-size: 26px;

        span {
            display: table-cell;
            vertical-align: middle;
        }

        .ec-list_item-titre-nb {
            @include bgimg('pink-bullet.png');
            vertical-align: top;
            width: 64px;
            height: 64px;
            color: $white;
            text-align: center;
            font: normal 17px/64px 'bentonsans-bold', Arial, Helvetica, sans-serif;
        }

        .ec-list_item-titre-txt {
            padding: 0 0 0 10px;
        }
    }

    .block-econtrat-subscribed & {
        padding: 15px;
        margin: 0;
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        color: $blue-base;
        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;

        span {
            display: block;
            color: $black;
        }
    }
}

h4, .styleH4 {
    font-size: 17px;

    .block-econtrat-col & {
        margin-bottom: 0.5em;
        text-transform: uppercase;
        color: $grey-blue;
        font-size: 12px;
        font-family: 'bentonsans-medium', Arial, Helvetica, sans-serif;
    }
}

h5, .styleH5 {

}

h6, .styleH6 {

}

p {
    margin: 0 0 25px 0;
    line-height: 17px;
}

ul, ol, dl {

    padding-left: 0;
    list-style-position: inside;
    margin: 0 0 25px 0;
}

ul {
    list-style-type: disc;

    .editorial-content & {
        list-style: none;

        li {
            position: relative;
            padding: 0 0 0 35px;

            &::before {
                content: "\e91e";
                font-family: 'icons' !important;
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                //color: $blue-base2;
            }
        }
    }
}

li {
    margin: 0 0 5px 0;
}

dd {
    margin-left: 0;
}

a {
    //color: $blue-base2;
    @include transition-ease-in-out();
    @include hover-focus-active {
        text-decoration: none;
    }
}

strong {
    color: $black;
}

.ftr-h4 {
    margin: 0 0 10px 0;
    color: $grey-blue;
    font: normal 15px/1em bentonsans-medium, sans-serif;

    a {
        color: $grey-blue;
    }
}

.mentions-footer {
    padding: 0;
    margin: 0;
    color: #495b69;
    font-size: 11px;
}

@media screen and (max-width: 767px) {
    h1 {
        .main-content &,
        .pharmacien-engagements .main-content &,
        .profil .main-content & {
            width: 100%;
            margin: 0;
            font-size: 27px;
            padding: 15px 20px 15px 20px;

            .h1-txt {
                margin: 0;
            }

            .h1-soustitre {
                display: none;
            }
        }

        .page .main-content & [class^="ic-"],
        .page .main-content & [class*=" ic-"] {
            font-size: 65px;
            width: 75px;
        }

        .pharmacien-services-patients .main-content &,
        .contrat_listing .main-content &,
        .pharmacien-equipe .main-content &,
        .pharmacien-dashboard .main-content & {
            width: 100%;
            margin: 0;
        }
    }
    h2 {
        .engagements-content & {
            font-size: 25px;
        }
    }
}

@media screen and (max-width: 550px) {
    .ec-list_item h3 {
        margin-left: -69px;

        .ec-list_item-titre-nb {
            -webkit-background-size: 55px auto;
            background-size: 55px auto;
            width: 55px;
            height: 55px;
            line-height: 55px;
        }
    }
    .ec-list_item-legend {
        margin: 0 0 10px 0;
    }
}
