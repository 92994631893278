#popinCompteDemo {
    @media (max-width: 999px) {
        width: 350px;
        padding-left: 0;
        overflow-y: auto;
    }

    @media (min-width: 1000px) {
        width: 650px;
    }

    .popin-base {
        width: 100%;
        padding: 0;
        background-size: 100% 100%;

        div {
            width: 100%;

            &.popin-top {
                background-color: #019CDE;
                color: $white;
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.2em;
                letter-spacing: 3px;
                padding: 7px 0;

                .popin-seriousgame-close {
                    float: right;
                    color: $white;
                    margin: 0 10px 0 0;
                }

                &.world2 {
                    background-color: $green-base;
                }
            }
        }

        @media (max-width: 500px) {
            .popin-sub-title {
                font-size: 16px;
                font-weight: bold;
                margin-top: 1px;
                margin-bottom: 1px;
            }
        }

        @media (min-width: 501px) {
            .popin-sub-title {
                font-size: 15px;
                font-weight: bold;
                line-height: 13px;
            }
        }

        .popin-content {
            margin-bottom: 0px !important;
            @include bgimg('prehome/BG_Prehome.png', no-repeat, 0%, 10%);
            background-repeat: no-repeat;
            background-size: cover;
            -webkit-background-size: cover;

            @media (max-width: 500px) {
                padding-left: 0 !important;

                .logo-cancer {
                    text-align: center;
                    display: table-cell;
                    vertical-align: middle;
                    width: 200px;
                    position: relative;
                }

                .card-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .card-mdmb {
                    display: inline-block;
                }

                p {
                    color: white;
                    text-align: center;
                    line-height: 13px;
                }
            }

            @media (min-width: 501px) {
                .logo-cancer {
                    text-align: center;
                    display: table-cell;
                    vertical-align: middle;
                    width: 330px;
                    top: 80px;
                    position: relative;
                    left: 126px;
                }

                .card-container {
                    display: flex;
                    align-content: stretch;
                }

                .card-mdmb {
                    display: inline-block;
                    margin-left: 30px;
                }

                p {
                    color: white;
                    text-align: center;
                }
            }

            .card {
                position: relative;
                background-color: rgba(255, 255, 255, 0.7);
                display: inline-block;
                border-radius: 6px;

                @media (max-width: 500px) {
                    width: 65%;
                    margin: 10px 10px 20px 10px;

                    img {
                        margin: 2px auto 0 auto;
                        padding: 1px;
                        max-width: 110px;
                    }

                    p {
                        margin-bottom: 9px;
                        font-size: 13px;
                        line-height: 13px;
                    }
                }

                @media (min-width: 501px) {
                    width: 45%;
                    margin-bottom: 20px;

                    img {
                        margin: 10px auto 0 auto;
                        padding: 5px;
                        max-width: 140px;
                    }
                }

                &.indisponible {
                    background-color: rgba(255, 255, 255, 0.4);
                }

                .popin-title {
                    background-color: #495b69;
                    font: normal 10px/20px bentonsans-medium, sans-serif;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    color: white;
                    width: 50%;
                    position: absolute;
                    top: 10px;
                    padding: 0px 5px;
                }

                p {
                    color: #2c4762;
                    font-weight: bold;
                }

                .card-body {
                    text-align: center;

                    .button {
                        width: 80%;
                        vertical-align: middle;
                        display: inline-block;
                        margin-bottom: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }

                    a {
                        color: white;
                        text-transform: uppercase;

                        .popin-icon-container {
                            color: white;
                            width: 30px;
                            margin: 0;
                        }
                    }

                    .popin-link {
                        display: flex;
                        justify-content: center;
                        padding: 5px 0px;

                        &-title {
                            align-self: center;
                            font: normal 10px/30px bentonsans-medium, sans-serif;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            margin-left: 5px;
                        }

                        &-title-long {
                            align-self: center;
                            font: normal 10px/30px bentonsans-medium, sans-serif;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            margin-left: 5px;
                            text-align: justify;
                            line-height: 12px;
                        }
                    }

                    .button-bg-pink {
                        background-color: #D35992;
                    }

                    .button-bg-orange {
                        background-color: #D16630;
                    }
                }
            }

            .card-minute-pharma {
                display: inline-block;
            }
        }
    }
}
