.block-top-visites
{
    border-left: 3px solid #cb2f7b;

    &-header {
        justify-content: space-between;
        background: #cb2f7b;

        &-icon-plus {
            margin: auto 0 auto auto;
            font-size: 21px;

            a {
                color: #ffffff;
            }

            a:hover {
                color: #ffffff;
            }
        }
    }

    &-content {
        font-size: 12px;
    }
}

.home-block-content.block-top-visites-content {
    color: #cb2f7b;
}

.block-top-visites-header {
    .home-block-header-icon {
        font-size: 25px;
        padding: 5px 0 5px 0;
    }
}


.block-manage-top-visites {

    &-header {
        background: #cb2f7b;
    }

    &-sub-block {
        margin: 10px 0;
        padding: 7px;
        background: #F2F4F6;

        &-header {
            margin-bottom: 10px;
            padding-bottom: 2px;
            border-bottom: 2px solid ;
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            color: #cb2f7b;

            &-title {
                margin: auto 0;
            }

            &-icon-plus {
                font-size: 19px;

                a {
                    color: #cb2f7b;
                }

                a:hover {
                    color: #cb2f7b;
                }
            }
        }
    }

    &-rdp {
        &-text {
            padding: 0 2px;
            font-size: 12px;

            a {
                color: #cb2f7b;
                text-decoration: underline;
            }
        }

        &-header {
            margin: 15px 0;
        }

        &-content {
            border-left: 1px solid #E7EBEE;
            margin: 0 10px;
            padding: 10px;

            &-line {
                margin-bottom: 20px;

                &-header {
                    margin-top: -10px;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                &-content {

                }
            }
        }

        &-icon {
            margin-left: -18px;
            margin-right: 5px;
        }

        .icon-red {
            color: #E65455;
        }

        .icon-green {
            color: $green-base;
        }
    }

    &-contract {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;

        &-number {
            font-size: 35px;
            font-weight: 700;
        }

        &-text {

        }
    }

    &-order {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;

        &-number {
            font-size: 35px;
            font-weight: 700;
        }

        &-text {}

        &-link {
            a {
                color: #cb2f7b;
                text-decoration: underline;
            }
        }
    }

    &-otc {
        &-line {
            display: flex;
            margin: 15px 0;

            &-icon {
                margin: auto 5px;

                img {
                    margin: unset;
                    width: 30px;
                }
            }

            &-content {
                margin: 0 5px;
                display: flex;
                flex-direction: column;

                &-title {
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;

                    .is-otc {
                        color: $green-base;
                    }

                    .is-not-otc {
                        color: #E53838;
                    }
                }

                &-text {
                    font-size: 12px;
                }
            }
        }

        &-separator {
            width: 100%;
            border: 1px solid #DDE3EB;
        }
    }
}
