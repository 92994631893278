.commande-flash-overview {
    background: #E9EDEF;

    &-text {
        text-align: center;
        font-family: "bentonsans-book", sans-serif;
        font-size: 16px;
        color: #485E68;
        margin: 25px auto;
        width: 60%;
        padding: 0 10px;

        &-gras {
            font-family: "bentonsans-bold", sans-serif;
        }
    }

    .commande-help-and-recap {
        display: flex;
        justify-content: space-between;
        width: 60%;
        margin: 0 auto;

        &-help {
            padding: 10px 20px;
            background-color: #FFFFFF;
            text-align: center;
            font-family: 'bentonsans-bold', sans-serif;
            margin-top: 15px;
            height: 100%;

            &-callUs {
                color: #64275D;
                font-size: 16px;
                margin: 10px auto;

                span {
                    font-family: "Bentonsans-book", sans-serif;
                    font-size: 12px;
                }
            }

            &-callNumberIcon {
                display: flex;
                padding: 7px 12px;
                background-color: #64275D;

                &-icon {
                    margin: 0 10px 0 0;
                }

                &-num {
                    align-self: center;
                    color: #FFFFFF;
                    font-size: 16px;
                }
            }

            &-tarif {
                font-family: "Bentonsans-book", sans-serif;
                font-size: 12px;
                color: #62197B;
                margin-top: 10px;
            }
        }

        &-recap {
            background-color: #FFFFFF;
            font-family: 'bentonsans-bold', sans-serif;
            margin-top: 15px;
            margin-bottom: 15px;
            text-align: end;
            padding: 10px;

            &-totalSansRemise {
                font-size: 11px;
                font-family: Bentonsans-book, sans-serif;
                color: #485E68;
                margin: 10px;

                &-gras {
                    font-family: bentonsans-bold, sans-serif;
                }
            }

            &-montantRemise {
                margin: 10px;
                font-size: 12px;
                font-family: bentonsans-bold, sans-serif;
                color: #485E68;
            }

            &-totalHT {
                margin: 10px;
                font-size: 16px;
                font-family: bentonsans-bold, sans-serif;
                color: $green-base;
            }

            &-validation {
                text-align: center;

                &-btn {
                    background: $green-base;
                    color: #FFFFFF;
                    padding: 8px 12px;
                    font-family: bentonsans-condensedbold, sans-serif;
                    font-size: 16px;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    &-icon {
                        margin: 0 10px 0px 0px;
                    }
                }

                &-url {
                    text-decoration: none;
                    color: #FFFFFF;
                    align-self: center;
                }

                &-url:hover {
                    text-decoration: none;
                    color: #FFFFFF;
                }
            }
        }
    }

    &-footer {
        text-align: center;
        font-family: "Bentonsans-book", sans-serif;
        font-size: 10px;
        color: #485E68;
        padding: 0 20%;
        margin: 30px auto 15px auto;

        &-num-po {
            margin: 10px auto;
        }

        &-texte {
            color: #000000;
            margin: 10px auto;
        }
    }
}

.commande-date-livraison {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;

    &-texte {
        color: #485E68;
        text-align: center;
        font-size: 12px;
        font-family: Bentonsans-book, sans-serif;
        padding: 10px;
    }
}

@media screen and (max-width: 767px) {
    .commande-flash-overview {
        &-text {
            width: 95%;
            padding: unset;
        }

        .commande-help-and-recap {
            flex-direction: column-reverse;
        }

        &-footer {
            padding: 0 5%;
        }
    }
}

@media screen and (min-width: 770px) and (max-width: 1000px) {
    .commande-help-and-recap-help {
        &-callUs-gras {
            font-size: 14px;
        }

        &-callNumberIcon-num {
            font-size: 13px;
        }
    }

    .commande-help-and-recap-recap-validation-btn {
        font-size: 13px;
        margin-left: 10px;
    }
}

@media screen and (min-width: 1500px) {
    .commande-flash-overview {
        &-text {
            width: 85%;
        }

        .commande-help-and-recap {
            width: 85%;
        }
    }

    .commande-date-livraison {
        width: 85%;
    }
}
