.simotc-calculator-priceline {
    @extend %display-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 100px;
    margin: 1rem 0;

    .simotc-calculator-priceline-legend {
        margin-right: 2rem;
        color: $white;

        @media handheld, screen and (max-width: 768px) {
            font-weight: bold;
            position: absolute;
            margin-right: inherit;
            left: -4px;
            top: 22px;
            font-size: 12px;
        }
    }

    .simotc-calculator-priceline-arrow {
        font-size: 2.5rem;
        background-color: white;
        position: relative;
        @extend %display-flex;
        flex-direction: row;
        align-items: center;
        border-radius: 1em;
        width: 80%;
        height: 5px;

        @media handheld, screen and (max-width: 768px) {
            width: 100%;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 0;
            background-color: white;
            width: .7em;
            height: .2em;
            border-radius: 1em;
        }
        &::before {
            top: 50%;
            transform: translate(0, -.15em) rotate(-45deg);
            transform-origin: 100% 50%;
        }
        &::after {
            top: 50%;
            transform: translate(0, -.05em) rotate(45deg);
            transform-origin: 100% 50%;
        }

        .priceline-current {
            z-index: 1;
            position: absolute;
            display: block;
            padding: 0.5rem 1.5rem;
            font-size: 15px;
            color: $simotc-blue;
            background-color: $white;
            border-radius: 5px;
            font-family: bentonsans-medium;
            bottom: 20px;

            &::after {
                content: '';
                position: absolute;
                left: calc(50% - 20px);
                top: 70%;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 10px solid transparent;
                border-top: 30px solid $white;
                clear: both;
                transform: rotate(45deg);
                z-index: -1;
            }

            @media handheld, screen and (max-width: 768px) {
                top: 20px;
                bottom: inherit;

                &::after{
                    border-top: inherit;
                    border-left: 10px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 30px solid $white;
                    transform: rotate(-45deg);
                    top: -60%;
                }

                &.inversed::after{
                    border-top: inherit;
                    border-bottom: 30px solid $white;
                    border-left: 5px solid transparent;
                    border-right: 10px solid transparent;
                    top: -60%;
                    right: calc(50% - 20px);
                    transform: rotate(45deg);
                    left: inherit;
                }
            }
        }

        .priceline-dot {
            width: 6px;
            height: 18px;
            background-color: white;
            border-radius: 1em;
            display: block;
            position: relative;
            z-index: 1;

            &.priceline-dot-min {
                background-color: $green-dashboard-light;
            }

            &.priceline-dot-moy {
                background-color: $orange-base;
            }

            &.priceline-dot-max {
                background-color: $pink-base;
            }

            span {
                position: absolute;
                font-size: 12px;

                &.label {
                    top: 20px;
                    left: -20px;
                    width: 40px;
                    text-align: center;
                    font-family: Bentonsans-book;
                    text-transform: capitalize;

                    @media handheld, screen and (max-width: 768px) {
                        bottom: 20px;
                        top: inherit;
                    }

                }
            }

        }
    }
}

.simotc-calculator-block-content-left {
    .simotc-calculator-priceline-arrow {
        .priceline-current {
            font-size: 17px;

            @media handheld, screen and (max-width: 768px) {
                font-size: 15px;
            }
        }
    }
}
