@charset "UTF-8";
.rdp-content_rightcol {
    width: 69%; //73%
    float: left;
    max-width: 900px;
    border-left: 1px solid $grey-lighter;

    h2.rdp-title {
        margin: 30px 0 30px 20px;
        color: $blue-base;
    }

    .block-title {
        color: $blue-base;
        text-transform: uppercase;
        font: normal 14px/1.8em "bentonsans-medium", sans-serif;
    }

    .block-sub-title {
        color: $blue-base-dark;
        font-weight: bold;
        margin-top: 25px;
    }

    .block-list-container {
        border-bottom: 3px solid $blue-base;
    }

    .rdp-gif {
        margin: 0 auto;
        width: 305px;
    }

    .block-loyality {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;

        &-top {
            background-color: $rdpBlueDark;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            font-family: 'bentonsans-book';
            padding: 15px;
            width: 725px;
            height: 244px;

            &-title {
                display: none;
            }

            &-icon {
                margin-top: 0px;
                margin-right: auto;
                margin-bottom: 0px;
                margin-left: auto;

            }

            &-text {
                text-transform: uppercase;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 21.5px;
                margin-top: 10px;
                display: inline-block;

                &-number {
                    color: $rdpBlueLight;
                    font-family: 'bentonsans-condensedmedium';
                }
            }

            &-text-btm {
                margin-top: 0;
            }

            &-nember {
                color: $rdpBlueLight;
                font-size: 32px;
                font-weight: 700;
                margin-top: 22px;
                display: inline-block;
            }

            &-separation {
                border-top: 1px solid $rdpBlueLight;
                transform: rotate(90deg);
                margin-bottom: 16px;
                width: 36px;
            }

            &-plus {
                color: $rdpBlueLight;
                font-size: 32px;
                line-height: 49px;
            }

            &-item {
                text-align: center;
                padding: 5px 15px 25px 15px;

                &-1 {
                    align-self: flex-start;
                }
            }

        }

        &-bottom {
            background-color: $rdpBlueLight;
            margin-top: -2.5vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 25px 25px 25px;
            min-width: 33%;
            font-family: 'bentonsans-condensedmedium';
            font-size: 24px;
            font-weight: 700 !important;
            color: $rdpBlueDark;

            &-icon-equal {
                font-weight: 500;
                font-size: 40px;
                line-height: 40px;
            }

            &-text {
                text-transform: uppercase;
                font-size: 24px;

                &-1 {
                    font-size: 40px;
                }
            }

            //&-subtext {
            //    font-size: 18px;
            //    color: #c2e9f2;
            //}

            &-icon {
                margin-top: 0px;
                margin-right: auto;
                margin-bottom: 5px;
                margin-left: auto;
                color: $rdpBlueDark;
                font-size: 16px;
                font-weight: bolder;
            }

            &-marker {
                color: $rdpBlueDark;
                font-weight: normal;
                font-size: 16px;
                top: -1.4em;
            }
        }
    }

}

.rdp-fact-selections > div {
    color: $blue-base;

    h4 {
        font-family: robotocondensed;
        text-transform: uppercase;
        font-stretch: extra-condensed;
        font-weight: bold;
    }

    ul {
        list-style-position: outside;
        line-height: 1.2;
        padding-left: 15px;
    }

    li {
        margin-bottom: 20px;
    }
}

.pharmacien-dashboard .breadcrumb {
    margin-bottom: 0px;
}

.comprendre-rdp-menu {
    a::before,
    li::before {
        color: $blue-base;
    }
}

.comprendre-rdp-content {
    background-color: white;

    .content_leftcol-menu {
        li {
            a {
                &:hover,
                &:focus,
                &:active {
                    color: $blue-base;
                }

                > span.hover {
                    color: $blue-base;
                }
            }
        }
    }
}

div#blockListOpenablesLinks {
}

symbol#bioPlus, symbol#bioMoins {
    line {
        stroke: rgb(255, 255, 255);
        stroke-width: 2;
    }

    circle {
        fill: $blue-base3;
    }
}

symbol#bioPlusSel {
    line {
        stroke: $blue-base3;
        stroke-width: 2;
    }

    circle {
        fill: white;
    }
}


@-webkit-keyframes rotateIn {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@-moz-keyframes rotateIn {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@-o-keyframes rotateIn {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes rotateIn {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.rdp-list_item-leftcol {
    float: left;
    width: 60%;
    padding: 0 70px 0 0;
    border-right: 1px solid $grey-lighter;
}

.rdp-list_item-rightcol {
    position: relative;
    float: left;
    width: 40%;
    padding: 10px 50px 20px 20px;

    &:after {
        @include bgimg('comprendre-rdp-leftarrow.png');
        content: "";
        position: absolute;
        left: -18px;
        top: 50%;
        margin: -9px 0 0 0;
        width: 19px;
        height: 19px;
    }
}

.rdp-list_item {
    padding: 15px 0 0 20px;
    margin: 0 0 20px 0;
    color: $grey-blue-light;

    strong {
        color: inherit;
    }

    h3 {
        color: $grey-blue2;
    }

    &#rpd_point3 {
        padding-bottom: 20px !important;
    }

    #servicesutiles + &, #disponibilite + & {
        background-color: lighten($blue-base3, 50%);
    }
}

.rdp-list_item-content {
    line-height: 1.8em;
}

.rdp-list_item-ic {
    @include border-radius(50%);
    background-color: $blue-base3;
    width: 63px;
    height: 63px;
    line-height: 63px;
    font-size: 40px;
    text-align: center;
    color: $white;

    .rdp-list_item2 & {
        font-size: 52px;
    }
}

.rdp-list_item-number {
    margin: 30px 0 15px 0;
    color: $blue-base3;
    font: normal 33px/1em 'bentonsans-regular', sans-serif;
    white-space: nowrap;

    sup {
        font-size: 11px;
        position: relative;
        top: -20px;
    }
}

.rdp-list_item-legend {
    font-size: 13px;
    margin-top: -50px;
    padding-top: 50px;
}

.rdp-notation {
    background-color: $blue-base2;
    padding: 30px 85px;
    text-align: center;
    margin: 50px 0;
}

.rdp-notation-nb {
    margin: 0 0 15px 0;
    font: normal 42px/1em 'bentonsans-regular', sans-serif;
    color: $white;

    sup {
        font-size: 12px;
        position: relative;
        top: -25px;
    }
}

.rdp-notation-txt {
    color: lighten($blue-base2, 25%);
    font-size: 13px;
}

.rdp-list-legend {
    counter-reset: legends;
    list-style-type: none;
    padding: 40px 0 0 50px;
    margin: 0 0 0 -20px;
    font-size: 11px;

    li {
        counter-increment: legends;
        position: relative;

        &:before {
            position: absolute;
            left: -15px;
            content: "(" counter(legends) ")";
            margin: 0 20px 0 -20px;
        }

        a {
            text-decoration: underline;
            color: inherit;
            font-weight: bold;
        }
    }
}

.svg-anim svg {
    margin: 10px 0 0;
    padding-right: 1px;
    padding-top: 5px;
    transform: scale(1.4);
}

.svg-anim path {
    stroke: #ffffff;
    fill: none;
    stroke-width: 0.019em;
    /* fill: white;
     stroke: transparent;*/
}

.svg-anim #svgPill, .svg-anim #svgCup {
    stroke-width: 0.03em;
}

.svg-anim #svgClock {
    margin: 0;
    padding-top: 0;
    transform: scale(1.5);
}

.svg-anim #svgSilhouette {
    margin-top: 16px;
    padding-top: 0;
    transform: scale(1.7);
}

.svg-anim #svgSilhouette path {
    /* fill: white;
     stroke: transparent;*/
}

/* ajout slider */
.comprendre-rdp_slider {
    float: right;
    margin: 15px 5% 0 0;
    position: relative;
    transition: all 0.4s ease 0s;
    width: 26%;

    .bx-wrapper {
        margin: 0;
        padding: 20px 0 20px 35px;
        min-height: 190px;
        border-left: 2px solid $pink-base;
        background-color: $white;

        img {
            margin: 0 auto;
            height: auto;
        }

        .bx-controls {
            left: 0;
            position: absolute;
            top: 15px;
            width: 52px;
        }

        .bx-controls-direction {
            height: 90px;
            margin: 50px 0 0 -16px;
            position: relative;
            width: 34px;

            a.bx-next, a.bx-prev {
                display: block;
                left: 0;
                position: absolute;
                background-color: $pink-base;
            }

            a.bx-next {
                border-top: 1px solid lighten($pink-base, 30%);
                top: 62px;
            }

            a.bx-prev {
                top: 20px;
            }
        }
    }

    .ic-ul-arrow {
        width: 49px;
        height: 63px;
        cursor: pointer;
        position: absolute;
        left: -20px;
        background-color: $pink-base;
        color: $white;
        text-align: center;
        line-height: 63px;
        font-size: 23px;

        &#comprendre-rdp_slider-prev {
            top: 108px;

            &:before {
                transform: rotate(180deg);
                display: inline-block;
            }
        }

        &#comprendre-rdp_slider-next {
            top: 44px;
        }

        a {
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

/*-- AOS ajout durée delai (limité à 3000 ---*/
// Generate Duration && Delay
[data-aos] {
    @for $i from 60 through 200 {
        body[data-aos-duration='#{$i * 50}'] &,
        &[data-aos][data-aos-duration='#{$i * 50}'] {
            transition-duration: #{$i * 50}ms;
        }

        body[data-aos-delay='#{$i * 50}'] &,
        &[data-aos][data-aos-delay='#{$i * 50}'] {
            transition-delay: 0;

            &.aos-animate {
                transition-delay: #{$i * 50}ms;
            }
        }
    }
}

@media screen and (min-width: 767px) {
    // Gestion menu
    .absolutemenu {
        position: fixed;
        top: 0;

        & + .comprendre-rdps-content-rightcol {
            position: relative;
            left: 200px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .rdp-content_rightcol {

        .block-loyality {
            padding: 30px 0 30px 0;
            &-top {
                flex-direction: column;
                width: 100%;
                height: auto;


                &-title {
                    display: block;
                    font-family: "bentonsans-condensedbold";
                    font-weight: 700;
                    font-size: 24px;
                    color: white;
                    text-align: center;
                    margin-bottom: 20px;
                }

                &-text {
                    margin: 15px 0;


                    &-number {
                        margin: 0;
                    }
                }

                &-separation {
                    display: none;
                }

                &-plus {
                    font-size: 50px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                &-item {
                    margin-bottom: 0;
                    display: flex;
                    flex-direction: column;
                    padding: 0 15px 0 15px;;
                }
            }

            &-bottom {
                background-color: white;
                text-align: center;
                margin-top: 0;
                width: 100%;

                &-text {
                    font-size: 18px;

                    &-1 {
                        font-size: 32px;
                    }
                }
            }
        }
    }

    .rdp-list_item-leftcol {
        width: 65%;
        padding: 0 25px 0 0;
    }

    .rdp-list_item-rightcol {
        width: 35%;
        padding: 0 10px 0 20px;
    }

    .comprendre-rdp_slider {
        width: 35%;
    }

    .rdp-list_item-number {
        font-size: 22px;

        sup {
            top: -10px;
        }
    }

    .rdp-list_item {
        h3 {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width: 950px) {
    .comprendre-rdp_slider {
        width: 50%;
    }
}

@media screen and (max-width: 767px) {
    .rdp-content_rightcol {
        width: 100%;
    }

    .comprendre-rdps-content-leftcol {
        display: none;
    }

    .comprendre-rdps-content-rightcol {
        border: none;
        padding: 40px 20px 0 18px;
        width: 100%;
    }

    .comprendre-rdp_slider {
        /*width: 100%;
        float: none;*/
    }
    .rdp-list_container {
        margin: 0 0 0 30px;
    }
    .rdp-list_item {
        h3 {
            margin-bottom: 10px;
        }
    }
    .rdp-list-legend {
        margin: 0;
        padding: 30px 0 30px 35px;
    }

    .comprendre-rdps-content-rightcol .rdp-list_item .rdp-list_item-titre-nb {
        width: 55px;
        height: 55px;

        &::before {
            width: 55px;
            height: 55px;
            background-size: 55px auto;
        }
    }
    .svg-anim {

        #svgPill {
            margin-top: 7px;
        }

        #svgClock, #svgSilhouette {
            margin: 11px 0 0 0;
            transform: scale(1.4);
        }

        #svgCup {
            margin-top: 8px;
        }

        #svgStar {
            margin-top: 6px;
        }
    }
}

@media screen and (max-width: 550px) {
    .rdp-list_item {
        margin: 0;

        h3 {
            font-size: 20px;
        }
    }
    .rdp-list_item-ic {
        font-size: 30px;
        width: 53px;
        height: 53px;
        line-height: 53px;

        .rdp-list_item2 & {
            font-size: 35px;
        }
    }
    .rdp-list_item-leftcol {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }
    .comprendre-rdp_slider {
        width: 100%;
        padding-left: 23px;
    }
    #comprendre-rdp_slider-prev,
    #comprendre-rdp_slider-next {
        left: 0;
    }
    .rdp-list_item-rightcol {
        width: 100%;
        float: none;
        display: table;

        &:before {
            height: 100%;
            top: 0;
        }

        &:after {
            display: none;
        }

        .rdp-list_item-mobilleftcol {
            display: table-cell;
            vertical-align: top;
            width: 53px;
        }

        .rdp-list_item-mobilrightcol {
            display: table-cell;
            padding: 0 0 0 15px;

            .rdp-list_item-number {
                margin: 0 0 5px 0;
            }
        }
    }
    .rdp-notation {
        padding: 30px;
    }
}

.h1-col-rdp {
    padding-left: 200px;
}
