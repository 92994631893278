@charset "UTF-8";
//@if $class == xs {
//  @media (max-width: 767px) { @content; }
//}
//
//@else if $class == sm {
//  @media (min-width: 768px) { @content; }
//}
//
//@else if $class == md {
//  @media (min-width: 992px) { @content; }
//}
//
//@else if $class == lg {
//  @media (min-width: 1200px) { @content; }
//}

///************************************************************
// *  @_00 : XXS : max-width: 480px
// ************************************************************/
@include responsive($max: 480px) {

}
///************************************************************
// *  @_00 : XS : max-width: 767px
// ************************************************************/
@include responsive(xs) {
}
///************************************************************
// *  @_00 : SM : min-width: 768px
// ************************************************************/

@include responsive(sm) {
}


///************************************************************
// *  @_00 : MD : min-width: 992px
// ************************************************************/

@include responsive(md) {
}

///************************************************************
// *  @_00 : LG : min-width: 1200px
// ************************************************************/

@include responsive(lg) {


}