@charset "UTF-8";

// General layout
.news_listing .main-content {
    background-color: $grey-really-lighter; //$grey-bodybg
}

// Highlighted news
.news_listing-hltdnews_container {
    margin: 0 -20px 40px -20px;
}

.news_listing-hltdnews_col {
    width: 49%;
    float: left;

    &:first-child {
        margin: 0 1% 0 0;
    }
}

.hltdnews-img {
    @include border-radius(3px);
    width: 100%;
    padding: 0 0 45% 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-background-size: cover;
    background-size: cover;
}

.hltdnews-content {
    position: relative;
    width: 85%;
    min-height: 200px;
    padding: 20px;

    .news_listing-hltdnews_col:first-child & {
        background-color: $orange-base;
        margin: -70px auto 0 auto;
    }

    .news_listing-hltdnews_col:last-child & {
        background-color: $orange-base;
        margin: -70px auto 0 auto;
    }
}

.hltdnews-content-accroche {
    font-size: 30px;
    margin: 0 0 5px 0;

    .news_listing-hltdnews_col:first-child & {
        color: lighten($green-newsHl1, 30%);
    }

    .news_listing-hltdnews_col:last-child & {
        color: lighten($green-newsHl2, 30%);
    }
}

.hltdnews-content-date {
    text-transform: capitalize;
    font-size: 12px;
    color: $white;
}

.hltdnews-content-liresuite {
    background-color: $white;
    text-transform: uppercase;
    font-family: 'bentonsans-condensedregular', sans-serif;
    padding: 6px 10px;
    margin-top: 15px;

    .news_listing-hltdnews_col:first-child & {
        color: $orange-base;
    }

    .news_listing-hltdnews_col:last-child & {
        color: $orange-base;
    }
}

// News filters
.news_listing-filter {
    margin: 0 0 20px 0;
}

.news_listing-filter-label {
    display: inline-block;
    margin: 0 10px 0 0;
    color: $grey-blue;
    font-size: 12px;
}

// Listing
ul.news_listing {
    li {
        @include border-radius(4px);
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 31.5%;
        height: 471px;
        background-color: $white;
        border: 1px solid lighten($grey-lighter, 8%);
        padding: 25px 20px;
        margin: 0 0 2% 2%;

        &::before {
            content: "";
            display: block;
            @include border-top-left-radius(3px);
            @include border-top-right-radius(3px);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 3px;
            background-color: $blue-base2;
        }

        &:nth-child(3n+1) {
            margin-left: 0;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1050px) {
    ul.news_listing {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        li {
            width: 45%;
            margin: 15px 0px;
        }
    }
}

@media screen and (min-width: 840px) and (max-width: 899px) {
    ul.news_listing {
        li {
            width: 42%;
        }
    }
}

@media screen and (min-width: 900px) and (max-width: 1050px) {
    ul.news_listing {
        li {
            width: 40%;
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px)  {
    ul.news_listing {
        li {
            width: 32%;
            padding: 20px 15px;
            margin: 15px 5px;
        }
    }
}

// Bloc news
.news_listing-date {
    position: relative;
    margin: 0 0 30px 0;
    font-size: 13px;
    line-height: 1.3em;
    color: $grey-blue-light;
    text-transform: capitalize;

    span {
        display: block;
        color: $grey-blue;
        font-size: 15px;
    }

    a {
        text-decoration: underline;
        color: $grey-blue-light;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -13px;
        display: block;
        width: 38px;
        height: 2px;
        background-color: $grey-lighter;
    }

    &::after {
        content: "";
        position: absolute;
        left: 38px;
        bottom: -12px;
        display: block;
        width: 40%;
        height: 1px;
        background-color: $grey-bodybg;
    }
}

.news_listing-img_ctnr {
    display: block;
    overflow: hidden;
    margin: 0 -20px 20px -20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-background-size: cover;
    background-size: cover;
    height: 132px;
}

.news_listing-intro {
    margin: 0;
    font-size: 13px;
    line-height: 1.4em;
    color: $grey-blue-light;
}

.news_listing-liresuite {
    @include border-radius(10px);
    display: inline-block;
    position: absolute;
    left: 20px;
    bottom: 25px;
    padding: 0 20px;
    border: 1px solid $grey-blue-light;
    color: $grey-blue-light;
    font-size: 20px;
    text-align: center;
    @include hover-focus-active {
        color: $green-base2;
        border-color: $green-base2;
    }
}

.news-header {
    background-color: $orange-base !important;
}

.hltdnews-content {
    &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-category {
        font-size: 12px;
        color: $white;
        border: 1px solid $white;
        padding: 5px 6px;
        border-radius: 5px;
    }
}

.news_listing {
    &-category {
        display: block;
        color: $blue-base2;
        margin-bottom: 25px;
        margin-left: 10px;

        span {
            border: 1px solid #e6e6e6;
            padding: 3px 5px;
            border-radius: 5px;
        }

        div {
            border: 1px solid #e6e6e6;
            padding: 3px 7px;
            border-radius: 8px;
            width: fit-content;
        }

    }

    &-title {
        display: block;
        margin-bottom: 15px;
        text-decoration: none;
        font-family: 'bentonsans-condensedregular', sans-serif;
        font-size: 16px;
        color: $black;
        height: 66px;
    }

}


// Fin bloc news
/* Media queries */
@media screen and (min-width: 767px) and (max-width: 887px) {
    ul.news_listing {
        li {
            height: 500px;
        }
    }
}

@media screen and (min-width: 600px) and (max-width: 930px) {
    .hltdnews-content {
        height: 260px;
    }
    .hltdnews-content-accroche {
        font-size: 20px;
    }
    h2.hltdnews-content-titre {
        padding: 0;
    }
    .hltdnews-content-date {
        position: static;
    }
}

@media screen and (max-width: 767px) {
    ul.news_listing {
        li {
            width: 100%;
            height: auto;
            margin: 0 0 20px 0;
        }

        h3 {
            height: auto;
            margin: 0;
        }
    }
    .news_listing-img_ctnr {
        float: left;
        width: 30%;
        margin: 0;
    }
    .news_listing-intro {
        display: none;
    }
    .new_listing-header {
        float: right;
        width: 65%;
    }
    .news_listing-liresuite {
        @include border-radius(8px);
        right: 15px;
        bottom: 15px;
        left: auto;
        padding: 0 15px;
        font-size: 16px;
        line-height: 30px;
    }
    .news_listing-date {
        margin: 0 50px 30px 0;
    }
    .news_listing-category {
        margin-bottom: 10px;
    }
    .news_listing {
        &-category {
            div {
                text-align: center;
                width: fit-content;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .newslisting-content {
        padding-top: 0;
    }
    .news_listing-hltdnews_col {
        width: 100%;
        float: none;

        &:first-child {
            display: none;
        }
    }
    .hltdnews-content {
        height: auto;
        padding: 20px 20px 40px 20px;

        .news_listing-hltdnews_col:last-child & {
            margin: -170px auto 0 auto;
        }
    }
    h2.hltdnews-content-titre {
        padding: 0;
    }
    .hltdnews-img {
        padding: 0 0 55% 0;
    }
    .hltdnews-content-date {
        position: static;
    }
    .hltdnews-content-liresuite {
        right: 0;
        bottom: 0;
    }
}

@media screen and (max-width: 480px) {
    ul.news_listing h3 {
        font-size: 16px;
    }
    .hltdnews-content {
        .news_listing-hltdnews_col:last-child & {
            margin: -130px 20px 0 0;
        }
    }
}

@media screen and (max-width: 360px) {
    .news_listing-img_spacermobile {
        padding: 0 0 150px 0;
    }
}
