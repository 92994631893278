/* Popin Engagement Conseil */
.popinDisplayCgu {
    .popin-base {
        width: 100%;
        padding: 0;

        div {
            width: 100%;

            &.popin-title {
                color: $white;
                text-align: center;
                text-transform: uppercase;
                font: Condensed Book 15px/28px BentonSans;
                letter-spacing: 0.75px;
                padding: 7px 0;

                .popin-close {
                    float: right;
                    color: $white;
                    margin: 0 10px 0 0;
                }

                &.biogaran-blue-popin {
                    background-color: $blue-base4;
                }
            }
        }

        .popin-content-container {
            width: 600px;
            height: 300px;
            border-radius: 1px;
            border: 1px solid #cccccc;
            margin: 15px;

            .popin-content {
                margin: 15px;
                text-align: left;
                height: 90%;
                width: 97%;
                overflow-y: scroll;
                overflow-x: hidden;
                font-size: 13px;
                color: #888;
            }

        }

        .popin-button-container {
            margin-top: 5px;
            margin-bottom: 20px;

            .button-accept {
                text-transform: uppercase;
                background-color: #93B90D;
                padding: 2px 35px;
                color: $white;
                font: normal 10px/30px bentonsans-medium, sans-serif;
                font-weight: normal;
                letter-spacing: 2px;
            }

            .button-pass {
                color: #8c8c8c;
                float: right;
                margin-top: 10px;
                margin-right: 15px;
                cursor: pointer;
                text-decoration: underline;
            }
        }

        /* width */
        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}
