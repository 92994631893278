.block-engagement {
    border-left: 3px solid #004C97;

    &-header {
        justify-content: space-between;
        background: #004C97;

        &-icon-plus {
            margin: auto 0 auto auto;
            font-size: 21px;

            a {
                color: #ffffff;
            }

            a:hover {
                color: #ffffff;
            }
        }
    }

    &-content {
        font-size: 12px;
        color: #485E68 !important;
    }
}
