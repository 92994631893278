.block-manage-pharmacy-rdp {
    &-text {
        margin-bottom: 18px;
        font-family: 'bentonsans-regular', sans-serif;
        font-size: 11px;
        color : #485E68;

        a {
            color: $blue-base;
            text-decoration: underline;
        }
    }

    &-content {
        border-left: 1px solid #E7EBEE;
        margin: 0 10px;
        padding: 10px;

        &-line {
            margin-bottom: 50px;

            &-header {
                margin-top: -10px;
                font-family: bentonsans-condensedbold,sans-serif;
                font-size: 12px;
                color: #485E68;
                text-transform: uppercase;
            }

            &-content {
                // Graph
                .graph-bar {
                    clear: both;
                    position: relative;
                    margin: 0 0 25px 0;

                    .legend {
                        margin: 7px 0 5px 0;
                        display: flex;
                        justify-content: space-between;
                        color: #B2BAC5;
                        font-size: 10px;
                        font-family: bentonsans-regular, sans-serif;
                    }

                    .track {
                        clear: both;
                        position: relative;

                        &::before {
                            content: "";
                            display: block;
                            @include border-radius(2px);
                            height: 3px;
                            width: 100%;
                            background-color: $grey-lighter;
                        }

                        .bar {
                            @include border-radius(5px);
                            height: 10px;

                            margin-top: -6px;
                            position: absolute;
                            overflow: hidden;
                        }

                        .orange-bar {
                            background-color: $orange-base;
                        }

                        .green-bar {
                            background-color: $green-base;
                        }

                        .red-bar {
                            background-color: #E65455;
                        }
                    }

                    .cursor-position {
                        position: absolute;
                        margin-top: 7px;
                        width: fit-content;
                        font-size: 11px;
                        font-family: bentonsans-bold, sans-serif;
                    }

                    .green-cursor {
                        color: $green-base;
                    }

                    .orange-cursor {
                        color: $orange-base;
                    }

                    .red-cursor {
                        color: #E53838;
                    }
                }
            }
        }

        .eligibility-block {
            margin-bottom: -10px !important;
            &-content {
                margin-top: -10px;
                margin-left: 10px;
            }

            .eligible-title {
                font-size: 16px;
                color: $green-base;
            }

            .eligible-content {
                font-size: 11px;
                color: $green-base;
            }

            .non-eligible-title {
                font-size: 16px;
                color: #E53838;
            }

            .non-eligible-content {
                font-size: 11px;
                color: #E53838;
            }
        }
    }

    &-icon {
        margin-left: -16px;
        margin-right: 5px;
    }

    .in-progress-term {
        padding: 12px;
        background: #FFFFFFBF 0 0 no-repeat padding-box;
        border: 1px solid $blue-base;
        border-radius: 4px;

        &-header {
            margin-bottom: 18px;
            display: flex;
            justify-content: space-between;
            color: $blue-base;
            text-transform: uppercase;

            &-left {
                font-size: 12px;
                font-family: bentonsans-bold, sans-serif;
            }

            &-right {
                font-size: 10px;
                font-family: bentonsans-regular, sans-serif;
            }
        }
    }

    .icon-red {
        color: #E65455;
    }

    .icon-orange {
        color: #F09A04;
    }

    .icon-green {
        color: $green-base;
    }

    .icon-img {
        img {
            margin: 0 0 -10px -10px;
            display: initial;
            width: 30px;
        }
    }

}

