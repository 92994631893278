.simotc-input-group {
    @extend %display-flex;
    flex-direction: column;
    align-items: center;

    &.simotc-input-group-row-mobile {
        @media handheld, screen and (max-width: 768px) {
            flex-direction: row;
            justify-content: center;
        }
    }

    .simotc-input-label {
        color: $white;
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-family: bentonsans-condensedmedium;
        font-size: 15px;

        @media handheld, screen and (max-width: 768px) {
            margin-bottom: 0;
        }
    }

    .simotc-input-symbol-euro {
        margin: 1rem 0;
        color: $simotc-blue;
        position: relative;
        @extend %display-flex;
        flex-direction: row;
        background-color: $white;

        input {
            @media handheld, screen and (max-width: 768px) {
                width: 100px;
                font-size: 20px;
                height: 32px;
                padding: 0.5rem 2rem 0.5rem 0.5rem;
            }

            font-family: 'bentonsans-regular';
            width: 120px;
            border: 0;
            padding: 0.5rem 3rem 0.5rem 0.5rem;
            text-align: center;
            color: $simotc-blue;
            font-size: 24px;
            height: 40px;
        }

        .simotc-input-pna-bgr {
            font-family: bentonsans-bold;
        }

        .simotc-input-marge-bgr {
            font-family: bentonsans-medium;
        }

        .simotc-input-ppttc-bgr {
            font-family: bentonsans-medium;
        }

        .simotc-input-pna-otc {
            font-family: bentonsans-light;
        }

        .simotc-input-marge-otc {
            font-family: bentonsans-light;
        }

        .simotc-input-pptc-otc {
            font-family: bentonsans-light;
        }

        &::before {
            position: absolute;
            right: 1rem;
            //font-family: 'bentonsans-regular';
            color: $simotc-blue;
            font-size: 24px;
            content: "€";
            height: 40px;
            line-height: 40px;

            @media handheld, screen and (max-width: 768px) {
                font-size: 16px;
                height: 32px;
                line-height: 32px;
            }
        }

    }

    .simotc-input-btn-group {

        &.simotc-input-symbol-euro:before {
            right: calc(1rem + 40px);

            @media handheld, screen and (max-width: 768px) {
                right: calc(1rem + 32px);
            }

        }

        button.btn {
            width: 40px;
            padding: 0;

            @media handheld, screen and (max-width: 768px) {
                width: 32px;
                height: 32px;
            }

            span.hoverEffect {

                i.fas, i.fab, i.far {
                    font-size: 20px;

                    &::before {
                        width: 20px;
                        height: 20px;
                    }
                }

                @media handheld, screen and (max-width: 768px) {
                    i.fas, i.fab, i.far {
                        font-size: 16px;

                        &::before {
                            width: 16px;
                            height: 16px;
                        }

                    }
                }

            }

        }

    }

}

.simotc-remise-radio-group {
    margin: 1rem 0;
    @extend %display-flex;
    flex-direction: row;
    align-items: center;

    .simotc-remise-radio-field {

        input[type="radio"][name="simotc-remise-radio"] {
            margin: 0;
            display: none;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            cursor: pointer;

            &:checked + label.simotc-remise-radio-label {
                @extend .btn-darkblue;
            }
        }

        label.simotc-remise-radio-label {
            margin: 0;

            @media handheld, screen and (max-width: 768px) {
                height: 32px;
                padding: 10px 6px;
            }

            span.hoverEffect {
                font-family: 'bentonsans-regular';
                letter-spacing: normal;
                font-size: medium;

                @media handheld, screen and (max-width: 768px) {
                    font-size: 17px;
                    font-family: 'bentonsans-condensedbold';
                }
            }
        }
    }
}

.ospharm-indication-clickable {
    @extend %display-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    input[type="checkbox"], label {
        position: relative;
        font-family: bentonsans-regular;
        font-size: 13px;
        text-align: left;
        margin: 0;
        font-weight: inherit;
        left: 0;
        top: 0;
    }
    input[type="checkbox"] {
        left: -20px;
    }
}

.simotc-calculator-compare {
    .selectric {
        .label, option {
            white-space: nowrap;
        }
    }
    .selectric-scroll {
        ul li {
            white-space: nowrap;
        }
    }
}
