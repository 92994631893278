.headband-reduced {
    padding: 15px 45px;
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;

    &-title {
        display: flex;
        align-items: center;

        &-icon {
            margin-right: 10px;
            font-size: 24px;
        }

        &-text {
            font-family: BentonSans-Medium, sans-serif;
            font-size: 20px;
        }
    }

    @media screen and (max-width: 480px) {
        &-title {
            justify-content: center;
        }
    }

    &-contact-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .contact-info {
            &-icon {
                margin: auto 7px;
                font-size: 14px;
            }

            &-name {
                font-family: Bentonsans-bold, sans-serif;
                font-size: 16px;
            }

            &-tel {
                padding: 9px 16px;
                background-color: #FFFFFF;
                border-radius: 25px;
                font-family: BentonSans-Medium, sans-serif;
                font-size: 14px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        &-contact-info {
            justify-content: space-evenly;
        }
    }
}

@media screen and (max-width: 480px) {
    .headband-reduced {
        flex-direction: column;
    }
}
